export const cache = {
    title: `Gestionnaire des caches E-compliance`,
    headers:{
        cacheName: `Nom du cache`,
        nbOccurences:`Nombre d'occurences`,
        size:`Taille du cache`
    },
    buttons:{
        clear:`Vider le cache`,
        clearAll:`Vider tous les caches`
    }
};
