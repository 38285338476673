import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

import _ from 'lodash';

import {User} from "../../../../../../common/class/user/user";
import {HabilitationService} from "../../../../../../common/service/auth/habilitation.service";
import {PasswordService} from "../../../../../../common/login/shared/password.service";
import {AuthService} from "../../../../../../common/service/auth/auth.service";
import {Account} from "../../../../../../common/class/user/account";
import {ROLE_ADMIN, TYPE_USER_CREATE_ACCOUNT_ADMIN} from "../../../../../../common/class/auth/habilitation";

import {AccountService} from "../../../../../../common/service/user/account.service";

import {ComplianceToastrService} from "../../../../../../common/service/toastr/compliance-toastr.service";
import {UserService} from "../../../../../../common/service/user/user.service";
import {APP_FRONT_OFFICE} from "../../../../../../common/class/compliance-constant";
import {BgForestComponent} from "../../../../../../common/class/background/bg-compliance";

@Component({
    selector: 'compliance-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent extends BgForestComponent implements OnInit, AfterViewInit {

    // @ViewChild(NgbTabset) ngbTabSet: NgbTabset;
    tabs:any = {
        idTabUser :'user',
        idTabUserList: 'user-list'
    };
    userId: string;
    typeUserToCreate: string;
    tab: string;
    user: User;
    account: Account;
    users: User[];
    accountFormStatus: any = {};
    userFormStatus: any = {};
    app: string = APP_FRONT_OFFICE;

    constructor(private route: ActivatedRoute, private router: Router, private userService: UserService, private accountService: AccountService,
                private habilitationService: HabilitationService, private toastr: ComplianceToastrService,
                private authService:AuthService, private passwordService: PasswordService) {
        super();
        this.userService.setTarget(this.app);
    }

    ngOnInit() {
        this.userId = this.route.snapshot.paramMap.get("userId");
        this.typeUserToCreate = this.route.snapshot.paramMap.get("type");
        this.tab = this.route.snapshot.paramMap.get("tab");
        if (this.userId) {
            this.findUserById();
        } else {
            this.user = new User();
            this.account= new Account();
        }

    }
    ngAfterViewInit(): void {
        let tabToSelect = this.tab || this.tabs.idTabUser
        // this.ngbTabSet.select(tabToSelect);
    }


    private findUserById() {
        this.userService.findUser(this.userId).then(user => {
            this.user = user;
            this.account =  _.cloneDeep(user.account);
            this.findUsersByAccount(user);
        });
    }

    setAccountFormValid(event) {
        this.accountFormStatus = {
            valid: event.valid,
            dirty: event.dirty
        };
        this.account = event.account;
    }

    setUserFormValid(event) {
        this.userFormStatus = {
            valid: event.valid,
            dirty: event.dirty
        };
    }

    isformValid(): boolean {
        return this.userFormStatus.valid && (this.accountFormStatus.valid || this.typeUserToCreate !== TYPE_USER_CREATE_ACCOUNT_ADMIN || this.habilitationService.isRoleAdmin());
    }

    gotBack() {
        this.router.navigate(['compliance/users'])
    }

    save() {
        console.log({
            user: this.user
        });
        if (this.typeUserToCreate === TYPE_USER_CREATE_ACCOUNT_ADMIN) {
            this.user.account = this.account;
            if (this.accountFormStatus.dirty) {
                this.accountService.saveAccount(this.user).then((data) => this.createAccountSuccess(data));
            } else if (this.userFormStatus.dirty) {
                this.userService.saveUser(this.user).then((user) => this.createSuccess(user));

            }else{
                this.toastr.successI18n('user.edit.success.save', 'user.edit.title');
            }

        } else {
            this.userService.saveUser(this.user).then((user) => this.createSuccess(user));
        }

    }

    private createSuccess(user: User) {
        this.user = user;

        if(!this.route.snapshot.paramMap.get("userId")) {
            this.router.navigate([`compliance/user/${this.typeUserToCreate}/${user.id}`]);
            this.toastr.successI18n('user.edit.success.create', 'user.edit.title');
        }else{
            this.toastr.successI18n('user.edit.success.save', 'user.edit.title');

        }

    }

    private createAccountSuccess(data: Account | User) {
        if(_.has(<User>data,'account')){
            // this.account = (data as User).account;
            this.createSuccess(data as User);
        }else {
            this.user.account = data as Account;
            this.findUsersByAccount(this.user);
            if (this.userFormStatus.dirty) {
                this.userService.saveUser(this.user).then((user) => this.createSuccess(user));
            } else {
                this.toastr.successI18n('user.edit.success.createAccount', 'user.edit.title');
            }
        }
    }

    private findUsersByAccount(user: User) {
        if (user.role.id === ROLE_ADMIN) {
            this.userService.findAllUsersByAccount(user.account.id).then(users => {
                // _.remove(users, {id:this.user.id});
                this.users = users;
            });
        }
    }

    getAccountId(): string {
        if (this.account && this.account.id) {
            return this.account.id;
        } else {
            return this.habilitationService.getConnectedUser().account.id;
        }
    }


    isDisabled() {
        return this.habilitationService.isRoleAdmin()
    }

    isDifferentOfConnectedUser():boolean{
        if(!this.userId) return false;
        return this.userId !== this.authService.getConnectedUser().id;
    }

    deleteUser(){
        if(this.user.id === this.authService.getConnectedUser().id){
            this.toastr.errorI18n("user.edit.user.error.delete.same");
        }else {
            this.userService.deleteUsers([this.user]).then(() => {
                this.toastr.successI18n('user.edit.success.delete');
                this.gotBack();
            })
        }
    }

    public resetPassword():void {
        this.passwordService.forgottenPassword(this.user.email).then(() => {
            this.toastr.successI18n('user.edit.success.resetPassword.msg', 'user.edit.success.resetPassword.title');
        });
    }

    changeTab(event){
        console.log(event);
        let activeTab = event.nextId;
        setTimeout(()=>{
            this.router.navigate([`compliance/user/${this.typeUserToCreate}/${activeTab}/${this.userId}`], {replaceUrl:true});
        });
    }
}
