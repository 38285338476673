import {Component} from '@angular/core';
import {Router} from "@angular/router";

import {LoginService} from "../service/auth/login.service";
import {AuthService} from "../service/auth/auth.service";
import {BgForestComponent} from "../class/background/bg-compliance";


@Component({
    selector: 'compliance-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BgForestComponent {

    constructor(private loginService: LoginService, private router: Router,
                private authService: AuthService) {
        super();
    }

    connection(loginForm: any): void {
        this.loginService.login({
            email: loginForm.email,
            password: loginForm.password
        }).then(() => {
            let url = this.authService.getRedirectUrl() || '/compliance/domain-choice';
            this.router.navigate([url]);
        });
    }

    forgotPassword(url: string): void {
        this.router.navigate([url]);
    }

}
