import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {environment} from './environments/environment';
import 'hammerjs';
import {hmrModule} from "@angularclass/hmr";
import {AppFrontOfficeModule} from "./app/app.module";


import Quill from "quill";

var Bold = Quill.import('formats/bold');
Bold.tagName = 'B';   // Quill uses <strong> by default
var Italic = Quill.import('formats/italic');
Italic.tagName = 'i';   // Quill uses <strong> by default
Quill.register(Bold, true);
Quill.register(Italic, true);



if (environment.production) {
    enableProdMode();
    if(window){
        window.console.log=function(){};
    }
}
const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppFrontOfficeModule);

if (environment.hmr) {
    if (module[ 'hot' ]) {
        bootstrap().then((ngModuleRef) => {
            return hmrModule(ngModuleRef, module);
        }).catch(err => console.log(err));
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
} else {
    bootstrap();
}
