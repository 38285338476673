import {Component, Input, OnInit} from '@angular/core';
import *as _ from 'lodash';
import {AffectationItem} from "../shared/affectation-item";
import {GraphField, TableItemExport} from "../shared/table-item";
import {ComplianceI18nLoader} from "../../../../../../common/class/i18n/compliance-i18n-loader";
import {AFFECTATION_COMPTE} from "../../../compliance/tva/control/affectation-compte/shared/lot-affectation";
import {ReportingService} from "../../../compliance/project/dashboard/tva-dashboard/reporting.service";
import {ActivatedRoute} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {NotificationService} from "../../../../../../common/service/push/notification.service";
import {FileUtilsService} from "../../../../../../common/service/file/file-utils.service";
import {TYPE_EXPORT, TypeExport} from "../../export-file/export-file";
import {Subject} from "rxjs";
import {v4 as uuid} from 'uuid';

@Component({
    selector: 'compliance-affectation',
    templateUrl: './affectation.component.html',
    styleUrls: ['./affectation.component.scss']
})
export class AffectationComponent extends ComplianceI18nLoader implements OnInit {
    private _uuid = uuid();
    @Input() graphField: GraphField[];
    @Input() export: TableItemExport;
    @Input() data: any;
    @Input() destroy$: Subject<boolean>;
    affectToShow: AffectationItem;
    affectationCompte = AFFECTATION_COMPTE;
    private projectId: string;
    private exerciceId: string;
    public exportLoading: boolean = false;

    svgStroke: any[] = [];

    constructor(private route: ActivatedRoute, private reportingService: ReportingService, private _notificationService: NotificationService,
                private _fileUtils: FileUtilsService) {
        super();
    }

    ngOnInit() {
        this.projectId = this.route.snapshot.paramMap.get("projectId");
        this.exerciceId = this.route.snapshot.paramMap.get("exerciceId");
        this.setAffectToShow();

        this.graphField.reduce((prev, current) => {
            let stroke = {
                'color': current.color,
                'value': _.get(this.data, current.field),
                'offset': prev.offset - prev.value
            };
            this.svgStroke.push(stroke);
            return stroke;
        }, {'offset': 25, 'value': 0});
        this._notificationService.fileNotificationObservable$
            .pipe(takeUntil(this.destroy$))
            .subscribe((msg) => {
                if (this._uuid == msg.uuid) {
                    let ecriture = this.export.ecriture;
                    this._fileUtils.saveFile(ecriture.fileName, this._fileUtils.blobPdfFromBase64String(msg.blob), new TypeExport(TYPE_EXPORT[ecriture.fileType]));
                    this.exportLoading = false;
                }
            });
    }

    setAffectToShow() {
        this.affectToShow = this.graphField.map((gf:GraphField): AffectationItem => {
            return {
                color: gf.color,
                label: super.getValue(gf.label),
                value: _.get(this.data, gf.field)
            };
        }).reduce((maxField, field) => maxField && maxField.value >= field.value ? maxField : field, null);

    }

    exportAction(event): void {
        event.stopPropagation();
        this.exportLoading = true;
        let ecriture = this.export.ecriture;
        this.reportingService.exportEcrituresAffectation(this.projectId, this.exerciceId, ecriture.codes[0], this.data['code'], this._uuid);
    }
}
