import {Injectable} from "@angular/core";
import {ParallelHasher} from 'ts-md5/dist/parallel_hasher';
import _ from 'lodash';
import moment from 'moment';
import {UploadInfo} from "../../class/upload/upload-info";
import {FILE_FORMAT_ARCHIVE, FILE_FORMAT_CSV, FILE_FORMAT_EDI, FILE_FORMAT_PDF, FILE_FORMAT_XML, FileStatus, FileType} from "../../class/compliance-constant";
import {FileService} from "./file.service";

import {v4 as uuid} from 'uuid';
import {AppConfigService} from "../app-config.service";
import {ARCHIVE_EXT} from "../../class/upload/file-constant";
import {Observable, Subject, Subscription} from "rxjs";
import {FileSaverService} from "ngx-filesaver";
import {TypeExport} from "../../../compliance-front-office/src/app/shared/export-file/export-file";
import {TranslateService} from "@ngx-translate/core";
import {ComplianceToastrService} from "../toastr/compliance-toastr.service";
import {NgxFileDropEntry} from "ngx-file-drop";
import {FileUpload} from "primeng/fileupload";
import XRegExp from 'xregexp';


@Injectable()
export class FileUtilsService {

    emitChangeSource = new Subject<UploadInfo[]>();
    changeEmitted$ = this.emitChangeSource.asObservable();
    showUploadInfoSubject = new Subject<boolean>();
    showUploadInfoObservable = this.showUploadInfoSubject.asObservable();


    updateUploadInfoSubject: Subject<UploadInfo> = new Subject<UploadInfo>();
    updateUploadInfoObservable: Observable<UploadInfo> = this.updateUploadInfoSubject.asObservable();
    updateUploadInfoSubscription: Subscription;

    uploadFiles: UploadInfo[] = [];
    resumablesMap: any = {};
    private showUploadInfo = false;

    constructor(private fileService: FileService, private appConfigService: AppConfigService, private fileSaverService: FileSaverService) {
    }

    generateId(file: any): string {
        let relativePath = file.webkitRelativePath || file.name; // Some confusion in different versions of Firefox
        let size = file.size;
        let lastModified = moment(file.lastModifiedDate);
        return (size + '-' + relativePath.replace(/[^0-9a-zA-Z_-]/img, '') + '-' + lastModified.valueOf());
    }

    hashFile(file: File): Promise<string> {
        let hasher = new ParallelHasher('assets/md5/md5_worker.js');
        return hasher.hash(file);
    }


    emitChanges(change: Array<UploadInfo>, showUploadInfo: boolean = true) {
        _.forEach(change, (file: UploadInfo) => {
            this.uploadFiles.push(file);
        });
        this.showUploadInfo = true;
        this.emitChangeSource.next(this.uploadFiles);
        if (showUploadInfo) {
            this.showUploadInfoSubject.next(this.showUploadInfo);
        } else {
            this.showUploadInfo = false;
        }
    }


    emitChange(change: UploadInfo) {
        this.emitChanges([change]);
    }


    emitUpdateUploadInfo(change: UploadInfo) {
        this.updateUploadInfoSubject.next(change);
    }

    showUploadInfoComponent() {
        this.showUploadInfo = !this.showUploadInfo;
        this.showUploadInfoSubject.next(this.showUploadInfo);
    }

    deleteAll() {
        this.uploadFiles = [];
        this.emitChangeSource.next(this.uploadFiles);
    }

    getFile(fileId): any {
        return _.find(this.uploadFiles, {fileId: fileId});
    }


    CSVImportGetHeaders(file: File): Promise<string> {
        console.time("CSVImportGetHeaders");
        return new Promise<string>((resolve, reject) => {
            // Instantiate a new FileReader
            let reader = new FileReader();

            // Read our file to an ArrayBuffer
            let blob = file.slice(0, 999);
            reader.readAsArrayBuffer(blob);
            // Handler for onloadend event.  Triggered each time the reading operation is completed (success or failure)
            reader.onloadend = function (evt) {
                // Get the Array Buffer
                let target = evt.target as any;
                let data = target.result;
                // Grab our byte length
                let byteLength = data.byteLength;
                // Convert to conventional array, so we can iterate though it
                let ui8a = new Uint8Array(data, 0);
                // Used to store each character that makes up CSV header
                let headerString = '';
                // Iterate through each character in our Array
                for (let i = 0; i < byteLength; i++) {
                    // Get the character for the current iteration
                    let char = String.fromCharCode(ui8a[i]);
                    // Check if the char is a new line
                    if (char.match(/[^\r\n]+/g) !== null) {
                        // Not a new line so lets append it to our header string and keep processing
                        headerString += char;
                    } else {
                        // We found a new line character, stop processing
                        break;
                    }
                }
                // Split our header string into an array
                console.timeEnd("CSVImportGetHeaders");
                resolve(headerString);
            };
        });
    }

    fileTestReader(file: File): Promise<any> {
        console.time("CSVImportGetHeaders");
        return new Promise<any>((resolve, reject) => {

            // Instantiate a new FileReader
            let reader = new FileReader();

            // Read our file to an ArrayBuffer
            reader.readAsText(file);
            // Handler for onloadend event.  Triggered each time the reading operation is completed (success or failure)
            reader.onloadend = function (evt) {

                resolve(reader.result);
            };
        });


    }

    public getExtension(fileName: string): string {
        return fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1);
    }

    public isExtensionAuthorized(file: File | any, authorizedExtention: string[] = []): boolean {
        const ext = this.getExtension(file.name);
        if (authorizedExtention && authorizedExtention.length > 0) {
            const index = authorizedExtention.findIndex((authExt: string) => {
                return authExt.toUpperCase() === ext.toUpperCase();
            });
            return index >= 0;
        }

        return true;
    }

    public isTypeAuthorized(file: UploadInfo, typeList: FileType[]) {
        if (file.type === FileType.LIASSE || file.type === FileType.ARCHIVE) {
            return false;
        }
        if (file.format === FILE_FORMAT_PDF) {
            return typeList.find(type => {
                return [FileType.CA3, FileType.DES, FileType.DEB_EXPEDITION, FileType.DEB_INTRODUCTION].includes(type);
            });
        }
        if (file.type) {
            return typeList.includes(file.type);
        }
        return true;
    }

    public isError(file: UploadInfo): boolean {
        return !!file.isError;
    }


    public hasError(uploadInfo: UploadInfo): boolean {
        return !!(uploadInfo.errors && uploadInfo.errors.length);
    }


    private cleanHeader(input: string) {
        let output = "";
        for (let i = 0; i < input.length; i++) {
            if (input.charCodeAt(i) <= 127) {
                output += input.charAt(i);
            }
        }
        return output;
    }

    private computeTypeAndFormat(change: UploadInfo): Promise<UploadInfo> {
        return new Promise<UploadInfo>((resolve, reject) => {
            let extension = this.getExtension(change.fileName);
            if (_.indexOf(ARCHIVE_EXT, extension) > -1) {
                change.type = FileType.ARCHIVE;
                change.format = FILE_FORMAT_ARCHIVE;
                resolve(change);
            } else {
                this.CSVImportGetHeaders(change.file).then(header => {
                    header = this.cleanHeader(header);
                    console.log("clean header fec", header);
                    const pdfReg: RegExp = new RegExp(/[%]PDF.*/);
                    const fecReg: RegExp = XRegExp(/(\bjournal[ _-]*code\b|\bjournal[ _-]*lib\b|\becriture[ _-]*num\b|\becriture[ _-]*date\b|\bcompte[ _-]*num\b|\bCompteLib\b|\bcomp[ _-]*aux[ _-]*num\b|\bcomp[ _-]*aux[ _-]*lib\b|\becriture[ _-]*lib\b|\bdebit\b|\bcredit\b|\bmontant\b|\bsens\b)/gmi);
                    const ediReg: RegExp = XRegExp("(UNB[+].*NAD[+]HP(.+?'))", 'gms');
                    if (pdfReg.test(header)) {
                        change.type = null;
                        change.format = FILE_FORMAT_PDF;
                        resolve(change);
                    } else if (fecReg.test(header)) {
                        if (_.uniq(header.match(fecReg)).length !== 11) {
                            change.status = FileStatus.ERROR_FEC;
                            change.errors = [
                                {
                                    filed: "",
                                    line: 1,
                                    message: "L'entête du fichier n'est pas correcte.",
                                    rejectedValue: null
                                }
                            ];
                        }
                        change.format = FILE_FORMAT_CSV;
                        change.type = FileType.FEC;
                        resolve(change);
                    } else if (ediReg.test(header)) {
                        change.format = FILE_FORMAT_EDI;
                        if (header.indexOf("DGI_EDI_TDFC") >= 0) {
                            change.type = FileType.LIASSE;
                        } else {
                            change.type = FileType.CA3;
                        }
                        resolve(change);
                    } else {
                        this.fileTestReader(change.file).then((text) => {
                            if (ediReg.test(text)) {

                                change.format = FILE_FORMAT_EDI;
                                if (text.indexOf("DGI_EDI_TDFC") >= 0) {
                                    change.type = FileType.LIASSE;
                                } else {
                                    change.type = FileType.CA3;
                                }
                            } else {
                                change.format = FILE_FORMAT_XML;
                                change.type = null;
                            }
                            resolve(change);
                        });
                    }
                });
            }
        });

    }

    public fileToUploadInfo(file: File, projectId, fileType: FileType = null): Promise<UploadInfo> {

        let change = this.fileToUploadInfoSimple(file, projectId, fileType);
        return this.computeTypeAndFormat(change);
    }

    public fileToUploadInfoSimple(file: File, projectId, fileType: FileType = null): UploadInfo {

        let fileId = uuid();
        let change = {
            fileId: fileId,
            uuid: fileId,
            file: file,
            fileName: file.name,
            progress: 0,
            error: false,
            uploading: false,
            dossierId: projectId,
            animateArchiveDiv: true,
            type: fileType,
            status: FileStatus.UPLOADING
        } as UploadInfo;
        change.isResumable = change.file.size >= this.appConfigService.config.chunkSize * 1024 * 1024;
        return change;
    }


    public saveFile(fileName: string, blob: Blob, typeExport: TypeExport) {
        fileName = fileName || "fichier";
        this.fileSaverService.save(blob, `${fileName}.${typeExport.type.extention}`)
    }

    public blobPdfFromBase64String(base64String: string): Blob {
        const byteArray = Uint8Array.from(
            atob(base64String)
                .split('')
                .map(char => char.charCodeAt(0))
        );
        return new Blob([byteArray], {type: 'application/xlsx'});
    }

    private exdploitUnauthorizedFiles(unauthorizedFiles: string[], translate: TranslateService, toastr: ComplianceToastrService) {
        if (unauthorizedFiles.length > 0) {
            let title = translate.instant('upload.errorExtention');
            let error = `<ul>`;
            _.forEach(unauthorizedFiles, (file) => {
                error += `<li>${file}</li>`
            });
            error += `</ul>`;
            toastr.error(error, title);
        }
    }

    ngxFileChange({fileList, authorizedExtention = [], upload, translate, toastr}: { fileList: NgxFileDropEntry[], authorizedExtention?: string[], upload: (file: File) => void, translate: TranslateService, toastr: ComplianceToastrService }) {
        let unauthorizedFiles: string[] = [];
        for (let file of fileList) {

            let fe: any = file.fileEntry;
            if (fe.file) {
                if (this.isExtensionAuthorized(file.fileEntry, authorizedExtention)) {
                    fe.file(fileData => {
                        upload(fileData);
                    });
                } else {
                    unauthorizedFiles.push(file.fileEntry.name);
                }
            }

        }
        this.exdploitUnauthorizedFiles(unauthorizedFiles, translate, toastr);

    }

    uploadHandler({fileList, authorizedExtention = [], upload, translate, toastr, fileUpload}: { fileList: File[], authorizedExtention?: string[], upload: (file: File) => void, translate: TranslateService, toastr: ComplianceToastrService, fileUpload?: FileUpload }) {
        let unauthorizedFiles: string[] = [];

        for (let file of fileList) {
            if (file instanceof File) {
                if (this.isExtensionAuthorized(file, authorizedExtention)) {
                    upload(file);
                } else {
                    unauthorizedFiles.push(file.name);
                }
            }
        }
        this.exdploitUnauthorizedFiles(unauthorizedFiles, translate, toastr);
        if(fileUpload) {
            fileUpload.clearInputElement();
            fileUpload.clear();
        }
    }
}
