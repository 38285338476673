import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectSirenComponent} from './project-siren/project-siren.component';
import {ProjectSirenRoutingModule} from "./project-siren-routing.module";
import {ProjectFormComponent} from './project-form/project-form.component';
import {ComplianceSharedModule} from "../../../../../common/compliance-shared.module";
import {UploadFileModule} from "../../shared/upload-file/upload-file.module";
import {ProjectListComponent} from './project-list/project-list.component';
import {ExerciceCreateComponent} from './exercice/exercice-create/exercice-create.component';
import {GestionFileComponent} from './gestion-file/gestion-file.component';
import {TableFileDateComponent} from './gestion-file/table-file-date/table-file-date.component';
import {ExerciceSupervisionComponent} from "./exercice/exercice-supervision/exercice-supervision.component";
import {ProjectBlockComponent} from "./project-form/project-block/project-block.component";
import { ManualCa3Component } from './gestion-file/edit/ca3/manual-ca3.component';

@NgModule({
    imports: [
        CommonModule,
        ComplianceSharedModule,
        ProjectSirenRoutingModule,
        UploadFileModule
    ],
    declarations: [
        ProjectSirenComponent,
        ProjectFormComponent,
        ProjectBlockComponent,
        ProjectListComponent,
        ExerciceCreateComponent,
        ExerciceSupervisionComponent,
        GestionFileComponent,
        TableFileDateComponent,
        ManualCa3Component
    ],
    exports:[ProjectListComponent],
    providers: []
})
export class ProjectModule {
}
