<div id="content" class="page-folders" *ngIf="projects && projects.length">
    <div class="container">
        <div class="row">
            <div class="col col-xl-12">
                <div class="block-group-title">
                    <h2 class="title big">
                        {{'project.list.title' | translate}}
                        <button class="btn btn-heroic-secondary btn-small btn-circle mb-2 ml-3"
                                pTooltip="{{'buttons.camera' | translate}}" tooltipPosition="bottom"
                                [routerLink]="['/compliance', domain, category, 'video', 'utilisation_generale']">
                            <i class="fal fa-film-alt text-white text-size-14"></i>
                        </button>
                    </h2>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col col-xl-12 d-flex justify-content-between">
                <div class="folder-usage">
                    <div class="text-size-12 mb-2">{{useFile }}</div>
                    <p-progressBar [value]="getFolderUsage()"
                                   [showValue]="false"
                                   [style]="{'height': '10px'}"
                                   [ngClass]="{'overmax-usage': getFolderUsage() > 100}"></p-progressBar>
                </div>
                <form action="#" class="form-basic form-search">
                    <div class="form-group search-item">
                        <input type="text" class="form-control" id="search" name="search" (keyup)="filterProject()" [(ngModel)]="filter" placeholder="{{'project.list.search.placeholder' | translate}}">
                        <button class="btn btn-primary d-flex" (click)="filterProject()"><i class="fas fa-search m-auto"></i></button>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col col-xl-12">
                <div class="list-folders">
                    <div class="row" style="margin-bottom: 30px;">
                        <div class="folder-container col-12 col-lg-6 col-xl-4" [ngClass]="{deleting:project.deleting}"
                             *ngFor="let project of projectsToDisplay">
                            <div class="folder" (click)="goToProject(project)">
                                <ngx-loading [show]="project.deleting"></ngx-loading>
                                <div class="folder-delete text-white" (click)="deleteProject($event, project)">
                                    <i class="action-hover far fa-trash-alt"></i>
                                </div>
                                <p class="name">{{project.name}} <span>SIREN : {{project.code | siren }}</span></p>
                                <div class="panel">
                                    <br/>
                                    <p class="span-1">{{project.name}}</p>
                                    <!--<ul class="slider">-->
                                    <!--<li><a href="#">2012</a></li>-->
                                    <!--<li><a class="active" href="#">2013</a></li>-->
                                    <!--<li><a href="#">2014</a></li>-->
                                    <!--<li><a href="#">2015</a></li>-->
                                    <!--<li><a href="#">2016</a></li>-->
                                    <!--<li><a href="#">2017</a></li>-->
                                    <!--</ul>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="block-bottom-actions">
        <div class="inner">
            <a href="#" [routerLink]="['/compliance/home']" class="btn btn-primary btn-back"><span>{{'buttons.back' | translate}}</span></a>
            <a href="#" (click)="alertProjectMaxNumber()" class="btn btn-primary align-center"><span>{{'project.list.create' | translate}}</span></a>
        </div>
    </div>
</div>
