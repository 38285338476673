<div class="container-fluid w-70 mx-auto page-question height-100">
    <ng-template complianceQuestion></ng-template>

    <p-dialog [(visible)]="display" [modal]="true" [closable]="modalData.closeIcon" [closeOnEscape]="modalData.autoClose" [dismissableMask]="modalData.autoClose"
              [style]="{widht:'600px'}" [resizable]="false" [draggable]="false" [blockScroll]="true" [styleClass]="'modal-question'">
        <p-header>
            <span [innerHtml]="modalData.header?.text | translate:modalData.header?.params | safe:'html'"></span>
        </p-header>
        <div [innerHtml]="modalData.content?.text | translate:modalData.content?.params | safe:'html'" class="modal-main-content"></div>
        <p-footer *ngIf="modalData.buttons && modalData.buttons.length">
            <button class="btn mx-2  btn-sm" *ngFor="let button of modalData.buttons" [ngClass]="{'btn-primary':!button.secondary, 'btn-heroic-secondary':button.secondary }" (click)="actionModal(button.actions)">
                <i *ngIf="button.text.icon" [ngClass]="button.text.icon"></i>{{button.text.label | translate}}
            </button>
        </p-footer>
    </p-dialog>

</div>
