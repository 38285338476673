import {
    Component,
    ElementRef,
    EventEmitter,
    Input, OnInit, Optional,
    Output,
    Renderer2, Self
} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Tooltip} from "primeng/tooltip";

@Component({
    selector: 'compliance-light-subscription-button',
    templateUrl: './light-subscription-button.component.html',
    styleUrls: ['./light-subscription-button.component.scss']
})
export class LightSubscriptionButtonComponent implements OnInit {
    insideClass: string;
    @Input('class')
    set class(className: string) {
        this.renderer.removeAttribute(this.elementRef.nativeElement, 'class');
        this.insideClass = className;
    }

    insideDisabled: boolean;
    @Input('disabled')
    set disabled(disabled: boolean) {
        this.insideDisabled = disabled;
        if (this._tooltip) {
            this._tooltip.text = this.insideDisabled ? (this._tooltipDisabledText?this._tooltipDisabledText:this._tooltipLightText ): this._tooltipText;
        }
    }
    get disabled(): boolean {
        return false;
    }

    private _tooltipText: string;
    private _tooltipLightText: string;
    @Input('pTooltip')
    set tooltipText(tooltipText: string) {
        this._tooltipText = tooltipText;
    }
    get tooltipText(): string {
        return this.insideDisabled ? this._tooltipLightText : this._tooltipText;
    }

    private _tooltipDisabledText: string;

    @Input('tooltipDisabledText')
    set tooltipDisabledText(tooltipText: string) {
        this._tooltipDisabledText = tooltipText;
        if (this._tooltip) {
            this._tooltip.text = this.insideDisabled ? (this._tooltipDisabledText?this._tooltipDisabledText:this._tooltipLightText ): this._tooltipText;
        }
    }
    get tooltipDisabledText(): string {
        return this._tooltipDisabledText;
    }

    private _tooltip: Tooltip;


    @Output() click = new EventEmitter();

    constructor(private renderer: Renderer2, private elementRef: ElementRef,private _translate: TranslateService, @Self() @Optional() private tooltip: Tooltip) {
        this._translate.get('user.account.notPremium').subscribe((translatedText) => {
            this._tooltipLightText = translatedText;
        });
    }


    ngOnInit(): void {
        if (this.tooltip) {
            this.tooltipText = this.tooltip.text;
            this.tooltip.text = this.insideDisabled ? (this._tooltipDisabledText?this._tooltipDisabledText:this._tooltipLightText ): this._tooltipText;
            this._tooltip = this.tooltip;
        }
    }

    clickEvent($event) {
        // this.click.emit($event);
    }

}
