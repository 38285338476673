import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {ComplianceComponent} from "../../compliance.component";
import {AuthGuard} from "../../../shared/auth/auth.guard";
import {DashboardComponent} from "./dashboard/dashboard.component";

const routes: Routes = [
    {
        path: 'compliance',
        component: ComplianceComponent,
        canActivateChild: [AuthGuard],
        children:
            [
                {
                    path: ':domain/:category/project',
                    canActivateChild: [AuthGuard],
                    children:
                        [
                            {
                                path: ':projectId',
                                children: [
                                    {
                                        path: 'dashboard',
                                        component: DashboardComponent
                                    },
                                    {
                                        path: ':exerciceId/dashboard',
                                        component: DashboardComponent
                                    }
                                ]
                            }
                        ]
                }
            ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule {
}
