import {Component, OnDestroy, OnInit} from '@angular/core';
import {BgSpaceComponent} from "../../../../../../common/class/background/bg-compliance";
import {ActivatedRoute, Router} from "@angular/router";
import {ExerciceService} from "../../../../../../common/service/exercice/exercice.service";
import {FileService} from "../../../../../../common/service/file/file.service";
import {UploadFile} from "../../../../../../common/class/upload/upload-file";
import {UploadActionType} from "../../../../../../common/class/upload/upload-constantes";
import {DATE_FORMAT, FILE_TYPES, FileType} from "../../../../../../common/class/compliance-constant";
import {Exercice} from "../../../../../../common/class/exercice/exercice";
import _ from 'lodash';
import {UploadInfo} from "../../../../../../common/class/upload/upload-info";
import moment from "moment";
import {ConfirmationService} from "primeng/api";
import {NotificationService} from "../../../../../../common/service/push/notification.service";
import {ComplianceLoaderService} from "../../../../../../common/service/loader/compliance-loader.service";
import {TvaSubscriptionService} from "../../../../../../common/service/subscriptions/tva-subscription.service";
import {FlecTVASubscription} from "../../../../../../common/class/subscription/subscription";
import {HelpService} from "../../../shared/export-file/help.service";
import {ViesService} from "../../../shared/upload-file/drag-n-drop/shared/vies.service";
import {Subject, Subscription} from "rxjs";
import {FileSaverService} from "ngx-filesaver";
import {FileUtilsService} from "../../../../../../common/service/file/file-utils.service";
import {takeUntil} from "rxjs/operators";
import {HabilitationService} from "../../../../../../common/service/auth/habilitation.service";

@Component({
    selector: 'compliance-gestion-file',
    templateUrl: './gestion-file.component.html',
    styleUrls: ['./gestion-file.component.scss']
})
export class GestionFileComponent extends BgSpaceComponent implements OnInit, OnDestroy {

    domain: string;
    category: string;
    private projectId: string;
    private exerciceId: string;
    public uploadAction: UploadActionType = UploadActionType.COMPLIANCE_FILE;
    private subscriptions: Subscription[] = [];

    public fileTypeEnum = FileType;
    uploadFiles: UploadFile[];
    exercice: Exercice;
    fileByType: { [key: string]: UploadFile[] };
    userSubscription: FlecTVASubscription;
    deletingByType: { [key: string]: boolean };
    extractingViesAll: boolean;
    private typeList = [FileType.FEC, FileType.CA3, FileType.DEB_INTRODUCTION, FileType.DEB_EXPEDITION]; // TODO FileType.DES to add later
    types: { show: boolean, type: string, newFile?: boolean }[];
    private compare: boolean;
    private fileTypes = FILE_TYPES;

    private modalHeader: string;
    private modalContent: string;
    private wording: any;
    private _fileUuid: string;
    display = false;

    destroy$: Subject<boolean> = new Subject<boolean>();
    hasVieControl: boolean;
    toolTipVie: string;

    constructor(private _route: ActivatedRoute, private _router: Router, private _notificationService: NotificationService,
                private _exerciceService: ExerciceService, private _fileService: FileService, private _confirmationService: ConfirmationService,
                private _complianceLoaderService: ComplianceLoaderService, private _userSubscriptionservice: TvaSubscriptionService,
                private _helpService: HelpService, private _viesService: ViesService, private _fileSaverService: FileSaverService,
                private _fileUtils: FileUtilsService, private _habilitationService: HabilitationService) {
        super();
        this.types = [];
        this.fileByType = {};
        this.deletingByType = {};
        this.typeList.forEach((type) => {
            this.types.push({show: false, type: type});
            this.fileByType[type] = [];
            this.deletingByType[type] = false;
        });
    }

    ngOnInit() {
        // this._notificationService.initializePush();
        this.domain = this._route.snapshot.paramMap.get('domain');
        this.category = this._route.snapshot.paramMap.get('category');
        this.projectId = this._route.snapshot.paramMap.get('projectId');
        this.exerciceId = this._route.snapshot.paramMap.get('exerciceId');
        this._fileUuid = this._route.snapshot.queryParamMap.get('uuid');
        this.compare = false;
        this.getUserSubscription();
        this.getFiles();
        this.getExercice();
        this.wording = super.getValue("gestionFile");
        this.hasVieControl = this._habilitationService.hasAccessControlVie();
        this._notificationService.fileNotificationObservable$.pipe(takeUntil(this.destroy$)).subscribe((msg) => {
            const fileName = moment().format("YYYYMMDD_HHMMSS") + "_vies.xlsx";
            this._fileSaverService.save(this._fileUtils.blobPdfFromBase64String(msg.blob), fileName);
            let allFiles = this.fileByType["DEB_EXPEDITION"];
            let files = allFiles.filter(value => msg.sourceIds.includes(value.id));
            _.forEach(files, (uf: UploadFile) => {
                uf.activeLoader = false;
                this.deletingByType[uf.type] = false;
            });
            if (allFiles.length == files.length) {
                this.extractingViesAll = false
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


    async getUserSubscription() {
        this.userSubscription = await this._userSubscriptionservice.getUserSubscription();
    }

    async getFiles() {
        let oldValues = _.cloneDeep(this.uploadFiles);
        this.uploadFiles = await this._fileService.findFiles(this.projectId, this.exerciceId);
        this.typeList.forEach((type) => {
            this.fileByType[type] = [];
        });
        this.uploadFiles.forEach((uf: UploadFile) => {
            if (this.typeList.indexOf(uf.type as FileType) >= 0) {
                console.log(uf);
                let debutPeriode = moment(uf.debutPeriode, DATE_FORMAT.YYYYMMDD.moment);
                let finPeriode = moment(uf.finPeriode, DATE_FORMAT.YYYYMMDD.moment);
                uf.debutPeriodeDate = debutPeriode.isValid() ? debutPeriode.toDate() : null;
                uf.finPeriodeDate = finPeriode.isValid() ? finPeriode.toDate() : null;
                if (this.compare) {
                    let idx = _.findIndex(oldValues, (val: UploadFile) => val.id === uf.id);
                    let type = _.find(this.types, {type: uf.type});
                    if (idx < 0 && uf.status === 'EXTRACTED') {
                        uf.new = true;
                        type.newFile = true;
                        type.show = true;
                    }
                }
                if (uf.uid === this._fileUuid) {
                    let type = _.find(this.types, {type: uf.type});
                    uf.new = true;
                    type.show = true;
                }

                this.fileByType[uf.type].push(uf);
                this.deletingByType[uf.type] = false;
            }
        });

        this.compare = true;

        this.toolTipVie = super.getValue("gestionFile.table.export.vies") ;

        if(!this.hasVieControl){
            this.toolTipVie = this.toolTipVie+"</br></br>" + super.getValue("user.account.notPremium");
        }else if(!this.fileByType[FileType.DEB_EXPEDITION].length){
            this.toolTipVie = this.toolTipVie+"</br></br>" + super.getValue("control.vies.nofFileTooltipText");
        }
    }

    async getExercice() {
        this.exercice = await this._exerciceService.findExercicesById(this.exerciceId);
    }

    goBack() {
        this._router.navigate(['/compliance', this.domain, this.category, 'project', this.projectId, this.exerciceId, 'dashboard']);
    }

    showFiles($event, idx: number) {
        console.log($event);
        $event.stopPropagation();
        let type = this.types[idx];
        if (this.isFilesDisabled(type)) {
            return;
        }
        type.show = !type.show;
    }

    deleteAll($event, type: { show: boolean; type: string }) {
        $event.stopPropagation();

        let i18n = super.getTranslation();

        if (this.fileByType && this.fileByType[type.type] && this.fileByType[type.type].length > 0) {
            if (type.type === FileType.FEC.toString()) {
                this.modalHeader = this.wording.ModalDeleting.headers.deleteAll;
                this.modalContent = this.wording.ModalDeleting.contents.deleteAll.part1FEC + this.exercice.annee + this.wording.ModalDeleting.contents.deleteAll.part2;
            } else {
                this.modalHeader = this.wording.ModalDeleting.headers.deleteAll;
                this.modalContent = this.wording.ModalDeleting.contents.deleteAll.part1Declarations + this.exercice.annee + this.wording.ModalDeleting.contents.deleteAll.part2;
            }
            this._confirmationService.confirm({
                acceptVisible: true,
                acceptLabel: i18n.buttons.confirm,
                rejectVisible: true,
                rejectLabel: i18n.buttons.cancel,
                header: this.modalHeader,
                message: this.modalContent,
                accept: () => {
                    this.deletingByType[type.type] = true;
                    $event.stopPropagation();
                    this.deleteFiles(this.fileByType[type.type]);
                }
            });
        }
    }

    deleteFile(file: UploadFile) {
        let i18n = super.getTranslation();

        if (this.fileByType && this.fileByType[file.type] && this.fileByType[file.type].length > 0) {
            this.modalHeader = this.wording.ModalDeleting.headers.deleteOne;
            this.modalContent = this.wording.ModalDeleting.contents.deleteOne.part1 + file.fileName + this.wording.ModalDeleting.contents.deleteOne.part2;

            this._confirmationService.confirm({
                acceptVisible: true,
                acceptLabel: i18n.buttons.confirm,
                rejectVisible: true,
                rejectLabel: i18n.buttons.cancel,
                header: this.modalHeader,
                message: this.modalContent,
                accept: () => {
                    this.deleteFiles([file]);
                }
            });
        }
    }

    private async deleteFiles(files: UploadFile[]) {
        let ids = [];
        _.forEach(files, (uf: UploadFile) => {
            uf.deleting = true;
            ids.push(uf.id);
        });
        await this._fileService.deletefiles(this.projectId, this.exerciceId, ids);
        _.forEach(files, (uf: UploadFile) => {
            this.deletingByType[uf.type] = false;
        });

        this.getFiles();
    }

    exportViesAll($event, type: { show: boolean; type: string }) {
        $event.stopPropagation();

        if (!this.extractingViesAll && this.fileByType && this.fileByType[type.type] && this.fileByType[type.type].length > 0 && this.hasVieControl) {
            this.extractingViesAll = true;
            this.exportVies(this.fileByType[type.type], true);
        }
    }

    exportViesFile(file: UploadFile) {
        if(this.hasVieControl) {
            this.exportVies([file]);
        }
    }

    private async exportVies(files: UploadFile[], all = false) {
        let ids = [];
        _.forEach(files, (uf: UploadFile) => {
            uf.activeLoader = true;
            ids.push(uf.id);
        });
        this._viesService.export(this.projectId, this.exerciceId, ids);
    }

    uploadEnd(val: UploadInfo) {
        console.log(val);
        // TODO check if this part of code is always needed.
        val.progress = 0;
        let type = _.find(this.types, (s) => s.type === val.type);
        type.newFile = true;
        this.getFiles();
    }

    selectAll($event: any, type: string) {
        console.log($event);
        let checked = $event.target.checked;
        _.forEach(this.fileByType[type], (uf: UploadFile) => {
            uf.selected = checked;
        });
    }

    atLeastOneSelected(type: string) {
        return _.findIndex(this.fileByType[type], (uf: UploadFile) => uf.selected) >= 0;
    }

    editCa3() {
        this._router.navigate(['/compliance', this.domain, this.category, 'project', this.projectId, this.exerciceId, 'file-list', 'edit-ca3']);
    }

    editFile(file: UploadFile) {
        this._router.navigate(['/compliance', this.domain, this.category, 'project', this.projectId, this.exerciceId, 'file-list', 'edit-ca3', file.id]);
    }

    isFilesDisabled(file) {
        if (this.userSubscription && this.userSubscription.authorizedFiles) {
            return !this.userSubscription.authorizedFiles.find(data => data === file.type);
        }
        return false;
    }

    showHelp() {
        this.display = true
    }

    downloadCa3Help() {
        this._helpService.downloadCa3Help();
    }

    downloadDebIntroHelp() {
        this._helpService.donwloadDebIntroductionHelp();
    }

    downloadDebExpeHelp() {
        this._helpService.donwloadDebExpeditionHelp();
    }
}
