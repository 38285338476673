import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'siren'
})
export class SirenPipe implements PipeTransform {

    transform(value: string, args?: any): any {
        return value.replace(/(\d{3})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
    }

}
