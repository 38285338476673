<div class="fixed-top menu">
    <!--<compliance-menu [menus]="menus" [uploadStatus]="true"></compliance-menu>-->
    <compliance-header [menus]="menus" [uploadStatus]="true" [typeContent]="typeContent"></compliance-header>
</div>

<div class="compliance-app height-100 mt-0">
    <ngx-loading [show]="complianceLoaderService.$loader | async"></ngx-loading>
    <router-outlet></router-outlet>
</div>
<!--<compliance-upload-info ></compliance-upload-info>-->
