import {Graphe} from "../../../../../../../../common/class/graphique/graphique";
import {BlocText} from "../../../../../../../../common/class/text/bloc-text";
import {TableItem} from "../../../../../shared/table/shared/table-item";
import * as _ from "lodash";
import {ExportType} from "../../../../../shared/export-file/export-file";

export enum DispositionType {
    ROW = "row",
    COLUMN = "column"
}

export class KeyNumberBlock {
    block: BlocText;
}

export class TableBlock {
    tableField: string;
    metaDataRef: string;
    title?: string;
    data?: any[];
    order?: string;
    footer?: any;
    // tableItems: TableItem[];
}

export class BlockExportTableOption {
    metaDataRef: string;
    tableField: string;
    headers: string[];
    footer: any;
}

export class ExportBlock {
    type: ExportType[];
    tableOption?: BlockExportTableOption;
    fileName: string;
    code?: string;
    label?:string;
}

export class DataBlock {
    tableBlock?: TableBlock;
    keyNumberBlock?: BlocText;
    graphe?: Graphe;
    blocText?: BlocText;
    exportBlock?: ExportBlock;
    options: {
        style: { [keys: string]: string },
        class?: string[]
    };
}

export class CRDBlock {
    dispositionBlock?: DispositionBlock;
    dataBlock?: DataBlock;
}


export class DispositionBlock {
    ratio?: number;
    disposition?: DispositionType;
    blocks: CRDBlock [];

    constructor(val: DispositionBlock = {blocks: []}) {
        _.merge(this, val);
        this.ratio = this.ratio || 1;
        this.disposition = this.disposition || DispositionType.ROW;
    }
}

export class CompteRenduDetailleItem {
    title?: string;
    dispositionBlocks: DispositionBlock[];
}

export class CompteRenduDetailleDescriber {
    tableItems: { [keys: string]: TableItem[] };
}

export class CompteRenduDetaille {
    title: string;
    compteRenduDetailleItems: CompteRenduDetailleItem[];
    controlResult: any;
    describer: CompteRenduDetailleDescriber;
}
