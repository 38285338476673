import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from "@angular/common";

@Pipe({
    name: 'complianceCurrencyOrString'
})
export class ComplianceCurrencyOrStringPipe implements PipeTransform {
    constructor(private _currencyPipe: CurrencyPipe) {
    }

    transform(value: any, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string | null {
        if (isNaN(value)) {
            return value;
        }
        return this._currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
    }
}
