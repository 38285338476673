import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";
import {NotificationMessage} from "../../class/polling/notification-message";
import {ComplianceToastrService} from "../toastr/compliance-toastr.service";
import {FileType} from "../../class/compliance-constant";
import {Observable, Subject, Subscription} from "rxjs";

@Injectable()
export class NotificationMessageService extends ComplianceApiService {
    private pollObservable: Observable<number>;
    private pollSubscription: Subscription;
    public messagesSubject: Subject<NotificationMessage[]> = new Subject<NotificationMessage[]>();
    public messagesObservable: Observable<NotificationMessage[]> = this.messagesSubject.asObservable();

    constructor(private restService: RestService, private toaster: ComplianceToastrService) {
        super('controlMessage', 'api');
    }

    cleanUploadMessages(projectId: string, type: FileType) {
        this.restService.delete({
            url: this.baseUri,
            relativeUri: "clean/upload",
            parameters: [projectId, type.toString()],
            arg: null
        });
    }

    deleteMessage(messageId: string) {
        this.restService.delete({
            url: this.baseUri,
            parameters: [messageId],
            arg: null
        });
    }
}
