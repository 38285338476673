import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ComplianceComponent} from "./compliance.component";
import {AuthGuard} from "../shared/auth/auth.guard";
import {AideComponent} from "./aide/aide.component";
import {CgvuComponent} from "./cgvu/cgvu.component";
import {VersionCompleteComponent} from "./version-complete/version-complete.component";


const routes: Routes = [
    {
        path: 'compliance',
        component: ComplianceComponent,
        children:
            [
                {
                    path: 'aide',
                    component: AideComponent,
                    data: {
                        title: "breadcrumb.help"
                    }
                },
                {
                    path: 'version-complete',
                    component: VersionCompleteComponent,
                    canActivate: [AuthGuard],
                    data: {
                        title: "breadcrumb.versionComplete"
                    }
                },
                {
                    path: 'cgvu',
                    component: CgvuComponent,
                    canActivateChild: [AuthGuard],
                    data: {
                        title: "breadcrumb.cgvu"
                    }
                }
            ]
    }]
;


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ComplianceRoutingModule {
}
