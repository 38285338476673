import {Injectable} from '@angular/core';
import {ConfirmationService} from "primeng/api";
import {ComplianceI18nLoader} from "../../class/i18n/compliance-i18n-loader";

@Injectable()
export class ConfirmDeleteService extends ComplianceI18nLoader {

    constructor(private confirmationService: ConfirmationService) {
        super();
    }

    confirmDelete(message: string, header: string, deleteFunc: () => void, reject?: () => void) {
        let i18n = super.getTranslation();

        this.confirmationService.confirm({
            acceptVisible: true,
            acceptLabel: i18n.buttons.confirm,
            rejectVisible: true,
            rejectLabel: i18n.buttons.cancel,
            message: message,
            header: header,
            accept: deleteFunc,
            reject: () => {
                if (reject) {
                    reject();
                }
            }
        });
    }

}
