<h1>{{'dashboard.graph.pie.title' | translate}}</h1>
<div id="chart-container" class="d-flex">
    <p-chart #chartPie type="doughnut"
             class="col-6"
             [data]="pieGraph"
             [options]="pieOption">
    </p-chart>
    <div id="chartjs-legend" class="noselect col-6">
        <ul class="legend">
            <li *ngFor="let data of pieGraph.datasets[0]?.data; let i = index"
                (click)="setArea(i)">
                <span class="badge-legend" [style.backgroundColor]="pieGraph.datasets[0].backgroundColor[i]"></span>
                <br/>
                <span class="text-legend">{{pieGraph.labels[i]}}</span>
            </li>
        </ul>
    </div>
</div>
