<p-dialog [(visible)]="display" [modal]="true" [closable]="true" [closeOnEscape]="true" [dismissableMask]="false"
          [style]="{width:'600px'}" [resizable]="false" [draggable]="false" [blockScroll]="true" [styleClass]="'modal-question'">
    <p-header>
        <span class="text-size-16">{{'dashboard.tvaDashboard.modalReport.header' | translate}}</span>
    </p-header>
    <div class="modal-main-content check">
        <form [formGroup]="formGroup" novalidate *ngIf="formGroup">
            <div *ngFor="let element of reportElement" class="form-check abc-checkbox abc-checkbox-info abc-checkbox-circle my-3">
                <input type="checkbox" class="form-check-input" [attr.id]="element.code" [formControlName]="element.code"/>
                <label [attr.for]="element.code" class="form-check-label text-size-12"><span class=" ml-3">{{('report.elements.' + element.code) | translate}}</span></label>
            </div>
        </form>

    </div>
    <p-footer>
        <div class="row">
            <div class="col-6 text-left d-flex my-auto"><span class="text-size-12">{{'dashboard.tvaDashboard.modalReport.footer' | translate}}</span></div>
            <div class="col-6 text-right">
                <button (click)="export('docx')" [disabled]="!activateButtons()" class="btn btn-heroic-secondary mr-2 btn-report">
                    <i class="fas fa-file-word text-size-16 mr-3"></i>
                    <span class="text-size-12">Word</span>
                </button>
                <button (click)="export('pdf')" [disabled]="!activateButtons()" class=" btn btn-primary ml-2 btn-report">
                    <i class="fas fa-file-pdf text-size-16 mr-3"></i>
                    <span class="text-size-12">PDF</span>
                </button>
            </div>
        </div>

    </p-footer>
</p-dialog>
