import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'compliance-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    @Input() backAdress: string;
    @Input() isCallback: boolean;
    @Output() backEvent:EventEmitter<any> = new EventEmitter<any>();

    constructor( private router: Router) {
    }

    ngOnInit() {
    }

    goBack(){
        if(this.isCallback){
            this.backEvent.emit(true);
        }
        if(this.backAdress){
            this.backEvent.emit(this.backAdress);
            this.router.navigate([this.backAdress]);
        }
    }

}
