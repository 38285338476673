<div class="drop-zone action-hover {{lot.lot.display.type}}" (click)="selectLot($event)"
     [ngClass]="{'active':lot.lot.active}">
    <div class="in d-flex justify-content-start align-items-center flex-{{direction}}">
        <div class="nb m-3 badge-circle-lg {{lot.lot.display.type}}" [ngStyle]="badgeStyle"
             [ngClass]="{'colored':lot.lot.display.color}">{{lot.data.length}}</div>
        <div class="label flex-fill text-center px-2"
             [innerHtml]="lot.lot.display.lotLabel[getSize()] | translate | safe:'html'"
             pTooltip="{{getTitle() | translate}}" [tooltipDisabled]="!lot.lot.display.lotTitle" [escape]="false" tooltipPosition="top">
            </div>
        <a href="#" class="trash" [innerHtml]="'affectation.lots.clean' | translate | safe:'html'"
           (click)="clean($event)"></a>
    </div>
</div>
