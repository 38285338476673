import {Directive, ElementRef, HostListener, Input} from "@angular/core";

@Directive({
    selector: 'a',
})
export class MyInhertLink {
    @Input() href;

    constructor(private el: ElementRef) {
    }

    @HostListener('click', ['$event']) onclick(event) {
        let href = this.href || this.el.nativeElement.href.replace(this.el.nativeElement.baseURI, "");
        console.log('click on a link, relative uri', href);
        if (!href || href == 0 || href === '#') event.preventDefault();
    }
}
