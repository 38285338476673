import {Component, OnDestroy, OnInit} from '@angular/core';
import {BgForestComponent} from "../../../../../../common/class/background/bg-compliance";
import {DossierService} from "../../../../../../common/service/dossier/dossier.service";
import {Dossier} from "../../../../../../common/class/dossier/dossier";
import {ActivatedRoute, Router} from "@angular/router";
import * as _ from "lodash";
import {CacheService} from "../../../../../../common/service/cache/cache.service";
import {ConfirmationService} from "primeng/api";
import {AuthService} from "../../../../../../common/service/auth/auth.service";
import {User} from "../../../../../../common/class/user/user";

@Component({
    selector: 'compliance-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent extends BgForestComponent implements OnInit, OnDestroy {
    connectedUser: User;

    translate: any;
    filter = "";
    projects: Dossier[] = [];
    projectsToDisplay: Dossier[] = [];
    useFile: string;
    domain: string;
    category: string;

    constructor(private _router: Router, private _route: ActivatedRoute,
                private _dossierService: DossierService, private _cacheService: CacheService,
                private _confirmationService: ConfirmationService, private _authService: AuthService) {
        super();
    }

    ngOnInit(): void {
        this.connectedUser = this._authService.getConnectedUser();

        this.domain = this._route.snapshot.paramMap.get('domain');
        this.category = this._route.snapshot.paramMap.get('category');
        this._dossierService.findByAccount().then((dossiers) => {
            if (dossiers && dossiers.length) {
                this.projects = _.sortBy(dossiers, ['name']);
                this.projectsToDisplay = this.projects;
                this._setUseFileText();
            } else {
                this._router.navigate(['/compliance', this.domain, this.category, 'project', 'create']);
            }

        });

    }

    private _setUseFileText() {
        if (this.projects.length > 1) {
            this.useFile = super.getValue('project.list.useFile.used.many', {used: this.projects.length});
        } else {
            this.useFile = super.getValue('project.list.useFile.used.oneOrLess', {used: this.projects.length});
        }
        if (this.connectedUser.account.accountProjectNumber > 1) {
            this.useFile = this.useFile + super.getValue('project.list.useFile.max.many', {max: this.connectedUser.account.accountProjectNumber});
        } else {
            this.useFile = this.useFile + super.getValue('project.list.useFile.max.oneOrLess', {max: this.connectedUser.account.accountProjectNumber});
        }
    }

    ngOnDestroy(): void {
    }

    filterProject(): void {
        let keyWord = this.filter.trim().toLowerCase();
        if (keyWord !== "") {
            this.projectsToDisplay = _.filter(this.projects, project => {
                return _.includes(_.lowerCase(project.name), keyWord) || _.includes(_.lowerCase(project.code), keyWord);
            });
        } else {
            this.projectsToDisplay = this.projects;
        }
    }

    goToProject(project: Dossier): void {
        if (!project.deleting) {
            this._router.navigate(['/compliance', this.domain, this.category, 'project', project.id, 'dashboard']);
        }
    }

    alertProjectMaxNumber() {
        this._router.navigate(['/compliance', this.domain, this.category, 'project', 'create']);
    }

    deleteProject($event, project: Dossier) {
        $event.stopPropagation();
        // project.deleting = true;

        let i18n = super.getTranslation();
        let projectI18n = i18n.project;

        if (this.connectedUser.role.id !== 'user') {
            this._confirmationService.confirm({
                acceptVisible: true,
                acceptLabel: i18n.buttons.confirm,
                rejectVisible: true,
                rejectLabel: i18n.buttons.cancel,
                message: `${projectI18n.dialogDelete.text}${project.name} ?`,
                header: projectI18n.dialogDelete.title,
                accept: () => {
                    // Actual logic to perform a confirmation
                    project.deleting = true;
                    this._dossierService.deleteDossiers([project.id]).then(() => {
                        _.remove(this.projects, {id: project.id});
                        this.filterProject();
                        this._setUseFileText();
                        if (!this.projects.length) {
                            this._router.navigate(['/compliance', this.domain, this.category, 'project', 'create']);
                        }
                    });
                }
            });
        } else {
            this._confirmationService.confirm({
                rejectVisible: false,
                message: projectI18n.dialogNoDelete.text,
                header: projectI18n.dialogNoDelete.title,
                acceptLabel: projectI18n.dialogNoDelete.accept
            });
        }
    }

    getFolderUsage(): number {
        let maxFolders = this.connectedUser.account.accountProjectNumber;
        if (!maxFolders) {
            return 100;
        }
        return (100 * this.projects.length / maxFolders);
    }
}
