import {ComplianceApiService} from "../../../../../../../common/class/compliance-api.service";
import {map} from "rxjs/operators";
import {HttpErrorResponse, HttpEventType, HttpResponse} from "@angular/common/http";
import {UploadActionInterface} from "./upload-action-interface";
import {UploadInfo} from "../../../../../../../common/class/upload/upload-info";
import {Observable} from "rxjs";

export abstract class UploadActionService extends ComplianceApiService implements UploadActionInterface {

    constructor(uriToken: string) {
        super(uriToken, 'api');
    }

    protected uploadProgressEventMap() {
        return map((event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                const percentDone = Math.ceil(100 * (event.loaded / event.total)/3);
                return {
                    progress: percentDone,
                    error: false,
                    value: event.body
                };
            } else if (event instanceof HttpResponse) {
                return {
                    progress: 33,
                    error: false,
                    value: event.body,
                    uploaded: true
                };

            } else if (event instanceof HttpErrorResponse) {
                return {
                    progress: 100,
                    error: true,
                    value: {},
                    uploaded: true
                };
            } else {
                return {
                    error: false,
                    value: {},
                    uploaded: false
                };
            }
        })
    }

    protected computeFormData(file: File, anneeExercice?: any, projectName?: string, projectCode?: string) {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        if(anneeExercice)
        formData.append('anneeExercice', JSON.stringify(anneeExercice));
        if(projectName)
        formData.append('projectName', projectName);
        if(projectCode)
        formData.append('projectCode', projectCode);
        return formData;
    }

    abstract upload(uploadInfo: UploadInfo, anneeExercice: any, projectName: string, projectCode: string, authorizedFilesControl: string[]): Observable<any>
}
