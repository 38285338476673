export const versionComplete = {
    title: `Découvrez notre version complète`,
    contactButton: `Je veux être recontacté`,
    header:{
        premium:`Complète`,
        light:`Flash intracom`
    },
    commercial:{
        title: `VERSION COMPLÈTE`,
        subTitle:`L'offre adaptée pour un usage avancé`,
        text1:`Grâce à la version Complète vous pourrez réaliser <b style="color: rgb(149, 211, 255)">tous les contrôles de TVA (TVA collectée, TVA déductible, TVA intracommunautaire)</b>, en bénéficiant du rapprochement avec les données comptables (FEC).`,
        text2:`Vous êtes intéressé et souhaitez en savoir plus ? Appelez-nous directement au <b style="color: rgb(149, 211, 255)">01&nbsp;41&nbsp;05&nbsp;57&nbsp;88</b> ou accédez au formulaire ci-dessous pour être recontacté par notre équipe commerciale.`
    }
};
