<div id="content" class="page-botom-transparent">

    <div class="container-fluid  w-90">
        <div class="block-group-title ">
            <h2 class="title col-12">
                {{'versionComplete.title' | translate}}
            </h2>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="line head row">
                    <div class="col-6 col-long">&nbsp;</div>
                    <div class="col-3">{{'versionComplete.header.light' | translate}}</div>
                    <div class="col-3">{{'versionComplete.header.premium' | translate}}</div>

                </div>
                <div class="content-diff slimscroll pr-1">
                    <ng-container *ngFor="let data of datas">
                        <div class="line row separator">
                            <div class="col-12 ">{{data.bloc}}</div>

                        </div>
                        <div class="line row content" *ngFor="let content of data.contents">
                            <div class="col-6 col-long line-header">
                                <div [innerHTML]="content.label | translate | safe:'html'"></div>
                            </div>
                            <div class="col-3">
                                <div [innerHTML]="content[connectedUser.account.subscription.id] | translate | safe:'html'"></div>
                            </div>
                            <div class="col-3">
                                <div [innerHTML]="content.premium | translate | safe:'html'"></div>
                            </div>

                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-6 d-flex justify-content-center align-items-center" style=" height:70vh">
                <div class="flex-fill d-flex justify-content-between flex-column commercial-content" style="">
                    <p class="text-size-18 text-center font-weight-bold">{{'versionComplete.commercial.title' | translate}}</p>
                    <p class="text-size-16 text-center">{{'versionComplete.commercial.subTitle' | translate}}</p>
                    <p class="text-size-14 text-center" [innerHTML]="'versionComplete.commercial.text1' | translate | safe:'html'"></p>
                    <p class="text-size-14 text-center" [innerHTML]="'versionComplete.commercial.text2' | translate | safe:'html'"></p>
                    <button href="#" class="btn btn-primary text-size-16" (click)="contact()">
                        {{'versionComplete.contactButton' | translate}}
                    </button>
                </div>
            </div>
        </div>

    </div>


    <div class="block-bottom-actions">
        <div class="inner text-center d-flex justify-content-between">
            <button href="#" class="btn btn-primary btn-back" (click)="goBack()">{{'buttons.back' | translate}}</button>

        </div>
    </div>
</div>
