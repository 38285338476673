export const breadcrumb = {
    files: "Dossiers {{category}}",
    information: "Module Flash Intracom",
    cgvu: "CGVU",
    versionComplete:`Version complète`,
    "help": "Aide",
    "account": "Mon compte",
    "createProject" : "Création d'un projet",
    "createExercice" : "Création d'un exercice",
    "editExercice" : "Modification d'un exercice",
    "exerciceSupervision" : "Supervision d'un exercice",
    "file-list" : "Gestion des fichiers",
    "listControl" : "Liste des contrôles",
    "edit-ca3" : "Saisie CA3",
    "globalParams" : {
        "title": "Paramétrages",
        "balance": "Balance",
        "affectation": "Affectation",
        "question": "Question",
        "codeJournaux": "Codes Journaux",
    },
    "control": {
        "focus": "Focus",
        "presentation": "Présentation",
        "CRG": "Compte rendu général",
        "CRD": "Compte rendu détaillé",
        "uploadFiles" : "Dépôt des fichiers",
        "supervision" : "Supervision"
    },
    "video": "Vidéo de présentation",
    "videoList": "Liste vidéos explicatives",
    gestionCookie:"Gestion des cookies"
};
