import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NumberUtilsService {

    constructor() {
    }

    formatShortNumber(value: number, digits: number=0, currency:boolean=false){
        value = Math.round(value);
        let si = [
            {value: 1, symbol: " "},
            {value: 1E3, symbol: " k"},
            {value: 1E6, symbol: " M"},
            {value: 1E9, symbol: " Mrd"},
            {value: 1E12, symbol: " T"},
            {value: 1E15, symbol: " P"},
            {value: 1E18, symbol: " E"}
        ];
        let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let i;
        let negatif = value < 0 ? '-' : '';
        if (value < 0) {
            value = Math.abs(value);
        }
        for (i = si.length - 1; i > 0; i--) {
            if (value >= si[i].value) {
                break;
            }
        }
        let formated = (value / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
        if (currency){
            return negatif + formated + "€";
        }
        return negatif + formated;
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

}
