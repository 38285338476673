import {Component, OnDestroy, OnInit} from '@angular/core';
import {BackgroungType} from "../../../../../../../../common/class/background/bg-compliance";
import {NotificationService} from "../../../../../../../../common/service/push/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CategoryControl, ControlDescriptor, DomaineControl} from "../../../../../../../../common/class/control/control-descriptor";
import {ControlRoutesFlux} from "../../../../../../../../common/class/control/control-flux";
import {AbstractControl} from "../../../../../../../../common/component/control/abstract-control";
import {ExerciceService} from "../../../../../../../../common/service/exercice/exercice.service";
import {AppConfigService} from "../../../../../../../../common/service/app-config.service";
import {TvaSubscriptionService} from "../../../../../../../../common/service/subscriptions/tva-subscription.service";
import _ from 'lodash';
import {CONTROL_FILE_VIE_ID, FileType} from "../../../../../../../../common/class/compliance-constant";
import {FileService} from "../../../../../../../../common/service/file/file.service";
import {UploadFile} from "../../../../../../../../common/class/upload/upload-file";
import moment from "moment";
import {FileSaverService} from "ngx-filesaver";
import {FileUtilsService} from "../../../../../../../../common/service/file/file-utils.service";
import {ViesService} from "../../../../../shared/upload-file/drag-n-drop/shared/vies.service";
import {takeUntil} from "rxjs/operators";
import {HabilitationService} from "../../../../../../../../common/service/auth/habilitation.service";

@Component({
    selector: 'compliance-control-focus',
    templateUrl: './control-focus.component.html',
    styleUrls: ['./control-focus.component.scss']
})
export class ControlFocusComponent extends AbstractControl implements OnInit, OnDestroy {
    control: ControlDescriptor;
    launchDisabled = false;
    private files: UploadFile[];
    tooltipViesDisabled: string;

    constructor(private _notificationService: NotificationService,
                private _route: ActivatedRoute, private _router: Router, private _exerciceService: ExerciceService, private _viesService: ViesService,
                private _configService: AppConfigService, private _userSubscriptionservice: TvaSubscriptionService, private _fileService: FileService,
                private _fileSaverService: FileSaverService, private _fileUtils: FileUtilsService, private _habilitationService: HabilitationService) {
        super(BackgroungType.BG_SPACE);
    }

    ngOnInit() {
        this.domain = this._route.snapshot.paramMap.get('domain') as DomaineControl;
        this.category = this._route.snapshot.paramMap.get('category') as CategoryControl;
        this.controlId = this._route.snapshot.paramMap.get('controlId');
        this.projectId = this._route.snapshot.paramMap.get('projectId');
        this.exerciceId = this._route.snapshot.paramMap.get('exerciceId');
        this.getData();
        super.setContextWithRoute(this._route, () => {
        });
        super.subscribeToNotificationControl({
            notificationService: this._notificationService
        });

        this._notificationService.fileNotificationObservable$
            .pipe(takeUntil(this.destroy$))
            .subscribe((msg) => {
                const fileName = moment().format("YYYYMMDD_HHMMSS") + "_vies.xlsx";
                this._fileSaverService.save(this._fileUtils.blobPdfFromBase64String(msg.blob), fileName);
                this._complianceLoaderService.sendLoaderHide();
            });
    }

    async getData() {
        this.control = _.cloneDeep(await this._controlService.findControlById(this.controlId));
        const exercice = await this._exerciceService.findExercicesById(this.exerciceId);
        let userSubscription = await this._userSubscriptionservice.getUserSubscription();
        if (this.controlId === CONTROL_FILE_VIE_ID) {
            this.files = await this._fileService.findFilesByType(this.projectId, this.exerciceId, FileType.DEB_EXPEDITION)
        }
        this.computeData(exercice, userSubscription);
    }

    private computeData(exercice, userSubscription) {
        this.control.lienDocumentaire.forEach(lien => {
            if (parseInt(exercice.annee) < this._configService.config.mementoFiscalCurrentYear) {
                lien.lien = lien.lien.replace(/\${MILLESIME}/, exercice.annee);
            } else {
                lien.lien = lien.lien.replace(/\${MILLESIME}/, '');
            }
        });

        this.launchDisabled = this.isControlActionDisabled(this.controlId, userSubscription);
    }

    isControlActionDisabled(controlId, userSubscription) {
        console.log([controlId, userSubscription]);
        if (this.controlId === CONTROL_FILE_VIE_ID) {
            return this._isVieDisabled(this._habilitationService.hasAccessControlVie());
        }
        if (userSubscription && userSubscription.controlsMetadatas) {
            return !userSubscription.controlsMetadatas.find((data) => data.controlId === controlId).executionStepByStep;
        }
        return false;
    }

    private _isVieDisabled(hasAccessControlVie) {
        if (!hasAccessControlVie) {
            this.tooltipViesDisabled = null;
        } else if (!this.files || !this.files.length) {
            this.tooltipViesDisabled = super.getValue("control.vies.nofFileTooltipText")
        }
        return !this.files.length || !hasAccessControlVie;
    }

    async launchControl() {
        if(!this.launchDisabled) {
            this._complianceLoaderService.sendLoaderShow();
            if (this.controlId === CONTROL_FILE_VIE_ID) {
                this._viesService.export(this.projectId, this.exerciceId, this.files.map(value => value.id))
            } else {
                await this._controlService.launchExecutionControl(this.projectId, this.exerciceId, this.control.stateMachineId);
            }
        }
    }

    goBackPresentation() {
        this._router.navigate([this._notificationService.generateControlRoute(this.projectId, this.domain, this.category, this.control.id, ControlRoutesFlux.presentation, this.exerciceId)]);
    }

}
