import {Injectable} from '@angular/core';
import {ActionQuestion, ActionServiceType} from "../../class/meta/question/meta-question";
import {ControlService} from "../control/control.service";
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";
import {of} from "rxjs";
import {MetaExpressionService} from "./meta-expression.service";
import _ from 'lodash';
import {CalculatedValueService} from "../control/calculated-value.service";

@Injectable({
    providedIn: 'root'
})
export class MetaQuestionService extends ComplianceApiService {

    constructor(private constrolService: ControlService, private calculatedValueService: CalculatedValueService, private restService: RestService, private expressionService: MetaExpressionService) {
        super('question', 'api');
    }

    procesQuestionServiceAction(action: ActionQuestion, projectId, stateMachineId, exerciceId, question, event?, data?: any, dataSaveTarget?:any, stepByStep: boolean = true): Promise<any> {
        if (action.service) {
            if (action.service.type === ActionServiceType.BACK_CONTROL) {
                this.constrolService.backControl(projectId, stateMachineId, exerciceId, event, stepByStep);
                return of(true).toPromise();
            } else if (action.service.type === ActionServiceType.LAUNCH_CONTROL) {
                this.launchControl(data, question, projectId, stateMachineId, exerciceId, event, dataSaveTarget, stepByStep);
                return of(true).toPromise();
            } else if (action.service.type === ActionServiceType.CALCULATED_VALUE) {
                this.calculatedValueService.saveCalculatedValues(data, projectId, action.service.params, exerciceId);
                return of(true).toPromise();
            }
        }

    }

    private launchControl(data: any, question, projectId, stateMachineId, exerciceId, event, dataSaveTarget, stepByStep: boolean = true) {
        dataSaveTarget = dataSaveTarget || "parametersToSave";
        let dataToSend = {};
        dataToSend[dataSaveTarget] = {};
        if (!_.isNil(data)) {
            dataToSend[dataSaveTarget][question.responseName] = data;
        }
        this.constrolService.continueExecutionControl(projectId, stateMachineId, exerciceId, event, dataToSend, stepByStep);
    }

    procesQuestionProceesValueAction(action: ActionQuestion, data?: any): any {
        if (action.processValue) {
            let value = {};
            value[action.processValue.field] = this.expressionService.computeExpressionOrMember(action.processValue.expression, data);
            return value;
        }
    }

    private launchCustomService(custom: { uriToken: string; app: string; relativeUri?: string; method: string }, projectId, exerciceId, data?: any) {
        super.setBaseUrl(custom.uriToken, custom.app);
        return this.restService[custom.method]({
            url: this.baseUri,
            relativeUri: custom.relativeUri,
            parameters: [projectId, exerciceId],
            data: data,
            arg: null
        });
    }
}
