export enum Visa {
    DEFAULT,
    TO_REVIEW = 'TO_REVIEW',
    VALIDATE = 'VALIDATE',
}

const visaType = [
    {label: 'A revoir', value: Visa.TO_REVIEW, icon: 'far fa-ban', class: 'refused', color: '#003663'},
    {label: 'En attente', value: null, icon: 'far fa-hourglass-half', class: 'waiting', color: '#9dc4d1'},
    {label: 'Validé', value: Visa.VALIDATE, icon: 'far fa-check', class: 'accepted', color: '#00a9e1'}
];
export const VISA_TYPE = visaType;

const validationType = [
    {label: 'En attente', value: false, icon: 'far fa-hourglass-half', class: 'waiting', color: '#9dc4d1'},
    {label: 'Validé', value: true, icon: 'far fa-check', class: 'accepted', color: '#00a9e1'}
];
export const VALIDATION_TYPE = validationType;

export class SupervisionControl {
    observations: string;
    fixes: string;
    conclusion: string;
    visa: Visa;
}
