import {Injectable} from '@angular/core';
import {DROIT_MENU, DROIT_MODULE, ROLE_ADMIN, ROLE_ADMIN_EFL, ROLE_ADMIN_EFL_PARAMETRAGE, ROLE_USER} from "../../class/auth/habilitation";
import _ from 'lodash';
import {User} from "../../class/user/user";
import {DeviceDetectorService} from "ngx-device-detector";

@Injectable()
export class HabilitationService {

    private moduleRoles = DROIT_MODULE;
    private accountRole = [ROLE_ADMIN, ROLE_USER];
    private adminRole = [ROLE_ADMIN, ROLE_ADMIN_EFL];
    private user: User;

    constructor(private _deviceService:DeviceDetectorService) {
        this.setModuleDroit();
    }

    private setModuleDroit() {
    }

    getConnectedUser():User{
        return this.user;
    }

    setConnectedUser(user: User):void{
        this.user = user
    }

    isRole(role:string):boolean{
        return this.user.role.id === role;
    }

    isRoleAdminEFLParametrage():boolean{
        return this.isRole(ROLE_ADMIN_EFL_PARAMETRAGE);
    }

    isRoleAdminEFL():boolean{
        return this.isRole(ROLE_ADMIN_EFL);
    }

    isRoleAdmin():boolean{
        return this.isRole(ROLE_ADMIN);
    }

    isRoleUser():boolean{
        return this.isRole(ROLE_USER);
    }

    isShowMenu(menu:string):boolean{
        let droit = DROIT_MENU[menu];
        return _.includes(droit.roles, this.user.role.id);
    }

    isAuthorized(module: string):boolean{
        let roles = this.moduleRoles[module];
        return _.includes(roles, this.user.role.id);
    }

    isRoleTypeAccount() {
        return _.includes(this.accountRole, this.user.role.id);
    }

    isRoleTypeAdmin() {
        return _.includes(this.adminRole, this.user.role.id);
    }

    isCguRead() {
        let origin: string = this.user.account.origin.cgvu;
        return origin == null || origin === this.user.cgvu;
    }

    hasReadLightPage(){
        return  !!this.user.hasReadInformationPage;
    }

    hasMakeCookieChoice(){

        return  this.user.cookie && this.user.cookie.navigators && !!this.user.cookie.navigators.find(value => value === this._deviceService.browser);
    }

    hasAccessControlVie(){
        console.log("this.user.account.subscription.accessControlVies", this.user.account.subscription.accessControlVies)
        return this.user.account.subscription.accessControlVies
    }

    isPremium(){
        return this.user.account.subscription.id === "premium";
    }
}
