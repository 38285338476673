import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";

@Injectable()
export class BaseSirenService extends ComplianceApiService {

  constructor(private restService: RestService) {
      super('siren', 'api');
  }

    // findByAccount(): Promise<Dossier[]> {
    //     return this.restService.get({
    //         url: this.baseUri,
    //         arg: null as Dossier[]
    //     })
    // }

    // saveDossier(dossier:Dossier): Promise<Dossier> {
    //     if(dossier.id && dossier.id.length > 0){
    //         return this.updateDossier(dossier);
    //     }else{
    //         return this.createDossier(dossier);
    //     }
    // }

    // findById(id:string): Promise<Dossier> {

    //     return this.restService.get({
    //         url: this.baseUri,
    //         parameters: [id],
    //         arg: null as Dossier
    //     })
    // }

    // private createDossier(dossier: Dossier): Promise<Dossier> {
    //     return this.restService.post({
    //         url : this.baseUri,
    //         data: dossier,
    //         arg: dossier
    //     })
    // }

    // private updateDossier(dossier: Dossier): Promise<Dossier>{
    //     return this.restService.put({
    //         url : this.baseUri,
    //         data: dossier,
    //         arg: dossier
    //     })
    // }

    // deleteDossiers(dossierIds: string[]): Promise<any>{
    //     return this.restService.delete({
    //         url : this.baseUri,
    //         parameters: [dossierIds.join(',')],
    //         arg: null
    //     })
    // }

    findSiren(siren: string, type: string): Promise<any>{
        return this.restService.get({
            url : this.baseUri,
            relativeUri: "solr",
            parameters: [siren],
            arg: null as any
        });
    }

}
