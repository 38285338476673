<ng-container *ngIf="(datas && datas.length) || footer">
    <div class="panel" *ngIf="actionMenuObject" [ngStyle]="actionMenuObject.position" [ngClass]="getStyleClass()">
        <ul>
            <li *ngFor="let action of actionMenuObject.meta.actionMenu"><a href="#" (click)="menuAction(actionMenuObject.data, action.code, actionMenuObject.meta.field)"
                                                                           [innerHtml]="action.label | translate |safe:'html'"></a></li>
        </ul>
    </div>
    <p-table [columns]="items" [value]="datas" sortMode="multiple" #dt [styleClass]="getStyleClass()" [csvSeparator]="';'" [exportFilename]="tableOption.exportFileName">
        <ng-template pTemplate="header" let-data>
            <tr>
                <ng-container *ngFor="let meta of items">
                    <ng-container *ngIf="!meta.hide">
                        <th *ngIf="meta.sort" class="{{meta.type}} {{meta.class}}">
                            <span [innerHTML]="meta.header | translate | safe:'html'"></span>
                        </th>
                        <th *ngIf="!meta.sort && meta.type == itemType.actionMenu" class="actions">
                            <a href="#" (click)="showGlobalMultipleActionMenu(meta.field)"><i class="fas fa-caret-right"></i></a>
                            <div class="panel" *ngIf="showMultipleAffectMenu">
                                <ul>
                                    <li *ngFor="let action of meta.actionMenu"><a href="#" (click)="menuActionMultiple( action.code)" [innerHtml]="action.label | translate |safe:'html'"></a></li>
                                </ul>
                            </div>
                        </th>
                        <th *ngIf="!meta.sort && meta.type != itemType.actionMenu" class="{{meta.type}} {{meta.class}}">
                            <span [innerHTML]="meta.header | translate | safe:'html'"></span>
                        </th>
                    </ng-container>
                </ng-container>
            </tr>
            <tr class="tablesorter-ignoreRow spacer">
                <td *ngIf=" tableOption.selectionMode && tableOption.selectionMode == 'multiple'" [attr.colspan]="items.length" class="text-white text-right select-all no-select"><span
                    (click)="selectAll()">{{(isAllSelected() ? 'buttons.unselectAll' : 'buttons.selectAll') | translate}}</span>
                </td>
                <td *ngIf=" !tableOption.selectionMode || tableOption.selectionMode == 'single' " [attr.colspan]="items.length" class="text-white text-left"></td>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr [ngClass]="{'total':data.total, 'active':!!data[tableOption.activeField]}" class="{{data[tableOption.activeFieldType]}}" (click)="rowSelected(data)">
                <ng-container *ngFor="let meta of items; index as i">
                    <ng-container *ngIf="!meta.hide">
                        <ng-container *ngIf="!data.total">

                            <ng-container *ngIf="meta.type == itemType.actionMenu">
                                <td class="{{meta.class}}" [ngClass]="{'export-cell': !!meta.export}">
                                    <ng-container *ngIf="!data[tableOption.activeField]">
                                        <a href="#" (click)="showMenu($event, data, meta)"><i class="fas fa-caret-right"></i></a>
                                    </ng-container>
                                </td>
                            </ng-container>
                            <ng-container *ngIf="meta.type == itemType.affectation">
                                <td class="{{meta.class}}" [ngClass]="{'export-cell': !!meta.export}">
                                    <compliance-affectation [data]="data" [graphField]="meta.graphField" [export]="meta.export" [destroy$]="destroy$"></compliance-affectation>
                                </td>
                            </ng-container>
                            <ng-container *ngIf="meta.type == itemType.form">
                                <compliance-table-form data="data" [meta]="meta" [colIndex]="i" (dataChange)="updateData($event)"></compliance-table-form>
                            </ng-container>

                            <ng-container *ngIf="meta.computeValueType">
                                <th *ngIf="meta.isLineHeader" scope="row" [ngStyle]="data[meta.styleField]" class=" {{meta.class}}" [ngClass]="computeClass(data, meta)"
                                    [attr.title]="meta.prefix?meta.prefix + data[meta.field]:data[meta.field]">
                                    <compliance-value [meta]="meta" [data]="data" [hasExport]="!!meta?.export" [ngExportNotAvailable]="extendedData?.exportFunctionalityNotAvailable" [destroy$]="destroy$"></compliance-value>
                                </th>
                                <td *ngIf="!meta.isLineHeader " class="{{meta.type}} {{meta.class}}" [ngStyle]="data[meta.styleField]" [ngClass]="computeClass(data, meta)">
                                    <compliance-value [meta]="meta" [data]="data" [hasExport]="!!meta?.export" [ngExportNotAvailable]="extendedData?.exportFunctionalityNotAvailable" [destroy$]="destroy$"></compliance-value>
                                </td>
                            </ng-container>


                        </ng-container>
                        <ng-container *ngIf="data.total">
                            <td *ngIf="i ===0" [ngClass]="{'empty':meta.isLineHeader}" class="">
                                <compliance-value [meta]="meta" [data]="data" [hasExport]="!!meta?.export"
                                                  [ngExportNotAvailable]="extendedData?.exportFunctionalityNotAvailable" [destroy$]="destroy$"></compliance-value>
                            </td>
                            <td *ngIf="i > 0" [ngClass]="{'empty':meta.isLineHeader}" class="{{meta.type}} {{meta.class}}">
                                <compliance-value [meta]="meta" [data]="data" [hasExport]="!!meta?.export"
                                                  [ngExportNotAvailable]="extendedData?.exportFunctionalityNotAvailable" [destroy$]="destroy$"></compliance-value>
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>

            </tr>
        </ng-template>

        <ng-template pTemplate="footer" let-columns>
            <tr class="big-total" *ngIf="footer">
                <ng-container *ngFor="let meta of items; index as i">
                    <ng-container *ngIf="!meta.hide">
                        <ng-container *ngIf="meta.footer">
                            <th *ngIf="i ===0" class=" {{meta.footer.class}}" scope="row">
                                <compliance-value [meta]="meta.footer" [data]="footer" [hasExport]="!!meta?.footer?.export"
                                                  [ngExportNotAvailable]="extendedData?.exportFunctionalityNotAvailable"
                                                  class="{{meta.footer.class}}" [destroy$]="destroy$"></compliance-value>
                            </th>
                            <td *ngIf="i > 0" [ngClass]="{'empty':meta.footer.isLineHeader}" class="{{meta.footer.type}}  {{meta.footer.class}}">
                                <compliance-value [meta]="meta.footer" [data]="footer" [hasExport]="!!meta?.footer?.export"
                                                  [ngExportNotAvailable]="extendedData?.exportFunctionalityNotAvailable"
                                                  class="{{meta.footer.class}}" [destroy$]="destroy$"></compliance-value>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="!meta.footer">
                            <th *ngIf="i ===0" class=" {{meta.class}}" scope="row">
                                <compliance-value [meta]="meta" [data]="footer" class="{{meta.class}}" [destroy$]="destroy$"></compliance-value>
                            </th>
                            <td *ngIf="i > 0" [ngClass]="{'empty':meta.isLineHeader}" class="{{meta.type}}  {{meta.class}}">
                                <compliance-value [meta]="meta" [data]="footer" class="{{meta.class}}" [destroy$]="destroy$"></compliance-value>
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>

            </tr>
        </ng-template>


    </p-table>
</ng-container>
