import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpHeaders, HttpParams} from "@angular/common/http";
import {RestService} from "../rest.service";
import {REFRESH_TOKEN_LOCAL_NAME} from "../../class/compliance-constant";
import {Oauth2Token} from "../../class/auth/oauth2-token";
import {ComplianceApiService} from "../../class/compliance-api.service";

@Injectable()
export class AuthenticationService extends ComplianceApiService{


    constructor(private restService: RestService) {
        super('','oauth')
    }

    authenticate(email: string, password: string): Promise<Oauth2Token> {
        let token: Oauth2Token;

        let params: HttpParams = new HttpParams()
            .append('username', email)
            .append('password', password)
            .append('client_id', 'trusted-app')
            .append('client_secret', 'secret')
            .append('grant_type', 'password');
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.restService.post({
            url: this.baseUri,
            relativeUri:'token',
            data: params.toString(),
            headers: headers,
            arg: token
        });
    }

    refreshAuth(): Observable<Oauth2Token> {
        let token: Oauth2Token;

        let params: HttpParams = new HttpParams()
            .append('client_id', 'trusted-app')
            .append('client_secret', 'secret')
            .append('grant_type', 'refresh_token')
            .append('refresh_token', localStorage.getItem(REFRESH_TOKEN_LOCAL_NAME));
        let headers = new HttpHeaders()
            .append('Content-Type', 'application/x-www-form-urlencoded');

        return this.restService.postObservable({
            url: this.baseUri,
            relativeUri:'token',
            data: params.toString(),
            headers: headers,
            arg: token
        });
    }

}
