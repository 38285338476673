import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'compliance-periode-lot-year',
    templateUrl: './periode-lot-year.component.html',
    styleUrls: ['./periode-lot-year.component.scss']
})
export class PeriodeLotYearComponent implements OnInit {

    @Input() lot:{year:string, months:{label:string, enable?:boolean, active?:boolean}[]};
    @Output() selectPeriodEvent:EventEmitter<{label:string, enable?:boolean, active?:boolean}> = new EventEmitter();

    private selectedMax:{label:string, enable?:boolean, active?:boolean};
    private selectedMin:{label:string, enable?:boolean, active?:boolean};
    constructor() {
    }

    ngOnInit() {
    }


    activePeriod(month: { label: string; enable?: boolean; active?: boolean }) {
        this.selectPeriodEvent.emit(month);
    }
}
