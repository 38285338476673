import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CategoryControl, ControlForDashboard, DomaineControl, STATUS_COLORS, StatusControl} from "../../../../../../../common/class/control/control-descriptor";
import {DATE_FORMAT} from "../../../../../../../common/class/compliance-constant";
import {FormControl} from "@angular/forms";
import {ControlRoutesFlux} from "../../../../../../../common/class/control/control-flux";
import {NotificationService} from "../../../../../../../common/service/push/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ReportingService} from "./reporting.service";
import {VISA_TYPE} from "../../../../../../../common/class/control/supervision";
import {ComplianceLoaderService} from "../../../../../../../common/service/loader/compliance-loader.service";
import {ControlSubscription} from "../../../../../../../common/class/subscription/subscription";
import {ReportModalComponent} from "./report/report-modal/report-modal.component";

@Component({
    selector: 'compliance-tva-dashboard',
    templateUrl: './tva-dashboard.component.html',
    styleUrls: ['./tva-dashboard.component.scss']
})
export class TvaDashboardComponent {
    private _controlList: ControlForDashboard[];
    allControlList: ControlForDashboard[];

    @Input() set controlList(list: ControlForDashboard[]) {
        this.allControlList = list;
        if (!list) {
            return;
        }
        const sortFunction = this.sortControl.value + "Sort";
        this._controlList = list.filter((control) => {
            return control.status && (control.status !== StatusControl.NONE);
        }).sort(this[sortFunction]());
    }

    @Input() exerciceValidation: boolean;
    @Input() private controlSubscription: ControlSubscription[];

    @Output() launchStateMachine = new EventEmitter<{
        stateMachineId: string;
        controlId: string;
        stepByStep: boolean;
    }>();

    controlToExport : ControlForDashboard;
    @ViewChild(ReportModalComponent) reportModalComponant: ReportModalComponent;

    get controlList(): ControlForDashboard[] {
        return this._controlList;
    }

    private _controlLaunched: ControlForDashboard;

    sortControl = new FormControl("riskValue");
    DATE_FORMAT = DATE_FORMAT;
    STATUS_COLORS = STATUS_COLORS;
    private _projectId: string;
    private _exerciceId: string;

    constructor(private _route: ActivatedRoute, private _router: Router,
                private _notificationService: NotificationService,
                private reportingService: ReportingService, private _complianceLoaderService: ComplianceLoaderService) {
        this._projectId = this._route.snapshot.paramMap.get('projectId');
        this._exerciceId = this._route.snapshot.paramMap.get('exerciceId');
        this._route.params.subscribe((routeParam: { exerciceId: string }) => {
                this._exerciceId = routeParam.exerciceId;
        });
        this.sortControl.valueChanges.subscribe(() => {
            this.controlList = this.controlList;
        });
    }


    riskValueSort() {
        return (controlA: ControlForDashboard, controlB: ControlForDashboard) => {
            if (controlA.amountRisk === controlB.amountRisk) {
                const statusValues = StatusControl.values();
                if (statusValues.indexOf(controlA.status) === statusValues.indexOf(controlB.status)) {
                    const visaValues = VISA_TYPE.map((visa) => visa.value);
                    if (visaValues.indexOf(controlA.visa.value) === visaValues.indexOf(controlB.visa.value)) {
                        return controlA.nom.localeCompare(controlB.nom);
                    }
                    return visaValues.indexOf(controlA.visa.value) - visaValues.indexOf(controlB.visa.value);
                }
                return statusValues.indexOf(controlA.status) - statusValues.indexOf(controlB.status);
            }
            return controlB.amountRisk - controlA.amountRisk;
        };
    }

    acceptedRiskValueSort() {
        return (controlA: ControlForDashboard, controlB: ControlForDashboard) => {
            if (controlA.amountRiskToDisplay === controlB.amountRiskToDisplay) {
                const statusValues = StatusControl.values();
                if (statusValues.indexOf(controlA.status) === statusValues.indexOf(controlB.status)) {
                    const visaValues = VISA_TYPE.map((visa) => visa.value);
                    if (visaValues.indexOf(controlA.visa.value) === visaValues.indexOf(controlB.visa.value)) {
                        return controlA.nom.localeCompare(controlB.nom);
                    }
                    return visaValues.indexOf(controlA.visa.value) - visaValues.indexOf(controlB.visa.value);
                }
                return statusValues.indexOf(controlA.status) - statusValues.indexOf(controlB.status);
            }
            return controlB.amountRiskToDisplay - controlA.amountRiskToDisplay;
        };
    }

    riskLevelSort() {
        return (controlA: ControlForDashboard, controlB: ControlForDashboard) => {
            const statusValues = StatusControl.values();
            if (statusValues.indexOf(controlA.status) === statusValues.indexOf(controlB.status)) {
                if (controlA.amountRisk === controlB.amountRisk) {
                    const visaValues = VISA_TYPE.map((visa) => visa.value);
                    if (visaValues.indexOf(controlA.visa.value) === visaValues.indexOf(controlB.visa.value)) {
                        return controlA.nom.localeCompare(controlB.nom);
                    }
                    return visaValues.indexOf(controlA.visa.value) - visaValues.indexOf(controlB.visa.value);
                }
                return controlB.amountRisk - controlA.amountRisk;
            }
            return statusValues.indexOf(controlA.status) - statusValues.indexOf(controlB.status);
        };
    }

    visaSort() {
        return (controlA: ControlForDashboard, controlB: ControlForDashboard) => {
            const visaValues = VISA_TYPE.map((visa) => visa.value);
            if (visaValues.indexOf(controlA.visa.value) === visaValues.indexOf(controlB.visa.value)) {
                if (controlA.amountRisk === controlB.amountRisk) {
                    const statusValues = StatusControl.values();
                    if (statusValues.indexOf(controlA.status) === statusValues.indexOf(controlB.status)) {
                        return controlA.nom.localeCompare(controlB.nom);
                    }
                    return statusValues.indexOf(controlA.status) - statusValues.indexOf(controlB.status);
                }
                return controlB.amountRisk - controlA.amountRisk;
            }
            return visaValues.indexOf(controlA.visa.value) - visaValues.indexOf(controlB.visa.value);
        };
    }

    goToVisu(control: ControlForDashboard) {
        let link = this._notificationService.generateControlRoute(this._projectId, control.domaine as DomaineControl, control.categorie as CategoryControl, control.id, ControlRoutesFlux["compte-rendu-general"], this._exerciceId);
        this._router.navigate([link], {queryParams: {view: 'visualisation'}});
    }

    exportReport(control: ControlForDashboard, typeExport: string, reportElemeents: string[]) {
        this._complianceLoaderService.sendLoaderShow();
        this.reportingService.getReport(control.id, this._projectId, this._exerciceId, `Rapport ${control.nomCourt}`, typeExport, reportElemeents)
            .then(() => {
                this._complianceLoaderService.sendLoaderHide();
            });
    }

    launchControl(control: ControlForDashboard) {
        this._complianceLoaderService.sendLoaderShow();
        this._controlLaunched = control;
        this.launchStateMachine.emit({
            stateMachineId: this._controlLaunched.stateMachineId,
            controlId: this._controlLaunched.id,
            stepByStep: !this.controlSubscription.find((sub) => sub.controlId === control.id).executionAutomatic
        });
    }

    showDialog(control) {
        this.controlToExport = control;
        this.reportModalComponant.showDialog();
    }
}
