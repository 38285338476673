import {DOSSIER_TAB_IDS} from "../compliance-constant";


export const UNAUTH_MEDIA_VIDEO_EXT:string[] = ['avi', 'mp4', 'mkv', 'wmv', 'mov', 'mpg', 'mpeg'];
export const UNAUTH_MEDIA_AUDIO_EXT:string[] = ['mp3', 'aac', 'wav', 'mid'];
export const UNAUTH_MEDIA_IMG_EXT:string[] = ['jpg', 'jpeg', 'png', 'bpm', 'gif', 'tif', 'tiff', 'svg'];
export const UNAUTH_OTHER_EXT:string[] = ['exe', 'msi', 'iso', 'dll', 'js', 'html', 'doc', 'docx', 'ppt', 'pptx', 'css', 'scss'];
export const ARCHIVE_EXT:string[] = ['zip', '7z', 'gz', 'tar','rar'];

export const CA3_AUTH_EXT:string[] = [];

export const UNAUTH_EXT:string[] = [...UNAUTH_MEDIA_IMG_EXT, ...UNAUTH_MEDIA_VIDEO_EXT, ...UNAUTH_OTHER_EXT, ...UNAUTH_MEDIA_AUDIO_EXT];


export const AUTH_FEC_EXT:string[] = ['', 'txt'];
export const AUTH_CA3_EXT:string[] = ['edi', 'pdf'];
export const AUTH_DEB_EXT:string[] = ['pdf'];
export const AUTH_EXT:string[] = [...AUTH_FEC_EXT, ...AUTH_CA3_EXT, ...AUTH_DEB_EXT];

let extByType = {};


extByType[DOSSIER_TAB_IDS.uploadFecId] ={
    unauthorized : CA3_AUTH_EXT,
};

export const UPLOAD_EXT_AUTHORIZATION = extByType;
