<!-- START : content -->
<div id="content" class="page-assignment" *ngIf="comptes && actualLot">
    <div class="container">

        <div class="row">
            <div class="col-xl-5 col-md-12" style="height: 140px">
                <compliance-affectation-title [lotAffectation]="displayTitles"></compliance-affectation-title>
            </div>
            <div class="col-xl-7 "
                 *ngIf="comptes.describer.affectationAuto && affectationAutoFormGroup && actualLot.lot && actualLot.lot.display.type === compteAffecteConst.nonAffecte">

                <form action="#" class="form-basic form-assignment mt-3" [formGroup]="affectationAutoFormGroup">
                    <div class="d-flex block-affectation-auto">
                        <div class="form-group form-switch col-4 text-left">
                            <p class="label label-affecation text-right"
                               [innerHtml]="'affectation.automaticSection.slider.label' | translate | safe:'html'">
                                &nbsp;</p>
                            <label class="switch" for="show">
                                <input type="checkbox" id="show" formControlName="show" (change)="showAffectationAuto()">
                                <span class="slider round"></span>
                            </label>
                        </div>

                        <div *ngIf="affectationAutoFormGroup.value.show"
                             class="text-white col-5 text-justify text-size-12"
                             [innerHtml]="comptes.describer.affectationAuto.text | translate | safe:'html'"></div>
                        <div class="form-group form-group--auto"
                             [ngClass]="{'hidden':!affectationAutoFormGroup.value.show}">

                            <div class="form-group"
                                 *ngFor="let input of this.comptes.describer.affectationAuto.formulaire">
                                <!--<input type="number" required class="form-control" name="percent" [(ngModel)]="comptes.data.formValues" (keyup)="activeLigne()" (change)="activeLigne()"> <span>%</span>-->
                                <compliance-form [formInput]="input" [parentFormGroup]="affectationAutoFormGroup"
                                                 (onKeyUp)="activeLigne()" (onChange)="activeLigne()"></compliance-form>
                                <span class="text-white text-size-16 font-weight-normal">%</span>
                            </div>
                            <button [disabled]="!affectationAutoFormGroup.valid" class="btn btn-secondary" type="submit"
                                    (click)="affectAuto()">{{'buttons.validate' | translate}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-7 col-md-12 mt-4">
                <div class="block-table block-affectation-drop scroll-table">

                    <compliance-table [items]="comptes.describer.tableItems" [datas]="actualLot.data"
                                      [styleClass]="['table-data--04']" (actionMenuEvent)="affectData($event)"
                                      [tableOption]="tableOption" [destroy$]="destroy$"></compliance-table>
                    <div class="block-empty "
                         *ngIf="nonAffecte.data.length === 0 && actualAffectationType === compteAffecteConst.nonAffecte">
                        <p class="label"
                           [innerHtml]="affectationTranslate.mainContent.finished.label | safe:'html'"></p>
                    </div>
                </div>
            </div>


            <div class="col-xl-5 col-md-12">

                <div class="block-drop-zone">
                    <div id="zone" class="zones row justify-content-end" [ngClass]="{'active':formAffectation.show}">
                        <div class="col-12 drop-total action-hover"
                             [ngClass]="{'alt':!nonAffecte.lot.active}" (click)="selectLot(nonAffecte)">
                            <span class="nb">{{nonAffecte.data.length}}</span>
                            <p class="label"
                               [innerHtml]="nonAffecte.lot.display.lotLabel[getNonAffecteSize()] | translate | safe:'html'"></p>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <ng-container *ngFor="let c of affectedComptes; index as i">
                                    <div [ngClass]="setClassColumnDropzone(i)">
                                        <compliance-affectation-compte-lot [lot]="c" (cleanEvent)="clean(c)"
                                                                           [direction]="nbColumnDropzone === 3 ? 'column':'row'"
                                                                           (selectEvent)="selectLot($event)">
                                        </compliance-affectation-compte-lot>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>


    </div>
</div>
<!-- STOP : content -->

<!-- START : footer -->
<footer id="footer" *ngIf="comptes">
    <div class="inner">
        <a href="#" class="btn btn-primary btn-back" (click)="goBack()">
            <span>{{'buttons.back' | translate}}</span>
        </a>
        <button class="btn btn-primary btn-next" [disabled]="!nextActive" (click)="launchControl()">
            <span>{{(controlId ? 'buttons.next' : 'buttons.finished') | translate}}</span>
        </button>
    </div>
</footer>
<!-- STOP : footer -->
