import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DomaineChoiceComponent} from "./domaine-choice.component";
import {ComplianceSharedModule} from "../../../../../common/compliance-shared.module";
import {DomaineChoiceRoutingModule} from "./domaine-choice-routing.module";

@NgModule({
    imports: [
        CommonModule,
        ComplianceSharedModule,
        DomaineChoiceRoutingModule
    ],
    declarations: [DomaineChoiceComponent]
})
export class DomaineChoiceModule {
}
