import {Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl} from "../../../../../../../common/component/control/abstract-control";
import {BackgroungType} from "../../../../../../../common/class/background/bg-compliance";
import {NotificationMessage} from "../../../../../../../common/class/polling/notification-message";
import {NotificationService} from "../../../../../../../common/service/push/notification.service";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {debounceTime, distinctUntilChanged, map, takeUntil} from "rxjs/operators";
import {SupervisionControl, Visa, VISA_TYPE} from "../../../../../../../common/class/control/supervision";
import {merge, Subject} from "rxjs";
import {Editor} from "primeng/editor";
import {ExerciceService} from "../../../../../../../common/service/exercice/exercice.service";
import {Exercice} from "../../../../../../../common/class/exercice/exercice";
import {VersionCompleteService} from "../../../version-complete/shared/version-complete.service";
import {HabilitationService} from "../../../../../../../common/service/auth/habilitation.service";

@Component({
    selector: 'compliance-crd-global',
    templateUrl: './supervision-control.component.html',
    styleUrls: ['./supervision-control.component.scss']
})
export class SupervisionControlComponent extends AbstractControl implements OnInit, OnDestroy {
    supervisionForm: FormGroup;
    visaType = VISA_TYPE;
    visa = Visa;
    @ViewChildren(Editor) editors:QueryList<Editor>;
    destroy$: Subject<boolean> = new Subject<boolean>();
    private _cas0: string;
    view: string;
    exercice: Exercice;
    private _nbRequest = 2;

    constructor( private _router: Router, private _route: ActivatedRoute, private _habilitationService: HabilitationService,
                private _notificationService: NotificationService, private _fb: FormBuilder,
                private _exerciceService: ExerciceService, private _completeService:VersionCompleteService) {
        super( BackgroungType.BG_TREE_2);
    }

    ngOnInit() {
        this.view = this._route.snapshot.queryParamMap.get('view');
        super.setContextWithRoute(this._route, () => {
        });
        this.supervisionForm = this._fb.group({
            observations: null,
            fixes: null,
            conclusion: null,
            amountRisk: new FormControl({value: null, disabled: true}),
            acceptedAmountRisk: null,
            deniedAmountRisk: null,
            visa: null
        });
        super.subscribeToNotificationControl({notificationService:this._notificationService, manageDataEvent :(n:NotificationMessage)=> this.manageDataEvent(n)});
        this.getDatas();
        this._cas0 = this._route.snapshot.queryParamMap.get('cas0');
    }

    public isDisabled(): boolean {
        return this.supervisionForm.value.visa === this.visa.VALIDATE || (this.exercice && this.exercice.validation);
    }


    protected manageDataEvent(notification: NotificationMessage) {
    }

    private async getDatas() {
        this._exerciceService.findExercicesById(this.exerciceId).then((val: Exercice) => {
            this.exercice = val;
            this.updateEditors();
            this.updateLoading();
        });

        this._controlService.getSupervisionControlData(this.projectId, this.exerciceId, this.controlId).then((val: SupervisionControl) => {
            this.updateLoading();
            val['amountRisk'] = Math.round(val['amountRisk']);
            this.supervisionForm.patchValue(val);
            this.editors.forEach((editor) => {
                editor.quill.enable(this.supervisionForm.value.visa !== this.visa.VALIDATE)

            });
            this.disableAmmount();
            this.supervisionForm.get('visa').valueChanges.pipe(
                takeUntil(this.destroy$),
                debounceTime(200),
                distinctUntilChanged(),
            ).subscribe(() => {
                this._complianceLoaderService.sendLoaderShow();
                this.disableAmmount();

                this._controlService.setSupervisionControlData(this.projectId, this.exerciceId, this.controlId, this.supervisionForm.getRawValue()).then(() => {
                    this.updateEditors();
                    this._complianceLoaderService.sendLoaderHide();
                });
            });

            merge(
                this.supervisionForm.get('observations').valueChanges,
                this.supervisionForm.get('fixes').valueChanges,
                this.supervisionForm.get('conclusion').valueChanges,
                this.supervisionForm.get('acceptedAmountRisk').valueChanges,
                this.supervisionForm.get('deniedAmountRisk').valueChanges
            ).pipe(
                takeUntil(this.destroy$),
                debounceTime(200),
                map(() => this.supervisionForm.getRawValue()),
                distinctUntilChanged()
            ).subscribe((data) => {
                if (!this.isDisabled()) {
                    this._controlService.setSupervisionControlData(this.projectId, this.exerciceId, this.controlId, data);
                }
            });
        });
    }

    private disableAmmount() {
        if (this.isDisabled()) {
            this.supervisionForm.controls.acceptedAmountRisk.disable();
            this.supervisionForm.controls.deniedAmountRisk.disable();
        }else{
            this.supervisionForm.controls.acceptedAmountRisk.enable();
            this.supervisionForm.controls.deniedAmountRisk.enable();

        }
    }

    private updateLoading() {
        this._nbRequest = this._nbRequest - 1;
        if (this._nbRequest === 0) {
            this._complianceLoaderService.sendLoaderHide();
        }
    }

    private updateEditors() {
        const isEnable = !this.exercice.validation && (this.supervisionForm.value.visa !== this.visa.VALIDATE);
        this.editors.forEach((editor) => editor.quill.enable(isEnable));
    }

    goBack() {
        this._complianceLoaderService.sendLoaderShow();
        let route;
        if (this._cas0 === 'true') {
            route = this._notificationService.generateControlRoute(this.projectId, this.domain, this.category, this.controlId, 'compte-rendu-general', this.exerciceId);
        } else {
            route = this._notificationService.generateControlRoute(this.projectId, this.domain, this.category, this.controlId, 'compte-rendu-detaille', this.exerciceId);
        }
        this._router.navigate([route], {
            queryParamsHandling: 'preserve',
            preserveFragment: true
        });
    }

    goNext() {
        if (this.exercice.validation) {
            this.goNextPage();
        } else {
            this._complianceLoaderService.sendLoaderShow();
            this._controlService.setSupervisionControlData(this.projectId, this.exerciceId, this.controlId, this.supervisionForm.getRawValue()).then(() => {
                this.goNextPage();
            });
        }
    }

    private goNextPage(){
        if(this._completeService.haveToShowPremiumPage() && !this._habilitationService.isPremium()){
            this._completeService.haveSeenPremiumPage();
            this.goToPremium();
        }else{
            this.goToDashboard();
        }
    }

    private goToPremium() {
        this._router.navigate(['compliance', 'version-complete']);
    }

    private goToDashboard() {
        this._router.navigate(['compliance', this.domain, this.category, 'project', this.projectId, this.exerciceId, 'dashboard']);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
