import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {LegalService} from "../../../shared/export-file/legal.service";
import {HabilitationService} from "../../../../../../common/service/auth/habilitation.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {User} from "../../../../../../common/class/user/user";

@Component({
    selector: 'compliance-gestion-cookie-preference',
    templateUrl: './gestion-cookie-preference.component.html',
    styleUrls: ['./gestion-cookie-preference.component.scss']
})
export class GestionCookiePreferenceComponent implements OnInit {

    cookieFormGroup: FormGroup;
    private _user: User;
    @Output() saveCookieEvent: EventEmitter<any> = new EventEmitter();

    constructor(private _fb: FormBuilder, private _exportFileService: LegalService, private _habilitationService: HabilitationService,
                private _deviceService: DeviceDetectorService) {
    }

    ngOnInit() {
        const browser = this._deviceService.browser;
        this._user = this._habilitationService.getConnectedUser();
        let val: any;

        if (!this._habilitationService.hasMakeCookieChoice()) {
            val = {
                cookiePubPerso: true,
                cookieStat: true,
            }
        } else {
            val = {
                cookiePubPerso: this._user.cookie.cookiePubPerso,
                cookieStat: this._user.cookie.cookieStat,
            }
        }


        console.log("user connected", this._user);
        this.cookieFormGroup = this._fb.group({
            cookiePubPerso: [val.cookiePubPerso],
            cookieStat: [val.cookieStat],
        });
    }


    validateChoice() {
        let cookie = this.cookieFormGroup.getRawValue();
        cookie.navigators = this._user.cookie ? this._user.cookie.navigators : []
        this.saveCookieEvent.emit(cookie);
    }


}
