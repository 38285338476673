import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";
import {FormResponse} from "../../class/form/form-response";

;

@Injectable()
export class FormService extends ComplianceApiService{

  constructor(private rest: RestService) {
      super('form')
  }

  getDefault(): Promise<any>{
      return this.rest.get({
          url: this.baseUri,
          relativeUri: 'default',
          arg: null as any
      })
  }

  findById(formId:string): Promise<any>{
      return this.rest.get({
          url: this.baseUri,
          parameters: [formId],
          arg: null as any
      })
  }

  findResponse(formId:string, dossierId: string, type:string): Promise<any>{
      return this.rest.get({
          url: this.baseUri,
          relativeUri: "response",
          parameters: [dossierId, formId, type],
          arg: null as any
      })
  }

  saveResponse(response: FormResponse): Promise<any>{
      if(response.id){
          return this.updateResponse(response)
      }else{
          return this.createResponse(response)
      }
  }

  private createResponse(response: FormResponse): Promise<any>{
      return this.rest.post({
          url: this.baseUri,
          relativeUri: "response",
          data: response,
          arg: null as any
      })
  }

  private updateResponse(response: FormResponse): Promise<any>{
      return this.rest.put({
          url: this.baseUri,
          relativeUri: "response",
          data: response,
          arg: null as any
      })
  }

}
