import {AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import * as _ from "lodash";
import {PIE_OPTION} from "../../../../../../../common/class/graphique/graphique";
import {ComplianceI18nLoader} from "../../../../../../../common/class/i18n/compliance-i18n-loader";
import {ControlForDashboard, STATUS_COLORS, STATUS_COLORS_HOVER, StatusControl} from "../../../../../../../common/class/control/control-descriptor";
import {UIChart} from "primeng/chart";


class ControlDashboard {
    constructor() {
        StatusControl.values().forEach((status) => this.count[status] = 0);
    }

    count: { [keys: string]: number } = {};
}

@Component({
    selector: 'compliance-synthesis-graph',
    templateUrl: './synthesis-graph.component.html',
    styleUrls: ['./synthesis-graph.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SynthesisGraphComponent extends ComplianceI18nLoader implements AfterViewInit {
    private viewInit = false;
    private _areaSelected: StatusControl;
    @Input() set controlList(list: ControlForDashboard[]) {
        this.getGraphDatas(list);
    }

    @ViewChild('chartPie') uiChart: UIChart;
    private _orderStatus = [
        StatusControl.NONE,
        StatusControl.RED,
        StatusControl.ORANGE,
        StatusControl.GREEN,
    ];
    pieGraph = {
        labels: ['Non réalisés', 'Avec incohérences', 'Avec points de vigilance', 'Sans incohérences'],
        datasets: [{
            data: [],
            backgroundColor: [
                STATUS_COLORS[StatusControl.NONE],
                STATUS_COLORS[StatusControl.RED],
                STATUS_COLORS[StatusControl.ORANGE],
                STATUS_COLORS[StatusControl.GREEN],
            ],
            hoverBackgroundColor: [
                STATUS_COLORS_HOVER[StatusControl.NONE],
                STATUS_COLORS_HOVER[StatusControl.RED],
                STATUS_COLORS_HOVER[StatusControl.ORANGE],
                STATUS_COLORS_HOVER[StatusControl.GREEN],
            ]
        }]
    };
    pieOption = _.merge(_.cloneDeep(PIE_OPTION), {
        responsive:true,
        maintainAspectRatio: false,
        plugins: {
            // Change options for ALL labels of THIS CHART
            datalabels: {
                display: true,
                color: '#4e3875',
                font: {
                    style: 'bold'
                },
                formatter: (value, ctx) => {
                    return !!value ? value : '';
                },
            }
        },
        elements: {
            arc: {
                borderWidth: 0,
                borderColor: '#fff'
            }
        },
        cutoutPercentage: 60,
    });

    constructor() {
        super();
    }

    private getGraphDatas(controlList: ControlForDashboard[]) {
        let controlDashboard = new ControlDashboard();
        controlDashboard = this.computeControl(controlList, controlDashboard);

        this.pieGraph.datasets[0].data = [
            controlDashboard.count[StatusControl.NONE],
            controlDashboard.count[StatusControl.RED],
            controlDashboard.count[StatusControl.ORANGE],
            controlDashboard.count[StatusControl.GREEN]
        ];
        this.updateChartUi();
    }

    private computeControl(controlList: ControlForDashboard[], controlDsb: ControlDashboard): ControlDashboard {
        if (!controlList) {
            return controlDsb;
        }

        controlList.forEach((control: ControlForDashboard) => {
            switch (control.status) {
                case StatusControl.RED: {
                    control.order = 1;
                    break;
                }
                case StatusControl.ORANGE: {
                    control.order = 2;
                    break;
                }
                case StatusControl.GREEN: {
                    control.order = 3;
                    break;
                }
            }
            if (!control.status) {
                controlDsb.count[StatusControl.NONE]++;
            } else {
                controlDsb.count[control.status]++;
            }
        });
        return controlDsb;
    }

    private updateChartUi() {
        if (this.viewInit && this.uiChart) {
            this.uiChart.chart.update();
        }
    }

    ngAfterViewInit(): void {
        this.viewInit = true;
        this.updateChartUi();
    }

    setArea(index: number) {
        if (this._areaSelected) {
            const oldIndex = this._orderStatus.indexOf(this._areaSelected);
            this.pieGraph.datasets[0].backgroundColor[oldIndex] = STATUS_COLORS[this._areaSelected];
        }
        if (this._areaSelected === this._orderStatus[index]) {
            this._areaSelected = null;
        } else {
            this._areaSelected = this._orderStatus[index];
            this.pieGraph.datasets[0].backgroundColor[index] = STATUS_COLORS_HOVER[this._areaSelected];
        }
        this.updateChartUi();
    }
}
