import {Component, Input, OnInit} from '@angular/core';
import {ControlDescriptor} from "../../../../../../../../../common/class/control/control-descriptor";

@Component({
    selector: 'compliance-compte-rendu-detaille-origin-incoherence',
    templateUrl: './compte-rendu-detaille-origin-incoherence.component.html',
    styleUrls: ['./compte-rendu-detaille-origin-incoherence.component.scss']
})
export class CompteRenduDetailleOriginIncoherenceComponent implements OnInit {
    display: boolean;
    @Input() control:ControlDescriptor;

    constructor() {
    }

    ngOnInit() {
    }


    showDialog() {
        this.display = true;
    }
}
