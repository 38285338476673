export enum UploadActionType{
    EXTRACT_SIREN="extractSiren",
    COMPLIANCE_FILE="complianceFile"
}

export enum UploadActionNext{
    balance="balance",
    project="project"
}



export const resumableSizeMin = 1024 * 1024 * 5;
