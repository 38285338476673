import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";

@Injectable()
export class AccountRefService extends ComplianceApiService {

    constructor(private restService: RestService) {
        super('accountFormRef', 'bo');
    }

    public getAllAccountRef(): Promise<any> {
        return this.restService.get({
            url: this.baseUri,
            arg: null as any
        });
    }
    public getSubscriptionList(): Promise<any> {
        return this.restService.get({
            url: this.baseUri,
            relativeUri: 'susbcription',
            arg: null as any
        });
    }

    public getAccountOriginList(): Promise<any> {
        return this.restService.get({
            url: this.baseUri,
            relativeUri:'accountOrigin',
            arg: null as any
        });
    }
}
