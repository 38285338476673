<div id="page-dashboard" class="height-100 page-botom-transparent" *ngIf="project && exercices && exercices.length">
    <div class="container-fluid d-flex flex-column height-100">
        <div id="block-exercice" class="row mt-0 mb-3">
            <div class="height-100 col-12 pl-1 col-md-7">
                <compliance-legal-entity [project]="project"
                                         [exercices]="exercices"
                                         [selectedExercice]="currentExercice"
                                         [paramsSubscription]="commonSubscription?.parametersActivated"
                                         (selectedExerciceChange)="setExercice($event)"></compliance-legal-entity>
            </div>
            <div class="height-100 col-md-5 col-12 pl-0 pr-1">
                <compliance-fec-management [projectId]="_projectId" [ngClass]="{'disabled': !isFecUsable}"
                                           [exercice]="currentExercice"
                                           [disabled]="!isFecUsable"
                                           (launchStateMachine)="launchStateMachine($event)">
                </compliance-fec-management>
            </div>
        </div>

        <ng-container *ngIf="!(loading | async) || controlListFiltered?.length > 0">
            <ng-container *ngIf="!cas0; else templateCas0">
                <div class="row mt-3 ml-0 title-dashboard">
                    <h1>{{'dashboard.title' | translate}}</h1>
                </div>
                <div id="block-tva-dashboard" class="row my-3 flex-grow-1">
                    <div class="height-100 col-md-9 col-12 pl-1">
                        <compliance-tva-dashboard [controlList]="controlListFiltered"
                                                  [exerciceValidation]="currentExercice.validation"
                                                  [controlSubscription]="tvaSubscription?.controlsMetadatas"
                                                  (launchStateMachine)="launchStateMachine($event)">
                        </compliance-tva-dashboard>
                    </div>
                    <div class="block-graph height-100 col-md-3 col-12 slimscroll">
                        <div id="block-graph" class="row d-flex flex-row flex-md-column">
                            <div id="block-global-risk" class="col w-100 mb-3 pl-0 pr-1">
                                <compliance-global-risk [controlList]="controlListFiltered"></compliance-global-risk>
                            </div>
                            <div id="block-synthesis" class="col w-100 pl-0 pr-1">
                                <compliance-synthesis-graph [controlList]="controlListFiltered"></compliance-synthesis-graph>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #templateCas0>
                <div class="cas0 d-flex flex-column flex-grow-1">
                    <div class="row d-flex flex-column align-content-center text-center m-5">
                        <h1 class="text-size-30 font-weight-bold">{{'dashboard.cas0.title' | translate}}</h1>
                        <h3 class="text-size-18 mt-4">{{'dashboard.cas0.subtitles.first' | translate:{annee: currentExercice.annee} }}</h3>
                        <h3 class="text-size-18 font-weight-bold">{{'dashboard.cas0.subtitles.second' | translate:{annee: currentExercice.annee} }}</h3>
                    </div>
                    <div class="row align-items-center d-flex h-100 mt-3 mb-5">
                        <div class="proposal col-4 d-flex flex-column align-items-start h-100 mr-5">
                            <h1 class="text-size-20 text-uppercase font-weight-bold">{{'dashboard.cas0.stepByStep.title' | translate}}</h1>
                            <div class="my-5 d-flex flex-row align-items-center">
                                <div class="step-img d-flex mr-3">
                                    <img class="m-auto" src="assets/images/skin/dashboard/stepByStep.svg" />
                                </div>
                                <span class="text-size-16">{{'dashboard.cas0.stepByStep.text' | translate}}</span>
                            </div>
                            <button class="btn btn-heroic-secondary px-5 d-flex align-items-center" (click)="goToProject()">
                                <span>{{'dashboard.cas0.stepByStep.button' | translate}}</span>
                                <i class="far fa-play-circle fa-2x ml-4"></i>
                            </button>
                        </div>
                        <div class="separator h-100"></div>
                        <div class="proposal col d-flex flex-column align-items-start h-100 ml-5">
                            <h1 class="mb-5 text-size-20 text-uppercase font-weight-bold">{{'dashboard.cas0.automatic.title' | translate}}</h1>
                            <div class="d-flex flex-row align-items-center">
                                <div class="step-img d-flex mr-3">
                                    <img class="m-auto" src="assets/images/skin/dashboard/files.svg" />
                                </div>
                                <span class="text-size-16">{{'dashboard.cas0.automatic.texts.files' | translate}}</span>
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <div class="no-background small mr-3">
                                    <div class="separator h-100 m-auto"></div>
                                </div>
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <div class="step-img d-flex mr-3">
                                    <img class="m-auto" src="assets/images/skin/dashboard/parametrages.svg" />
                                </div>
                                <span class="text-size-16">{{'dashboard.cas0.automatic.texts.parameters' | translate}}</span>
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <div class="no-background small mr-3">
                                    <div class="separator h-100 m-auto"></div>
                                </div>
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <div class="no-background mr-3 text-center">
                                    <i class="far fa-play-circle fa-4x"></i>
                                </div>
                                <span class="text-size-16">{{'dashboard.cas0.automatic.texts.launch' | translate}}</span>
                            </div>
                            <div class="mt-5">
                                <compliance-light-subscription-button class="btn btn-heroic-primary d-flex align-items-center"
                                                                      pTooltip="{{'globalParams.accessTooltip' | translate}}" tooltipPosition="bottom"
                                                                      [disabled]="!commonSubscription?.parametersActivated"
                                                                      (click)="goToGlobalParams()">
                                    <span>{{'dashboard.cas0.automatic.button' | translate}}</span>
                                    <img class="ml-4" src="assets/images/skin/dashboard/parametrages.svg" />
                                </compliance-light-subscription-button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </div>
    <div class="block-bottom-actions">
        <div class="inner text-center d-flex justify-content-between">
            <button href="#" class="btn btn-primary btn-back" (click)="goBack()">
                <span>{{'buttons.back' | translate}}</span>
            </button>
            <button class="btn btn-primary px-5 d-flex align-items-center" (click)="goToProject()" *ngIf="!cas0 && !currentExercice?.validation">
                <span>{{'dashboard.startControl' | translate}}</span>
                <i class="far fa-play-circle fa-2x ml-4"></i>
            </button>
        </div>
    </div>
</div>
