import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../../../../../../common/class/compliance-api.service";
import {RestService} from "../../../../../../../common/service/rest.service";
import {SESSION_TAB_ID} from "../../../../../../../common/class/compliance-constant";

@Injectable({
    providedIn: 'root'
})
export class ViesService extends ComplianceApiService {
    private readonly sessionTabId: string;

    constructor(private _restService: RestService) {
        super('vies');
        this.sessionTabId = sessionStorage.getItem(SESSION_TAB_ID);
    }

    async export(projectId: string, exerciceId: string, fecFileIds: string[]) {
        let blob = await this._restService.getFile({
            url: this.baseUri,
            relativeUri: 'export',
            parameters: [projectId, exerciceId, fecFileIds.join(','), this.sessionTabId]
        });
    }

    async exportCsv(file: File, projectId: string, exerciceId: string) {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        let blob = await this._restService.post({
            url: this.baseUri,
            relativeUri: 'csv',
            data: formData,
            parameters: [projectId, exerciceId, this.sessionTabId],
            arg: null
        });
    }
}
