import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";
import {CategoryControl, ControlDescriptor, ControlForDashboard, DomaineControl} from "../../class/control/control-descriptor";
import {of} from "rxjs";
import {CacheService} from "../cache/cache.service";
import {NotificationService} from "../push/notification.service";
import {CONTROL_FILE_VIE_ID, SESSION_TAB_ID} from "../../class/compliance-constant";
import {SupervisionControl} from "../../class/control/supervision";
import {ControlFileService} from "./control.file.service";

@Injectable()
export class ControlService extends ComplianceApiService {

    constructor(private restService: RestService, private cacheService: CacheService, private notificationService: NotificationService, private _controlFileService: ControlFileService) {
        super("control");
    }

    async findControlByDomainAndCategory(domain: DomaineControl, category: CategoryControl): Promise<ControlDescriptor[]> {
        let cacheData = this.cacheService.getData("list_control");
        if (cacheData) {
            return of(cacheData).toPromise();
        }
        let controls = await this.restService.get({
            url: this.baseUri,
            parameters: [domain, category],
            arg: null as ControlDescriptor[]
        });
        this.cacheService.addAnyToCache(controls);
        this.cacheService.addToCache("list_control", controls);
        return of(controls).toPromise();
    }

    public continueExecutionControl(projectId: string, stateMachineId: string, exerciceId: string, event: string = "RELOAD", context: any = {}, stepByStep: boolean = true) {
        this.execute(projectId, exerciceId, stateMachineId, event, context, stepByStep);
    }

    public dataControl(projectId: string, stateMachineId: string, exerciceId: string, event: string = "RELOAD", stepByStep: boolean = true) {
        let sub = this.notificationService.webSocketConnectedSubjectObservable$.subscribe(() => {
            this.restService.get({
                url: this.baseUri,
                relativeUri: "data",
                parameters: [projectId, exerciceId, stateMachineId, event, stepByStep, sessionStorage.getItem(SESSION_TAB_ID)],
                arg: null
            });
            sub.unsubscribe();
        });

        this.notificationService.init();
    }

    async backControl(projectId: string, stateMachineId: string, exerciceId: string, event: string | any, stepByStep: boolean = true) {
        return this.restService.get({
            url: this.baseUri,
            relativeUri: "back",
            parameters: [projectId, exerciceId, stateMachineId, event, stepByStep, sessionStorage.getItem(SESSION_TAB_ID)],
            arg: null
        });

    }

    public launchExecutionControl(projectId: string, exerciceId: string, stateMachineId: string, stepByStep: boolean = true) {
        return this.restService.post({
            url: this.baseUri,
            relativeUri: "launchExecution",
            parameters: [projectId, exerciceId, stateMachineId, "RELOAD", stepByStep, sessionStorage.getItem(SESSION_TAB_ID)],
            data: {},
            arg: null
        });
    }

    private execute(projectId: string, year: string, stateMachineId: string, event: string, context: any, stepByStep: boolean = true): Promise<any> {
        return this.restService.post({
            url: this.baseUri,
            relativeUri: "continueExecution",
            parameters: [projectId, year, stateMachineId, event, stepByStep, sessionStorage.getItem(SESSION_TAB_ID)],
            data: context,
            arg: null
        });
    }


    private showControlStateValue(value: any) {
        // this.toaster.success(value.state);
    }

    sendQuestionForm(projectId: string, response: any, stateMachineId: string): Promise<any> {
        return this.restService.post({
            url: this.baseUri,
            relativeUri: "response",
            parameters: [projectId, stateMachineId],
            data: response,
            arg: null
        });
    }

    responseToForm(projectId: string, response: any, stateMachineId: string) {
        this.sendQuestionForm(projectId, response, stateMachineId);
    }

    async findControlById(controlId: string): Promise<ControlDescriptor> {
        let cacheData = this.cacheService.getData(controlId);
        if (cacheData) {
            return of(cacheData).toPromise();
        }
        let control;
        if(controlId === CONTROL_FILE_VIE_ID){
            control = await this._controlFileService.findControlVies();
        }else {
            control = await this.restService.get({
                url: this.baseUri,
                parameters: [controlId],
                arg: null as ControlDescriptor
            });
        }
        this.cacheService.addToCache(control.id, control);
        return of(control).toPromise();
    }

    findControlForDashboard(projectId, exerciceId): Promise<ControlForDashboard[]> {
        return this.restService.get({
            url: this.baseUri,
            relativeUri: "dashboard",
            parameters: [projectId, exerciceId],
            arg: null as ControlForDashboard[]
        });
    }

    // Supervision control
    getSupervisionControlData(projectId: string, exerciceId: string, controlId: string): Promise<SupervisionControl> {
        return this.restService.get({
            url: this.baseUri,
            relativeUri: "visa",
            parameters: [projectId, exerciceId, controlId],
            arg: null as SupervisionControl
        });
    }

    setSupervisionControlData(projectId: string, exerciceId: string, controlId: string, supervision: SupervisionControl): Promise<SupervisionControl> {
        return this.restService.put({
            url: this.baseUri,
            relativeUri: "visa",
            parameters: [projectId, exerciceId, controlId],
            data: supervision,
            arg: null as SupervisionControl
        });
    }
}
