import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserEditComponent} from './user-edit/user-edit.component';
import {UserRoutingModule} from "./user-routing.module";
import {ComplianceSharedModule} from "../../../../../common/compliance-shared.module";
import {UserAccountComponent} from "./account-users/user-account.component";
import {GestionCookiesModule} from "../gestion-cookies/gestion-cookies.module";


@NgModule({
    imports: [
        CommonModule,
        ComplianceSharedModule,
        GestionCookiesModule,
        UserRoutingModule,
    ],
    providers: [],
    declarations: [
        UserEditComponent,
        UserAccountComponent
    ]
})
export class UserModule {
}
