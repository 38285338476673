<div id="wrap">

    <!-- START : header -->
    <!-- STOP : header -->

    <!-- START : content -->
    <div id="content" class="page-navigation">


        <div class="block-tree block-tree--02" id="level-2" #upperTree>
            <div class="inner">
                <ul *ngIf="showUpperTree">
<!--                    <li class="item item-01"><a href=""><span [innerHtml]="'categorie.taxeSalaire' | translate | safe:'html'"></span></a></li>-->
<!--                    <li class="item item-02"><a href=""><span [innerHtml]="'categorie.CET' | translate | safe:'html'"></span></a></li>-->
<!--                    <li class="item item-03"><a href=""><span [innerHtml]="'categorie.resultatFiscal' | translate | safe:'html'"></span></a></li>-->
<!--                    <li class="item item-04"><a href=""><span [innerHtml]="'categorie.IS' | translate | safe:'html'"></span></a></li>-->
                    <li class="item item-05 active" (click)="goToProject(category.TVA)"><a href="#"><span [innerHtml]="'categorie.TVA' | translate | safe:'html'"></span></a></li>
                </ul>
            </div>
        </div>
        <div class="block-tree block-tree--01" id="level-1" #lowerTree>
            <div class="inner">
                <ul *ngIf="!showUpperTree">
                    <li class="item item-01 active"><a href="#" (click)="scrollToUpperTree(domain.FISCAL)"><span [innerHtml]="'domain.fiscal' | translate | safe:'html'"></span></a></li>
                    <li class="item item-02"><a href=""><span [innerHtml]="'domain.social' | translate | safe:'html'"></span></a></li>
                    <li class="item item-03"><a href=""><span [innerHtml]="'domain.juridique' | translate | safe:'html'"></span></a></li>
                </ul>
            </div>
        </div>

        <compliance-footer *ngIf="showUpperTree" [isCallback]="true" (backEvent)="executeBackAction()"></compliance-footer>

    </div>
    <!-- STOP : content -->

</div>
