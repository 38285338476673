export const gestionFile = {
    title: "Gestion des fichiers",
    head: {
        type: "Type",
        nbFile: "Nombre de fichiers",
        exercice: "Exercice",
    },

    table: {
        noFile: {
            p1:"Aucun document de type ",
            p2:" n'a été déposé au titre de l'exercice "
        },
        headers: {
            period: "Date",
            fileName: "Fichier",
            depotDate: "Date dépot",
            who: "Par"
        },
        export: {
            vies: "Vérification VIES TVA"
        },
        warnErrorFile: "Une erreur a eu lieu lors du dépôt du fichier. Afin de réaliser les contrôles, vous devez réimporter le fichier.",
        premium: "Uniquement disponible avec la version complète"
    },

    ModalDeleting: {
        headers : {
            deleteAll: "Confirmation de la suppression des fichiers",
            deleteOne: "Confirmation de la suppression du fichier"
        },
        contents : {
            deleteAll: {
                part1FEC: "Etes-vous sûr de vouloir supprimer définitivement tous vos FEC de l'exercice ",
                part1Declarations: "Etes-vous sûr de vouloir supprimer définitivement toutes vos déclarations de l'exercice ",
                part2:" ?"
            },
            deleteOne: {
                part1: "Etes-vous sûr de vouloir supprimer définitivement le fichier ",
                part2: " ?"
            }
        }
    },

    Modalbuttons: {
        cancel: "Annuler",
        confirm : "Confirmer"
    }



};
