export const globalParams = {
    title: 'Paramétrer mes contrôles',
    subtitle: 'Cliquer sur le paramétrage que vous souhaitez réaliser',
    accessTooltip: 'Paramétrages',
    legend: {
        DONE: 'Paramétrages réalisés',
        TO_REVIEW: 'Paramétrages à revoir',
        TO_DO: 'Paramétrages non réalisés',
        NOT_FEASIBLE: 'Paramétrages non réalisables'
    },
    startControl: 'Lancer les contrôles'
};
