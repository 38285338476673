import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";

@Injectable()
export class SubscriptionService extends ComplianceApiService {

    constructor(private restService: RestService) {
        super('subscription', 'bo');
    }

    public getSubscriptionList(): Promise<any> {
        return this.restService.get({
            url: this.baseUri,
            relativeUri: 'all',
            arg: null as any
        });
    }
}
