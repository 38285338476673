const compteRenduTooltip = {
    detailleControl3: {
        title: 'Tableaux de réconciliation détaillés',
        ecritures_simples: `Une écriture simple se découpe en 3 lignes : un compte de produit, un compte de TVA collectée, un compte de tiers.`,
        ecritures_complexes: `Une écriture complexe mono-taux de TVA est composée de plusieurs comptes de produits (ou éventuellement des comptes 654 et 665) mais où chaque compte est soumis au même taux de TVA.`,
        ecritures_complexes_multi: `Une écriture complexe multi-taux de TVA est composée de plusieurs comptes de produits (ou éventuellement des comptes 654 et 665), où au moins un compte est soumis à plusieurs taux de TVA.`,
    }
}


export const compteRendu = {
    general: {
        title: "Compte rendu général",
    },
    detaille: {
        titleSoumis: "Incohérences sur les produits soumis à TVA",
        titleNonSoumis: "Incohérences sur les produits non soumis à TVA",
        subTitle: "Résultats détaillés"
    },
    detailleControl4: {
        title: 'Tableaux de réconciliation détaillés',
        subtitle: {
            tableau_reconciliation_global: 'Synthèse',
            tableau_reconciliation_autres_operations_imposables: 'Autres opérations imposables',
            tableau_reconciliation_regularisations: 'Régularisations',
            tableau_reconciliation_ventes_prestations_services: 'Ventes et prestations de services',
        },
        table: {
            header: {
                monthAndyear: 'Date',
                baseHtComptab: 'Bases HT comptabilisées',
                baseHtDecla: 'Bases HT déclarées',
                diff: 'Différences',
                diffCumule: 'Différences cumulées',
            }
        }
    },
    detailleControl6: {
        title: 'Tableaux de réconciliation détaillés',
        subtitle: {
            synthese_base_ht_comptabilise: 'Synthèse des bases HT comptabilisées',
            synthese_base_ht_declaree: 'Synthèse des bases HT déclarées',
            tableau_reconciliation_2_1_dom :'Réconciliation des bases HT soumises à un taux de 2,10%',
            tableau_reconciliation_5_5 :'Réconciliation des bases HT soumises à un taux de 5,50%',
            tableau_reconciliation_8_5 :'Réconciliation des bases HT soumises à un taux de 8,50%',
            tableau_reconciliation_10_0 :'Réconciliation des bases HT soumises à un taux de 10%',
            tableau_reconciliation_20_0 :'Réconciliation des bases HT soumises à un taux de 20%',
        },
        synthese_base_ht: {
            table: {
                header: {
                    monthAndyear: 'Date',
                    t_2_1_dom: '2,10%',
                    t_5_5: '5,5%',
                    t_8_5: '8,5%',
                    t_10_0: '10%',
                    t_20_0: '20%',
                    total: 'Total'
                }
            }
        },
        reconciliation:{
            table:{
                header:{
                    monthAndyear: 'Date',
                    baseHtCompta : 'Bases HT comptabilisées',
                    baseHtDecla : 'Bases HT déclarées',
                    diff : 'Différence',
                    diffCumule : 'Différence cumulée',
                    impactCumule: 'Impact cumulé'


                }
            }
        }

    },
    detailleControl27: {
        title: 'Tableaux de réconciliation détaillés',
        subtitle: {
            deductible: "Incohérences sur les charges dont les écritures comportent normalement de la TVA déductible",
            nonDeductible: "Incohérences sur les charges dont les écritures ne comportent normalement pas de TVA déductible",
            ecritureSuspectes: `Écritures suspectes à vérifier`

        },
        table: {
            header: {
                deductible: {
                    compteNum: 'N° compte',
                    compteLib: `Intitulé du compte`,
                    nb_ecritures_sans_tva_deductible: `Absence d'écriture de TVA déductible`,
                    montant: `Bases HT`,
                    tva_non_deduite: `Impact`
                },
                nonDeductible: {
                    compteNum: 'N° compte',
                    compteLib: `Intitulé du compte`,
                    nb_ecritures_avec_tva_deductible: `Présence d'écritures de TVA déductible`,
                    tva_deduite_a_tort: `Impact`,
                },
                ecritureSuspectes: {
                    journalCode: `Codes journaux`,
                    journalLib: `Intitulé journaux`,
                    nb_ecritures_suspectes_tva_deductible: `Écritures`,
                    solde_tva_suspect_tva_deductible: `Solde`
                },
            }
        }
    },
    detailleControl18: {
        title: 'Résultats Détaillés',
        subtitle: 'Réconciliation des bases HT de LIC déclarées',
        table: {
            header: {
                monthAndyear: 'Date',
                lic_brutes_deb: `LIC brutes`,
                lic_minorations_deb: `Minorations`,
                lic_majorations_deb: `Majorations`,
                lic_nettes_deb: `LIC nettes DEB`,
                lic_nettes_tva: `LIC nettes CA3`,
                lic_nettes_ecart: `Différences`,
                lic_nettes_ecart_cumulee: `Impact TVA`,
            }
        }
    },
    detailleControl19: {
        title: 'Résultats Détaillés',
        subtitle: 'Réconciliation des bases HT de LIC déclarées',
        subtitleTvaFec: 'Réconciliation des bases HT de LIC comptabilisées et déclarées dans la CA3',
        subtitleSynthese: 'Synthèse',
        subtitleDebFec: 'Réconciliation des bases HT de LIC comptabilisées et déclarées dans la DEB',
        table: {
            header: {
                monthAndyear: 'Date',
                lic_brutes_deb: `LIC brutes`,
                lic_minorations_deb: `Minorations`,
                lic_majorations_deb: `Majorations`,
                lic_nettes_deb: `LIC nettes DEB`,
                lic_nettes_tva: `LIC nettes CA3`,
                lic_nettes_ecart: `Différences`,
                lic_nettes_ecart_cumulee: `Impact TVA`,
            },
            headerTvaFec: {
                monthAndyear: 'Date',
                lic_nettes_fec: `LIC comptabilisées`,
                lic_nettes_tva: `LIC nettes CA3`,
                lic_difference_fec_tva: `Différences`,
                lic_difference_cumulee_fec_tva: `Différences cumulées`,
            },
            headerDebFec: {
                monthAndyear: 'Date',
                lic_nettes_fec: `LIC comptabilisées`,
                lic_nettes_deb: `LIC nettes DEB`,
                lic_difference_fec_deb: `Différences`,
                impact_fec_tva: `Impact TVA`,
            },
            headerSynthese: {
                monthAndyear: 'Date',
                lic_nettes_fec: `LIC comptabilisées`,
                lic_nettes_tva: `LIC nettes CA3`,
                lic_nettes_deb: `LIC nettes DEB`,
            }
        }
    },
    detailleControl8: {
        title: `Tableaux de réconciliation détaillés`,
        synthese: `Synthèse des encaissements<br><span class="font-italic" style="font-size: 60%">La loupe permet de visualiser les écritures prises en compte pour les calculs en fonction de vos affectations clients.</span>`,
        encaissement: `Encaissements TTC par affectation<br><span class="font-italic" style="font-size: 60%">La loupe permet de visualiser les écritures prises en compte pour les calculs en fonction de vos affectations clients.</span>`,
        suspect: `Ecritures suspectes à vérifier`,
        table: {
            header: {
                synthese: {
                    monthAndyear: 'Date',
                    encaissement_ht_comptabilise_soumis: `Bases HT comptabilisées`,
                    encaissement_ht_declare: `Bases HT déclarées`,
                    difference: `Différences`,
                    difference_cumule: `Différences cumulées`
                },
                encaissement: {
                    monthAndyear: 'Date',
                    encaissement_ttc_comptabilise_soumis: `Soumis`,
                    encaissement_ttc_comptabilise_411_aux_non_soumis: `Non soumis`,
                    encaissement_ttc_comptabilise_411_aux_mixtes: `Mixtes`,
                    encaissement_ttc_comptabilise: `Total TTC`
                },
                suspect: {
                    codeJournal: 'Codes Journaux',
                    libelleJournal: `Intitulés journaux`,
                    num_ecritures_suspectes_411: `Nombre d'écritures`,
                    solde_encaissement_suspect_411: `Soldes`
                },
            }
        }
    },
    detailleControl3: {
        title: 'Tableaux de réconciliation détaillés',
        produits_incoherences_ecritures_simples: `Incohérences sur les écritures simples* de produits<br>
<span class="font-italic" style="font-size: 60%">* ${compteRenduTooltip.detailleControl3.ecritures_simples}</span>`,
        charges_incoherences_ecritures_simples: `Incohérences sur les écritures simples* de charges<br>
<span class="font-italic" style="font-size: 60%">* ${compteRenduTooltip.detailleControl3.ecritures_simples}</span>`,
        incoherences_ecritures_complexes_mono_taux: `Incohérences sur les écritures complexes mono-taux* de TVA<br>
<span class="font-italic" style="font-size: 60%">* ${compteRenduTooltip.detailleControl3.ecritures_complexes}</span>`,
        incoherences_ecritures_complexes_multi_taux: `Incohérences sur les écritures complexes multi-taux* de TVA<br>
<span class="font-italic" style="font-size: 60%">* ${compteRenduTooltip.detailleControl3.ecritures_complexes_multi}</span>`,
        table: {
            header: {
                incoherenceEcritureSimpleCharges: {
                    compteNum: 'N° compte',
                    compteLib: `Intitulé du compte`,
                    taux_tva: `Taux de TVA sélectionné`,
                    base_ht: `Bases HT`,
                    montant_tva_collectee_comptabilisee: `TVA collectée comptabilisée`,
                    montant_tva_collectee_theorique: `TVA collectée<br>théorique`,
                    difference: `Différences`,
                    nb_ecritures_incoherentes: `Nombre d’écritures en incohérences`,
                },
                incoherenceEcritureSimpleProduits: {
                    compteNum: 'N° compte',
                    compteLib: `Intitulé du compte`,
                    taux_tva: `Taux de TVA sélectionné`,
                    base_ht: `Bases HT`,
                    montant_tva_collectee_comptabilisee: `TVA collectée comptabilisée`,
                    montant_tva_collectee_theorique: `TVA collectée<br>théorique`,
                    difference: `Différences`,
                    nb_ecritures_incoherentes: `Nombre d’écritures en incohérences`,
                },
                incoherenceEcritureComplexeMonoTaux: {
                    taux_tva: `Taux de TVA sélectionné`,
                    base_ht: `Bases HT`,
                    montant_tva_collectee_comptabilisee: `TVA collectée comptabilisée`,
                    montant_tva_collectee_theorique: `TVA collectée<br>théorique`,
                    difference: `Différences`,
                    nb_ecritures_incoherentes: `Nombre d’écritures en incohérences`,
                },
                incoherenceEcritureComplexeMultiTaux: {
                    label: '',
                    base_ht: `Bases HT`,
                    montant_tva_collectee_comptabilisee: `TVA collectée comptabilisée`,
                    montant_tva_collectee_theorique: `TVA collectée<br>théorique`,
                    difference: `Différences`,
                    nb_ecritures_incoherentes: `Nombre d’écritures en incohérences`,
                },
            }
        }
    },
    detailleControl28: {
        title: 'Tableaux de réconciliation détaillés',
        soldes: 'Soldes TTC des comptes fournisseurs',
        anticipation: 'Anticipation de la déductibilité de la TVA',
        noTVA: 'Comptes fournisseurs sans écriture de TVA déductible',
        table: {
            header: {
                soldes: {
                    monthAndyear: 'Date',
                    solde_ttc_fournisseurs_biens: 'Fournisseurs de biens',
                    solde_ttc_fournisseurs_services: 'Fournisseurs de services',
                    solde_ttc_fournisseurs_mixtes: 'Fournisseurs mixtes',
                    solde_ttc_fournisseurs_sans_tva: 'Fournisseurs sans TVA',
                    solde_ttc_fournisseurs_total: 'Total',
                },
                anticipation: {
                    monthAndyear: 'Date',
                    solde_tva_deductible_comptabilise: 'Solde des comptes de TVA déductible',
                    solde_tva_deductible_theorique: 'Solde min théorique de TVA déductible',
                    difference: 'Différences',
                },
                noTVA: {
                    codeCompte: 'N° de compte',
                    libelleCompte: `Intitulé du compte`,
                },
            }
        }
    }

};
