import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ComplianceI18nLoader} from "../../../../../../../common/class/i18n/compliance-i18n-loader";
import {UploadFile} from "../../../../../../../common/class/upload/upload-file";
import _ from 'lodash';

@Component({
  selector: 'compliance-table-file-date',
  templateUrl: './table-file-date.component.html',
  styleUrls: ['./table-file-date.component.scss']
})
export class TableFileDateComponent extends ComplianceI18nLoader implements OnInit, OnChanges {

    @Input() files: UploadFile[];
    @Input() type: string;
    @Input() hasVieControl: boolean;
    @Input() toolTipVie: string;
    @Output() deleteEvent: EventEmitter<UploadFile> = new EventEmitter();
    @Output() editEvent: EventEmitter<UploadFile> = new EventEmitter();
    @Output() exportViesEvent: EventEmitter<UploadFile> = new EventEmitter();

    manualCA3Exist: boolean;

    constructor() {
        super();
    }

    ngOnInit() {
        this.checkExistingManualCA3();
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.files && changes.files.currentValue){
            this.files = _.sortBy(this.files, (uf: UploadFile) => {
                return uf.type === 'FEC'?uf.fileName:uf.finPeriodeDate
            });
        }


    }

    checkExistingManualCA3() {
        this.manualCA3Exist = false;
        this.files.forEach(file => {
           if (file.format === "MANUAL_FORM") {this.manualCA3Exist = true;}});
    }

    // processFiles(): UploadFile[] {
    //     return _.sortBy(this.files, (uf: UploadFile) => uf.finPeriodeDate);
    // }

    deleteFile(file: UploadFile) {
        this.deleteEvent.emit(file);
    }

    editFile(file: UploadFile) {
        this.editEvent.emit(file);
    }

    exportViesFile(file: UploadFile) {
        this.exportViesEvent.emit(file);
    }
}
