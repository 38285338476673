import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ComplianceComponent} from "../../compliance.component";
import {AuthGuard} from "../../../shared/auth/auth.guard";
import {CompteRenduGeneralComponent} from "./compte-rendu/compte-rendu-general/compte-rendu-general.component";
import {LaunchComponent} from "./launch/launch.component";
import {ControlFocusComponent} from "./launch/control-focus/control-focus.component";
import {ControlPresentationComponent} from "./launch/control-presentation/control-presentation.component";
import {CRDGlobalComponent} from "./compte-rendu/crd-global/crd-global.component";
import {SupervisionControlComponent} from "./supervision/supervision-control.component";
import {ExerciceAuthGuard} from "../../../shared/auth/exercice-auth.guard";
import {UploadComponent} from "./vies/upload/upload/upload.component";

const routes: Routes = [
    {
        path: 'compliance',
        component: ComplianceComponent,
        canActivateChild: [AuthGuard],
        children:
            [
                {
                    path: 'control/:domain/:category/:projectId/:exerciceId',
                    canActivateChild: [AuthGuard],
                    children: [

                            {
                                path: 'list',
                                component: LaunchComponent,
                                canActivate: [ExerciceAuthGuard],
                                data: {
                                    title: "breadcrumb.listControl"
                                }
                            },
                            {
                                path: 'uploadViesCsv',
                                component: UploadComponent,
                                canActivate: [ExerciceAuthGuard]
                            },
                            {
                                path: ':controlId',
                                canActivateChild: [AuthGuard],
                                children: [
                                    {
                                        path: 'focus',
                                        component: ControlFocusComponent,
                                        canActivate: [ExerciceAuthGuard],
                                        data: {
                                            title: "breadcrumb.control.focus"
                                        }
                                    },
                                    {
                                        path: 'presentation',
                                        component: ControlPresentationComponent,
                                        canActivate: [ExerciceAuthGuard],
                                        data: {
                                            title: "breadcrumb.control.presentation"
                                        }
                                    },
                                    {
                                        path: 'compte-rendu-general',
                                        component: CompteRenduGeneralComponent,
                                        data: {
                                            title: "breadcrumb.control.CRG"
                                        }
                                    },
                                    {
                                        path: 'compte-rendu-detaille',
                                        component: CRDGlobalComponent,
                                        data: {
                                            title: "breadcrumb.control.CRD"
                                        }
                                    },
                                    {
                                        path: 'supervision',
                                        component: SupervisionControlComponent,
                                        data: {
                                            title: "breadcrumb.control.supervision"
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                ]
        }
    ];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ControlRoutingModule {
}
