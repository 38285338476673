import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {distinctUntilChanged} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ComplianceLoaderService {
    private _loaderSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _$loader: Observable<boolean> = this._loaderSubject.asObservable().pipe(distinctUntilChanged());

    constructor() {}

    get $loader(): Observable<boolean> {
        return this._$loader;
    }

    sendLoaderShow() {
        this._loaderSubject.next(true);
    }

    sendLoaderHide() {
        this._loaderSubject.next(false);
    }
}
