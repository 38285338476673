<li class="">
    <div class="bloc label size-{{label.size}} {{label.textAlign}}" [ngClass]="{'bold':label.bold}" *ngFor="let label of blocByPosition['top']" [innerHTML]="label.text.text | safe:'html'"></div>
    <div class="d-flex " [ngClass]="getFlexJustifyBloc()">
        <div class="inline flex-grow-1 label height-{{bloc.valeur.size}} size-{{label.size}} {{label.textAlign}} d-flex align-items-center "
             [ngClass]="{'bold':label.bold}" *ngFor="let label of blocByPosition['left']">
            <div class="label size-{{label.size}} {{label.textAlign}}" [innerHTML]="label.text.text | safe:'html'"></div>
        </div>

        <div class="inline nb size-{{bloc.valeur.size}} text-{{bloc.valeur.textAlign}}" [ngClass]="computeMarginByBlocAlign()" [innerHTML]="bloc.valeur.text.text | safe:'html'"></div>

        <div class="inline flex-grow-1 label height-{{bloc.valeur.size}} size-{{label.size}} {{label.textAlign}} d-flex align-items-center "
             [ngClass]="{'bold':label.bold}" *ngFor="let label of blocByPosition['right']">
            <div class="label size-{{label.size}} text-{{label.textAlign}}" [innerHTML]="label.text.text | safe:'html'"></div>
        </div>
    </div>

    <div class="clearfix"></div>
    <div class=" label bloc size-{{label.size}} text-{{label.textAlign}}" [ngClass]="{'bold':label.bold}" *ngFor="let label of blocByPosition['bottom']" [innerHTML]="label.text.text | safe:'html'"></div>
</li>

