import {Injectable} from '@angular/core';
import {QuestionService} from "./question.service";

@Injectable({
    providedIn: 'root'
})
export class QuestionPeriodService implements QuestionService {

    constructor() {
    }

    getValues(values: { value: any; [p: string]: any }) {
    }


}
