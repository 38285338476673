import {Component, OnInit} from '@angular/core';
import {CompteRenduDetaille} from "../shared/compte-rendu-detaille";
import {AbstractControl} from "../../../../../../../../common/component/control/abstract-control";
import {BackgroungType} from "../../../../../../../../common/class/background/bg-compliance";
import {ActivatedRoute, Router} from "@angular/router";
import {CompteRenduService} from "../shared/compte-rendu.service";
import {NotificationService} from "../../../../../../../../common/service/push/notification.service";
import {NotificationMessage} from "../../../../../../../../common/class/polling/notification-message";
import {ControlDescriptor} from "../../../../../../../../common/class/control/control-descriptor";
import {CompteRenduDetaillUtilsService} from "./shared/compte-rendu-detaill-utils.service";
import {ControlRoutesFlux} from "../../../../../../../../common/class/control/control-flux";

@Component({
    selector: 'compliance-crd-global',
    templateUrl: './crd-global.component.html',
    styleUrls: ['./crd-global.component.scss'],
    providers:[CompteRenduDetaillUtilsService]
})
export class CRDGlobalComponent extends AbstractControl implements OnInit {

    crd: CompteRenduDetaille;
    controle: ControlDescriptor;

    constructor( private router: Router, private route: ActivatedRoute, private compteRenduService: CompteRenduService, private notificationService: NotificationService,
                private compteRenduDetaillUtilsService: CompteRenduDetaillUtilsService) {
        super( BackgroungType.BG_TREE_2);
    }

    ngOnInit() {
        super.setContextWithRoute(this.route, () => {
        });
        super.subscribeToNotificationControl({notificationService:this.notificationService, manageDataEvent :(n:NotificationMessage)=> this.manageDataEvent(n)});
        this.getDatas();
    }

    protected manageDataEvent(notification: NotificationMessage) {
    }


    private async getDatas() {
        this.controle = await this._controlService.findControlById(this.controlId);

        this.crd = await this.compteRenduService.findCompteRenduDetailleGlobalData(this.projectId, this.exerciceId, this.controlId);
        this.compteRenduDetaillUtilsService.CRDData = this.crd.controlResult;
        this.compteRenduDetaillUtilsService.compteRenduDetaille = this.crd;
        this._complianceLoaderService.sendLoaderHide();
    }

    goBack() {
        this._complianceLoaderService.sendLoaderShow();
        let route = this.notificationService.generateControlRoute(this.projectId, this.domain, this.category, this.controlId, ControlRoutesFlux["compte-rendu-general"], this.exerciceId);
        this.router.navigate([route], {
            queryParamsHandling: 'preserve',
            preserveFragment: true
        });
    }


    goNext() {
        this._complianceLoaderService.sendLoaderShow();
        let route = this.notificationService.generateControlRoute(this.projectId, this.domain, this.category, this.controlId, ControlRoutesFlux["supervision"], this.exerciceId);
        this.router.navigate([route], {
            queryParamsHandling: 'preserve',
            preserveFragment: true
        });
    }

}
