import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TableFormItemType, TableItem} from "../shared/table-item";

@Component({
    selector: 'compliance-table-form',
    templateUrl: './table-form.component.html',
    styleUrls: ['./table-form.component.scss']
})
export class TableFormComponent implements OnInit {

    @Input() data:any;
    @Input() meta: TableItem;
    @Input() colIndex: string;

    public typeForm = TableFormItemType;

    @Output() dataChange:EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

}
