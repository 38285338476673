import {FileType, TYPE_CONFIG_CCI_CAV, TYPE_CONFIG_CERFA_EDI} from "../compliance-constant";
import {affectation} from "./fr/affectation";
import {control} from "./fr/control";
import {balance} from "./fr/balance";
import {choixTva} from "./fr/choixTva";
import {compteRendu} from "./fr/compteRendu";
import {cgvu} from "./fr/cgvu";
import {dashboard} from "./fr/dashboard";
import {gestionFile} from "./fr/gestionFile";
import {question} from "./fr/question";
import {tableauSelection} from "./fr/tableauSelection";
import {breadcrumb} from "./fr/breadcrumb";
import {supervision} from "./fr/supervision";
import {guard} from "./fr/guard";
import {globalParams} from "./fr/globalParams";
import {ca3ManualForm} from "./fr/ca3ManualForm";
import {user} from "./fr/user";
import {upload} from "./fr/upload";
import {cache} from "./fr/cache";
import {report} from "./fr/report";
import {menu_FR_fr} from "./fr/menu_FR_fr";
import {pageInformationLight} from "./fr/page-information-light_fr_FR";
import {video} from "./fr/video";
import {versionComplete} from "./fr/versionComplete";
import {gestionCookie} from "./fr/gestionCookie";
import {project} from "./fr/project";


let translate: any = {};
translate.language = "fr";
translate.datePicker = {
    firstDayOfWeek: 0,
    dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
    monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    monthNamesShort: ["Jan", "Fév", "Mars", "Avr", "Mai", "Juin", "Juil", "Août", "Sept", "Oct", "Nov", "Déc"],
    today: "Aujourd'hui",
    clear: 'Nettoyer'
};
translate.buttons = {
    disable: "Désactiver",
    validate: "Valider",
    validateSelection: "Valider la sélection",
    delete: "Supprimer",
    cancel: "Annuler",
    back: "Retour",
    next: "Suivant",
    cancelPwd: "Réinitialiser le mot de passe",
    save: "Sauvegarder",
    launchScript: "Lancer le script",
    testScript: "Tester le script",
    resetMetaForm: "Lancer le script",
    backToProjectList: "Liste des dossiers",
    activateFilter: "Activer les filtres",
    yes: "Oui",
    no: "Non",
    ok: "OK",
    close: "Fermer",
    createProject: "Créer le dossier",
    launchControl: "Lancer le contrôle",
    launchControls: "Lancer les contrôles",
    beginControl: "Commencer le contrôle",
    focus: "Accéder au focus documentaire",
    documentation: "Documentation",
    returnPresentation: "Retour à la présentation",
    goCrd: "Voir le compte rendu détaillé",
    goSupervision: "Accéder à la supervision",
    finished: "Terminer",
    continue: "Continuer",
    confirm: "Confirmer",
    selectAll: "Tout sélectionner",
    unselectAll: "Tout déselectionner",
    camera: "Vidéo de présentation",
    vies: {
        launch: "Lancer avec vos DEB à l'expédition",
        launchCsv: "Lancer avec un fichier csv"
    }
};
translate.exports = {
    excel: "Excel",
    csv: "Csv"
};
translate.notificationAlert = {
    updateApplication:{
        label:"Une nouvelle version de notre logiciel e-Compliance est disponible. Cliquez sur OK pour bénéficier de ces nouveautés."
    },
    navigatorAler:{
        label1:"L'application Francis Lefebvre e-Compliance est optimisée pour fonctionner sous les dernières versions de Google Chrome et Mozilla Firefox.",
        label2:"Nous vous conseillons d'utiliser notre outil sur ces navigateurs."
    }
};
translate.security = {
    title: "Sécurité",
    unauthorized: "Vous n'avez pas les droits pour accéder à cette fonctionnalité"
};
translate.genre = {
    men: "Masculin",
    women: "Féminin"
};
translate.signin = {
    header: "Veuillez vous connecter",
    emailPlaceholder: "Identifiant / email",
    passwordPlaceholder: "Mot de passe",
    signinButton: "Connexion",
    forgottenPasswordLink: "Mot de passe oublié ?",
    error: {
        global: "Mauvais identifiant / mot de passe",
        email: {
            required: "L'adresse email est obligatoire",
            pattern: "L'adresse email n'est pas valide"
        },
        password: {
            required: "Le mot de passe est obligatoire"
        }
    },
    presentation: {
        title: "Réduisez votre exposition aux risques fiscaux en matière de TVA.",
        text: "Directement en ligne, à l’aide des fichiers émis par vos logiciels métiers<br>(FEC, CA3, DEB), réalisez de nombreux contrôles de conformité."
    }
};
translate.forgottenPassword = {
    title: "Mot de passe oublié ?",
    emailPlaceholder: "Veuillez saisir votre adresse email",
    button: {
        validate: "Réinitialiser mon mot de passe",
        cancel: "Retour"
    },
    error: {
        email: {
            required: "L'adresse email est obligatoire",
            pattern: "L'adresse email n'est pas valide"
        }
    },
    success: {
        reset: {
            title: "Réinitialisation de mot de passe",
            msg: "Votre mot de passe a été réinitialisé."
        }
    },
    info: {
        ligne1: "Renseignez votre adresse email ci-dessous.",
        ligne2: "Nous vous enverrons un lien pour réinitialiser votre mot de passe.",
        ligne3: "Si vous ne l'avez pas reçu dans un délai de 5 minutes, vérifiez dans vos courriers indésirables.",
    }
};
translate.createPassword = {
    title: "Définissez votre mot de passe",
    passwordLabel: "Mot de passe",
    passwordConfirmationLabel: "Confirmation du mot de passe",
    button: {
        create: "Créer",
        cancel: "Annuler"
    },
    error: {
        password: {
            required: "Le mot de passe est obligatoire",
            pattern: "Votre mot de passe doit contenir au minimum 6 caractères, une lettre majuscule, une lettre minuscule et un chiffre.",
            specialCharacters: "Votre mot de passe peut contenir également un (ou plusieurs) caractère spécial parmi #?!@$%^&*_-=",
            confirmPassword: "Veuillez saisir 2 fois le même mot de passe"
        },
        oldPassword: {
            required: "L'ancien mot de passe est obligatoire"
        }
    },
    success: {
        create: {
            title: "Création de mot de passe",
            msg: "Votre mot de passe a été créé"
        }
    }
};
translate.siret = {
    choice: {
        placeholder: "Numéro de siret"
    }
};
translate.tva = {
    fec: {
        calculatedValue: {
            goBackButton: "Retour à la gestion du dossier",
            title: "Valeurs Calculées",
            header: {
                code: "Code",
                description: "Description",
                value: "Valeur"
            }
        },
        file: {
            tableHeader: {
                fileName: "Fichier",
                uploadDate: "Date d'upload",
                fileType: "Type"
            },
            filter: {
                choose: "Choisissez"
            },
            successMesage: {
                title: "Gestion des FEC",
                delete: "FEC supprimé(s)"
            }
        }
    },
    journaux: {
        title: "Affectation des codes journaux par type",
        subTitle: "Veuillez valider le type des codes journaux",
        launch: "Lancer l'appareillage des journaux",
        "JVT": "Ventes",
        "JCA": "Caisse",
        "JAC": "Achats",
        "JAN": "À nouveau",
        "JBQ": "Banque",
        "JOD": "Opérations diverses",
        warningText: "Aucun appareillage de vos journaux n'a été lancé, vous pouvez exécuter l'appareillage en cliquant sur le boutton \"Lancer l'appareillage des journaux\"",
        inProgress: "L'apparreillage automatique de vos journaux est en cours, veuillez patienter",
        successMessage: "Appareillage pris en compte.",
        headers: {
            code: "Code journal",
            label: "Libellé du journal",
        }
    },
    balance: {
        title: "Balance",
        launch: "Calculer la balance",
        warningText: "La balance n'a pas été calculée. Pour la calculer veuillez cliquer sur le bouton \"Calculer la balance\"",
        inProgress: "Le calcul de la balance est en cours, veuillez patienter",
        headers: {
            compteNum: "N° compte",
            compteLib: "Intitulé de compte",
            ran: "RAN",
            debit: "Débit",
            credit: "Crédit",
            soldeDebit: "Solde débit",
            soldeCredit: "Solde credit",
        }
    },
    controle2: {
        title: "TVA Contrôle 2",
        launch: "Lancer le calcul",
        warningText: "Contrôle 2 n'a pas été calculé, pour le calculer veuillez cliquer sur le bouton \"Lancer le calcul\"",
        inProgress: "Le calcul est en cours, veuillez patienter",
        headers: {
            compteNum: "N° compte",
            compteLib: "Intitulé de compte",
            nbEcriture: "Nbre Ecritures",
            nbEcritureSoumisTva: "Nbre Ecritures avec TVA",
            nbEcritureNonSoumisTva: "Nbre Ecritures sans TVA",
            montantProduit: "Montant",
            montantProduitSoumisTva: "Montant avec TVA",
            montantProduitNonSoumisTva: "Montant sans TVA"
        },
        resultNoIncoherence: "Aucune incohérence n'a été détectée."
    }
};

translate.myAccount = {
    title: "Mon compte",
    myAccountLabel: "Mon compte",
    changePasswordLabel: "Modifier mon mot de passe",
    informationCompteLabel: "Information",
    gestionCookie: "Gestion des cookies",
    lastNameLabel: "Nom",
    firstNameLabel: "Prénom",
    telephonLabel: "Téléphone",
    emailLabel: "Email",
    oldPassword: "Ancien mot de passe",
    newPassword: "Nouveau mot de passe",
    confirmNewPassword: "Confirmation du nouveau mot de passe",
    button: {
        changePassword: "Modifier mon mot de passe",
        submitNewPassword: "Modifier mon mot de passe",
        saveInfo:"Sauvegarder mes informations"
    },
    success: {
        updatePassword: {
            title: "Nouveau mot de passe",
            msg: "Votre nouveau mot de passe a bien été enregistré."
        }
    }
};
translate.dossier = {
    title: "Gestion du dossier",
    nodDossiers: "Aucun dossier",
    tabs: {
        dossier: "Dossier",
        question: "Question",
        files: "Fichiers",
        codeJournal: "Appareillage des codes journaux",
        balance: "Balance",
        controle2: "TVA - Contrôle 2"
    },
    forms: {
        code: "Code",
        codeApe: "Code APE",
        name: "Nom",
        comment: "Commentaire",
        searchSirenSiretButtons: "Chercher par siren/siret",
        error: {
            code: {
                required: "Le code est requis.",
                siren: "Le siren est invalide.",
                siret: "Le siret est invalide.",
                tva: "Le Numéro tva est invalide",
                unknown: "Il ne s'agit ni d'un siren, ni d'un siret, ni d'un numéro de TVA."
            },
            codeApe: {
                required: "Le code APE est requis.",
                pattern: "Le code APE doit contenir 4 chiffres et une lettre",
            },
            name: {
                required: "Le nom du dossier est requis."
            }
        },
        success: {
            title: "Gestion des dossiers",
            create: "Le dossier a bien été créé",
            update: "Le dossier a bien été sauvegardé",
            delete: "Le dossier a bien été supprimé",
        },
        notFound: {
            siren: "Aucune donnée trouvée pour ce siren",
            siret: "Aucune donnée trouvée pour ce siret",
        }
    },
    create: {
        inputLabel: "Siren, Siret ou N° TVA",
        dropBoxLabel: " (Des, Deb, CA3 (edi ou xml)) ",
    },
    createButton: "Créer un dossier",

};
translate.script = {
    title: "Gestion des scripts",
    result: "Résultat",
    form: {
        name: {
            label: "Nom du script",
            error: {
                required: "Le nom du script est obligatoire"
            }
        }
    },
    message: {
        delete: "Les scripts ont bien été supprimés",
        save: "Le script a bien bien sauvegardé"
    },
    list: {
        empty: "Pas de script de règle de compliance",
        title: "Liste des règles de compliance",
        createButtom: "Créer une règle de calcul",
        headers: {
            name: "Nom du script",
            dependances: "Dépendances"
        }
    },
    edit: {
        title: "Modification du script",
        dossier: "Dossier",
        exercice: "Exercice",
        form: {
            id: "Id",
            dependencies: "Dépendances",
            nom: "Nom",
            description: "Description"
        },
        btn: {
            back: "Liste des scripts"
        }
    }
};
translate.configuration = {

    tabs: {
        upload: "Téléchargement du fichier de configuration",
        config: "Configuration",
        template: "Téléchargement des templates pdf"
    },
    confirm:{
        tempalteConf: {
            header: `Sauvegarde de la configuration des CA3 archive, millésime {{millesime}}`,
            body: `
                <div class="my-2 text-size-10">Vous êtes sur le point de sauvegarder un nouveau template de configuration pour les CA3 archive du millésime <b>{{millesime}}</b>.</div>
                <div class="my-2 text-size-10">Le fichier utilisé pour mettre à jour le template est le suivant:<br/><span class="text-secondary font-weight-bold"> {{fileName}}</span>.</div>
                <div class="my-2 text-size-10">Voulez-vous appliquer ces changements ?</div>
            `
        }
    },
    success:{
        templateConf:{
            message:`La sauvegarde de la configuration des CA3 archives millésime {{millesime}}, s'est bien effectuée.`,
        }
    }

};
translate.configuration[TYPE_CONFIG_CERFA_EDI] = {
    title: "Configuration des formulaires cerfa et fichiers EDI",
};
translate.configuration[TYPE_CONFIG_CCI_CAV] = {
    title: "Configuration des référence CCI et CAV",
};
let uploadFile = {
    uploadDnD: {
        extractSiren: "(CA3, DES, DEB)",
        complianceFile: {},
        actions: {
            depots: "Dépôt du fichier",
            extracted: "Fichier importé",
            waiting: "En attente d'une réponse",
            extracting: "Extraction des données",
            error: "Une erreur à eu lieu"

        }
    },
    uploadScreen: {
        title: "Dépôt des fichiers",
        subTitle: "Importer vos fichiers",
        subTitle2: "Tous les fichiers nécessaires au contrôle ont été déposés",
        facultatif: "(facultatif)",
        already: "Fichier(s) déposé(s) pour le contrôle",
        errorFile: "Fichier(s) en erreur à redéposer",
        missing: "Fichier(s) manquant(s)",
        info: {
            text: '',
            text2: ""
        },
        vies: {
            subTitle: "Importer votre fichier Csv",
            error: {
                extension: "Le format de ce fichier n'est pas autorisé. Seuls les fichiers .csv sont acceptés."
            }
        }
    }
};
uploadFile.uploadScreen.info[FileType.FEC.toString()] = `<p class="">Sur cette version, nous ne contrôlons pas le respect des conditions légales de forme du FEC.</p><p>Le FEC étant le reflet de votre comptabilité, nous vous recommandons de le tester préalablement avec l\'outil de la DGFIP <a href="https://www.economie.gouv.fr/dgfip/outil-test-des-fichiers-des-ecritures-comptables-fec" target="_blank"><b>téléchargeable</b></a>.</p>`;
uploadFile.uploadScreen.info[FileType.DEB_EXPEDITION.toString()] = `<p>Vous pouvez ne pas avoir établi de DEB à l'expédition au titre de certains mois.</br> En effet, cette déclaration est facultative si aucune opération intracommunautaire n'a été réalisée durant le mois de référence.</p>`;
uploadFile.uploadScreen.info[FileType.DEB_INTRODUCTION.toString()] = `<p>Vous pouvez ne pas avoir établi de DEB à l'introduction au titre de certains mois.</br>En effet, cette déclaration n'est obligatoire que lorsque l'entité juridique a réalisé des acquisitions (introductions) de biens d’un montant supérieur à 460 000 € HT au cours de l’année civile précédente ou lorsque ce seuil est dépassé pendant l'année en cours.</p>`;

translate.uploadFile = uploadFile;
translate.compteRendu = compteRendu;
translate.project = project;
translate.footer = {
    hotline: {
        question: "Une question ?",
        button: "Contacter la hotline"
    }
};
translate.domain = {
    fiscal: "Fiscal",
    social: "Social",
    juridique: "Juridique",
};
translate.categorie = {
    TVA: "TVA",
    CET: "CET",
    IS: "IS",
    taxeSalaire: "Taxe sur <br>les salaires",
    resultatFiscal: "Détermination <br>du résultat fiscal",
};
translate.help = {
    title: "Besoin d'aide ?",
    subTitle: "Un problème technique ou d'utilisation ? Posez vos questions ci-dessous. Nous vous recontacterons dans les meilleurs délais pour y répondre.",
    form: {
        company: "Société",
        lastName: "Nom",
        firstName: "Prénom",
        email: "Email",
        phone: "Téléphone",
        comment: "Message",
        btnBack: "Retour",
        btnSubmit: "Envoyer",
        successMessage: "Votre demande a bien été envoyée.",
        validate: {
            requiredMsg: "Ce champ est obligatoire",
            invalidEmail: "L’adresse e-mail n’est pas valide"
        }
    }
};
translate.errors = {
    generalMessage: "Une erreur est survenue. Veuillez contacter votre administrateur local."
};
translate.affectation = affectation;
translate.control = control;
translate.balance = balance;
translate.choixTva = choixTva;
translate.cgvu = cgvu;
translate.dashboard = dashboard;
translate.gestionFile = gestionFile;
translate.question = question;
translate.tableauSelection = tableauSelection;
translate.breadcrumb = breadcrumb;
translate.supervision = supervision;
translate.globalParams = globalParams;
translate.guard = guard;
translate.ca3ManualForm = ca3ManualForm;
translate.upload = upload;
translate.user = user;
translate.cache = cache;
translate.report = report;
translate.menu = menu_FR_fr;
translate.video = video;

translate.pageInformationLight = pageInformationLight;
translate.versionComplete = versionComplete;
translate.gestionCookie = gestionCookie;
export const fr = translate;
