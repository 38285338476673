import {Component, OnInit} from '@angular/core';
import {HabilitationService} from "../../../../common/service/auth/habilitation.service";
import {Menu} from "../../../../common/class/menu/menu";
import {DROIT_MENU} from "../../../../common/class/auth/habilitation";
import {User} from "../../../../common/class/user/user";
import {AuthService} from "../../../../common/service/auth/auth.service";
import {BgForestComponent} from "../../../../common/class/background/bg-compliance";
import {ComplianceLoaderService} from "../../../../common/service/loader/compliance-loader.service";

@Component({
    selector: 'compliance-compliance',
    templateUrl: './compliance.component.html',
    styleUrls: ['./compliance.component.scss']
})
export class ComplianceComponent extends BgForestComponent implements OnInit {
    progress: number;
    connectedUser: User;
    menus: Menu[];
    typeContent: string;
    loading: boolean = false;

    constructor( private _habilitationService: HabilitationService, private _authService: AuthService, public complianceLoaderService:ComplianceLoaderService) {
        super();
    }

    ngOnInit() {
        const user = this._authService.getConnectedUser();
        this.connectedUser = user ? user : new User();
        this.typeContent = user ? 'project' : 'login';
        let menuI18n = this.translate.instant('menu');
        this.menus = user ? [
            {
                id: 'config',
                label: menuI18n.config,
                iconClass: 'fas fa-cogs mr-1',
                show: true,
                subMenu: [

                    {
                        id: 'config-user',
                        label: menuI18n.users,
                        routerLink: `/compliance/user/admin/${this.connectedUser.id}`,
                        show: this._habilitationService.isShowMenu(DROIT_MENU.user.id),
                    },
                    {
                        id: 'config-myAccount',
                        label: menuI18n.myAccount,
                        routerLink: '/compliance/myAccount',
                        show: true,
                    }
                ]
            },
            {
                id: 'dossier',
                label: menuI18n.dossier,
                routerLink: '/compliance/home',
                show: this._habilitationService.isShowMenu(DROIT_MENU.dossier.id),
            },
        ] : [];
    }


}
