import {AbstractControl} from "@angular/forms";

export const REGEX_SIRET = new RegExp("^[0-9]{14}$");
export const REGEX_SIREN = new RegExp("^[0-9]{9}$");
export const REGEX_TVA_NUM =  new RegExp("^[fF][rR][0-9]{11}$");
export const REGEX_MAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d$@!%*#?&^_=-]{6,}$/;

export class ComplianceValidator {

    static checkSiret(siret: string):boolean {

        if (REGEX_SIRET.test(siret)) {
            // Donc le SIRET est un numérique à 14 chiffres
            // Les 9 premiers chiffres sont ceux du SIREN (ou RCS), les 4 suivants
            // correspondent au numéro d'établissement
            // et enfin le dernier chiffre est une clef de LUHN.
            let somme = 0;
            let tmp;
            for (let cpt = 0; cpt < siret.length; cpt++) {
                if ((cpt % 2) === 0) { // Les positions impaires : 1er, 3è, 5è, etc...
                    tmp = Number(siret.charAt(cpt)) * 2; // On le multiplie par 2
                    if (tmp > 9)
                        tmp -= 9;	// Si le résultat est supérieur à 9, on lui soustrait 9
                }
                else
                    tmp = siret.charAt(cpt);
                somme += parseInt(tmp+'');
            }

            return (somme % 10) === 0;
        } else {
            return false;
        }
    }
    static checkTvaNum(tvaNum: string):boolean {

        if (REGEX_TVA_NUM.test(tvaNum)) {
           let siren = tvaNum.substr(4,9);
           if(this.checkSiren(siren)){
               let key = Number(tvaNum.substr(2,2));
               let calculatKey = (12 + 3*(Number(siren) % 97)) % 97;
               return calculatKey === key;
           }else{
               return false
           }
        } else {
            return false;
        }
    }

    static checkSiren(siren: string):boolean {

        if (REGEX_SIREN.test(siren)) {
            // Donc le SIREN est un numérique à 9 chiffres
            let somme = 0;
            let tmp;
            for (let cpt = 0; cpt < siren.length; cpt++) {
                if ((cpt % 2) === 1) { // Les positions paires : 2ème, 4ème, 6ème et 8ème chiffre
                    tmp = Number(siren.charAt(cpt)) * 2; // On le multiplie par 2
                    if (tmp > 9)
                        tmp -= 9;	// Si le résultat est supérieur à 9, on lui soustrait 9
                }
                else
                    tmp = siren.charAt(cpt);
                somme += parseInt(tmp+'');
            }
            return (somme % 10) === 0;
        } else {
            return false
        }
    }

    // @dynamic
    static createMatchTwoField(controlName1: string, controlName2: string):(AC: AbstractControl)=>any {
        const control:(AC: AbstractControl)=>any = (AC: AbstractControl) => {
            let field1 = AC.get(controlName1).value;
            let field2 = AC.get(controlName2).value;
            if (field1 !== field2) {
                AC.get(controlName2).setErrors({matchPassword: true});
            } else {
                return null;
            }
        };
        return control;
    }

    // @dynamic
    static createSiretSirenAndTvaValidator(controlName: string):(AC: AbstractControl)=>any {
        const control:(AC: AbstractControl)=>any =  (AC: AbstractControl) => {
            let code = AC.value;
            if(code != null){
                code = code.replace(/\s/g, "");
            }
            let field1 = code;
            if(REGEX_SIREN.test(field1)){
                return this.checkSiren(field1)?null:{siren:true}
            }else if(REGEX_SIRET.test(field1)){
                return this.checkSiret(field1)?null:{siret:true}
            }else if(REGEX_TVA_NUM.test(field1)){
                return this.checkTvaNum(field1)?null:{tva:true}
            }else{
               return{siren: true, siret:true, tva:true};
            }

        };
        return control;
    }
}
