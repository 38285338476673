import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from "@angular/common/http";
import * as _ from "lodash";
import {Observable} from "rxjs";

@Injectable()
export class RestService {

    constructor(private httpClient: HttpClient) {
    }

    private request<T>(req: Observable<T>): Promise<T> {
        return req
            .toPromise()
            .catch(this.handleError);
    }


    private processUrl(baseUri: string, relativeUri: string, parameters: string[], parameterType?: string): string {
        let url: string = `${baseUri}`;
        if (relativeUri) {
            url = `${url}/${relativeUri}`;
        }
        if (parameters.length) {
            if (parameterType) {
                url = `${url}?${parameters.join('&')}`;
            } else {
                url = `${url}/${parameters.join('/')}`;
            }
        }
        return url;
    }

    req<T>(request: HttpRequest<any>): Observable<any> {
        return this.httpClient.request(request);
    }

    post<T>({url, relativeUri = '', parameters = [], data, headers = new HttpHeaders(), arg}: { url: string, relativeUri?: string, parameters?: any[], data: any, headers?: HttpHeaders, arg: T }): Promise<T> {
        return this.request(this.httpClient.post<T>(this.processUrl(url, relativeUri, parameters), data, {
            headers: headers.append('ngsw-bypass', 'true')
        }));
    }

    postObservable<T>({url, relativeUri = '', parameters = [], data, headers = new HttpHeaders(), options = {}, arg}: { url: string, relativeUri?: string, parameters?: any[], data: any, headers?: HttpHeaders, options?: any, arg: T }): Observable<T> {
        let option = {
            headers: headers.append('ngsw-bypass', 'true')
        };
        option = _.extend(option, options);
        return this.httpClient.post<T>(this.processUrl(url, relativeUri, parameters), data, option);
    }

    uploadRequest<T>({url, relativeUri = '', parameters = [], data, headers = new HttpHeaders(), options = {}, arg}: { url: string, relativeUri?: string, parameters?: any[], data: any, headers?: HttpHeaders, options?: any, arg: T }): Observable<T> {
        let option = {
            headers: headers.append('ngsw-bypass', 'true'),
            reportProgress: true
        };
        option = _.extend(option, options);
        const req = new HttpRequest('POST', this.processUrl(url, relativeUri, parameters), data, option);
        return this.req(req);
    }

    uploadRequestPromise<T>({url, relativeUri = '', parameters = [], data, headers = new HttpHeaders(), options = {}, arg}: { url: string, relativeUri?: string, parameters?: any[], data: any, headers?: HttpHeaders, options?: any, arg: T }): Promise<T> {
        let option = {
            headers: headers.append('ngsw-bypass', 'true'),
            reportProgress: true
        };
        option = _.extend(option, options);
        // const req = new HttpRequest('POST', this.processUrl(url, relativeUri, parameters), data, option);
        return this.request(this.httpClient.post<T>(this.processUrl(url, relativeUri, parameters), data, option));
    }

    put<T>({url, relativeUri = '', parameters = [], data, headers = new HttpHeaders(), arg}: { url: string, relativeUri?: string, parameters?: any[], data: any, headers?: HttpHeaders, arg: T }): Promise<T> {
        let option = {
            headers: headers.append('ngsw-bypass', 'true'),
        };
        return this.request(this.httpClient.put<T>(this.processUrl(url, relativeUri, parameters), data, option));
    }

    delete<T>({url, relativeUri = '', parameters = [], headers = new HttpHeaders(), arg}: { url: string, relativeUri?: string, parameters?: any[], headers?: HttpHeaders, arg: T }): Promise<T> {
        let option = {
            headers: headers.append('ngsw-bypass', 'true'),
        };
        return this.request(this.httpClient.delete<T>(this.processUrl(url, relativeUri, parameters), option));
    }

    get<T>({url, relativeUri = '', parameters = [], parameterType = null, headers = new HttpHeaders(), arg}: { url: string, relativeUri?: string, parameters?: any[], parameterType?: string, headers?: HttpHeaders, arg: T }): Promise<T> {
        let option = {
            headers: headers.append('ngsw-bypass', 'true'),
        };
        return this.request(this.httpClient.get<T>(this.processUrl(url, relativeUri, parameters, parameterType), option));
    }

    getObservable<T>({url, relativeUri = '', parameters = [], parameterType = null, headers = new Headers(), arg}: { url: string, relativeUri?: string, parameters?: any[], parameterType?: string, headers?: any, arg: T }): Observable<T> {
        let option = {
            headers: headers.append('ngsw-bypass', 'true'),
        };
        return this.httpClient.get<T>(this.processUrl(url, relativeUri, parameters, parameterType), option);
    }

    getFile({url, relativeUri = '', parameters = [], parameterType = null, headers = new HttpHeaders()}: { url: string, relativeUri?: string, parameters?: any[], parameterType?: string, headers?: HttpHeaders }): Promise<Blob> {
        return this.request(this.httpClient.get(this.processUrl(url, relativeUri, parameters, parameterType), {responseType: 'blob', headers: {'ngsw-bypass': 'true'}}));
    }

    getReportFile({url, relativeUri = '', parameters = [], data, headers = new HttpHeaders()}: { url: string, relativeUri?: string, parameters?: any[], data?: string[], headers?: HttpHeaders }): Promise<Blob> {
        let option = {
            responseType: 'blob' as 'json',
            headers: headers.append('ngsw-bypass', 'true')
        };
        return this.request(this.httpClient.post<Blob>(this.processUrl(url, relativeUri, parameters), data, option));
    }

    getString({url, relativeUri = '', parameters = [], parameterType = null, headers = new HttpHeaders()}: { url: string, relativeUri?: string, parameters?: any[], parameterType?: string, headers?: HttpHeaders }): Promise<string> {
        return this.request(this.httpClient.get(this.processUrl(url, relativeUri, parameters, parameterType), {responseType: 'text', headers: {'ngsw-bypass': 'true'}}));
    }


    private handleError(error: any): Promise<any> {
        return Promise.reject(error);
    }

}
