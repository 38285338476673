<ng-container>
    <div class="header d-flex flex-column">
        <div class="title">
            <h1>{{'dashboard.globalRisk.graph.title' | translate}}</h1>
            <h1 class="number">{{riskAmount?.global | currency:'EUR':'symbol':'1.0-0'}}</h1>
        </div>
        <div class="d-flex flex-row risks" *ngIf="riskAmount?.global != 0">
            <div class="col-4 p-2 d-flex risk-refused">
                <i class="mx-2 my-auto far fa-ban"></i>
                <div>
                    <div>{{'dashboard.globalRisk.risk.refused' | translate}}</div>
                    <div class="number">{{riskAmount?.refused | currency:'EUR':'symbol':'1.0-0'}}</div>
                </div>
            </div>
            <div class="col-4 p-2 d-flex risk-accepted">
                <i class="mx-2 my-auto far fa-check"></i>
                <div>
                    <div>{{'dashboard.globalRisk.risk.accepted' | translate}}</div>
                    <div class="number">{{riskAmount?.accepted | currency:'EUR':'symbol':'1.0-0'}}</div>
                </div>
            </div>
            <div class="col-4 p-2 d-flex risk-waiting">
                <i class="mx-2 my-auto far fa-hourglass-half"></i>
                <div>
                    <div>{{'dashboard.globalRisk.risk.waiting' | translate}}</div>
                    <div class="number">{{riskAmount?.waiting | currency:'EUR':'symbol':'1.0-0'}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-grow-1 px-2 py-4">
        <div class="polar-graph w-100 height-100" *ngIf="riskAmount?.global > 0; else noRisk">
            <p-chart #chartPolar type="polarArea"
                     [data]="polarGraph"
                     [options]="polarOption"
                     *ngIf="showGraph">
            </p-chart>
            <!-- TODO To adapt or remove -->
            <!--<div id="chartjs-tooltip"></div>-->
        </div>
        <ng-template #noRisk>
            <div class="d-flex flex-column align-items-center text-center m-auto no-risk">
                <i [ngClass]="noRiskText?.icon" [style.color]="noRiskText?.color"></i>
                <div class="text-size-14 mt-4">{{noRiskText?.text | translate}}</div>
            </div>
        </ng-template>
    </div>
</ng-container>
