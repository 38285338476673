import {AfterViewInit, Component, OnInit} from '@angular/core';
import {User} from "../../../../../common/class/user/user";
import {Menu} from "../../../../../common/class/menu/menu";
import {HabilitationService} from "../../../../../common/service/auth/habilitation.service";
import {AuthService} from "../../../../../common/service/auth/auth.service";
import {FileService} from "../../../../../common/service/file/file.service";
import {FileUtilsService} from "../../../../../common/service/file/file-utils.service";
import {DROIT_MENU} from "../../../../../common/class/auth/habilitation";
import {BgTreeNavComponent} from "../../../../../common/class/background/bg-compliance";

@Component({
    selector: 'compliance-compliance-tree-nav',
    templateUrl: './compliance-tree-nav.component.html',
    styleUrls: ['./compliance-tree-nav.component.scss'],
})
export class ComplianceTreeNavComponent extends BgTreeNavComponent implements OnInit, AfterViewInit {

    climb:boolean=false;
    progress: number;
    connectedUser: User;

    menus: Menu[];
    constructor( private habilitationService: HabilitationService, private authService: AuthService,
                private fileUtils: FileUtilsService, private fileService: FileService) {
        super();
    }

    ngOnInit() {
        this.connectedUser = this.authService.getConnectedUser();
        let menuI18n = this.translate.instant('menu');
        this.menus = [
            {
                id: 'config',
                label: menuI18n.config,
                iconClass: 'fas fa-cogs mr-1',
                show: true,
                subMenu: [

                    {
                        id: 'config-user',
                        label: menuI18n.users,
                        routerLink: `/compliance/user/admin/${this.connectedUser.id}`,
                        show: this.habilitationService.isShowMenu(DROIT_MENU.user.id),
                    },
                    {
                        id: 'config-myAccount',
                        label: menuI18n.myAccount,
                        routerLink: '/compliance/myAccount',
                        show: true,
                    }
                ]
            },
            {
                id: 'dossier',
                label: menuI18n.dossier,
                routerLink: '/compliance/home',
                show: this.habilitationService.isShowMenu(DROIT_MENU.dossier.id),
            },
        ];

    }

    ngAfterViewInit(): void {

        // if(this.fileUtils.getStat().ngFileNotDone === 0){
        //     let files:UploadInfo[] = []
        //     this.fileService.findFilesInfos().then((filesInfo:UploadInfo[]) =>{
        //         _.each(filesInfo, (file)=>{
        //             file.animateArchiveDiv = !this.fileUtils.isArchivDone(file);
        //             files.push( _.assign(new UploadInfo(), file));
        //         });
        //         this.fileUtils.emitChanges(files, false);
        //     })
        // }

    }

}
