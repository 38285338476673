export const tableauSelection = {
    title:"Comptes de TVA déductible",
    subtitle:"Sélectionner les comptes de TVA déductible utilisés pour les achats de biens et services",
    other:'Autre',
    buttons:{
        addNew:"Ajouter un taux"
    },
    errors: {
        existRate: "Ce taux existe déjà.",
        multipleModal:{
            header:`Attention`,
            content:`<div class="text-size-13">Pour la réalisation de ce contrôle, un seul et unique taux doit être appliqué.<br> Si votre entité juridique réalise des opérations multi-taux de TVA, vous ne pourrez pas effectuer ce contrôle.</div>`
        }
    }

};
