import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../../../../../../common/class/compliance-api.service";
import {RestService} from "../../../../../../../common/service/rest.service";
import {ExportType, TYPE_EXPORT, TypeExport} from "../../../../shared/export-file/export-file";
import {FileUtilsService} from "../../../../../../../common/service/file/file-utils.service";
import {SESSION_TAB_ID} from "../../../../../../../common/class/compliance-constant";

@Injectable({
    providedIn: 'root'
})
export class ReportingService extends ComplianceApiService {
    private readonly sessionTabId: string;

    constructor(private restService: RestService, private fileSaverService:FileUtilsService) {
        super('reporting', 'api');
        this.sessionTabId = sessionStorage.getItem(SESSION_TAB_ID);
    }

    async getReport(controlId, projectId, exerciceId, fileName, typeExport = 'pdf', data: any): Promise<any> {
        switch (typeExport) {
            case 'pdf':
                return this.restService.getReportFile({
                    url: this.baseUri,
                    relativeUri: 'exportReport',
                    parameters: [controlId, projectId, exerciceId, 'pdf'],
                    data: data
                }).then((blob:Blob) => {
                    this.fileSaverService.saveFile(fileName, blob, new TypeExport(TYPE_EXPORT.pdf));
                });
            case 'docx':
                return this.restService.getReportFile({
                    url: this.baseUri,
                    relativeUri: 'exportReport',
                    parameters: [controlId, projectId, exerciceId, 'docx'],
                    data: data
                }).then((blob:Blob) => {
                    this.fileSaverService.saveFile(fileName, blob, new TypeExport(TYPE_EXPORT.docx));
                });
            default:
                break;
        }

    }

    async exportEcritures(projectId, exerciceId, controlId, uuid, period, codes: string[]): Promise<any> {
        let parameters = period != null ? [projectId, exerciceId, controlId, uuid, period, codes] : [projectId, exerciceId, controlId, uuid, codes];
        parameters.push(this.sessionTabId);
        await this.restService.getFile({
            url: this.baseUri,
            relativeUri: 'exportEcritures',
            parameters: parameters
        });
    }

    async exportEcrituresAffectation(projectId, exerciceId, calculatedValueCode: string, code: string, uuid: string): Promise<any> {
        await this.restService.getFile({
            url: this.baseUri,
            relativeUri: 'exportEcrituresAffectation',
            parameters: [projectId, exerciceId, calculatedValueCode, code, uuid, this.sessionTabId]
        });
    }

    getReportElements(): Promise<any> {
        return this.restService.get({
            url: this.baseUri,
            relativeUri: "reportElements",
            arg: null as any
        });
    }

}
