import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[complianceQuestion]'
})
export class QuestionDirective {

    constructor(public viewContainerRef: ViewContainerRef) { }

}
