import {Injectable, OnDestroy} from '@angular/core';
import {RestService} from "../../../../../common/service/rest.service";
import {ComplianceApiService} from "../../../../../common/class/compliance-api.service";
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class LoggingService extends ComplianceApiService implements OnDestroy{
    private destroy$: Subject<boolean> = new Subject();

    constructor(private _restService: RestService) {
        super('logging-front', 'guest')

    }

    logToBack(error):Promise<any>{
        return this._restService.post({
            url: this.baseUri,
            data: error,
            arg: {} as any
        })
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
    }
}
