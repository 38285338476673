import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";
import {User} from "../../class/user/user";
import {Account} from "../../class/user/account";
import _ from 'lodash';

@Injectable()
export class AccountService extends ComplianceApiService {

    constructor(private restService: RestService) {
        super('account');
    }

    public createAccount(user: User): Promise<User> {
        return this.restService.post({
            url: this.baseUri,
            data: user,
            arg: user
        });
    }

    public saveAccount(user: User): Promise<any> {
        if (user.account.id) {
            return this.updateAccount(user.account);
        } else {
            return this.createAccount(user);
        }
    }

    public updateAccount(account: Account): Promise<Account> {
        return this.restService.put({
            url: this.baseUri,
            data: account,
            arg: account
        });
    }

    public checkAccount(codeIse: string): Observable<any> {
        return this.restService.getObservable({
            url: this.baseUri,
            relativeUri: 'codeIse',
            parameters: [codeIse],
            arg: null as any
        });
    }

    disableAccount(usersToDisable: User[]): Promise<any> {
        let accountIds = _.map(usersToDisable, (user: User) => {
            return user.account.id
        });
        return this.restService.put({
            url: this.baseUri,
            relativeUri: 'disable',
            data: accountIds,
            arg: {}
        })
    }

    deleteAccount(usersToDelete: User[]) {
        let accountIds = _.map(usersToDelete, (user: User) => {
            return user.account.id
        });
        return this.restService.delete({
            url: this.baseUri,
            parameters: [accountIds.join(',')],
            arg: {}
        })
    }
}
