<div class="height-100 page-botom-transparent slimscroll">
    <div class="d-flex flex-column text-white justify-content-center align-items-center">
        <div>
            <img src="assets/images/skin/logo-white.svg" width="220"/>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center information-text ">
            <div class="block-group-title mb-auto">
                <h1 class="title" [innerHTML]="'pageInformationLight.title' | translate | safe:'html'"></h1>
            </div>
            <div class="paragraphe text-size-20 mt-5">
                <p class="mb-5" [innerHTML]="'pageInformationLight.paragraphes.1' | translate | safe:'html'"></p>
                <p *ngIf="!isAccountTypeDivers()" class="mb-5" [innerHTML]="'pageInformationLight.paragraphes.2' | translate:{'link': getLienWebinaire()} | safe:'html'"></p>
                <p *ngIf="isAccountTypeDivers()" class="mb-5" [innerHTML]="'pageInformationLight.paragraphes.divers_2' | translate:{'lienEntreprise': getLienEntreprise(), lienExpCompEtAvocat: getLienExpCompEtAvocat()} | safe:'html'"></p>
                <p class="mb-5" [innerHTML]="'pageInformationLight.paragraphes.3' | translate | safe:'html'"></p>
                <p [innerHTML]="'pageInformationLight.paragraphes.4' | translate | safe:'html'"></p>
            </div>
        </div>
    </div>

    <div class="block-bottom-actions">
        <div class="inner text-center d-flex justify-content-between">
            <button href="#" class="btn btn-primary btn-back" (click)="goBack()">
                <span>{{'buttons.back' | translate}}</span>
            </button>
            <button class="btn btn-next btn-primary" (click)="goToProject()">
                <span>{{'buttons.next' | translate}}</span>
            </button>
        </div>
    </div>
</div>
