import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ComplianceI18nLoader} from "../../../class/i18n/compliance-i18n-loader";
import {MetaQuestion, MultipleChoiceQuestion, QuestionEvent, QuestionEventType} from "../../../class/meta/question/meta-question";
import {MetaExpressionService} from "../../../service/meta/meta-expression.service";
import {ActivatedRoute} from "@angular/router";
import {OriginPage} from "../../control/control-message-handler";

@Component({
    selector: 'compliance-table-selection',
    templateUrl: './table-selection.component.html',
    styleUrls: ['./table-selection.component.scss']
})
export class TableSelectionComponent extends ComplianceI18nLoader implements OnInit {

    metaQuestion: MetaQuestion<MultipleChoiceQuestion>;
    context: any;
    question: MultipleChoiceQuestion;
    choice: any = [];

    isControl: boolean;

    @Output() actionEvent: EventEmitter<QuestionEvent> = new EventEmitter();
    nextActive: boolean | number;

    constructor(route: ActivatedRoute, private expressionService: MetaExpressionService) {
        super();
        this.isControl = route.snapshot.queryParamMap.get('originPage') !== OriginPage.GLOBALPARAMS;
    }

    ngOnInit() {
        this.question = new MultipleChoiceQuestion(this.metaQuestion.question);

        console.log(this.question);
        this.setMultipleChoice();
        console.log('----- TableSelectionComponent ngOnInit', this.choice);
        this.computeNextActive();
    }

    private setMultipleChoice() {
        Object.entries(this.question.items).forEach(([key, item]) => {
            this.choice.push({
                code: item[this.question.codeField],
                checked: this.question.data ? !!this.question.data.find((response) => response === item[this.question.codeField]) : false,
                label: item[this.question.labelField]
            });
        });
    }

    goBack() {
        this.actionEvent.emit({type: QuestionEventType.GLOBAL, data: {code: 'back', data: this.question.data}});
    }

    goNext() {
        this.actionEvent.emit({type: QuestionEventType.GLOBAL, data: {code: 'next', data: this.question.data}});
    }


    private computeNextActive() {
        let next = this.question.buttons.next;
        if (next) {
            if (next.active) {
                this.nextActive = this.expressionService.computeExpression(next.active, this.question);
            } else {
                this.nextActive = true;
            }
        } else {
            this.nextActive = true;
        }
    }

    isAllSelected(): boolean {
        return this.choice.filter(value => value.checked).length === this.choice.length;
    }

    selectAll() {
        const selectAllActive = !this.isAllSelected();
        this.choice.forEach((value) => {
            value.checked = selectAllActive;
        });

        this.modifyChoices();
    }

    setSelectionChecked($event: Event, j) {
        $event.stopPropagation();
        j.checked = !j.checked;

        this.modifyChoices();
    }

    private modifyChoices() {
        let choices = [];
        this.choice.forEach((value) => {
            if (value.checked) {
                choices.push(value.code);
            }
        });
        this.question.data = {};
        this.question.data = choices;
        this.metaQuestion.question.data = choices;
        this.computeNextActive();
    }
}
