import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ToastContainerDirective, ToastrService} from "ngx-toastr";
import {ComplianceI18nLoader} from "../../../common/class/i18n/compliance-i18n-loader";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Breadcrumb} from "../../../common/class/breadcrumb/breadcrumb";
import {distinctUntilChanged, filter, takeUntil} from "rxjs/operators";
import {BreadcrumbService} from "../../../common/service/breadcrumb/breadcrumb.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {AuthService} from "../../../common/service/auth/auth.service";
import {UpdateService} from "./shared/update.service";
import {Subject} from "rxjs";

@Component({
    selector: 'compliance-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends ComplianceI18nLoader implements OnInit, OnDestroy {
    title = 'compliance';
    labels: any;


    @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
    showAlert: boolean;
    showUpdateApplication: boolean = false;

    private _destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private toastrService: ToastrService, private authService: AuthService,
                private breadcrumbService: BreadcrumbService,
                private router: Router, private route: ActivatedRoute, private deviceService: DeviceDetectorService,
                private _updateService:UpdateService) {
        super();
        this._updateService.$updateAvailable
            .pipe(takeUntil(this._destroy$))
            .subscribe((applicationUpdated)=> {
                console.warn("Je suis sensé afficher le message de maj de l'application", applicationUpdated);
                this.showUpdateApplication = applicationUpdated
            })
    }

    ngOnInit() {
        this.authService.setConnectedUser();
        this.toastrService.overlayContainer = this.toastContainer;
        this.labels = super.getValue('buttons');
        this.router.events.pipe(filter(event => event instanceof NavigationEnd), distinctUntilChanged()).subscribe(event => {
            // set breadcrumbs
            let root: ActivatedRoute = this.route.root;
            this.getBreadcrumbs(root);
        });
        this.showAlert = this.isNotFirefoxOrChrome();
        document.body.setAttribute('data-browser', this.deviceService.browser);
        document.body.setAttribute('data-browser-version', this.deviceService.browser_version.split('.')[0]);
        document.body.setAttribute('data-scroll-width', this.getScrollWidth());
    }

    isNotFirefoxOrChrome(): boolean {
        console.log(this.deviceService);
        return this.deviceService.browser !== 'Chrome' && this.deviceService.browser !== 'Firefox';
    }

    hideAlert() {
        this.showAlert = false;
    }

    private getBreadcrumbs(route: ActivatedRoute): Breadcrumb[] {
        // debugger
        if (route.firstChild) {
            // If we are not on our current path yet,
            // there will be more children to look after, to build our breadcumb
            return this.getBreadcrumbs(route.firstChild);
        }
        this.breadcrumbService.parseRouteSnap(route.snapshot);
    }

    /*
    * Basic scroll width : 17px
    * Chrome use of '::-webkit-scrollbar' => custom width of 6px
    * Firefox use of 'scrollbar-width' for 64+ => specific thin scroll of browser 6px
    */
    private getScrollWidth() {
        let scrollWidth = '17';
        if (this.deviceService.browser === 'Chrome') {
            scrollWidth = '6';
        } else if (this.deviceService.browser === 'Firefox' && +this.deviceService.browser_version.split('.')[0] >= 64) {
            scrollWidth = '6';
        }
        return `${scrollWidth}px`;
    }

    reloadApplication() {
        document.location.reload();
    }

    ngOnDestroy(): void {
        this._destroy$.next(true);
        this._destroy$.unsubscribe();
    }
}
