import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Lot} from "../shared/lot-affectation";
import {ComplianceI18nLoader} from "../../../../../../../../common/class/i18n/compliance-i18n-loader";
import {LotUtilsService} from "../shared/lot-utils.service";

@Component({
    selector: 'compliance-affectation-compte-lot',
    templateUrl: './affectation-compte-lot.component.html',
    styleUrls: ['./affectation-compte-lot.component.scss']
})
export class AffectationCompteLotComponent extends ComplianceI18nLoader implements OnInit {

    @Input() lot: Lot;
    @Input() direction = 'row';
    @Output() cleanEvent: EventEmitter<any> = new EventEmitter();
    @Output() selectEvent: EventEmitter<any> = new EventEmitter();
    badgeStyle: any = {};


    constructor( private lotUtils: LotUtilsService) {
        super();
    }

    ngOnInit() {
        if (this.lot.lot.display.color) {
            this.badgeStyle = {background: this.lot.lot.display.color};
        }
    }

    clean(event) {
        event.stopPropagation();
        this.cleanEvent.emit(true);
        this.lot.data = [];

    }

    getSize() {
        return this.lotUtils.getOneOrManyFromLotData(this.lot.data);
    }

    selectLot(event) {
        event.stopPropagation();
        this.selectEvent.emit(this.lot);
    }

    getTitle():string {
        if (this.lot.lot.display.lotTitle) {
            return this.lot.lot.display.lotTitle[this.getSize()];
        }
        return ';'
    }
}
