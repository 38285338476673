import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";
import {CategoryControl, ControlDescriptor, ControlForDashboard, DomaineControl} from "../../class/control/control-descriptor";
import {of} from "rxjs";
import {CacheService} from "../cache/cache.service";
import {NotificationService} from "../push/notification.service";
import {SESSION_TAB_ID} from "../../class/compliance-constant";
import {SupervisionControl} from "../../class/control/supervision";
import {FECInfos} from "../../class/globalParams/fecInfos";

@Injectable({
    providedIn: 'root'
})
export class VideoService extends ComplianceApiService {
    constructor(private _restService: RestService) {
        super('tva/videos', 'api');
    }

    getVideoList(): Promise<any> {
        return this._restService.get({
            url: this.baseUri,
            arg: null as Array<any>
        });
    }
}
