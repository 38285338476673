<compliance-login-header></compliance-login-header>
<div class="page-login">

    <div class="container">
        <div class="row">

            <div class="col-xl-12">
                <div class="block-group-title text-white">
                    <h2 class="title">{{'forgottenPassword.title' | translate}}</h2>
                    <h2 class="text-size-16 mt-4">{{'forgottenPassword.info.ligne1' | translate}}</h2>
                    <h2 class="text-size-16">{{'forgottenPassword.info.ligne2' | translate}}</h2>
                    <h2 class="text-size-16 ">{{'forgottenPassword.info.ligne3' | translate}}</h2>
                </div>

            </div>
        </div>

        <forgotten-password-form [i18n]="i18n" (saveEmitter)="submit($event)" (resetEmitter)="reset()"></forgotten-password-form>
    </div>
</div>
