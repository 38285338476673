import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ComplianceSharedModule} from "../../../../../../common/compliance-shared.module";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {DashboardRoutingModule} from "./dashboard-routing.module";
import {LegalEntityComponent} from "./legal-entity/legal-entity.component";
import {FecManagementComponent} from "./fec-management/fec-management.component";
import {TvaDashboardComponent} from "./tva-dashboard/tva-dashboard.component";
import {GlobalRiskComponent} from "./global-risk/global-risk.component";
import {SynthesisGraphComponent} from "./synthesis-graph/synthesis-graph.component";
import { ReportModalComponent } from './tva-dashboard/report/report-modal/report-modal.component';
import {ControlModule} from "../../tva/control/control.module";

@NgModule({
    imports: [
        CommonModule,
        ComplianceSharedModule,
        DashboardRoutingModule,
        ControlModule,
    ],
    declarations: [
        DashboardComponent,
        LegalEntityComponent,
        FecManagementComponent,
        TvaDashboardComponent,
        GlobalRiskComponent,
        SynthesisGraphComponent,
        ReportModalComponent,
    ],
    exports:[DashboardComponent],
    providers: []
})
export class DashboardModule {
}
