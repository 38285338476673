import {Component, Input, OnInit} from '@angular/core';
import {CompteRenduDetailleItem, DispositionBlock} from "../../shared/compte-rendu-detaille";
import {CompteRenduDetaillUtilsService} from "../shared/compte-rendu-detaill-utils.service";
import {Subject} from "rxjs";

@Component({
    selector: 'compliance-crd-item-block',
    templateUrl: './crd-item-block.component.html',
    styleUrls: ['./crd-item-block.component.scss']
})
export class CrdItemBlockComponent implements OnInit {
    @Input() destroy$: Subject<boolean>;

    @Input() crdItem : CompteRenduDetailleItem;
    constructor(private compteRenduDetaillUtilsService:CompteRenduDetaillUtilsService) {
    }

    ngOnInit() {

    }

    getStyle(dispositionBlock: DispositionBlock) {
        return this.compteRenduDetaillUtilsService.getDispostionBlockStyle(dispositionBlock)
    }
}
