import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComplianceComponent} from './compliance.component';
import {UserModule} from "./user/user.module";
import {ComplianceRoutingModule} from "./compliance-routing.module";
import {HomeComponent} from './home/home.component';
import {ComplianceConfirmDialogComponent} from './shared/compliance-confirm-dialog/compliance-confirm-dialog.component';
import {ComplianceSharedModule} from "../../../../common/compliance-shared.module";
import {DomaineChoiceModule} from "./domaine-choice/domaine-choice.module";
import {ComplianceTreeNavComponent} from "./compliance-tree-nav/compliance-tree-nav.component";
import {UploadFileModule} from "../shared/upload-file/upload-file.module";
import {UploadComplianceFileService} from "../shared/upload-file/drag-n-drop/shared/compliance-file/upload-compliance-file.service";
import {UploadComplianceFileResumableService} from "../shared/upload-file/drag-n-drop/shared/compliance-file/upload-compliance-file-resumable.service";
import {UploadComplianceFileDirectService} from "../shared/upload-file/drag-n-drop/shared/compliance-file/upload-compliance-file-direct.service";
import {ExtractSirenService} from "../shared/upload-file/drag-n-drop/shared/extract-siren/extract-siren.service";
import {TableModule} from "../shared/table/table.module";
import {AideComponent} from './aide/aide.component';
import {CgvuComponent} from './cgvu/cgvu.component';
import {ProjectModule} from "./project/project.module";
import {TvaModule} from "./tva/tva.module";
import {DashboardModule} from "./project/dashboard/dashboard.module";
import {GlobalParamsModule} from "./global-params/global-params.module";
import {VersionCompleteComponent} from './version-complete/version-complete.component';
import {GestionCookiesModule} from "./gestion-cookies/gestion-cookies.module";

@NgModule({
    declarations: [
        ComplianceComponent,
        ComplianceTreeNavComponent,
        HomeComponent,
        ComplianceConfirmDialogComponent,
        AideComponent,
        CgvuComponent,
        VersionCompleteComponent,
    ],
    providers: [
        UploadComplianceFileService,
        UploadComplianceFileResumableService,
        UploadComplianceFileDirectService,
        ExtractSirenService
    ],
    imports: [
        CommonModule,
        UserModule,
        ComplianceRoutingModule,
        ComplianceSharedModule,
        DomaineChoiceModule,
        UploadFileModule,
        TableModule,
        ProjectModule,
        TvaModule,
        DashboardModule,
        GlobalParamsModule,
        GestionCookiesModule
    ],
    entryComponents: [

    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComplianceModule {
}
