import {Injectable} from '@angular/core';
import {ComplianceCache} from "../../class/cache/compliance-cache";
import _ from 'lodash';
import moment from "moment";
import {AppConfigService} from "../app-config.service";

@Injectable({
    providedIn: 'root'
})
export class CacheService {

    private cache: { [key: string]: ComplianceCache } = {};

    constructor(private appConfigService: AppConfigService) {
    }

    addToCache(id: string, data: any) {
        this.cache[id] = {endLife: moment().add(this.appConfigService.config.cacheMaxDuration, 'minutes'), data: data};
    }

    deleteFromCache(id: string) {
        delete this.cache[id];
    }

    addAnyToCache(data: any[]) {
        _.each(data, (d) => {
            this.addToCache(d.id, d);
        });
    }

    getData(id: string) {
        let data = this.cache[id];
        if (!data) {
            return null;
        }
        if (moment().isAfter(data.endLife)) {
            return null;
        }
        return data.data;
    }
}
