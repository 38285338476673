import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PasswordService} from "../shared/password.service";
import {Router} from "@angular/router";

import {REGEX_MAIL} from "../../validator/compliance-validator";
import {ComplianceToastrService} from "../../service/toastr/compliance-toastr.service";
import {BgForestComponent} from "../../class/background/bg-compliance";

@Component({
    selector: 'compliance-forgotten-password',
    templateUrl: './forgotten-password.component.html',
    styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent extends BgForestComponent implements OnInit {
    form: FormGroup;
    i18n: any;
    constructor(private fb: FormBuilder, private passwordService: PasswordService, private router: Router,
                private toastr: ComplianceToastrService) {
        super();
        this.i18n = super.getTranslation();
    }

    ngOnInit() {
        this.createForm();
    }

    private createForm() {
        this.form = this.fb.group({
            email: ['', [
                Validators.required,
                Validators.pattern(REGEX_MAIL)
            ]]
        });
    }

    public submit(event):void {
        this.passwordService.forgottenPassword(event).then(() => {
            this.toastr.successI18n('forgottenPassword.success.reset.msg', 'forgottenPassword.success.reset.title');
            this.router.navigateByUrl('/');
        });
    }

    public reset():void {
        this.router.navigate(['/']);
    }
}
