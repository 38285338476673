<span class="cell-value" [attr.tooltip]="meta.tooltipField?data[meta.field]:''" *ngIf="meta.type == itemType.string">{{meta.prefix}}{{data[meta.field]}}</span>
<span class="cell-value" [attr.tooltip]="meta.tooltipField?data[meta.field]:''" *ngIf="meta.type == itemType.number">{{meta.prefix}}{{data[meta.field] | number:meta.numberFormat}}</span>
<span class="cell-value" [attr.tooltip]="meta.tooltipField?data[meta.field]:''" *ngIf="meta.type == itemType.currency">{{meta.prefix}}{{data[meta.field] | complianceCurrencyOrString:'EUR':'symbol-narrow':meta.numberFormat}}</span>
<span class="cell-value" [attr.tooltip]="meta.tooltipField?data[meta.field]:''" *ngIf="meta.type == itemType.date">{{meta.prefix}}{{data[meta.field] | complianceDate:meta.dateFormat.inFormat:meta.dateFormat.outFormat}}</span>
<span class="cell-value" [attr.tooltip]="meta.tooltipField?data[meta.field]:''" *ngIf="meta.type == itemType.binary">{{meta.prefix}}{{data[meta.field] | complianceBinary:meta.formatObject.firstValue:meta.formatObject.secondValue}}</span>

<button class="btn btn-primary btn-circle btn-xs ml-2" *ngIf="hasExport && !exporting" [disabled]="data[meta.field] === null || data[meta.field] === undefined" (click)="exportMeta()" [ngbTooltip]="tooltip">
    <i class="fal fa-search"></i>
    <ng-template #tooltip>
        <div [innerHtml]="'affectation.mainContent.table.exportLabel' | translate | safe:'html'"></div>
    </ng-template>
</button>

<button class="btn btn-primary btn-circle btn-xs ml-2" *ngIf="hasExport && exporting" [disabled]="data[meta.field] === null || data[meta.field] === undefined" [ngbTooltip]="tooltip">
    <i class="fas fa-spinner fa-spin"></i>
    <ng-template #tooltip>
        <div [innerHtml]="'affectation.mainContent.table.exportLoadingLabel' | translate | safe:'html'"></div>
    </ng-template>
</button>
