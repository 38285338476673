export const ca3ManualForm = {
    title: `Saisie des données reportées sur la CA3`,
    subtitle:`Compléter le formulaire ci-dessous avec les données nécessaires pour la réalisation des contrôles`,
    period:{
        title:`Période`,
        monthLabel:`Mois`
    },
    noConfig:{
        title:`Vous ne pouvez pas saisir de déclaration de TVA au titre de l'année {{annee}}.`,
        subTitle:`Veuillez sélectionner une période sur une année différente.`
    },
    noFileToEdit:{
        title:`Vous avez déjà déposé toutes vos déclarations de TVA pour l'exercice {{annee}}.`,
    },
    update:{
        title: `Modification des données reportées sur la CA3`,
        subtitle: `Modifier le formulaire ci-dessous avec les données nécessaires pour la réalisation des contrôles`,
    }

};
