import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";
import {UploadInfo} from "../../class/upload/upload-info";
import {UploadFile} from "../../class/upload/upload-file";
import {SESSION_TAB_ID} from "../../class/compliance-constant";


@Injectable()
export class FileService extends ComplianceApiService{

    private readonly sessionTabId: string;

    constructor(private restService:RestService) {
        super("importedFile");
        this.sessionTabId = sessionStorage.getItem(SESSION_TAB_ID);
    }

    public findFiles(dossierId: string, exerciceId:string): Promise<UploadFile[]> {
        let arg:UploadFile[] = [];
        return this.restService.get({
            "url": this.baseUri,
            parameters: [dossierId, exerciceId],
            "arg":arg
        });
    }

    public findFilesByType(dossierId: string, exerciceId:string, types:string): Promise<UploadFile[]> {
        let arg:UploadFile[] = [];
        return this.restService.get({
            "url": this.baseUri,
            parameters: [dossierId, exerciceId, types],
            "arg":arg
        });
    }

    public findFilesInfos(): Promise<UploadInfo[]> {
        return this.restService.get({
            url: this.baseUri,
            relativeUri: "findFileInfo",
            arg:null as UploadInfo[]
        });
    }

    public deletefiles(projectId: string, exerciceId: string, fecFileIds: string[]): Promise<any> {
        return this.restService.delete({
            url : this.baseUri,
            arg: {},
            parameters: [projectId, exerciceId, fecFileIds.join(',')]
        })
    }

    public deleteNewfiles(projectId: string, exerciceId: string, fecFileIds: string[]): Promise<any> {
        return this.restService.delete({
            url : this.baseUri,
            relativeUri: "newFiles",
            arg: {},
            parameters: [projectId, exerciceId, fecFileIds.join(',')]
        })
    }

    public deleteOldFileAndExtractNewOne(projectId: string, exerciceId: string, oldIds: string[], newId: string){
        return this.restService.delete({
            url : this.baseUri,
            arg: {},
            parameters: [projectId, exerciceId, oldIds.join(','), newId, this.sessionTabId]
        });
    }

    public mergeFec(projectId: string, projectName: string,exerciceId: string,fileId: string, status: string): Promise<any> {
        return this.restService.post({
            url: this.baseUri,
            relativeUri: 'mergeFec',
            parameters: [projectId, exerciceId, fileId, status, this.sessionTabId],
            data: { projectName: projectName },
            arg: null
        });
    }

    public continueUploadDebWithSamePeriodThenExistantOne(projectId: string, projectName: string, projectCode: string, exerciceId: string, fileId: string, anneeExercice: any) {
        return this.restService.put({
            url: this.baseUri,
            relativeUri: 'force/deb',
            parameters: [projectId, exerciceId, fileId, this.sessionTabId],
            data: {'projectName': projectName, 'projectCode': projectCode, 'anneeExercice': anneeExercice},
            arg: null
        });
    }

    continueUploadWithDifferentExercice(projectId: string, projectName: string, projectCode: string, exerciceId: string, fileId: string, anneeExercice: any) {

        return this.restService.put({
            url: this.baseUri,
            relativeUri: 'force/exercice',
            parameters: [projectId, exerciceId, fileId, this.sessionTabId],
            data: {'projectName': projectName, 'projectCode': projectCode, 'anneeExercice': anneeExercice},
            arg: null
        });
    }

    continueUploadWithWrongSiren(projectId: string, projectName: string, projectCode: string, exerciceId: string, fileId: string, anneeExercice: any) {

        return this.restService.put({
            url: this.baseUri,
            relativeUri: 'force/siren',
            parameters: [projectId, exerciceId, fileId, this.sessionTabId],
            data: { 'projectName': projectName, 'projectCode': projectCode, 'anneeExercice': anneeExercice},
            arg: null
        });
    }
}
