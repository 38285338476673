import {MetaService} from "./meta-service";

export enum ControlType {
    required = 'required',
    maxlength = 'maxlength',
    max = 'max',
    min = 'min',
    pattern = 'pattern',
    size = 'size',
    step = 'step',
    siren = 'siren'
}

export interface FormInputControl{
    controlType: ControlType;
    value?:any ;
}

export class InputDateOption{
    name:string;
    dateFormat:string;
    monthNavigator?:boolean;
    yearNavigator?:boolean;
    yearRange?:string;
}

export class ComboBoxOptions{
    options?:any[];
    optionService?:MetaService;
    labelField?:string;
    valueField?;
}


export class FormInput {
    id: string;
    formType: string;
    inputType?: string;
    saveType?: string;
    field?: string;
    label?: string;
    value?: string;
    order?: number;
    onlyInt?:boolean;
    controlType?: FormInputControl[];
    inputDateOption?:InputDateOption;
    comboBoxOption?:ComboBoxOptions
}



export interface MetaFormulaire {
    form : FormInput[];
}
