import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'complianceBinary'
})
export class ComplianceBinaryPipe implements PipeTransform {

  transform(value: any, firstValue: string, secondValue: string): string {
    return value?firstValue:secondValue;
  }

}
