import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ComplianceSharedModule} from "../../../../../common/compliance-shared.module";
import {NgxGraphModule} from "@swimlane/ngx-graph";
import {GlobalParamsRoutingModule} from "./global-params-routing.module";
import {GraphD3Component} from "./graph-d3/graph-d3.component";

@NgModule({
    imports: [
        CommonModule,
        ComplianceSharedModule,
        NgxGraphModule,
        GlobalParamsRoutingModule
    ],
    declarations: [
        GraphD3Component
    ],
    exports:[],
    providers: []
})
export class GlobalParamsModule {
}
