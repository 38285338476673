<div class="container-fluid p-0 height-100">
    <div toastContainer></div>
    <div class="navigator-update-alert d-flex align-items-center" *ngIf="showUpdateApplication">
            <div class="update-text">{{'notificationAlert.updateApplication.label' | translate}}</div>
            <div class="update-action text-right mr-5">
                <button type="button" class="btn btn-primary btn-sm"  (click)="reloadApplication()">
                    <span>{{'buttons.ok' | translate}}</span>
                </button>
            </div>
    </div>
    <div class="navigator-alert" *ngIf="showAlert">
        <div class="position-relative">
            <p>{{'notificationAlert.navigatorAler.label1' | translate}}</p>
            <p>{{'notificationAlert.navigatorAler.label2' | translate}}</p>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="hideAlert()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
    </div>
    <router-outlet></router-outlet>

    <p-confirmDialog appendTo="body" #cd [style]="{width:'600px'}">
        <p-footer>
            <button type="button" class="btn btn-sm btn-heroic-secondary col-2 mr-1" *ngIf="cd.rejectVisible" (click)="cd.reject()">{{cd.rejectLabel != "No"? cd.rejectLabel: labels.cancel}}</button>
            <button type="button" class="btn btn-sm btn-primary col-2 ml-1" *ngIf="cd.acceptVisible" (click)="cd.accept()">{{cd.acceptLabel != "Yes"? cd.acceptLabel: labels.confirm}}</button>
        </p-footer>
    </p-confirmDialog>

</div>
