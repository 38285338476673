import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {PasswordService} from "../shared/password.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../service/auth/auth.service";
import {ComplianceToastrService} from "../../service/toastr/compliance-toastr.service";
import {BgForestComponent} from "../../class/background/bg-compliance";


@Component({
    selector: 'compliance-create-password',
    templateUrl: './create-password.component.html',
    styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent extends BgForestComponent implements OnInit {
    form: FormGroup;
    i18n:any;
    tempId: string;

    constructor(private fb: FormBuilder, private passwordService: PasswordService, private route:ActivatedRoute,
                private authService: AuthService, private router: Router, private toastr: ComplianceToastrService) {
        super();
        this.i18n = super.getTranslation();
    }

    ngOnInit() {
        this.tempId = this.route.snapshot.paramMap.get("tempId");
    }



    public specifyPassword(event):void {
        this.passwordService.specifyPassword(event.tempId, event.password).then(() => {
            this.toastr.successI18n('createPassword.success.create.msg', 'createPassword.success.create.title');
            this.authService.resetAuthValues();
            this.router.navigateByUrl('/');
        });
    }

}
