import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {ComplianceApiService} from "../class/compliance-api.service";
import {RestService} from "./rest.service";

@Injectable()
export class AppConfigService extends ComplianceApiService {
    config: any;

    constructor(private http: HttpClient, private restService: RestService) {
        super('config', 'guest');
    }

    load(): Promise<any> {
        return this.restService.get({
            url: this.baseUri,
            arg: {}
        }).then(res => this.config = res)
            .catch(this.handleError);
    }

    private handleError(error: HttpResponse<any> | any) {
        let errMsg: string;
        if (error instanceof HttpResponse) {
            const currentError: any = error;
            const body = currentError.json() || '';
            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        return new Promise((resolve) => {
            resolve(errMsg);
        });
    }
}
