import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
// import * as Survey from 'survey-angular';
// import {Question, SurveyModel} from 'survey-angular';
import {FormInput} from "../../class/meta/meta-formulaire";
import {ComplianceI18nLoader} from "../../class/i18n/compliance-i18n-loader";
import {MetaFormulaireService} from "../../service/meta/meta-formulaire.service";
import {FormGroup} from "@angular/forms";


@Component({
  selector: 'compliance-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent extends ComplianceI18nLoader implements OnInit {
    @Input() formInput: FormInput;
    @Input() parentFormGroup: FormGroup;
    @Output() onClick:EventEmitter<any> = new EventEmitter();
    @Output() onBlur:EventEmitter<any> = new EventEmitter();
    @Output() onChange:EventEmitter<any> = new EventEmitter();
    @Output() onKeyUp:EventEmitter<any> = new EventEmitter();

    constructor( private formService:MetaFormulaireService) {
        super();
    }

    ngOnInit() {

    }

    click($event) {
        this.onClick.emit($event);
    }

    blur($event) {
        this.onBlur.emit($event);
    }

    change($event) {
        this.onChange.emit($event);
    }

    keyup($event) {
        this.onKeyUp.emit($event);
    }
}
