import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'complianceDate'
})
export class ComplianceDatePipe implements PipeTransform {

    transform(value: any, inFormat: string, outFormat: string): any {
        // return moment(value, 'YYYYMM').format('MMMM YYYY');
        return moment(value, inFormat).format(outFormat);
    }

}
