import {Injectable} from '@angular/core';
import _ from 'lodash';
import {Expression, Member, Operator} from "../../class/meta/meta-expression";


@Injectable({
    providedIn: 'root'
})
export class MetaExpressionService {

    private operatorFunction: { [keys: string]: (firstMember: Member, secondMember: Member | Member[], data: any) => boolean | number } = {};

    constructor() {
        this.operatorFunction[Operator.GT] = this.greaterThan.bind(this);
        this.operatorFunction[Operator.LT] = this.lesserThan.bind(this);
        this.operatorFunction[Operator.LTE] = this.lesserThanOrEquale.bind(this);
        this.operatorFunction[Operator.GTE] = this.greaterThanOrEquale.bind(this);
        this.operatorFunction[Operator.EQ] = this.equal.bind(this);
        this.operatorFunction[Operator.NEQ] = this.notEqual.bind(this);
        this.operatorFunction[Operator.IN] = this.in.bind(this);
        this.operatorFunction[Operator.DIVIDE] = this.divide.bind(this);
        this.operatorFunction[Operator.MULTIPLE] = this.multiple.bind(this);
        this.operatorFunction[Operator.ADD] = this.add.bind(this);
        this.operatorFunction[Operator.SUBSTRACT] = this.substract.bind(this);
        this.operatorFunction[Operator.AND] = this.and.bind(this);
        this.operatorFunction[Operator.OR] = this.or.bind(this);
        this.operatorFunction[Operator.SIZE] = this.size.bind(this);
        this.operatorFunction[Operator.GET] = this.get.bind(this);
        this.operatorFunction[Operator.EMPTY] = this.empty.bind(this);
        this.operatorFunction[Operator.NOT_EMPTY] = this.notEmpty.bind(this);
        this.operatorFunction[Operator.NULL] = this.isNull.bind(this);
        this.operatorFunction[Operator.NOT_NULL] = this.notNull.bind(this);
    }

    findByField(object: any, path: string) {
        return _.get(object, path);
    }

    computeExpressionOrMember(expression: Expression | Member, data: any = undefined): any {
        if (expression.hasOwnProperty('operator')) {
            return this.computeExpression(expression as Expression, data);
        } else {
            return this.computeMember(expression as Member, data);
        }
    }

    computeExpression(expression: Expression, data: any = undefined): boolean | number {
        if (this.operatorFunction.hasOwnProperty(expression.operator)) {
            return this.operatorFunction[expression.operator](expression.firstMember, expression.secondMember, data);
        } else {
            throw new Error(`error MetaExpressionService.computeExpression(): operator ${expression.operator} is not managed`);
        }
    }

    computeMember(member: Member, data: any = undefined): any {
        if (_.keys(member).length != 1) {
            throw new Error(`error MetaExpressionService.computeMember(): member ${JSON.stringify(member)} is not validµ. It contains more than one property`);
        }
        if (_.has(member, 'value')) {
            return member.value;
        }
        if (_.has(member, 'field')) {
            if (!data) {
                throw new Error(`error MetaExpressionService.computeMember() if(member.field): you can't search with field without data`);
            }
            return this.findByField(data, member.field);
        }
        if (_.has(member, 'expression')) {
            return this.computeExpression(member.expression, data);
        }
    }

    private greaterThan(firstMember: Member, secondMember: Member, data: any = undefined): boolean {
        let {firstMemberValue, secondMemberValue} = this.extractLeftAndRightMember(firstMember, data, secondMember);
        if (_.isNil(firstMemberValue) || _.isNil(secondMemberValue)) {
            return false;
        }
        return firstMemberValue > secondMemberValue;
    }


    private greaterThanOrEquale(firstMember: Member, secondMember: Member, data: any = undefined): boolean {
        let {firstMemberValue, secondMemberValue} = this.extractLeftAndRightMember(firstMember, data, secondMember);
        if (_.isNil(firstMemberValue) || _.isNil(secondMemberValue)) {
            return false;
        }
        return firstMemberValue >= secondMemberValue;
    }

    private lesserThan(firstMember: Member, secondMember: Member, data: any = undefined): boolean {
        let {firstMemberValue, secondMemberValue} = this.extractLeftAndRightMember(firstMember, data, secondMember);
        if (_.isNil(firstMemberValue) || _.isNil(secondMemberValue)) {
            return false;
        }
        return firstMemberValue < secondMemberValue;
    }

    private lesserThanOrEquale(firstMember: Member, secondMember: Member, data: any = undefined): boolean {
        let {firstMemberValue, secondMemberValue} = this.extractLeftAndRightMember(firstMember, data, secondMember);
        if (_.isNil(firstMemberValue) || _.isNil(secondMemberValue)) {
            return false;
        }
        return firstMemberValue <= secondMemberValue;
    }

    private equal(firstMember: Member, secondMember: Member, data: any = undefined): boolean {
        let {firstMemberValue, secondMemberValue} = this.extractLeftAndRightMember(firstMember, data, secondMember);
        if (_.isNil(firstMemberValue) || _.isNil(secondMemberValue)) {
            return false;
        }
        return firstMemberValue == secondMemberValue;
    }

    private notEqual(firstMember: Member, secondMember: Member, data: any = undefined): boolean {
        let {firstMemberValue, secondMemberValue} = this.extractLeftAndRightMember(firstMember, data, secondMember);
        if (_.isNil(firstMemberValue) || _.isNil(secondMemberValue)) {
            return false;
        }
        return firstMemberValue !== secondMemberValue;
    }

    private in(firstMember: Member, secondMember: Member[], data: any = undefined): boolean | number {
        let {firstMemberValue, secondMemberValue} = this.extractLeftAndRightMembers(firstMember, data, secondMember);
        if (_.isNil(firstMemberValue) || _.isNil(secondMemberValue)) {
            return false;
        }
        return _.indexOf(secondMemberValue, firstMemberValue) >= 0;
    }

    private divide(firstMember: Member, secondMember: Member, data: any = undefined): number {
        let {firstMemberValue, secondMemberValue} = this.extractLeftAndRightMember(firstMember, data, secondMember);
        firstMemberValue = Number(firstMemberValue);
        secondMemberValue = Number(secondMemberValue);
        if (_.isFinite(firstMemberValue) && _.isFinite(secondMemberValue)) {
            return firstMemberValue / secondMemberValue;
        } else {
            throw new Error(`error MetaExpressionService.divide(): one of those value [firstMemberValue:${firstMemberValue},secondMemberValue:${secondMemberValue}] is not a number `);
        }
    }

    private multiple(firstMember: Member, secondMember: Member, data: any = undefined): number {
        let {firstMemberValue, secondMemberValue} = this.extractLeftAndRightMember(firstMember, data, secondMember);
        firstMemberValue = Number(firstMemberValue);
        secondMemberValue = Number(secondMemberValue);
        if (_.isFinite(firstMemberValue) && _.isFinite(secondMemberValue)) {
            return firstMemberValue * secondMemberValue;
        } else {
            throw new Error(`error MetaExpressionService.multiple(): one of those value [firstMemberValue:${firstMemberValue},secondMemberValue:${secondMemberValue}] is not a number `);
        }
    }

    private add(firstMember: Member, secondMember: Member, data: any = undefined): number {
        let {firstMemberValue, secondMemberValue} = this.extractLeftAndRightMember(firstMember, data, secondMember);
        firstMemberValue = Number(firstMemberValue);
        secondMemberValue = Number(secondMemberValue);
        if (_.isFinite(firstMemberValue) && _.isFinite(secondMemberValue)) {
            return firstMemberValue + secondMemberValue;
        } else {
            throw new Error(`error MetaExpressionService.add(): one of those value [firstMemberValue:${firstMemberValue},secondMemberValue:${secondMemberValue}] is not a number `);
        }
    }

    private substract(firstMember: Member, secondMember: Member, data: any = undefined): number {
        let {firstMemberValue, secondMemberValue} = this.extractLeftAndRightMember(firstMember, data, secondMember);
        firstMemberValue = Number(firstMemberValue);
        secondMemberValue = Number(secondMemberValue);
        if (_.isFinite(firstMemberValue) && _.isFinite(secondMemberValue)) {
            return firstMemberValue - secondMemberValue;
        } else {
            throw new Error(`error MetaExpressionService.substract(): one of those value [firstMemberValue:${firstMemberValue},secondMemberValue:${secondMemberValue}] is not a number `);
        }
    }

    private or(firstMember: Member, secondMember: Member, data: any = undefined): boolean {
        let {firstMemberValue, secondMemberValue} = this.extractLeftAndRightMember(firstMember, data, secondMember);
        return firstMemberValue || secondMemberValue;
    }

    private and(firstMember: Member, secondMember: Member, data: any = undefined): boolean {
        let {firstMemberValue, secondMemberValue} = this.extractLeftAndRightMember(firstMember, data, secondMember);
        return firstMemberValue && secondMemberValue;
    }

    private size(firstMember: Member, secondMember: Member, data: any = undefined): number {
        let firstMemberValue = this.extractLeftMember(firstMember, data);
        if (!firstMemberValue) {
            return 0;
        }
        if (_.isArray(firstMemberValue) || _.isString(firstMemberValue)) {
            return firstMemberValue.length;
        } else {
            throw new Error(`error MetaExpressionService.size(): firstMemberValue is not an Array. `);
        }
    }

    private empty(firstMember: Member, secondMember: Member, data: any = undefined): boolean {
        let firstMemberValue = this.extractLeftMember(firstMember, data);
        console.log('empty', firstMemberValue);
        if (_.isArray(firstMemberValue) || _.isObject(firstMemberValue) || _.isSet(firstMemberValue) || _.isString(firstMemberValue) || _.isMap(firstMemberValue)) {
            return _.isEmpty(firstMemberValue);
        } else {
            return firstMemberValue === undefined || firstMemberValue == null;
        }
    }

    private notEmpty(firstMember: Member, secondMember: Member, data: any = undefined): boolean {
        return !this.empty(firstMember, secondMember, data);
    }

    private isNull(firstMember: Member, secondMember: Member, data: any = undefined): boolean {
        let firstMemberValue = this.extractLeftMember(firstMember, data);
        return _.isNil(firstMemberValue);
    }

    private notNull(firstMember: Member, secondMember: Member, data: any = undefined): boolean {
        return !this.isNull(firstMember, secondMember, data);
    }


    private get(firstMember: Member, secondMember: Member, data: any = undefined): any {
        if (firstMember.expression) {
            throw new Error(`error MetaExpressionService.get(): can't get form an expression `);
        }
        let firstMemberValue = this.extractLeftMember(firstMember, data);
        return firstMemberValue;
    }

    private extractLeftAndRightMember(firstMember: Member, data: any, secondMember: Member) {
        let firstMemberValue = this.computeMember(firstMember, data);
        let secondMemberValue = this.computeMember(secondMember, data);
        return {firstMemberValue, secondMemberValue};
    }

    private extractLeftMember(firstMember: Member, data: any) {
        let firstMemberValue = this.computeMember(firstMember, data);
        return firstMemberValue;
    }

    private extractLeftAndRightMembers(firstMember: Member, data: any, secondMember: Member[]) {
        let firstMemberValue = this.computeMember(firstMember, data);
        let secondMemberValue = [];
        _.each(secondMember, (member: Member) => {
            console.log(secondMember)
            console.log(secondMember)
            let value = this.computeMember(member, data);
            secondMemberValue.push(value)
        })

        return {firstMemberValue, secondMemberValue};
    }

}
