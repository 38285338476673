import {Injectable} from '@angular/core';

import {Router} from "@angular/router";
import {HttpHandler, HttpRequest} from "@angular/common/http";
import {RestService} from "../rest.service";
import {AuthService} from "./auth.service";
import {HabilitationService} from "./habilitation.service";
import {AuthenticationService} from "./authentication.service";
import {EMPTY, Observable, of} from "rxjs";
import {NotificationService} from "../push/notification.service";
import {catchError, flatMap} from "rxjs/operators";

@Injectable()
export class LoginService {


    constructor(private restService: RestService, private authService: AuthService, private router: Router, private habilitationService: HabilitationService,
                private authenticationService: AuthenticationService, private notificationService:NotificationService) {
    }

    login({email, password}: { email: string, password: string }): Promise<any> {
        return this.authenticationService.authenticate(email, password).then(token => {
            this.authService.setToken(token);
            this.notificationService.init();

        });
    }

    logout(): void {
        this.authService.resetAuthValues();
        this.notificationService.disconnect();
    }

    refreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return this.authenticationService.refreshAuth().pipe(
            flatMap((token) => {
                this.authService.setToken(token);
                req = req.clone({
                    setHeaders: {
                        'Authorization' : 'Bearer ' + token['access_token']
                    }
                });
                return next.handle(req);
            }),
            catchError(() => {
                return EMPTY;
            })
        )
    }


    refreshTokenBasic(): Promise<boolean> {
        return this.authenticationService.refreshAuth().pipe(
            flatMap((token) => {
                this.authService.setToken(token);
                return of(true).toPromise();
            }),
            catchError(() => {
                return of(false).toPromise();
            })
        ).toPromise();
    }
}
