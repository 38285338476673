import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GestionCookiesComponent} from './gestion-cookies.component';
import {ComplianceSharedModule} from "../../../../../common/compliance-shared.module";
import {GestionCookiesRoutingModule} from "./gestion-cookies-routing.module";
import {GestionCookiePreferenceComponent} from "./gestion-cookie-preference/gestion-cookie-preference.component";

@NgModule({
  declarations: [GestionCookiesComponent, GestionCookiePreferenceComponent],
  imports: [

      CommonModule,
      ComplianceSharedModule,
      GestionCookiesRoutingModule
  ],
    exports:[
        GestionCookiesComponent, GestionCookiePreferenceComponent
    ]
})
export class GestionCookiesModule { }
