<div class="page-import" *ngIf="uploadData">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="block-group-title">
                    <h2 class="title big">{{'uploadFile.uploadScreen.title' | translate}}</h2>
                </div>
            </div>

            <div class="col-xl-12">
                <form class="form-basic form-import-file">
                    <div class="form-group">
                        <p class="label">
                            <span *ngIf="uploadData.fileToUpload?.length || uploadData.fileNotExtracted?.length; else allFilesImported">
                                {{'uploadFile.uploadScreen.subTitle' | translate}}
                            </span>
                            <ng-template #allFilesImported>
                                <span>{{'uploadFile.uploadScreen.subTitle2' | translate}}</span>
                            </ng-template>
                        </p>
                        <div class="upload-info text-white text-center text-size-12 position-relative my-4" *ngFor="let infoLabel of infoLabels" [innerHtml]="infoLabel | safe:'html'"></div>
                        <div class="input-file">
                            <compliance-drag-n-drop [uploadActionType]="uploadAction" [fileType]="uploadData.fileTypeList" [projectId]="projectId"
                                                    (fileProgressEvent)="end($event)"  [exerciceId]="exerciceId"
                                                    (errorEvent)="manageError($event)" (beginEvent)="manageBeginUpload($event)"></compliance-drag-n-drop>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col col-xl-12 file-list d-flex justify-content-around">
                <div *ngIf="uploadData.uploadedFiles && uploadData.uploadedFiles.length">
                    <p class="title"><b>{{'uploadFile.uploadScreen.already' | translate}}</b></p>
                    <ul class="list-group" style="width: 480px; margin: auto">
                        <li class="list-group-item text-white bg-transparent border-0 " *ngFor="let file of uploadData.uploadedFiles">
                            <i *ngIf="file.deleting" class="fas fa-spinner fa-pulse fa-fw"></i>
                            <i *ngIf="!file.deleting" class="far fa-trash-alt text-primary mr-2 action-hover" (click)="deleteFile(file)"></i>
                            <span>&nbsp;{{file.type}} - {{file.fileName}}</span>
                        </li>
                    </ul>
                </div>
                <div *ngIf="uploadData.fileNotExtracted?.length || uploadData.fileToUpload?.length">
                    <div *ngIf="uploadData.fileNotExtracted?.length" class="mb-5">
                        <p class="title"><b>{{'uploadFile.uploadScreen.errorFile' | translate}}</b></p>
                        <ul class="list-group" style="width: 480px; margin: auto">
                            <li class="list-group-item text-white bg-transparent border-0 " *ngFor="let file of uploadData.fileNotExtracted">
                                <i *ngIf="file.deleting" class="fas fa-spinner fa-pulse fa-fw"></i>
                                <i *ngIf="!file.deleting" class="far fa-trash-alt text-primary mr-2 action-hover" (click)="deleteFile(file)"></i>
                                <span>&nbsp;{{file.type}} - {{file.fileName}}</span>
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="uploadData.fileToUpload?.length">
                        <p class="title"><b>{{'uploadFile.uploadScreen.missing' | translate}}</b></p>
                        <ul class="list-group" style="width: 480px; margin: auto">
                            <li class="list-group-item text-white bg-transparent border-0 " *ngFor="let file of uploadData.fileToUpload">
                                <span>&nbsp;{{file.type}}</span> -
                                <span class="text-capitalize"><ng-container *ngIf="file.prefix">{{file.prefix}}&nbsp;</ng-container>{{file.date}}</span>
                                <span *ngIf="file.facultatif">&nbsp;{{'uploadFile.uploadScreen.facultatif' | translate}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- START : footer -->
    <footer id="footer">
        <div class="inner">
            <a href="#" class="btn btn-primary btn-back" (click)="goBack()"><span>{{'buttons.back' | translate}}</span></a>

            <button class="btn btn-primary btn-next" (click)="gotToNext()" [disabled]="noFacultatifFileToupload"><span>{{'buttons.next' | translate}}</span></button>
        </div>
    </footer>
</div>
<!-- STOP : footer -->

