import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from "@angular/router";

import moment from "moment";
import {LoginService} from "../auth/login.service";
import {AuthService} from "../auth/auth.service";
import {ToastrService} from "ngx-toastr";
import {throwError} from "rxjs/internal/observable/throwError";
import {COMPLIANCE_TYPE_EXCEPTION} from "../../class/compliance-constant";
import {ComplianceLoaderService} from "../loader/compliance-loader.service";
import {NotificationService} from "../push/notification.service";
import {catchError} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable()
export class ComplianceHttpInterceptor implements HttpInterceptor {
    constructor(private _router: Router, private _authService: AuthService, private _toastr: ToastrService, private _injector: Injector,
                private _complianceLoaderService: ComplianceLoaderService, private _notificationService: NotificationService) {
    }

    addWithCredentials(req: HttpRequest<any>): HttpRequest<any> {
        return req.clone({withCredentials: false});
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | Observable<any> {
        let loginService = this._injector.get(LoginService);

        // Pass on the cloned request instead of the original request.
        return next.handle(this.addWithCredentials(req)).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    this._complianceLoaderService.sendLoaderHide();
                    let errorDescription = err.error.error_description;
                    let message = err.error.message;
                    if ((err.status === 401 || err.status === 403) && (err.error.error === 'token_expired' || errorDescription.indexOf('Access token expired') >= 0)) {
                        return loginService.refreshToken(this.addWithCredentials(req), next);
                    } else if (err.status === 400 && err.error.error === 'token_not_provided') {
                        this._notificationService.disconnect();
                        this._authService.resetAuthValues();
                        this._router.navigate(['login']);
                        return throwError(err);
                    } else if (err.status === 401 && err.error.error === 'invalid_token') {
                        this._router.navigate(['login']);
                        return throwError(err);
                    } else {
                        let date = moment(err.error.timestamp);
                        if (err.error.type !== COMPLIANCE_TYPE_EXCEPTION.FUNCTIONAL) {
                            this._toastr.error(message || errorDescription, date.format("DD/MM/YYYY HH:mm:ss.SSS"));
                        }
                        console.warn('erreur: ', err);
                        return throwError(err);
                    }
                }else{
                    return next.handle(req);
                }
            })
        );

    }
}
