import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../../../../../../../common/class/compliance-api.service";
import {RestService} from "../../../../../../../../common/service/rest.service";
import {CompteRenduGeneral} from "./compte-rendu-general";
import {CompteRenduDetaille} from "./compte-rendu-detaille";

@Injectable({
    providedIn: 'root'
})
export class CompteRenduService extends ComplianceApiService {

    constructor(private restService: RestService) {
        super('compteRendu')
    }


    findCompteRenduDetailleGlobalData(projectId: string, exerciceId: string, controlId: string): Promise<CompteRenduDetaille> {
        return this.restService.get({
            url:this.baseUri,
            relativeUri:'compteRenduDetaille',
            parameters: [projectId, exerciceId, controlId],
            arg:null as CompteRenduDetaille
        })
    }

    findCompteRenduGeneralData(projectId:string, exerciceId:string, controlId:string):Promise<CompteRenduGeneral>{
        return this.restService.get({
            url:this.baseUri,
            relativeUri:'compteRenduGeneral',
            parameters:[projectId, exerciceId, controlId],
            arg:null as CompteRenduGeneral
        })
    }


}
