export const upload = {
    alertUploadNotFind: "Veuillez importer un fichier",
    dropBoxLabel: "Déposez vos fichiers ici",
    fileUploadButtonLabel: "Choisissez votre fichier",
    uploadButonLabel: "Télécharger",
    tvaUploadFecLabel: "Téléchargement de vos fichiers",
    controls: "Contrôles",
    errorExtention: "Les fichiers suivants n'ont pas la bonne extension",
    fec: {
        warningHeader: "Le header de votre FEC n'est pas conforme aux normes et risque d'être rejeté lors du traitement.<br> Voulez-vous le télécharger quand même ?"
    },
    dragNDrop: {
        title: "GLISSER-déposer <br>VOTRE FICHIER DANS CETTE ZONE",
        fileChoice: "Ajouter votre fichier"
    },
    question: {
        alreadyExist: "Un fichier a déjà été déposé pour cette période. Que souhaitez-vous faire ?",
        NotTheSameSiren: "Le document que vous souhaitez déposer ne concerne pas le dossier courant. Que souhaitez-vous faire ?",
        fecError: `<b>Erreur</b> : Votre fichier FEC ne peut pas être déposé.`,
        differentExercice: {
            p1: "Le document que vous voulez déposer ne semble  pas concerner l'exercice ",
            p2: ". Que souhaitez-vous faire ? "
        },
        buttons: {
            deleteCurent: "Conserver l'ancien",
            deleteOld: "Remplacer par le nouveau",
            merge: "Compléter le dépôt (FEC multi-fichiers)",
            mergeDeb: "Compléter le dépôt"
        }
    },
    error: {
        part1: "Une erreur a eu lieu. Le fichier ",
        part2: " doit être réimporté.",
        fileInError: "Une erreur est survenue lors du chargement de votre fichier. Celui-ci n'a pas été enregistré.",
        type: {
            many: "Vous ne pouvez déposer que des ",
            one: "Vous ne pouvez déposer que des "
        },
        extension: "Le format de ce fichier n'est pas autorisé. Seuls les fichiers .pdf, .txt, .edi et les documents sans extension sont acceptés.",
        sirenError: "Votre entité n'a pas été trouvée dans la base SIREN. Le dossier ne peut pas être créé.",
        validatedExercice: `Le dépôt de votre fichier n'est pas autorisé. Vous ne pouvez pas déposer de fichier sur un exercice validé.`
    },
    info: {
        header: "Liste des téléchargements",
        uploadingLabel: "Traitement en cours...",
        uploadedLabel: "Fichier déposé",
        modal: {
            headerProjectCreation: "Création d'un nouveau dossier",
            header: "Erreur lors de l'import du fichier",
            footer: "[numéro de ligne][colonne]: message d'erreur",
            footerMaxSizeWarn1: "Attention votre fichier contiens plus de ",
            footerMaxSizeWarn2: "erreurs. Nous n'affichons que les 200 premières erreurs rencontrées"
        },
        table: {
            headers: {
                fileName: "Nom du fichier",
                status: "Status du téléchargement",
                project: "Dossier"
            }
        }
    }
};
