import {CategoryControl, DomaineControl} from "../../class/control/control-descriptor";
import {ComplianceI18nLoader} from "../../class/i18n/compliance-i18n-loader";
import {Subject, Subscription} from "rxjs";
import {NotificationMessage} from "../../class/polling/notification-message";
import * as _ from "lodash";
import {NotificationService} from "../../service/push/notification.service";
import {Directive, OnDestroy} from "@angular/core";
import {takeUntil} from "rxjs/operators";

export class ComplianceControlContext {
    projectId: string;
    domain: DomaineControl;
    category: CategoryControl;
    stateMachineId: string;
    exerciceId: string;
    messageParams: MessageParameters;
}

export class ControlMessageContext {
    notificationService: NotificationService;
    manageDataEvent?: (notification: NotificationMessage) => any;
    manageErrorEvent?: (notification: NotificationMessage) => void;
    manageRouteEvent?: (notification: NotificationMessage, domain: DomaineControl, category: CategoryControl, projectId: string, exerciceId: string, stateMachineId: string) => void;
    manageDoneParameterEvent?: (domain?: DomaineControl, category?: CategoryControl, projectId?: string, exerciceId?: string, stateMachineId?: string) => void;
    manageDoneEvent?: (domain?: DomaineControl, category?: CategoryControl, projectId?: string, exerciceId?: string, stateMachineId?: string) => void;
    manageExitEvent?: (domain: DomaineControl, category: CategoryControl, projectId: string, exerciceId: string, originPage: OriginPage) => void;
}

export class MessageParameters {
    originPage?: OriginPage;
    controlId?: string;
    stepByStep?: boolean;

    constructor() {
        this.originPage = OriginPage.LISTCONTROL;
    }
}

export enum OriginPage {
    DASHBOARD = 'D',
    GLOBALPARAMS = 'GP',
    LISTCONTROL = 'LC'
}

export class MessageContext {
    projectId: string;
    domain: DomaineControl;
    category: CategoryControl;
    stateMachineId: string;
    exerciceId: string;
    messageParams: MessageParameters;
    notification: NotificationMessage;
    manageDataEvent?: (notification: NotificationMessage) => any;
    manageErrorEvent?: (notification: NotificationMessage) => void;
    manageRouteEvent?: (notification: NotificationMessage, domain: DomaineControl, category: CategoryControl, projectId: string, exerciceId: string, stateMachineId: string, messageParams: MessageParameters) => void;
    manageDoneParameterEvent?: (domain?: DomaineControl, category?: CategoryControl, projectId?: string, exerciceId?: string, stateMachineId?: string) => void;
    manageDoneEvent?: (domain?: DomaineControl, category?: CategoryControl, projectId?: string, exerciceId?: string, stateMachineId?: string, messageParams?: MessageParameters) => void;
    manageExitEvent?: (domain?: DomaineControl, category?: CategoryControl, projectId?: string, exerciceId?: string, originPage?: OriginPage) => void;
}

// TODO: Add Angular decorator.
@Directive()
export abstract class ControlMessageHandler extends ComplianceI18nLoader implements OnDestroy {

    public subscriptions: Subscription[] = [];
    destroy$: Subject<boolean> = new Subject<boolean>();
    private messageContext: MessageContext = new MessageContext();

    constructor() {
        super();
    }

    setMessageContext(context: ComplianceControlContext) {
        _.extend(this.messageContext, {
            notification: undefined,
            projectId: context.projectId,
            domain: context.domain,
            category: context.category,
            stateMachineId: context.stateMachineId,
            exerciceId: context.exerciceId,
            messageParams: {
                originPage: context.messageParams.originPage,
                controlId: context.messageParams.controlId,
                stepByStep: context.messageParams.stepByStep
            }
        });
    }

    updateContext(partialContext: {stateMachineId?: string; controlId?: string, stepByStep?: boolean, exerciceId?:string}) {
        if (partialContext.stateMachineId) {
            this.messageContext.stateMachineId = partialContext.stateMachineId;
        }
        if (partialContext.controlId) {
            this.messageContext.messageParams.controlId = partialContext.controlId;
        }
        if (partialContext.exerciceId) {
            this.messageContext.exerciceId = partialContext.exerciceId;
        }
        if (typeof partialContext.stepByStep !== 'undefined' && partialContext.stepByStep !== null) {
            this.messageContext.messageParams.stepByStep = partialContext.stepByStep;
        }
    }

    protected subscribeToNotificationControl({
                                                 notificationService,
                                                 manageErrorEvent,
                                                 manageDataEvent,
                                                 manageRouteEvent,
                                                 manageDoneParameterEvent,
                                                 manageDoneEvent
                                             }: ControlMessageContext) {
        _.extend(this.messageContext, {
            manageErrorEvent: manageErrorEvent,
            manageRouteEvent: manageRouteEvent,
            manageDoneEvent: manageDoneEvent,
            manageDoneParameterEvent: manageDoneParameterEvent,
            manageDataEvent: manageDataEvent,
        });

        notificationService.controlNotificationObservable
            .pipe(takeUntil(this.destroy$))
            .subscribe((notification: NotificationMessage) => {
                _.extend(this.messageContext, {
                    notification: notification
                });
                notificationService.manageMessageEvent(this.messageContext);
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getSubscriptions() {
        return this.subscriptions;
    }

    protected manageDataEvent(notification: NotificationMessage) {

    }


}
