import {APP_INITIALIZER, ApplicationRef, ErrorHandler, Injector, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateModule} from "@ngx-translate/core";
import {ComplianceModule} from "./compliance/compliance.module";
import {ToastContainerModule, ToastrModule} from "ngx-toastr";
import {CommonModule, registerLocaleData} from "@angular/common";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";


import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";

import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/python/python';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/comment/continuecomment';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/anyword-hint';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/json-lint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/search/search';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/search/matchesonscrollbar';
import 'codemirror/addon/search/match-highlighter';
import 'codemirror/addon/search/jump-to-line';

import {AuthGuard} from "./shared/auth/auth.guard";
import {ConfirmationService} from "primeng/api";
import {LoginComponent} from "../../../common/login/login.component";
import {CreatePasswordComponent} from "../../../common/login/create-password/create-password.component";
import {ForgottenPasswordComponent} from "../../../common/login/forgotten-password/forgotten-password.component";
import {ComplianceServiceLibModule} from "../../../common/service/module-service";
import {ComplianceSharedModule} from "../../../common/compliance-shared.module";
import {NgProgressModule} from "@ngx-progressbar/core";
import {DossierUtilsService} from "../../../common/service/dossier/dossier-utils.service";
import {AppConfigService} from "../../../common/service/app-config.service";
import {NgxLoadingModule} from "ngx-loading";
import {UploadFileModule} from "./shared/upload-file/upload-file.module";
import {FileSaverModule} from "ngx-filesaver";
import {ExportFileControlService} from "./shared/export-file/export-file-control.service";
import {LegalService} from "./shared/export-file/legal.service";
import moment from 'moment';
import {ServiceLocator} from "../../../common/class/locator/service-locator";
import {environment} from "../environments/environment";
import {ServiceWorkerModule} from '@angular/service-worker';
import {UpdateService} from "./shared/update.service";
import {CookieGuard} from "./shared/auth/cookie.guard";
import {GlobalErrorHandlerService} from "./shared/error-handler/global-error-handler.service";

registerLocaleData(localeFr, localeFrExtra);
moment.locale('fr')

export function init_app(configService: AppConfigService) {
    // Load Config service before loading other components / services
    return () => {
        return configService.load();
    };
}


console.log('appModule environnement', environment);

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        CreatePasswordComponent,
        ForgottenPasswordComponent,

    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        NgbModule,
        TranslateModule.forRoot(),
        NgProgressModule,
        NgxLoadingModule.forRoot({}),
        ComplianceSharedModule,
        ComplianceModule,
        AppRoutingModule,
        ToastrModule.forRoot({
            enableHtml: true,
            closeButton: true,
            progressBar: true
        }),
        ToastContainerModule,
        ComplianceServiceLibModule.forRoot(),
        UploadFileModule,
        FileSaverModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'fr'},
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        ConfirmationService,
        AuthGuard, DossierUtilsService, CookieGuard,
        ExportFileControlService,
        LegalService,
        AppConfigService,
        UpdateService,
        {
            'provide': APP_INITIALIZER,
            'useFactory': init_app,
            'deps': [AppConfigService],
            'multi': true,
        }
    ],
    bootstrap: [AppComponent],
})
export class AppFrontOfficeModule {
    constructor(private injector: Injector, private updateService:UpdateService, public appRef: ApplicationRef) {
        ServiceLocator.injector = injector
    }

}
