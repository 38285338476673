<div id="content" class="page-form-ca3 page-botom-transparent">

    <div class="container-fluid mx-auto" *ngIf="ca3FormData">
            <div class="block-group-title">
                <h2 *ngIf = "fileId; else elseBlock"  class="title col-12">{{'ca3ManualForm.update.title' | translate}}</h2>
                <ng-template #elseBlock>
                    <h2 class="title col-12">{{'ca3ManualForm.title' | translate}}</h2>
                </ng-template>

                <h2 *ngIf = "fileId; else elseBlock2" class="sub-title col-12">{{'ca3ManualForm.update.subtitle' | translate}}</h2>
                <ng-template #elseBlock2>
                    <h2 class="sub-title col-12">{{'ca3ManualForm.subtitle' | translate}}</h2>
                </ng-template>
            </div>

        <ng-container *ngIf="ca3MontToEdit.length || fileId ;else noFileToEdit">
            <div class="text-white">
                <div class="row">
                    <div class="bloc form-head col-12">
                        <div class="bloc-title mb-3">{{'ca3ManualForm.period.title' | translate}}</div>
                        <ng-container *ngIf="!fileId" >
                            <div>
                                <label class="mb-2 mr-2">{{'ca3ManualForm.period.monthLabel' | translate}}</label>
                            </div>
                            <p-dropdown [options]="ca3MontToEdit" [(ngModel)]="ca3MonthSelected" required (onChange)="changeMillesime($event)"></p-dropdown>
                        </ng-container>
                        <ng-container *ngIf="fileId" >
                            <div class="blocMonth mb-3">{{ca3MonthSelected | date:'MMMM yyyy' | titlecase}}</div>
                        </ng-container>

                    </div>
                </div>
                <ng-container *ngIf="ca3FormData.blocFormulaire && ca3FormData.blocFormulaire.length;else noConfig">
                    <form [formGroup]="ca3Form" novalidate class="row">


                        <div class="bloc col-lg-6 col-12" *ngFor="let bloc of ca3FormData.blocFormulaire">
                            <div class="bloc-title mb-3">{{bloc.nameBloc}}</div>
                            <div class="paragraphe ml-4" *ngFor="let par of bloc.paragrapheFormulaire">
                                <div class="paragraphe-title mb-4">{{par.nameParagraphe}}</div>
                                <div class="ml-4 form-group" *ngFor="let ligne of par.lignesFormulaire">
                                    <label [attr.for]="ligne.transcodification" class="mb-2">{{ligne.ligneFormulaire}}</label>
                                    <input type="text" complianceLocalizedNumericInput [attr.id]="ligne.transcodification" class="form-control mb-4 compliance-localized-numeric-input"
                                           autofocus=""
                                           formControlName="{{ligne.transcodification}}"/>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-container>
                <ng-template #noConfig>
                    <div class="text-size-20 text-center">
                        <div>{{'ca3ManualForm.noConfig.title' | translate:{annee: ca3MonthSelected.format("YYYY")} }}</div>
                        <div>{{'ca3ManualForm.noConfig.subTitle' | translate}}</div>
                    </div>
                </ng-template>
            </div>
        </ng-container>
        <ng-template #noFileToEdit>
            <div class="no-file-to-edit-msg text-center text-white d-flex justify-content-center">
                <div class="m-auto">{{'ca3ManualForm.noFileToEdit.title' | translate:{annee: exercice.annee} }}</div>
            </div>
        </ng-template>
    </div>

    <div class="block-bottom-actions">
        <div class="inner text-center d-flex justify-content-between">
            <button href="#" class="btn btn-primary btn-back" (click)="goBack()">
                <span>{{'buttons.back' | translate}}</span></button>
            <button *ngIf="!fileId" href="#" class="btn btn-primary btn-next" (click)="save()">
                <span>{{'buttons.finished' | translate}}</span></button>
            <button *ngIf="fileId" href="#" class="btn btn-primary btn-next" (click)="update()">
                <span>{{'buttons.finished' | translate}}</span></button>
        </div>
    </div>
</div>
