<div class="chart chart-pie p-2">
    <p class="title">{{graph.titre}}</p>
    <p-chart type="{{graph.typeGraphe}}" [data]="data" [options]="options[graph.typeGraphe]"
             [height]="size[graph.typeGraphe].height" [width]="size[graph.typeGraphe].width" class="p-chart"
             [responsive]="true" [plugins]="[totalizer, dataLabels]"></p-chart>

    <div id="chart-1-legend" class="chart-legend" *ngIf="graph.options.displayLegend">
        <ul class="0-legend">
            <li *ngFor="let legend of graph.legends">
                <span class="color" [ngStyle]="{'background-color':legend.color}"></span>
                <span class="label" [innerHtml]="legend.blocText.text | safe:'html'"> </span>
            </li>
        </ul>
    </div>
</div>

