import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {ComplianceComponent} from "../compliance.component";
import {AuthGuard} from "../../shared/auth/auth.guard";
import {ExerciceAuthGuard} from "../../shared/auth/exercice-auth.guard";
import {AffectationCompteComponent} from "../tva/control/affectation-compte/affectation-compte.component";
import {QuestionComponent} from "../../../../../common/component/question/question.component";
import {BalanceComptableComponent} from "../tva/fec/balance-comptable/balance-comptable.component";
import {CodesJournauxComponent} from "../tva/fec/codes-journaux/codes-journaux.component";
import {GraphD3Component} from "./graph-d3/graph-d3.component";

const routes: Routes = [
    {
        path: 'compliance',
        component: ComplianceComponent,
        canActivateChild: [AuthGuard],
        children:
            [
                {
                    path: ':domain/:category/project',
                    canActivateChild: [AuthGuard],
                    children:
                        [
                            {
                                path: ':projectId',
                                children: [
                                    {
                                        path: ':exerciceId/globalParams',
                                        canActivateChild: [AuthGuard],
                                        children: [
                                            {
                                                path: 'graph',
                                                component: GraphD3Component,
                                                canActivate: [ExerciceAuthGuard],
                                                data: {
                                                    title: "breadcrumb.globalParams.title"
                                                }
                                            },
                                            {
                                                path: ':stateMachineId',
                                                children: [
                                                    {
                                                        path: 'question',
                                                        component: QuestionComponent,
                                                        canActivate: [ExerciceAuthGuard],
                                                        data: {
                                                            title: "breadcrumb.globalParams.question"
                                                        }
                                                    },
                                                    {
                                                        path: 'affectation',
                                                        component: AffectationCompteComponent,
                                                        canActivate: [ExerciceAuthGuard],
                                                        data: {
                                                            title: "breadcrumb.globalParams.affectation"
                                                        }
                                                    },
                                                    {
                                                        path: 'appareillage-codes-journaux',
                                                        component: CodesJournauxComponent,
                                                        canActivate: [ExerciceAuthGuard],
                                                        data: {
                                                            title: "breadcrumb.globalParams.codeJournaux"
                                                        }
                                                    },
                                                    {
                                                        path: 'balance',
                                                        component: BalanceComptableComponent,
                                                        data: {
                                                            title: "breadcrumb.globalParams.balance"
                                                        }
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                }
            ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class GlobalParamsRoutingModule {
}
