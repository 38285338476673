<ng-container [ngSwitch]="complexString.domType">
    <ng-container *ngSwitchCase="domType.SPAN">
        <span [ngStyle]="complexString.style" [ngClass]="complexString.styleClass">
            <ng-container *ngTemplateOutlet="complexStringTemplate;context:{cs:complexString}">
            </ng-container>
        </span>
    </ng-container>
    <ng-container *ngSwitchCase="domType.DIV">
        <div [ngStyle]="complexString.style" [ngClass]="complexString.styleClass">
            <ng-container *ngTemplateOutlet="complexStringTemplate;context:{cs:complexString}">
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="domType.PUCE">
        <ul [ngStyle]="complexString.style" [ngClass]="complexString.styleClass">
            <li *ngFor="let cs of complexString.complexString" [ngStyle]="complexString.style" [ngClass]="complexString.styleClass">
                <compliance-complex-string [pComplexString]="cs" [pContext]="context"></compliance-complex-string>
            </li>
        </ul>
    </ng-container>
    <ng-container *ngSwitchCase="domType.LINK">
        <a href="#" (click)="goTo(complexString.internLink.link)">{{complexString.internLink.label}}</a>
    </ng-container>
    <ng-container *ngSwitchCase="null">
        <p [ngStyle]="complexString.style" [ngClass]="complexString.styleClass">
            <ng-container *ngTemplateOutlet="complexStringTemplate;context:{cs:complexString}">
            </ng-container>
        </p>
    </ng-container>
</ng-container>


<ng-template #complexStringTemplate let-complexString="cs">
    <ng-container *ngIf="complexString.blocType === blocType.ATTENTION"><b>Attention</b> :</ng-container>
    <ng-container *ngIf="complexString.text"><span [innerHtml]="complexString.text | safe"></span></ng-container>
    <ng-container *ngFor="let cs of complexString.complexString">
        <compliance-complex-string [pComplexString]="cs" [pContext]="context"></compliance-complex-string>
    </ng-container>
</ng-template>
