<div class="height-100">
    <div class="height-100 container">
        <div class="d-flex justify-content-center height-100 flex-column">
            <div class="row">
                <div class="col col-xl-12">
                    <div class="block-group-title">
                        <h2 class="title big">{{'help.title' | translate}}</h2>
                        <h3 class="text-size-16 text-white mt-2">{{'help.subTitle' | translate}}</h3>
                    </div>
                </div>
            </div>

            <form class="form-basic" [formGroup]="contactForm" novalidate>
                <div class="row">
                    <div class="col-md-5">
                        <div class="form-group">
                            <label for="company" class="text-uppercase">{{'help.form.company' | translate}}</label>
                            <input type="text" id="company" name="company" formControlName="company" value="" size="40" class="form-control"/>
                            <div *ngIf="contactForm.controls['company']?.errors && !contactForm.controls['company']?.pristine" class="text-primary">
                                <span *ngIf="contactForm.controls['company']?.errors?.required">{{'help.form.validate.requiredMsg' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="lastName" class="text-uppercase">{{'help.form.lastName' | translate}}</label><br>
                            <input type="text" id="lastName" name="lastName" formControlName="lastName" value="" size="40" class="form-control"/>
                            <div *ngIf="contactForm.controls['lastName']?.errors && !contactForm.controls['lastName']?.pristine" class="text-primary">
                                <span *ngIf="contactForm.controls['lastName']?.errors?.required">{{'help.form.validate.requiredMsg' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="firstName" class="text-uppercase">{{'help.form.firstName' | translate}}</label><br>
                            <input type="text" id="firstName" name="firstName" formControlName="firstName" value="" size="40" class="form-control"/>
                            <div *ngIf="contactForm.controls['firstName']?.errors && !contactForm.controls['firstName']?.pristine" class="text-primary">
                                <span *ngIf="contactForm.controls['firstName']?.errors?.required">{{'help.form.validate.requiredMsg' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="email" class="text-uppercase">{{'help.form.email' | translate}}</label><br>
                            <input type="email" id="email" name="email" formControlName="email" value="" size="40" class="form-control"/>
                            <div *ngIf="contactForm.controls['email']?.errors && !contactForm.controls['email']?.pristine" class="text-primary">
                                <span *ngIf="contactForm.controls['email']?.errors?.required">{{'help.form.validate.requiredMsg' | translate}}</span>
                                <span *ngIf="contactForm.controls['email']?.errors?.pattern">{{'help.form.validate.invalidEmail' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="phone" class="text-uppercase">{{'help.form.phone' | translate}}</label><br>
                            <input type="tel" id="phone" name="phone" formControlName="phone" value="" size="40" class="form-control"/>
                            <div *ngIf="contactForm.controls['phone']?.errors && !contactForm.controls['phone']?.pristine" class="text-primary">
                                <span *ngIf="contactForm.controls['phone']?.errors?.required">{{'help.form.validate.requiredMsg' | translate}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <label for="comment" class="text-uppercase">{{'help.form.comment' | translate}}</label><br>
                            <textarea id="comment" name="comment" formControlName="comment" class="form-control"></textarea>
                            <div *ngIf="contactForm.controls['comment']?.errors && !contactForm.controls['comment']?.pristine" class="text-primary">
                                <span *ngIf="contactForm.controls['comment']?.errors?.required">{{'help.form.validate.requiredMsg' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <br/><br/>
                <div class="d-flex justify-content-between pt-4">
                    <div>
                        <button class="btn btn-primary btn-back" (click)="goBack()"><span>{{'help.form.btnBack' | translate}}</span></button>
                    </div>
                    <div>
                        <button class="btn btn-primary btn-next" [disabled]="!contactForm.valid" (click)="submit(contactForm)"><span>{{'help.form.btnSubmit' | translate}}</span></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
