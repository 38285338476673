import {Component, Input, OnInit,} from '@angular/core';
import _ from 'lodash';
import {Dataset, Graphe, GraphOptions, Legend, PIE_OPTION, TypeGraphe} from "../../../../../../../../../common/class/graphique/graphique";
import {BlocText} from "../../../../../../../../../common/class/text/bloc-text";
import {NumberUtilsService} from "../../../../../../../../../common/service/utils/number-utils.service";
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'compliance-graph',
    templateUrl: './graph.component.html',
    styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit {

    @Input() graph: Graphe;
    options: any = {};
    size: any = {};
    data: any;
    generator: any = {};
    totalizer: any;
    dataLabels = ChartDataLabels;

    constructor(private numberUtils: NumberUtilsService) {
        this.totalizer = {
            id: 'totalizer',

            // Used to concat datas on bar chart.
            beforeUpdate: chart => {
                let totals = {};
                let utmost = 0;

                chart.data.datasets.forEach((dataset, datasetIndex) => {
                    if (chart.isDatasetVisible(datasetIndex)) {
                        utmost = datasetIndex;
                        dataset.data.forEach((value, index) => {
                            totals[index] = (totals[index] || 0) + value;
                        });
                    }
                });

                chart.$totalizer = {
                    totals: totals,
                    utmost: utmost
                };
            }
        };

        this.options[TypeGraphe.pie] = _.merge(_.cloneDeep(PIE_OPTION), {
                tooltips: {
                    enabled: true,
                    callbacks: {
                        label: (tooltipItem, data) => {
                            let value = data.datasets[0].data[tooltipItem.index];
                            let currency = data.datasets[0].currency;
                            return this.numberUtils.formatShortNumber(value, 1, currency);

                        }
                    },
                }
            }
        );

        this.generator[TypeGraphe.pie] = this.generatePieData.bind(this);
        this.generator[TypeGraphe.bar] = this.generateBarData.bind(this);
        this.generator[TypeGraphe.horizontalBar] = this.generateHorizontalBarData.bind(this);
        this.size[TypeGraphe.pie] = {
            height: '110px',
            width: '100%'
        };
        this.size[TypeGraphe.bar] = {
            height: '150px',
            width: '100%'
        };
        this.size[TypeGraphe.horizontalBar] = {
            height: '150px',
            width: '100%'
        };

    }

    ngOnInit() {
        this.generator[this.graph.typeGraphe](this.graph);

    }

    generatePieData(graph: Graphe) {
        this.data = {
            labels: this.getLabels(_.map(graph.legends, (legend: Legend) => legend.blocText)),
            datasets: [{
                data: graph.element.datasets[0].values,
                backgroundColor: graph.element.datasets[0].color,
                currency: this.graph.element.datasets[0].currency
            }]
        };
    }


    private getLabels(blocTexts: BlocText[]) {
        return _.map(blocTexts, (legend: BlocText) => legend.text);
    }

    generateBarData(graph: Graphe) {

        let {datasets, suggestedMin, suggestedmax} = this.setBarData(graph);
        let barOption = this.getBarOption(suggestedMin, suggestedmax, graph.options, datasets[0].currency);

        _.assignIn(this.options[TypeGraphe.bar], barOption);
        this.data = {
            labels: this.getLabels(graph.element.categories),
            datasets: datasets
        };
    }

    generateHorizontalBarData(graph: Graphe) {

        let {hasAllValuesPostive, hasAllValuesNegative} = this.hasAllValuesPositiveOrNegative(graph);
        let {datasets, suggestedMin, suggestedmax} = this.setBarData(graph, hasAllValuesPostive, hasAllValuesNegative);
        let barOption = this.getHorizontalBarOption(suggestedMin, suggestedmax, graph.options, datasets[0].currency, hasAllValuesPostive, hasAllValuesNegative);

        _.assignIn(this.options[TypeGraphe.horizontalBar], barOption);
        this.data = {
            labels: this.getLabels(graph.element.categories),
            datasets: datasets
        };
    }

    private setBarData(graph: Graphe, hasAllValuesPostive?, hasAllValuesNegative?) {
        let datasets = [];
        let values = [];
        _.each(graph.element.datasets, (dataset: Dataset, index) => {
            let val = []
            dataset.values.forEach(value => {
                if (value != null) val.push(value);
            });
            values = [...values, ...val];
            datasets.push({
                data: val,
                backgroundColor: dataset.color[0],
                label: graph.legends[index].blocText.text,
                currency: dataset.currency
            });
        });
        let {suggestedMin, suggestedmax} = this.extractMinStepAndMaxStep(values, hasAllValuesPostive, hasAllValuesNegative);
        return {datasets, suggestedMin, suggestedmax};
    }

    private getBarOption(suggestedMin: number, suggestedmax: any, options: GraphOptions, currency: boolean) {


        this.options[TypeGraphe.bar] = {
            responsive: true,
            plugins: {
                // Change options for ALL labels of THIS CHART
                datalabels: {
                    // display: options.displayDatasetValues,
                    color: '#ffffff',
                    anchor: 'end',
                    align: 'start',
                    offset: -18,
                    clamp: true,
                    formatter: (value, ctx) => {
                        // return formatNumber(value,'fr', '1.0-0');

                        if (options.stacked) {
                            const total = ctx.chart.$totalizer.totals[ctx.dataIndex];
                            return this.numberUtils.formatShortNumber(total, 1, currency);
                        } else {
                            return this.numberUtils.formatShortNumber(value, 1, currency);
                        }
                    },
                    display: function (ctx) {

                        if (options.stacked) {
                            return ctx.datasetIndex === ctx.chart.$totalizer.utmost;
                        }
                        return options.displayDatasetValues;
                    }
                }
            },
            title: {
                fontColor: '#ffffff',
                display: false
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        return this.numberUtils.formatShortNumber(tooltipItem.yLabel, 1, currency);

                    },
                    title: (tooltipItem, data) => {
                        return '';
                    }
                }
            },
            legend: false,
            scales: {
                xAxes: [
                    {
                        gridLines: {color: "#ffffff", drawOnChartArea: false, tickMarkLength: 1},
                        ticks: {
                            display: options.displayStepX,
                            fontColor: "#ffffff",
                            padding: 2
                        },
                        stacked: options.stacked,
                    }
                ],
                yAxes: [
                    {
                        gridLines: {color: "#ffffff", drawOnChartArea: false, drawTicks: false},
                        ticks: {
                            display: options.displayStepY,
                            fontColor: "#ffffff",
                            padding: 2,
                            min: suggestedMin,
                            max: suggestedmax,
                        },
                        stacked: options.stacked,
                    }
                ]
            },
        };

    }

    private getHorizontalBarOption(suggestedMin: number, suggestedmax: any, options: GraphOptions, currency: boolean,  hasAllValuesPostive, hasAllValuesNegative) {


        this.options[TypeGraphe.horizontalBar] = {
            responsive: true,
            plugins: {
                // Change options for ALL labels of THIS CHART
                datalabels: {
                    // display: options.displayDatasetValues,
                    color: '#ffffff',
                    anchor: function (context) {
                        if(hasAllValuesPostive) return 'end';
                        if(hasAllValuesPostive) return 'sart';
                        var index = context.dataIndex;
                        var value = context.dataset.data[index];
                        return value >= 0 ? 'start' : 'end';
                    },
                    align: function (context) {
                        if(hasAllValuesPostive) return 'end';
                        if(hasAllValuesPostive) return 'sart';
                        var index = context.dataIndex;
                        var value = context.dataset.data[index];
                        return value >= 0 ? 'start' : 'end';
                    },
                    offset: 0,
                    clamp: true,
                    font:{
                        size: 10
                    },
                    formatter: (value, ctx) => {
                        // return formatNumber(value,'fr', '1.0-0');
                        if (options.stacked) {
                            const total = ctx.chart.$totalizer.totals[ctx.dataIndex];
                            return this.numberUtils.formatShortNumber(total, 1, currency);
                        } else {
                            return this.numberUtils.formatShortNumber(value, 1, currency);
                        }
                    },
                    display: function (ctx) {
                        if (options.stacked) {
                            return ctx.datasetIndex === ctx.chart.$totalizer.utmost;
                        }
                        return options.displayDatasetValues;
                    }
                }
            },
            title: {
                fontColor: '#ffffff',
                display: false
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        return this.numberUtils.formatShortNumber(tooltipItem.xLabel, 1, currency);

                    },
                    title: (tooltipItem, data) => {
                        return '';
                    }
                }
            },
            legend: false,
            scales: {
                xAxes: [
                    {
                        gridLines: {color: "#ffffff", drawOnChartArea: false, drawTicks: false},
                        ticks: {
                            display: options.displayStepX,
                            fontColor: "#ffffff",
                            padding: 2,
                            suggestedMin: suggestedMin,
                            min: suggestedMin,
                            max: suggestedmax,
                        },
                        stacked: options.stacked,
                    }
                ],
                yAxes: [
                    {
                        gridLines: {color: "#ffffff", drawOnChartArea: false, tickMarkLength: 1},
                        ticks: {
                            display: options.displayStepY,
                            fontColor: "#ffffff",
                            padding: 2
                        },
                        stacked: options.stacked,
                    }
                ]
            },
        };

    }

    private extractMinStepAndMaxStep(values, hasAllValuesPostive, hasAllValuesNegative) {
        let numberValuesPos: number[] = [];
        let numberValuesNeg: number[] = [];
        values.forEach((value) => {
            if (value > 0) {
                numberValuesPos.push(value)
            } else if (value < 0) {
                numberValuesNeg.push(value)
            }
        });
        let multiplePos = 0.2;
        let multipleNeg = 0.2;
        if(hasAllValuesPostive)
            multiplePos = 0.4;
        if(hasAllValuesNegative)
            multipleNeg = 0.4;

        let suggestedMinPos = 0;
        let suggestedmaxPos = 0;
        let suggestedMinNeg = 0;
        let suggestedmaxNeg = 0;
        if (numberValuesPos.length) {
            let maxPos = _.max(numberValuesPos);
            let minPos = _.min(numberValuesPos);
            let diffPos = Math.abs(maxPos - minPos);
            suggestedMinPos = Math.max(minPos - diffPos, -5);
            suggestedmaxPos = maxPos + minPos + (maxPos * multiplePos);
        }

        if (numberValuesNeg.length) {
            let maxNeg = _.max(numberValuesNeg);
            let minNeg = _.min(numberValuesNeg);
            let diffNeg = Math.abs(minNeg - maxNeg);
            suggestedmaxNeg = Math.min(maxNeg - diffNeg, 5);
            suggestedMinNeg = minNeg + maxNeg + (minNeg * multipleNeg);
        }
        let suggestedmax = Math.max(suggestedmaxPos, suggestedmaxNeg);
        let suggestedMin = Math.min(suggestedMinPos, suggestedMinNeg);

        return {suggestedMin, suggestedmax};
    }

    hasAllValuesPositiveOrNegative(graphe: Graphe){
        let hasAllValuesPostive = true;
        let hasAllValuesNegative = true;
        graphe.element.datasets.forEach((dataset:Dataset) =>{
            dataset.values.forEach(value => {
                if (value < 0){
                    hasAllValuesPostive = false;
                }else{
                    hasAllValuesNegative = false;
                }
            })
        });

        return {hasAllValuesPostive, hasAllValuesNegative}
    }

}
