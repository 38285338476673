import {Component, OnInit} from '@angular/core';
import {BgSpaceComponent} from "../../../../../../../../common/class/background/bg-compliance";
import {ActivatedRoute, Router} from "@angular/router";
import {ExerciceService} from "../../../../../../../../common/service/exercice/exercice.service";
import {FileService} from "../../../../../../../../common/service/file/file.service";
import {ComplianceLoaderService} from "../../../../../../../../common/service/loader/compliance-loader.service";
import {UploadService} from "../../../../../shared/upload-file/drag-n-drop/shared/upload.service";
import {Exercice} from "../../../../../../../../common/class/exercice/exercice";
import {FormBuilder, FormGroup} from "@angular/forms";
import {LigneFormulaireDto, ManualCa3Form} from "./manual-ca3-form";
import moment from "moment";
import {UploadFile} from "../../../../../../../../common/class/upload/upload-file";
import {DATE_FORMAT} from "../../../../../../../../common/class/compliance-constant";
import {v4 as uuid} from 'uuid';
import {ComplianceToastrService} from "../../../../../../../../common/service/toastr/compliance-toastr.service";


class Ca3Month {
    value: moment.Moment;
    label: string;
}

@Component({
    selector: 'compliance-manual-ca3',
    templateUrl: './manual-ca3.component.html',
    styleUrls: ['./manual-ca3.component.scss']
})
export class ManualCa3Component extends BgSpaceComponent implements OnInit {
    private _domain: string;
    private _projectId: string;
    private _category: string;
    private _exerciceId: string;
    fileId: string;
    ca3Form: FormGroup;
    ca3FormData: ManualCa3Form;
    datePickerI18n;
    invalidDates: Date[] = [];
    ca3MontToEdit: Ca3Month[] = [];
    ca3MonthSelected: moment.Moment;
    dateFormat = DATE_FORMAT;
    private _currentYear;
    currentMonthSelected: string;
    exercice: Exercice;
    ca3Values: {};

    constructor(private _route: ActivatedRoute, private _router: Router, private _exerciceService: ExerciceService, private _complianceLoaderService: ComplianceLoaderService,
                private _uploadService: UploadService, private fb: FormBuilder, private _fileService: FileService, private _toastrService:ComplianceToastrService) {
        super();
    }

    ngOnInit() {
        this._domain = this._route.snapshot.paramMap.get('domain');
        this._category = this._route.snapshot.paramMap.get('category');
        this._projectId = this._route.snapshot.paramMap.get('projectId');
        this._exerciceId = this._route.snapshot.paramMap.get('exerciceId');
        this.fileId = this._route.snapshot.paramMap.get('fileId');
        this.datePickerI18n = super.getTranslation().datePicker;
        this._initData(this._exerciceId, this._projectId, this.fileId);
        this.invalidDates.push();

    }

    private async _initData(exerciceId: string, projectId: string, fileId: string) {
        this.exercice = await this._exerciceService.findExercicesById(exerciceId);
        if (this.fileId) {
            this.ca3Values = await this._uploadService.getManualCA3Values(this._projectId, this.fileId);
            const file = await this._uploadService.findFile(fileId);
            this.ca3MonthSelected = moment(file.debutPeriode, DATE_FORMAT.YYYYMMDD.moment);
        } else {
            const files: UploadFile[] = await this._fileService.findFilesByType(projectId, exerciceId, "CA3");
            this.ca3MonthSelected = this.computeCa3MonthToEdit(this.exercice, files);
        }
        if(this.ca3MonthSelected) {
            this.changeMillesime({value: this.ca3MonthSelected});
        } else {
            this.ca3FormData = new ManualCa3Form();
        }
    }

    private computeCa3MonthToEdit(exercice: Exercice, files: UploadFile[]) {
        let dateDebut = moment(exercice.dateDebutExercice, DATE_FORMAT.YYYYMMDD.moment).startOf('month');
        let dateFin = moment(exercice.dateFinExercice, DATE_FORMAT.YYYYMMDD.moment).endOf('month');
        this.ca3MontToEdit = [];
        let date = dateDebut.clone();
        do {
            let f = files.find(file => {
                let dateDebutPeriode = moment(file.debutPeriode, DATE_FORMAT.YYYYMMDD.moment).startOf('month');
                return dateDebutPeriode.isSame(date);
            });
            if (!f) {
                this.ca3MontToEdit.push({
                    value: date,
                    label: date.format(DATE_FORMAT.MMMM_YYYY.moment)
                });
            }
            date = dateDebut.add(1, "M").clone();
        } while (date.isBefore(dateFin));

        return (this.ca3MontToEdit && this.ca3MontToEdit.length)?this.ca3MontToEdit[0].value:null;
    }

    private getForm(annee: string) {
        this._uploadService.getFormCa3(annee).then(data => {
            this.ca3FormData = data;
            this.createForm(data);
        });
    }

    private createForm(data: ManualCa3Form) {
        const lignesFormulaires = this.computeDataForm(data);
        let formObj = {};
        if(this.fileId) {
            lignesFormulaires.forEach(ligne => formObj[ligne.transcodification] = [this.ca3Values[ligne.transcodification]]);
        } else {
            lignesFormulaires.forEach(ligne => formObj[ligne.transcodification] = ['']);
        }
        this.ca3Form = this.fb.group(formObj);
    }

    private computeDataForm(data: ManualCa3Form) {
        let lignesFormulaires: LigneFormulaireDto[] = [];
        data.blocFormulaire.forEach(bloc => {
            bloc.paragrapheFormulaire.forEach(par => {
                lignesFormulaires = [...lignesFormulaires, ...par.lignesFormulaire];
            });
        });

        return lignesFormulaires;
    }

    goBack() {
        this._router.navigate(['/compliance', this._domain, this._category, 'project', this._projectId, this._exerciceId, 'file-list']);

    }

    changeMillesime(event) {
        let year = event.value.get('year');
        this.currentMonthSelected = event.value.format(DATE_FORMAT.MMMM_YYYY.moment);
        if (year != this._currentYear) {
            this._currentYear = year;
            this.getForm(this._currentYear);
        }

    }

    saveOrUpdate(save: boolean){
        if(this.ca3FormData.blocFormulaire && this.ca3FormData.blocFormulaire.length) {
            let value = this.stripNoValuePropertie(this.ca3Form.getRawValue());
            let periode = this.ca3MonthSelected.startOf('month').format(DATE_FORMAT.YYYYMMDD.moment);
            let uid = uuid();
            if(save) {
                this._uploadService.saveFormCa3(this._projectId, this._exerciceId, uid, periode, value).then(() => {
                    this._router.navigate(['/compliance', this._domain, this._category, 'project', this._projectId, this._exerciceId, 'file-list'], {queryParams: {uuid: uid}});
                });
            } else {
                this._uploadService.updateFormCa3(this._projectId, this.fileId , value).then(() => {
                    this._router.navigate(['/compliance', this._domain, this._category, 'project', this._projectId, this._exerciceId, 'file-list'], {queryParams: {uuid: uid}});
                });
            }
        } else {
            this.goBack();
        }
    }

    save() {
       this.saveOrUpdate(true);
    }

    update() {
        this.saveOrUpdate(false);
    }

    private stripNoValuePropertie(rawValue: any) {
        let value = {};
        Object.keys(rawValue).forEach(key => {
            let val = rawValue[key];
            if (val != null && val != undefined) {
                val = val.replace(/[^0-9]*/g, '');
                if(val !== '') {
                    value[key] = val
                }
            }
        });
        return value;
    }
}
