import {Component, Input, OnInit} from '@angular/core';
import {CRDBlock, DispositionBlock} from "../../shared/compte-rendu-detaille";
import {CompteRenduDetaillUtilsService} from "../shared/compte-rendu-detaill-utils.service";
import {Subject} from "rxjs";

@Component({
    selector: 'compliance-crd-disposition-block',
    templateUrl: './crd-disposition-block.component.html',
    styleUrls: ['./crd-disposition-block.component.scss']
})
export class CrdDispositionBlockComponent implements OnInit {
    @Input() destroy$: Subject<boolean>;
    @Input() crdDispositionBlock: DispositionBlock;
    constructor(public compteRenduDetaillUtilsService:CompteRenduDetaillUtilsService) {
    }

    ngOnInit() {

    }

    isDispositionBlock(block :CRDBlock){
        return block.dispositionBlock !== undefined && block.dispositionBlock !== null;
    }


    getStyle(dispositionBlock: DispositionBlock) {
        return this.compteRenduDetaillUtilsService.getDispostionBlockStyle(dispositionBlock)
    }


}
