import {Injectable} from '@angular/core';

import moment from "moment";

const SHOWN_PREMIUM_PAGE_CACHE = "hasViewPremiumPage";
class Premium{
    seenPremiumPage:boolean;
    expire:moment.Moment;

    constructor(seenPremiumPage) {
        this.seenPremiumPage = seenPremiumPage;
        this.expire = moment().add(6,'h');
    }

}
@Injectable({
  providedIn: 'root'
})
export class VersionCompleteService {
  constructor() { }

  haveToShowPremiumPage(): boolean{
      let premium:Premium = localStorage.getItem(SHOWN_PREMIUM_PAGE_CACHE)?JSON.parse(localStorage.getItem(SHOWN_PREMIUM_PAGE_CACHE)):undefined;
      return !premium || !premium.seenPremiumPage || moment().isSameOrAfter(premium.expire);
  }

  haveSeenPremiumPage(){
      localStorage.setItem(SHOWN_PREMIUM_PAGE_CACHE, JSON.stringify(new Premium(true)));
  }
}
