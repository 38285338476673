import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ComplianceI18nLoader} from "../../../class/i18n/compliance-i18n-loader";
import {ActionQuestion, ChoiceQuestionItem, MetaQuestion, MultipleChoiceQuestion, QuestionEvent, QuestionEventType} from "../../../class/meta/question/meta-question";
import {MetaExpressionService} from "../../../service/meta/meta-expression.service";
import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";
import {MetaFormulaireService} from "../../../service/meta/meta-formulaire.service";
import {FormGroup} from "@angular/forms";
import _ from 'lodash';
import {MetaQuestionService} from "../../../service/meta/meta-question.service";
import {ActivatedRoute} from "@angular/router";
import {OriginPage} from "../../control/control-message-handler";

@Component({
    selector: 'compliance-multiple-choice-question',
    templateUrl: './multiple-choice-question.component.html',
    styleUrls: ['./multiple-choice-question.component.scss'],
    animations: [
        trigger('showAddTaux', [
            state('true', style({transform: 'translateY(0)', opacity: '1'})),
            state('false', style({transform: 'translateY(-20%)', opacity: '0'})),
            transition('true => false', [
                // animate('300ms ease-out'),

                animate(300, keyframes([
                    style({opacity: 1, transform: 'translateX(0)', offset: 0}),
                    style({opacity: 0.5, transform: 'translateY(-40px)', offset: 0.7}),
                    style({opacity: 0, transform: 'translateY(-60px)', offset: 1.0})
                ])),
                style({display: 'none'})
            ]),
            transition('false => true', [
                style({display: 'block'}),
                animate(300, keyframes([
                    style({opacity: 0, transform: 'translateY(-60px)', offset: 0}),
                    style({opacity: 0.5, transform: 'translateY(-40px)', offset: 0.7}),
                    style({opacity: 1, transform: 'translateX(0)', offset: 1.0})
                ]))
            ])
        ])
    ]
})
export class MultipleChoiceQuestionComponent extends ComplianceI18nLoader implements OnInit {

    metaQuestion: MetaQuestion<MultipleChoiceQuestion>;
    context: any;
    question: MultipleChoiceQuestion;
    addNew: boolean;
    choice: any = {};

    isControl: boolean;

    @Output() actionEvent: EventEmitter<QuestionEvent> = new EventEmitter();
    nextActive: boolean | number;
    questionFormGroup: FormGroup;
    itemAlreadyExist: boolean;

    constructor(route: ActivatedRoute,
                private expressionService: MetaExpressionService,
                private formService: MetaFormulaireService,
                private questionService: MetaQuestionService) {
        super();
        this.isControl = route.snapshot.queryParamMap.get('originPage') !== OriginPage.GLOBALPARAMS;
    }

    ngOnInit() {
        this.question = new MultipleChoiceQuestion(this.metaQuestion.question);

        console.log(this.question);
        if (this.question.formulaire) {
            this.addNew = false;
            this.questionFormGroup = this.formService.toFormGroup(this.question.formulaire);
        }
        this.setChoice();
        console.log('MultipleChoiceQuestionComponent ngOnInit', this.choice);
        this.computeNextActive();
    }


    private setChoice() {
        if (this.question.multiple) {
            this.setMultplieChoice();
        } else {
            this.setSingleChoice();
        }
    }

    private setSingleChoice() {
        _.each(this.question.items, (item) => {
            if (_.isEqual(item[this.question.codeField], this.question.data)) {
                this.choice = this.question.data;
            }
        });
    }

    private setMultplieChoice() {
        _.each(this.question.items, (item) => {
            this.choice[item[this.question.codeField]] = {
                code: item[this.question.codeField],
                checked: !!_.find(this.question.data, (response) => response === item[this.question.codeField]),
                label: item[this.question.labelField]
            };
        });
    }

    goBack() {
        this.actionEvent.emit({type: QuestionEventType.GLOBAL, data: {code: 'back', data: this.question.data}});
    }

    goNext() {
        this.actionEvent.emit({type: QuestionEventType.GLOBAL, data: {code: 'next', data: this.question.data}});
    }


    private computeNextActive() {
        let next = this.question.buttons.next;
        if (next) {
            if (next.active) {
                this.nextActive = this.expressionService.computeExpression(next.active, this.question);
            } else {
                this.nextActive = true;
            }
        } else {
            this.nextActive = true;
        }

    }

    addItem() {
        let item = {};
        _.each(this.question.buttons.add.actions, (action: ActionQuestion) => {
            _.assignIn(item, this.questionService.procesQuestionProceesValueAction(action, this.questionFormGroup.value));
        });
        console.log(item);
        if (!_.isEmpty(item)) {
            let findItem = _.find(this.question.items, (questionItem) => questionItem[this.question.codeField] === item[this.question.codeField]);
            this.itemAlreadyExist = !!findItem;
            if (!findItem) {
                this.question.items = _.sortBy([...this.question.items, item], 'code');
                this.choice[item[this.question.codeField]] = {
                    code: item[this.question.codeField],
                    checked: true,
                    label: item[this.question.labelField]
                };

                if (this.question.multiple) {
                    this.setResponseMulti();
                } else {
                    this.setResponseSingle(item);
                }
            }


        }
    }

    showForm() {
        this.addNew = !this.addNew;
    }

    setResponseMulti() {
        let choices = [];
        _.forIn(this.choice, (value, code) => {
            if (value.checked) {
                // let item = {}
                // item[this.question.codeField] = code;
                choices.push(value.code);
            }

        });
        console.log(choices);
        this.question.data = {};
        this.question.data = choices;
        this.metaQuestion.question.data = choices;
        this.computeNextActive();
    }

    setResponseSingle(item: ChoiceQuestionItem) {
        this.question.data = this.choice;
        this.metaQuestion.question.data = this.choice;

        this.computeNextActive();
        console.log('MultipleChoiceQuestionComponent setResponseSingle', item);
        console.log('MultipleChoiceQuestionComponent setResponseSingle', item);
        if (item.actions && item.actions.length) {
            this.actionEvent.emit({
                type: QuestionEventType.ITEM,
                data: {
                    actions: item.actions,
                    data: this.question.data
                }
            });
        }
    }
}
