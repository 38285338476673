<div id="content" class="page-botom-transparent">

    <div class="container-fluid  w-70 text-center text-white">
        <!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" id="Mode_Isolation" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
             y="0px" viewBox="0 0 217.7 52.2" style="enable-background:new 0 0 217.7 52.2;" xml:space="preserve">
            <style type="text/css">
                .st0 {
                    fill: #fff;
                }

                .st1 {
                    clip-path: url(#SVGID_2_);
                    fill: #fff;
                }

                .st2 {
                    clip-path: url(#SVGID_4_);
                    fill: #fff;
                }

                .st3 {
                    clip-path: url(#SVGID_6_);
                    fill: #fff;
                }

                .st4 {
                    clip-path: url(#SVGID_8_);
                    fill: #fff;
                }

                .st5 {
                    clip-path: url(#SVGID_10_);
                    fill: #fff;
                }

                .st6 {
                    clip-path: url(#SVGID_12_);
                    fill: #D93A78;
                }

                .st7 {
                    fill: #D93A78;
                }

                .st8 {
                    clip-path: url(#SVGID_14_);
                    fill: #D93A78;
                }

                .st9 {
                    clip-path: url(#SVGID_16_);
                    fill: #D93A78;
                }

                .st10 {
                    clip-path: url(#SVGID_18_);
                    fill: #DB3B6D;
                }

                .st11 {
                    clip-path: url(#SVGID_18_);
                    fill: #D93A78;
                }
            </style>
            <polygon class="st0" points="6.3,2.2 6.3,5.1 10.5,5.1 10.5,6.9 6.3,6.9 6.3,11.9 4.3,11.9 4.3,0.3 11.6,0.3 11.6,2.2 "/>
            <g>
                <defs>
                    <rect id="SVGID_1_" x="-525.8" y="-593" width="1218.9" height="870.2"/>
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;"/>
                </clipPath>
                <path class="st1" d="M19,5.2h2.7c1.3,0,1.9-0.6,1.9-1.5c0-0.9-0.7-1.5-2-1.5H19V5.2z M21.6,0.3c2.2,0,3.9,1.3,3.9,3.4
                    c0,1.6-1.1,2.8-2.3,3.2l2.6,4.9h-2.2l-2.5-4.8H19v4.8h-1.9V0.3H21.6z"/>
            </g>
            <path class="st0" d="M36.4,4l-1.6,3.6h3.1L36.4,4z M34,9.3l-1.1,2.5h-2.1L36.4,0l5.5,11.9h-2.1l-1.1-2.5H34z"/>
            <polygon class="st0" points="55,0.3 56.9,0.3 56.9,12.2 49,4.4 49,11.9 47.1,11.9 47.1,0 55,7.8 "/>
            <g>
                <defs>
                    <rect id="SVGID_3_" x="-525.8" y="-593" width="1218.9" height="870.2"/>
                </defs>
                <clipPath id="SVGID_4_">
                    <use xlink:href="#SVGID_3_" style="overflow:visible;"/>
                </clipPath>
                <path class="st2" d="M73.3,10c-1.2,1.3-2.7,2.1-4.6,2.1c-3.1,0-6-2.6-6-6c0-3.1,2.6-6,6-6c1.9,0,3.4,0.8,4.3,2l-1.5,1.2
                    C70.8,2.6,69.9,2,68.8,2c-2,0-4,1.8-4,4.1c0,2.3,1.7,4.1,4,4.1c1.1,0,2.2-0.7,2.9-1.4L73.3,10z"/>
            </g>
            <rect x="79" y="0.3" class="st0" width="2" height="11.5"/>
            <g>
                <defs>
                    <rect id="SVGID_5_" x="-525.8" y="-593" width="1218.9" height="870.2"/>
                </defs>
                <clipPath id="SVGID_6_">
                    <use xlink:href="#SVGID_5_" style="overflow:visible;"/>
                </clipPath>
                <path class="st3" d="M90,6.6c-1.8-0.6-2.7-1.7-2.7-3.4c0-1.9,1.6-3,3.5-3c1.5,0,2.7,0.5,4,1.2L93.9,3c-1.3-0.7-2.1-1-2.9-1
                    c-1.4,0-1.7,0.8-1.7,1.3s0.2,1,1,1.3l2.3,0.9c1.8,0.7,2.5,1.8,2.5,3.2c0,2.1-1.8,3.4-3.9,3.4c-1.2,0-3-0.6-4.6-1.6l0.9-1.7
                    c1.6,0.9,2.7,1.3,3.8,1.3c1.2,0,1.8-0.7,1.8-1.5c0-0.8-0.6-1.2-1.3-1.5L90,6.6z"/>
            </g>
            <polygon class="st0" points="116.6,10 116.6,11.9 109.3,11.9 109.3,0.3 111.3,0.3 111.3,10 "/>
            <polygon class="st0" points="123.9,2.2 123.9,5.1 128.5,5.1 128.5,6.9 123.9,6.9 123.9,10.1 129.5,10.1 129.5,11.9 122,11.9
                122,0.3 129.4,0.3 129.4,2.2 "/>
            <polygon class="st0" points="137.6,2.2 137.6,5.1 141.9,5.1 141.9,6.9 137.6,6.9 137.6,11.9 135.7,11.9 135.7,0.3 143,0.3 143,2.2
                "/>
            <polygon class="st0" points="150.4,2.2 150.4,5.1 155,5.1 155,6.9 150.4,6.9 150.4,10.1 156,10.1 156,11.9 148.5,11.9 148.5,0.3
                155.9,0.3 155.9,2.2 "/>
            <g>
                <defs>
                    <rect id="SVGID_7_" x="-525.8" y="-593" width="1218.9" height="870.2"/>
                </defs>
                <clipPath id="SVGID_8_">
                    <use xlink:href="#SVGID_7_" style="overflow:visible;"/>
                </clipPath>
                <path class="st4" d="M164.1,10h3c1.1,0,1.9-0.8,1.9-1.8c0-0.9-0.8-1.8-1.9-1.8h-3V10z M164.1,4.7h2.5c0.8,0,1.3-0.6,1.3-1.3
                    c0-0.6-0.5-1.3-1.3-1.3h-2.5V4.7z M166.6,0.3c2,0,3.2,1.4,3.2,3.1c0,0.9-0.6,1.7-1,2c0.9,0.4,2,1.5,2,2.8c0,2-1.5,3.6-3.8,3.6h-4.9
                    V0.3H166.6z"/>
            </g>
            <polygon class="st0" points="174.9,0.3 177.1,0.3 180.4,8.1 183.8,0.3 185.9,0.3 180.4,12.2 "/>
            <g>
                <defs>
                    <rect id="SVGID_9_" x="-525.8" y="-593" width="1218.9" height="870.2"/>
                </defs>
                <clipPath id="SVGID_10_">
                    <use xlink:href="#SVGID_9_" style="overflow:visible;"/>
                </clipPath>
                <path class="st5" d="M193.1,5.2h2.7c1.3,0,1.9-0.6,1.9-1.5c0-0.9-0.7-1.5-2-1.5h-2.6V5.2z M195.7,0.3c2.2,0,3.9,1.3,3.9,3.4
                    c0,1.6-1.1,2.8-2.3,3.2l2.6,4.9h-2.2l-2.5-4.8h-2.2v4.8h-1.9V0.3H195.7z"/>
            </g>
            <polygon class="st0" points="207.7,2.2 207.7,5.1 212.3,5.1 212.3,6.9 207.7,6.9 207.7,10.1 213.3,10.1 213.3,11.9 205.8,11.9
                205.8,0.3 213.3,0.3 213.3,2.2 "/>
            <g>
                <defs>
                    <rect id="SVGID_11_" x="-525.8" y="-593" width="1218.9" height="870.2"/>
                </defs>
                <clipPath id="SVGID_12_">
                    <use xlink:href="#SVGID_11_" style="overflow:visible;"/>
                </clipPath>
                <path class="st6" d="M58.1,41.9c-1.9,2-4.4,3.4-7.5,3.4c-5.1,0-9.8-4.3-9.8-9.6c0-4.9,4.3-9.5,9.8-9.5c3.3,0,5.8,1.5,7.1,3.3
                    l-2.9,2.3c-1.2-1.4-2.5-2.4-4.3-2.4c-2.8,0-5.9,2.8-5.9,6.3c0,3.6,2.6,6.3,5.9,6.3c1.7,0,3.4-1.1,4.5-2.5L58.1,41.9z"/>
                <path class="st6" d="M64.1,35.7c0,3.7,2.5,6.3,5.7,6.3c2.9,0,5.7-2.6,5.7-6.3c0-3.4-2.7-6.3-5.7-6.3C66.8,29.4,64.1,32,64.1,35.7
                     M79.5,35.7c0,5.1-3.9,9.6-9.7,9.6c-5.6,0-9.7-4.2-9.7-9.6c0-5.4,3.8-9.5,9.7-9.5C75.2,26.1,79.5,30.2,79.5,35.7"/>
            </g>
            <polygon class="st7" points="98.1,44.9 98.1,34.6 92.4,40.5 86.7,34.6 86.7,44.9 82.8,44.9 82.8,25.9 92.4,35.9 102,25.9 102,44.9
                "/>
            <g>
                <defs>
                    <rect id="SVGID_13_" x="-525.8" y="-593" width="1218.9" height="870.2"/>
                </defs>
                <clipPath id="SVGID_14_">
                    <use xlink:href="#SVGID_13_" style="overflow:visible;"/>
                </clipPath>
                <path class="st8" d="M110.1,34.2h3.1c1.5,0,2.6-0.8,2.6-2.3c0-1.6-1-2.3-2.6-2.3h-3.1V34.2z M113.4,26.5c3.5,0,6.4,2.3,6.4,5.4
                    c0,3.1-2.7,5.4-6.4,5.4h-3.3v7.6h-3.8V26.5H113.4z"/>
            </g>
            <polygon class="st7" points="134.8,41.8 134.8,44.9 122.5,44.9 122.5,26.5 126.3,26.5 126.3,41.8 "/>
            <rect x="137.6" y="26.5" class="st7" width="3.9" height="18.5"/>
            <path class="st7" d="M153.1,33.2l-2,4.6h4L153.1,33.2z M149.8,40.9l-1.8,4h-4l9.1-19l9.1,19h-4l-1.7-4H149.8z"/>
            <polygon class="st7" points="177.2,26.5 181.1,26.5 181.1,45.4 168.6,34.1 168.6,44.9 164.8,44.9 164.8,25.9 177.2,37.3 "/>
            <g>
                <defs>
                    <rect id="SVGID_15_" x="-525.8" y="-593" width="1218.9" height="870.2"/>
                </defs>
                <clipPath id="SVGID_16_">
                    <use xlink:href="#SVGID_15_" style="overflow:visible;"/>
                </clipPath>
                <path class="st9" d="M201.6,41.9c-1.9,2-4.4,3.4-7.5,3.4c-5.1,0-9.8-4.3-9.8-9.6c0-4.9,4.3-9.5,9.8-9.5c3.3,0,5.8,1.5,7.1,3.3
                    l-2.9,2.3c-1.2-1.4-2.5-2.4-4.3-2.4c-2.8,0-5.9,2.8-5.9,6.3c0,3.6,2.6,6.3,5.9,6.3c1.7,0,3.4-1.1,4.5-2.5L201.6,41.9z"/>
            </g>
            <polygon class="st7" points="208.8,29.6 208.8,33.9 216,33.9 216,37.1 208.8,37.1 208.8,41.8 217.7,41.8 217.7,44.9 205,44.9
                205,26.5 217.6,26.5 217.6,29.6 "/>
            <g>
                <defs>
                    <rect id="SVGID_17_" x="-525.8" y="-593" width="1218.9" height="870.2"/>
                </defs>
                <clipPath id="SVGID_18_">
                    <use xlink:href="#SVGID_17_" style="overflow:visible;"/>
                </clipPath>
                <path class="st10" d="M20.3,40.5c0.1,0.3,0.2,0.7,0.4,1.1L20.3,40.5z"/>
                <path class="st11" d="M16.8,30.4c2.6,0,4,1.8,4.3,3.4h-8.4C12.9,32.1,14.8,30.4,16.8,30.4 M20.3,40.5l0.4,1.1
                    C20.5,41.1,20.4,40.8,20.3,40.5c-0.7,0.3-1.7,0.6-2.6,0.6c-3,0-5-2.1-5.1-4.3h12.3c0.1-0.4,0.1-1.1,0.1-1.1c0-5.4-3.3-8.8-8.2-8.8
                    c-3.9,0-8.2,3.1-8.2,8.8c0,5.8,4.6,8.8,8.9,8.8c1.4,0,3-0.3,4.1-0.8l15-7l0-3.9L20.3,40.5z"/>
            </g>
            <polygon class="st0" points="28.7,47.9 4.3,47.9 4.3,23.5 28.7,23.5 28.7,31.8 33,29.9 33,19.2 0,19.2 0,52.2 33,52.2 33,42.6
                28.7,44.4 "/>
        </svg>

        <div class="block-group-title ">
            <h2 class="title col-12" [innerHTML]="'gestionCookie.title' | translate | safe:'html'"></h2>
        </div>

        <div class=" text-size-16">
            <ng-container *ngIf="!showForm">
                <p class="paragraphe">En poursuivant votre visite sur ce site, vous acceptez l'utilisation de Cookies pour la personnalisation de votre navigation, la réalisation de statistiques anonymes, d'études comportementales et
                    l'affichage éventuel de
                    publicités adaptées à vos centres d'intérêts.</p>
                <p class="paragraphe little">Pour en savoir plus sur notre politique de confidentialité, <span class="action" (click)="downloadPolitique()">cliquez ici</span>.</p>
                <p class="paragraphe little" *ngIf="!showForm">Pour paramétrer vos cookies, <span class="action" (click)="showOption()">cliquez ici</span>.</p>
                <button class="btn btn-primary btn-cookie" (click)="acceptAllCookie()">J'ai compris</button>
            </ng-container>
            <compliance-gestion-cookie-preference *ngIf="showForm" (saveCookieEvent)="saveCookie($event)"></compliance-gestion-cookie-preference>



        </div>

    </div>
</div>

