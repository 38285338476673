export enum Operator {
    GT = 'GT',
    LT = 'LT',
    LTE = 'LTE',
    GTE = 'GTE',
    EQ = 'EQ',
    NEQ = 'NEQ',
    IN = 'IN',
    DIVIDE = 'DIVIDE',
    MULTIPLE = 'MULTIPLE',
    ADD = 'ADD',
    SUBSTRACT = 'SUBSTRACT',
    AND = 'AND',
    OR = 'OR',
    SIZE= 'SIZE',
    EMPTY= 'EMPTY',
    NOT_EMPTY= 'NOT_EMPTY',
    NULL= 'NULL',
    NOT_NULL= 'NOT_NULL',
    GET= 'GET'
}

export enum LogicOperator {
    AND, OR
}

// export interface LogicExpression{
//     operator?:LogicOperator;
//     logicExpressions?:LogicExpression[];
//     member?:Member;
//
// }

export class Expression{
    operator:Operator;
    firstMember:Member;
    secondMember?:Member | Member[];
}

export interface Member {
    value?:string|number|boolean|any[];
    field?:string;
    expression?:Expression

}

interface Filter<T> {
    data:T
}

export interface Test<T> {
    expression:Expression;
    filter?:T[]
}


export interface MetaExpression<T> {
    [keys: string]: Test<T>,

}
