import {Component, Input, OnInit} from '@angular/core';
import {CompteRenduText} from "../../shared/compte-rendu-general";
import {ConfirmationService} from "primeng/api";
import {NotificationService} from "../../../../../../../../../common/service/push/notification.service";
import {FileUtilsService} from "../../../../../../../../../common/service/file/file-utils.service";
import {ReportingService} from "../../../../../project/dashboard/tva-dashboard/reporting.service";
import {takeUntil} from "rxjs/operators";
import _ from "lodash";
import {TYPE_EXPORT, TypeExport} from "../../../../../../shared/export-file/export-file";
import {Subject} from "rxjs";
import {v4 as uuid} from 'uuid';

@Component({
    selector: 'compliance-general-rapport-detaille-text',
    templateUrl: './general-rapport-detaille-text.component.html',
    styleUrls: ['./general-rapport-detaille-text.component.scss']
})
export class GeneralRapportDetailleTextComponent  implements OnInit {
    private _uuid = uuid();
    @Input() meta: CompteRenduText;
    @Input() extendedData: any;
    @Input() projectId: string;
    @Input() exerciceId: string;
    @Input() controlId: string;
    @Input() destroy$: Subject<boolean>;
    exporting = false;

    constructor(private reportingService: ReportingService, private _confirmationService: ConfirmationService, private _notificationService: NotificationService,
                private _fileUtils: FileUtilsService) {
    }

    ngOnInit(): void {
        this._notificationService.fileNotificationObservable$
            .pipe(takeUntil(this.destroy$))
            .subscribe((msg) => {
                if (this.meta.export) {
                    if (this._uuid === msg.uuid) {
                        let exportData = this.meta.export.ecriture;
                        let fileName: string = exportData.fileName;
                        this._fileUtils.saveFile(fileName, this._fileUtils.blobPdfFromBase64String(msg.blob), new TypeExport(TYPE_EXPORT[exportData.fileType]));
                        this.exporting = false;
                    }
                }
            });
    }

    exportAction() {
        if (this.meta.export) {
            if (this.extendedData && this.extendedData['ROOT'] && this.extendedData['ROOT']['exportFunctionalityNotAvailable'] === true) {
                this._confirmationService.confirm({
                    acceptVisible: false,
                    rejectVisible: true,
                    rejectLabel: 'Fermer',
                    header: 'Information',
                    message: 'Vous devez relancer le calcul du contrôle pour pouvoir exporter les écritures correspondantes.'
                });
            } else {
                let exportData = this.meta.export.ecriture;
                let period = null;
                this.reportingService.exportEcritures(this.projectId, this.exerciceId, this.controlId, this._uuid, period, exportData.codes);
                this.exporting = true;
            }
        }
    }

}
