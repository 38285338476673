export const control = {
    list:{
        title:"Liste des contrôles",
        bloc:{
            tva:`En matière de TVA`,
            vies:`Vies`
        }
    },
    focus:{
        title:"FOCUS",
        docTitle:"Lien documentaire",
        formationTitle:"Lien formation",
        blocFocusTitle:"Focus"
    },
    presentation:{
        title:"PRÉSENTATION",
        descriptionTitle:"Description",
        requiredDataTitle:"Données nécessaires au contrôle",
        objectifTitle:"Objectifs du contrôle",
    },
    warning:{
        header:{
            affectationSoumisNonSoumisMixteImpossibleAccederAuxResultatDesControles: "Impossible d'accéder aux résultats de ce contrôle",
            clients411JournalVentesSoumisNonSoumisMixteTvaImpossibleAccederAuxResultatDesControles: "Impossible d'accéder aux résultats de ce contrôle",
            tauxTvaDoitEtreUnique: "Impossible d'accéder aux résultats de ce contrôle"
        },
        message:{
            affectationSoumisNonSoumisMixteImpossibleAccederAuxResultatDesControles: "Vous avez classifié tous les comptes en non soumis à la TVA collectée ce qui rend le contrôle inopérant.",
            clients411JournalVentesSoumisNonSoumisMixteTvaImpossibleAccederAuxResultatDesControles: "Vous avez classifié tous les comptes en non soumis à la TVA collectée ce qui rend le contrôle inopérant.",
            tauxTvaDoitEtreUnique: "Vous avez indiqué que l'entité juridique applique plusieurs taux de TVA collectée."
        }
    },
    vies:{
        nofFileTooltipText: "Veuillez déposer vos DEB à l'expédition pour réaliser ce contrôle"
    }
};
