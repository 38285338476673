import {Component, Input, OnInit} from '@angular/core';
import {BlocText} from "../../../../../../../../../../common/class/text/bloc-text";

@Component({
    selector: 'compliance-text-block',
    templateUrl: './text-block.component.html',
    styleUrls: ['./text-block.component.scss']
})
export class TextBlockComponent implements OnInit {

    @Input() blockText:BlocText;
    constructor() {
    }

    ngOnInit() {
    }

}
