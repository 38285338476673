import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {ComplianceToastrService} from "../../../../../common/service/toastr/compliance-toastr.service";
import {ExerciceService} from "../../../../../common/service/exercice/exercice.service";
import {map} from "rxjs/operators";
import {CategoryControl, DomaineControl} from "../../../../../common/class/control/control-descriptor";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ExerciceAuthGuard implements CanActivate, CanActivateChild {

    constructor(private _exerciceService: ExerciceService, private _router: Router, private _toastr: ComplianceToastrService) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkAuth(next, state);
    }

    canActivateChild(next: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkAuth(next, state);
    }

    private checkAuth(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const exerciceId = next.paramMap.get('exerciceId');
        return this._exerciceService.isExerciceValidate(exerciceId).pipe(
            map(exerciceValidated => {
                if (exerciceValidated) {
                    const projectId = next.paramMap.get('projectId');
                    const domain = next.paramMap.get('domain') as DomaineControl;
                    const category = next.paramMap.get('category') as CategoryControl;
                    this._toastr.infoI18n('guard.exercice.validated.unauthorized');
                    this._router.navigate([`compliance/${domain}/${category}/project/${projectId}/${exerciceId}/dashboard`]);
                }
                return !exerciceValidated;
            })
        );

    }


}
