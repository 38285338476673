import {Component, OnInit} from '@angular/core';
import {BackgroungType} from "../../../../../../common/class/background/bg-compliance";
import {ActivatedRoute, Router} from "@angular/router";
import {CategoryControl, DomaineControl} from "../../../../../../common/class/control/control-descriptor";
import {UserService} from "../../../../../../common/service/user/user.service";
import {HabilitationService} from "../../../../../../common/service/auth/habilitation.service";

@Component({
    selector: 'compliance-information-page',
    templateUrl: './information-page.component.html',
    styleUrls: ['./information-page.component.scss']
})
export class InformationPageComponent implements OnInit {
    private _domain: DomaineControl;
    private _category: CategoryControl;

    constructor(private _router:Router, private _route:ActivatedRoute, private _userService:UserService, private _habilitationService:HabilitationService) {
        document.body.className = BackgroungType.BG_SPACE;
        this._domain = this._route.snapshot.paramMap.get('domain') as DomaineControl;
        this._category = this._route.snapshot.paramMap.get('category') as CategoryControl;
    }

    ngOnInit() {
    }

    goBack() {
        this._router.navigate(['compliance','home']);
    }

    goToProject() {
        this._userService.updateHasReadLightPage().then((user)=>{
            this._habilitationService.setConnectedUser(user);
            this._router.navigate(['/compliance', this._domain, this._category, 'project', 'list']);
        })
    }

    getLienEntreprise() {
        return 'https://attendee.gotowebinar.com/rt/6586650106469358091'
    }

    getLienExpCompEtAvocat() {
        return 'https://attendee.gotowebinar.com/rt/2341093754054770189'
    }

    getLienWebinaire() {
        let connectedUser = this._habilitationService.getConnectedUser();
        let accountType = connectedUser.account.accountType;
        switch(accountType.id) {
            case 'E': {
                return this.getLienEntreprise();
            }
            case 'EC':
            case 'CA': {
                return this.getLienExpCompEtAvocat();
            }
        }
    }

    isAccountTypeDivers() {
        let connectedUser = this._habilitationService.getConnectedUser();
        let accountType = connectedUser.account.accountType;
        switch(accountType.id) {
            case 'E':
            case 'EC':
            case 'CA': {
                return false;
            }
            default:
                return true
        }
    }
}
