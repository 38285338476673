<div class="global-params overall d-flex flex-column page-botom-transparent slimscroll">
    <div class="forest d-flex justify-content-between">
        <div class="col-5 block-group-title">
            <h1 class="title">{{'globalParams.title' | translate}}</h1>
            <h2 class="sub-title">{{'globalParams.subtitle' | translate}}</h2>
        </div>
        <div class="d-flex flex-column">
            <div class="legend d-flex flex-column justify-content-around">
                <div *ngFor="let status of paramStatusArray">
                    <svg width="10" height="10">
                        <circle cx="5" cy="5" r="5" [attr.fill]="status.value" stroke="#ff91d9" stroke-width="1" />
                    </svg>
                    <span class="legendText">{{'globalParams.legend.' + status.key | translate}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="ground"></div>
    <div class="col roots"  [ngClass]="'graph-height-' + treeDepth"></div>
    <div class="graph" [ngClass]="'graph-height-' + treeDepth" xmlns:svg="http://www.w3.org/2000/svg" *ngIf="hierarchialGraph.nodes.length > 0">
        <ngx-graph
            class="chart-container"
            [links]="hierarchialGraph.links"
            [nodes]="hierarchialGraph.nodes"
            [curve]="curve"
            [autoCenter]="true"
            layout="dagre"
            [layoutSettings]="layoutOption"
            [draggingEnabled]="false"
            [enableZoom]="false"
            panningAxis="horizontal"
        >

            <ng-template #defsTemplate>
                <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4" markerHeight="4"
                            orient="auto">
                    <svg:path d="M0,-5L10,0L0,5" class="arrow-head" fill="#cccccc"/>
                </svg:marker>
                <svg:linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <svg:stop offset="0%" stop-color="#ff91d9" stop-opacity="1"/>
                    <!--<svg:stop offset="18.5%" stop-color="#ff91d9" stop-opacity="0.86" />
                    <svg:stop offset="37%" stop-color="#ff91d9" stop-opacity="0.91" />
                    <svg:stop offset="58%"  stop-color="#ff91d9" stop-opacity="0.29" />
                    <svg:stop offset="79%"  stop-color="#ff91d9" stop-opacity="0.25" />-->
                    <svg:stop offset="100%" stop-color="#ff91d9" stop-opacity="0.21"/>
                </svg:linearGradient>
            </ng-template>

            <ng-template #nodeTemplate let-node>
                <ng-container *ngIf="node.id == 'plant'">
                    <!--<svg:image x="-30" y="0" width="160" height="160"
                               xlink:href="assets/images/skin/globalParams/graine.svg"/>-->
                    <svg:g class="nodePlant" width="0" height="0">
                        <svg:circle cx="16" cy="16" r="16"/>
                    </svg:g>
                </ng-container>
                <svg:g class="node" *ngIf="node.id != 'plant'" (click)="selectParam(node)" [ngClass]="node.status">
                    <svg:rect [attr.width]="node.dimension?.width"
                              height="46" fill="url(#gradient)"
                              rx="9" ry="9" y="0"/>
                    <svg:circle [attr.cx]="node.dimension?.width/2" cy="0" r="6" [attr.fill]="paramStatusObject[node.status]"/>
                    <svg:text alignment-baseline="central" [attr.x]="10"
                              [attr.y]="node.label.length === 2 ? 4 : 13">
                        <tspan class="nodeTitle" x="10" dy="1.2em" *ngFor="let label of node.label">{{label}}</tspan>
<!--                        {{node.label}}-->
                    </svg:text>
                </svg:g>
            </ng-template>

            <ng-template #linkTemplate let-link>
                <svg:g class="edge" [ngClass]="edgeClass(link)">
                    <svg:path
                        class="line"
                        stroke="#f487d9"
                        stroke-width="3">
                    </svg:path>
                </svg:g>
            </ng-template>

        </ngx-graph>
    </div>

    <div class="plant">
        <img src="assets/images/skin/globalParams/graine.svg"/>
    </div>

    <div class="block-bottom-actions">
        <div class="inner text-center d-flex justify-content-between">
            <button href="#" class="btn btn-primary btn-back" (click)="goBack()">
                <span>{{'buttons.back' | translate}}</span>
            </button>
            <button class="btn btn-primary px-5 d-flex align-items-center" (click)="goToProject()">
                <span>{{'globalParams.startControl' | translate}}</span>
                <i class="far fa-play-circle fa-2x ml-4"></i>
            </button>
        </div>
    </div>
</div>
