import {Visa} from "./supervision";

export enum DomaineControl {
    FISCAL = 'FISCAL'
}

export enum CategoryControl {
    TVA = 'TVA'
}

export enum StatusControl {
    NONE = "NONE",
    RED = "RED",
    ORANGE = "ORANGE",
    GREEN = "GREEN"
}

export enum StatusRiskControl {
    REFUSED = "REFUSED",
    ACCEPTED = "ACCEPTED",
    NOT_REFERRED = "NOT_REFERRED"
}

let statusColors = {};

statusColors[StatusControl.NONE] = 'rgb(191,191,191)';
statusColors[StatusControl.RED] = 'rgb(235,80,113)';
statusColors[StatusControl.ORANGE] = 'rgb(255,201,95)';
statusColors[StatusControl.GREEN] = 'rgb(84,176,119)';

let statusColorsHover = {};

statusColorsHover[StatusControl.NONE] = 'rgb(173,173,173)';
statusColorsHover[StatusControl.RED] = 'rgb(255,31,79)';
statusColorsHover[StatusControl.ORANGE] = 'rgb(255,190,61)';
statusColorsHover[StatusControl.GREEN] = 'rgb(52,183,102)';

let statusLabels = {};

statusLabels[StatusControl.NONE] = 'Non réalisés';
statusLabels[StatusControl.RED] = 'Avec incohérences';
statusLabels[StatusControl.ORANGE] = 'Avec points de vigilance';
statusLabels[StatusControl.GREEN] = 'Sans incohérences';

let statusRiskColors = {};

statusRiskColors[Visa.TO_REVIEW] = 'rgb(0,54,99)';
statusRiskColors[Visa.VALIDATE] = 'rgb(0,169,225)';
statusRiskColors[Visa.DEFAULT] = 'rgb(157,196,209)';

export const STATUS_COLORS = statusColors;

export const STATUS_COLORS_HOVER = statusColorsHover;

export const STATUS_LABELS = statusLabels;

export const STATUS_RISK_COLORS = statusRiskColors;

export namespace StatusControl {

    export function values() {
        return Object.keys(StatusControl).filter(
            (type) => isNaN(<any>type) && type !== 'values'
        );
    }
}

interface LienDescriptor {
    lien: string;
    text: string;
    textLien: string;
}

interface ControlMetadata {
    controlActivated: boolean;
    displayPresentation: boolean;
    executionAutomatic: boolean;
    executionStepByStep: boolean;
}

export class InternLink {
    label: string;
    link: { [keys: string]: string }[];
}

export enum DomType {
    SPAN = "SPAN",
    DIV = "DIV",
    P = "P",
    PUCE = "PUCE",
    LINK = "LINK"
}

export enum BlocType {
    ATTENTION = "ATTENTION"
}

export class ComplexString {
    text: string;
    internLink: InternLink;
    complexString: ComplexString[];
    domType: DomType;
    blocType: BlocType;
    style: { [keys: string]: string };
    styleClass: string;
}

export class ControlDescriptor {
    categorie: CategoryControl;
    description: string;
    domaine: DomaineControl;
    donneeNecessaire: string;
    focus: string[];
    id: string;
    lienDocumentaire: LienDescriptor[];
    lienFormation: LienDescriptor[];
    infos: string[];
    nom: string;
    sousTitre: string;
    nomCourt: string;
    objectifs: string[] | ComplexString[];
    stateMachineId: string;

    controlMetadatas: ControlMetadata;
}

export interface ControlForDashboard {
    id: string;
    nom: string;
    nomCourt: string;
    stateMachineId: string;
    domaine: string;
    categorie: string;
    status?: StatusControl;
    amountRiskToDisplay?: number;
    amountRisk?: number;
    acceptedAmountRisk?: number;
    deniedAmountRisk?: number;
    order?: number;
    when?: Date;
    lastUpdate?: Date;
    noIncoherence: boolean;
    period?: {
        dateDebut: Date,
        dateFin: Date,
        format: string
    };
    visa?: any;
    probablyInvalid: boolean;
    parentIsAvailableMsg: string;
    link?: string;
}
