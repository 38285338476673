<div class="header d-flex flex-row align-items-center">
    <h1>{{'dashboard.tvaDashboard.title' | translate:{nbDone: controlList?.length, nbTotal: allControlList?.length} }}</h1>
    <div class="ml-auto">Trier par
        <select [formControl]="sortControl">
            <option value="riskValue">{{'dashboard.tvaDashboard.sort.riskValue' | translate}}</option>
            <option value="acceptedRiskValue">{{'dashboard.tvaDashboard.sort.acceptedRiskValue' | translate}}</option>
            <option value="riskLevel">{{'dashboard.tvaDashboard.sort.riskLevel' | translate}}</option>
            <option value="visa">{{'dashboard.tvaDashboard.sort.visa' | translate}}</option>
        </select>
    </div>
</div>
<div class="block-table scroll-table">
    <p-table [value]="controlList" [styleClass]="'table-data'">
        <ng-template pTemplate="header">
            <tr>
                <th class="category" *ngIf="false"></th>
                <th class="control-info">{{'dashboard.tvaDashboard.header.name' | translate}}</th>
                <th></th>
                <th class="period justify-content-center">{{'dashboard.tvaDashboard.header.period' | translate}}</th>
                <th class="amount-risk justify-content-end">{{'dashboard.tvaDashboard.header.acceptedAmountRisk' | translate}}</th>
                <th class="amount-risk justify-content-end">{{'dashboard.tvaDashboard.header.amountRisk' | translate}}</th>
                <th class="td-separator"></th>
                <th class="actions">{{'dashboard.tvaDashboard.header.actions' | translate}}</th>
                <th class="td-separator"></th>
                <th class="supervision">{{'dashboard.tvaDashboard.header.supervision' | translate}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-control>
            <tr>
                <th class="category" *ngIf="false">A</th>
                <td class="control-info d-flex flex-column align-items-start justify-content-center">
                    <div class="name ellipsis font-weight-bold">{{control.nomCourt}}</div>
                    <div class="last-update font-italic">
                        {{'dashboard.tvaDashboard.controlData.lastUpdate' | translate}}
                        {{control.lastUpdate | date:DATE_FORMAT['DD/MM/YYYY à HH:mm'].datePipe}}
                    </div>
                </td>
                <td class="justify-content-start">
                    <i class="fas fa-exclamation-triangle text-primary text-size-18"
                       *ngIf="control.probablyInvalid"
                       [ngbTooltip]="tooltip">
                        <ng-template #tooltip>
                            <div>{{'dashboard.tvaDashboard.probablyInvalid' | translate}}</div>
                        </ng-template>
                    </i>
                    <i class="fas fa-exclamation-triangle text-warning text-size-18 pl-2"
                       *ngIf="control.parentIsAvailableMsg"
                       [ngbTooltip]="tooltip">
                        <ng-template #tooltip>
                            <div>{{control.parentIsAvailableMsg}}</div>
                        </ng-template>
                    </i>
                </td>
                <td class="period justify-content-center font-weight-bold text-center">
                    <div *ngIf="control.period; else wholePeriod">
                        <div>
                            {{'dashboard.tvaDashboard.controlData.period.from' | translate}}
                            {{control.period.dateDebut | date:'MM/yy'}}
                        </div>
                        <div>
                            {{'dashboard.tvaDashboard.controlData.period.to' | translate}}
                            {{control.period.dateFin | date:'MM/yy'}}
                        </div>
                    </div>
                    <ng-template #wholePeriod>{{'dashboard.tvaDashboard.controlData.period.fec' | translate}}</ng-template>
                </td>
                <td class="justify-content-end font-weight-bold">{{control.acceptedAmountRisk | currency:'EUR':'symbol-narrow':'1.0-0'}}</td>
                <td class="amount-risk justify-content-end font-weight-bold">{{control.amountRisk | currency:'EUR':'symbol-narrow':'1.0-0'}}</td>
                <td class="td-separator"></td>
                <td class="actions d-flex">
                    <div class="action height-100 d-flex justify-content-center align-items-center"
                         (click)="goToVisu(control)">
                        <i class="far fa-eye text-size-25" [ngbTooltip]="tooltipShow">
                            <ng-template #tooltipShow>
                                <div>{{'dashboard.tvaDashboard.actions.show' | translate}}</div>
                            </ng-template>
                        </i>
                    </div>
                    <div class="separator height-100"></div>
                    <div class="action height-100 d-flex justify-content-center align-items-center"
                         (click)="launchControl(control)" *ngIf="!exerciceValidation">
                        <i class="fas fa-play-circle text-size-25" [ngbTooltip]="tooltipRun">
                            <ng-template #tooltipRun>
                                <div>{{'dashboard.tvaDashboard.actions.run' | translate}}</div>
                            </ng-template>
                        </i>
                    </div>
                    <div class="separator height-100"></div>
                    <div class="action height-100 d-flex justify-content-center align-items-center">

<!--                        <ng-container *ngIf="controle"><compliance-report-modal [control]="controle"></compliance-report-modal></ng-container>-->
                        <button type="button" class="btn-invisible flex-grow-1" (click)="showDialog(control)" >
                            <i class="fas text-size-25 fa-file-download" [ngbTooltip]="tooltipExport">
                                <ng-template #tooltipExport>
                                    <div>{{'dashboard.tvaDashboard.actions.export' | translate}}</div>
                                </ng-template>
                            </i>
                        </button>

                    </div>
                </td>
                <td class="td-separator"></td>
                <td class="supervision">
                    <div class="action height-100 d-flex justify-content-center align-items-center">
                        <i class="fas fa-circle text-size-16" [ngStyle]="{color:STATUS_COLORS[control.status]}"></i>
                    </div>
                    <div class="action visa height-100 d-flex justify-content-center align-items-center"
                         [ngStyle]="{backgroundColor:control.visa.color}">
                        <span>
                            <i class="{{control.visa.icon}} text-size-25"></i>
                        </span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <compliance-report-modal [control]="controlToExport" (exportEvent)="exportReport($event.control, $event.typeExport, $event.selectedElements)" #crm></compliance-report-modal>
</div>

