export const balance = {
    title:"BALANCE",
    headers:{
        compteNum:"N° de compte",
        compteLib:"Intitulé du compte",
        soldeRan:"Solde Ouverture",
        debit:"Débit",
        credit:"Crédit",
        soldeCloture:"Solde Clôture "
    },
    result:{
        title:"Résultat",
        isBalanced: {
            true: "La balance est équilibrée",
            false: "La balance n'est pas équilibrée",
            notCalculated: "Accéder à la balance"
        }
    }
};
