export const cgvu = {
    title:"Veuillez accepter les CGVU pour continuer",
    paragraphes:{
        p1:"Les présentes conditions générales d’utilisation (ci-après désignées les « CGU »), ont pour objet de définir les conditions dans lesquelles la société  Francis Lefebvre e-Compliance, société par actions simplifiée, au capital de 10 000 Euros, immatriculée au Registre du Commerce et des Sociétés de Nanterre, sous le numéro 831 822 218, ayant son siège social  au 42, rue de Villiers 92300 Levallois Perret, dûment représentée par Monsieur Lefebvre en sa qualité de Président (ci-après désignée « FLEC »), propose ses services au travers d’une solution, actuellement en phase d’expérimentation, permettant à ses utilisateurs  (ci-après désigné « Utilisateur(s) ») de procéder à des contrôles de compliance en matière comptable, fiscale, sociale et juridique (ci-après désignée la « Solution ») destinée à tout professionnel souhaitant faire des contrôles de compliance en matière comptable et fiscale (ci-après désigné « Client(s) »). La Solution disponible en mode SAAS, est accessible via l’adresse URL suivante <a href=\"https://app.fl-ecompliance.com\">https://app.fl-ecompliance.com</a> (ci-après désigné le « Site »).",
        p2:"Après avoir pris connaissance des caractéristiques de la Solution et s’être assuré de son adéquation avec ses besoins, le Client a souhaité bénéficier d’une version bêta de la Solution.",
        p3:"Le Client reconnaît que l’acceptation des présentes CGU a pour conséquence d’écarter l’application de ses propres conditions générales quelles qu’elles soient. Francis Lefebvre e-Compliance se réserve le droit à sa seule discrétion, de modifier, en totalité et/ou en partie, à tout moment les présentes CGU. Ces mises à jour entreront en vigueur à compter de la publication des nouvelles CGU. L’utilisation de la Solution à l’entrée en vigueur des CGU modifiées, vaudra reconnaissance et acceptation des nouvelles conditions [...]",
    },
    donwloadInfo:{
        text:"L'ensemble des conditions d'utilisation du site est téléchargeable via ce ",
        link:"lien"
    },
    confidentialite:{
        text:"La politique de confidentialité de Francis Lefebvre e-Compliance est téléchargeable via ce ",
        link:"lien"
    },
    acceptlabel:{
        part1:"J'accepte les ",
        linkCgu:"conditions générales de vente et d'utilisation",
        part2:" de l'application ainsi que la ",
        linkConf:"politique de confidentialité",
        part3:" de Francis Lefebvre e&#8209;Compliance."
    }
};
