export class Breadcrumb {
    label:string;
    iconClass?:string;
    href?:string;
    queryParams?: {[key: string]:any};
    click?:Function;
    level?:number;

    constructor(label:string, href:string, level:number, iconClass:string='') {
        this.label = label;
        this.href = href;
        this.level = level;
        this.iconClass = iconClass;
    }
}

export interface BreadcrumbOption {
    clean:boolean;
    delete:boolean;
    insert:boolean;
}
