import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";
import {CacheService} from "../cache/cache.service";
import {of} from "rxjs";
import {FlecCommonSubscription} from "../../class/subscription/subscription";
import {HabilitationService} from "../auth/habilitation.service";

@Injectable({
    providedIn: 'root'
})
export class CommonSubscriptionService extends ComplianceApiService {

    constructor(private _restService: RestService, private _cacheService: CacheService, private _habilitationService:HabilitationService) {
        super('common/authorities', 'api');
    }

    async getUserSubscription(): Promise<FlecCommonSubscription> {
        let cacheId = `CommonSubscriptionService-${this._habilitationService.getConnectedUser().account.id}`;
        let cache = this._cacheService.getData(cacheId);
        if (cache) {
            return of(cache).toPromise();
        }
        let data = await this._restService.get({
            url: this.baseUri,
            relativeUri: null,
            arg: null as FlecCommonSubscription
        });
        this._cacheService.addToCache(cacheId, data);
        return of(data).toPromise();
    }
}
