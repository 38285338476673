<div id="content" class="page-botom-transparent height-100 slimscroll" *ngIf="videoId">
    <div class="container-fluid height-100 d-flex flex-column">
        <div class="block-group-title">
            <h2 class="title">{{'video.title' | translate}}</h2>
            <p class="sub-title">{{video?.label}}</p>
        </div>

        <div class="block-text-content col" *ngIf="video?.url">
            <div class="video-container">
                <iframe width="560" height="315" frameborder="0"
                        [src]="video.url"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe>
            </div>
        </div>
    </div>



    <div class="block-bottom-actions">
        <div class="inner">
            <button href="#" class="btn btn-primary btn-back" (click)="goBack()"><span>{{'buttons.back' | translate}}</span></button>
        </div>
    </div>
</div>
