import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {COMPLIANCE_LANGS} from "../../class/i18n/i18n";
import * as _ from "lodash";
import {Injectable} from "@angular/core";

@Injectable()
export class ComplianceToastrService {
    translate: TranslateService;
    lang:string;

  constructor(translate: TranslateService, private toastr: ToastrService) {
      let langs = translate.getLangs();

      let browserLang = translate.getBrowserLang();
      this.lang = browserLang.match(/fr/) ? 'fr' : 'fr';
      if (!_.includes(langs, this.lang)) {
          translate.addLangs([this.lang]);
          translate.setTranslation(this.lang, COMPLIANCE_LANGS[this.lang]);


          translate.use(this.lang);

      }
      this.translate = translate;
  }

  success(message:string, title?:string):void{
      this.toastr.success(message, title);
  }
  error(message:string, title?:string):void{
      this.toastr.error(message, title);
  }

  info(message:string, title?:string):void{
      this.toastr.info(message, title);
  }

  successI18n(messageI18n:string, titleI18n?:string):void{
      let message = this.getValue(messageI18n);
      let title = titleI18n?this.getValue(titleI18n):null;
      this.toastr.success(message, title);
  }

  successI18nParam({messageI18n, titleI18n, paramMessage={}, paramTitle={}}:{messageI18n:string, titleI18n?:string, paramMessage?:any, paramTitle?:any}):void{
      let message = this.getValue(messageI18n, paramMessage);
      let title = titleI18n?this.getValue(titleI18n, paramMessage):null;
      this.toastr.success(message, title);
  }
  errorI18n(messageI18n:string, titleI18n?:string):void{
      let message = this.getValue(messageI18n);
      let title = titleI18n?this.getValue(titleI18n):null;
      this.toastr.error(message, title);
  }
  infoI18n(messageI18n:string, titleI18n?:string):void{
      let message = this.getValue(messageI18n);
      let title = titleI18n?this.getValue(titleI18n):null;
      this.toastr.info(message, title);
  }

  private getValue(i18nProperty:string, params?:any):string{
      return this.translate.instant(i18nProperty, params);
  }

}
