import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComplianceSharedModule} from "../../../../../../common/compliance-shared.module";
import {ControlRoutingModule} from "./control-routing.module";
import {TableModule} from "../../../shared/table/table.module";
import {CompteRenduGeneralComponent} from './compte-rendu/compte-rendu-general/compte-rendu-general.component';
import {GeneralRapportDetailleComponent} from './compte-rendu/compte-rendu-general/general-rapport-detaille/general-rapport-detaille.component';
import {KeyNumberComponent} from './compte-rendu/compte-rendu-general/key-number/key-number.component';
import {GraphComponent} from './compte-rendu/compte-rendu-general/graph/graph.component';
import {ExportFileModule} from "../../../shared/export-file/export-file.module";
import {AffectationCompteComponent} from "./affectation-compte/affectation-compte.component";
import {AffectationCompteLotComponent} from "./affectation-compte/affectation-compte-lot/affectation-compte-lot.component";
import {LaunchComponent} from './launch/launch.component';
import {ControlFocusComponent} from './launch/control-focus/control-focus.component';
import {ControlPresentationComponent} from './launch/control-presentation/control-presentation.component';
import {AffectationTitleComponent} from './affectation-compte/affectation-title/affectation-title.component';
import {CompteRenduDetailleOriginIncoherenceComponent} from './compte-rendu/crd-global/compte-rendu-detaille-origin-incoherence/compte-rendu-detaille-origin-incoherence.component';
import {CRDGlobalComponent} from "./compte-rendu/crd-global/crd-global.component";
import {CrdItemBlockComponent} from "./compte-rendu/crd-global/crd-item-block/crd-item-block.component";
import {CrdDispositionBlockComponent} from './compte-rendu/crd-global/crd-disposition-block/crd-disposition-block.component';
import {TableBlockComponent} from './compte-rendu/crd-global/crd-data-block/table-block/table-block.component';
import {TextBlockComponent} from './compte-rendu/crd-global/crd-data-block/text-block/text-block.component';
import {GraphBlockComponent} from './compte-rendu/crd-global/crd-data-block/graph-block/graph-block.component';
import {KeyNumberBlockComponent} from './compte-rendu/crd-global/crd-data-block/key-number-block/key-number-block.component';
import {ExportBlockComponent} from './compte-rendu/crd-global/crd-data-block/export-block/export-block.component';
import {SupervisionControlComponent} from "./supervision/supervision-control.component";
import {ComplexStringComponent} from './launch/control-presentation/complex-string/complex-string.component';
import { UploadComponent } from './vies/upload/upload/upload.component';
import { GeneralRapportDetailleTextComponent } from './compte-rendu/compte-rendu-general/general-rapport-detaille-text/general-rapport-detaille-text.component';

@NgModule({
    declarations: [
        CompteRenduGeneralComponent,
        GeneralRapportDetailleComponent, KeyNumberComponent, GraphComponent, AffectationCompteComponent,
        AffectationCompteLotComponent, LaunchComponent, ControlFocusComponent, ControlPresentationComponent,
        AffectationTitleComponent,
        CompteRenduDetailleOriginIncoherenceComponent,
        CRDGlobalComponent,
        CrdItemBlockComponent,
        CrdDispositionBlockComponent,
        TableBlockComponent,
        TextBlockComponent,
        GraphBlockComponent,
        KeyNumberBlockComponent,
        ExportBlockComponent,
        SupervisionControlComponent,
        ComplexStringComponent,
        UploadComponent,
        GeneralRapportDetailleTextComponent
    ],
    imports: [
        CommonModule,
        ComplianceSharedModule,
        ControlRoutingModule,
        TableModule,
        ExportFileModule,
    ]
})
export class ControlModule {
}
