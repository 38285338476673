export enum TypePollingMessage {
    QUESTION = "QUESTION",
    ROUTING = "ROUTING",
    DATA = "DATA",
    WARNING= "WARNING",
    UPLOAD = "UPLOAD",
    DONE = "DONE",
    DONE_PARAMETER = "DONE_PARAMETER",
    ERROR = "ERROR",
    EXIT = "EXIT",
}

export class NotificationMessage {
    id: string;
    when: string;
    type: TypePollingMessage;
    corp: any;
    projectId: string;
    accountId: string;
    description: string;
    userId: string;
    sessionTabId: string;
}

