<header id="header">
    <div class="inner d-flex justify-content-between align-items-center">
        <div>
            <a href="https://fl-ecompliance.com" target="_blank" class="logo">Francis Lefebvre e-compliance</a>
        </div>
        <!--<compliance-menu *ngIf="contentType!='login'" [menus]="menus" [uploadStatus]="true"></compliance-menu>-->

        <!--<div class="float-right" *ngIf="typeContent!='login'">-->
        <div class="d-flex justify-content-end align-items-center">
            <div >
                <a class="mx-3" [routerLink]="'/compliance/aide'">
                    <i class="far fa-question-circle text-primary fa-2x" title="{{'menu.help' | translate}}"></i>
                </a>
            </div>
        </div>
        <!--<span class="badge badge-dark mr-2">{{connectedUser.firstName}} {{connectedUser.lastName}}</span>-->
        <!--<button class="btn btn-danger" (click)="logout()"><i class="fas fa-sign-out-alt mr-1" aria-hidden="true"></i>{{'menu.logout' | translate}}</button>-->
        <!--</div>-->
    </div>
</header>


