import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MetaQuestion, PeriodeQuestion, PeriodeType, QuestionEventType} from "../../../class/meta/question/meta-question";
import moment from 'moment';
import _ from 'lodash';
import {ComplianceI18nLoader} from "../../../class/i18n/compliance-i18n-loader";
import {MetaExpressionService} from "../../../service/meta/meta-expression.service";
import {DATE_FORMAT} from "../../../class/compliance-constant";
import {ActivatedRoute} from "@angular/router";
import {OriginPage} from "../../control/control-message-handler";


class Period {
    label: string;
    value?: moment.Moment;
    enable?: boolean;
    active?: boolean;
}

@Component({
    selector: 'compliance-periode-question',
    templateUrl: './periode-question.component.html',
    styleUrls: ['./periode-question.component.scss']
})
export class PeriodeQuestionComponent extends ComplianceI18nLoader implements OnInit {

    metaQuestion: MetaQuestion<any>;
    question: PeriodeQuestion;
    lots: any[];
    allPeriod: Period[];
    nextActive: boolean | number;
    private months: (string)[];
    private selectedPeriod: Period[] = [];
    private lastSelected: Period;
    private secondLastSelected: Period;
    private thirdLastSelected: Period;
    private nbClick: number = 0;

    isControl: boolean;

    @Output() actionEvent: EventEmitter<any> = new EventEmitter();

    constructor(route: ActivatedRoute, private expressionService: MetaExpressionService) {
        super();
        this.isControl = route.snapshot.queryParamMap.get('originPage') !== OriginPage.GLOBALPARAMS;
        this.months = super.getTranslation().datePicker.monthNames;
    }

    ngOnInit() {
        this.question = this.metaQuestion.question;
        this.computePeriodLots();
        this.computeNextActive();
    }

    private computePeriodLots() {
        this.lots = [];
        let debut = moment(this.question.dateDebutPeriode, this.question.dateDebutPeriodeFormat);
        let fin = moment(this.question.dateFinPeriode, this.question.dateFinPeriodeFormat);
        console.log("dates", [debut, fin]);
        if (this.question.periodeType === PeriodeType.MONTH) {
            let yearDebut = debut.get('year');
            let yearFin = fin.get('year');
            for (let i = yearDebut; i <= yearFin; i++) {
                this.lots.push({year: i});
            }
        }

        this.computePeriodItemsByLot(debut, fin);
    }

    private computePeriodItemsByLot(debut: moment.Moment, fin: moment.Moment) {
        this.allPeriod = [];
        _.each(this.lots, (l) => {
            l.months = [];
            _.each(this.months, (m, idx) => {

                let date = moment(`${l.year}${idx + 1}`, 'YYYYM');
                let period: Period = {label: m, value: date, enable: date.isBetween(debut, fin, 'month', '[]'), active: false};
                l.months.push(period);
                this.allPeriod.push(period);
            });
        });
    }

    activePeriod(period: Period) {
        if (period.enable) {
            let minLastSelectedPeriod: Period;
            let maxLastSelectedPeriod: Period;
            if (this.question.fromFirst) {
                minLastSelectedPeriod = _.find(this.allPeriod, {enable: true});
                maxLastSelectedPeriod = period;
            } else {
                this.nbClick++;
                this.secondLastSelected = this.lastSelected || period;
                this.lastSelected = period;

                if (this.nbClick === 3) {
                    this.thirdLastSelected = null;
                    this.secondLastSelected = null;
                    this.question.data = [this.lastSelected];
                    _.each(this.allPeriod, (p: Period) => {
                        p.active = false;
                    });
                    period.active = true;
                    this.nbClick = 1;
                } else {
                    let tempPeriod = [this.secondLastSelected, this.lastSelected];
                    minLastSelectedPeriod = _.minBy(tempPeriod, (p) => p.value.format('YYYYMM'));
                    maxLastSelectedPeriod = _.maxBy(tempPeriod, (p) => p.value.format('YYYYMM'));

                    console.log([this.lastSelected, this.secondLastSelected]);
                }
            }

            let periodData = [];
            this.question.data = {};
            _.each(this.allPeriod, (p: Period) => {
                if (p.value.isSameOrAfter(minLastSelectedPeriod.value) && p.value.isSameOrBefore(maxLastSelectedPeriod.value)) {
                    p.active = true;
                    periodData.push(p.value.format(DATE_FORMAT.YYYYMM.moment));
                } else {
                    p.active = false;
                }
            });
            this.question.data = periodData;
        }
        console.log(this.question.data);
        this.computeNextActive();
    }


    goBack() {
        this.actionEvent.emit({type: QuestionEventType.GLOBAL, data: {code: 'back', data: this.question.data}});
    }

    goNext() {
        this.actionEvent.emit({type: QuestionEventType.GLOBAL, data: {code: 'next', data: this.question.data}});
    }

    private computeNextActive() {
        let next = this.question.buttons.next;
        if (next) {
            if (next.active) {
                this.nextActive = this.expressionService.computeExpression(next.active, this.question);
            } else {
                this.nextActive = true;
            }
        } else {


            this.nextActive = true;
        }

    }
}
