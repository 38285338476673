export const affectation = {
    buttons: {
        showResult: "Afficher les résultats",
    },
    title: {
        one: {
            affectation_soumis_non_soumis_mixte: "Affectation des comptes de produits",
            affectation_par_type_operation: "Affectation par type d'opération",
            nonAffecte: "Produit non affecté",
            produits_soumis_tva: "Produit soumis à la TVA",
            produits_non_soumis_tva: "Produit non soumis à la TVA",
            produits_mixtes_tva: "Produit mixte (soumis et non soumis à la TVA)",
            produits_autres_operations_imposables: "Autres opérations imposable",
            produits_regularisations: "Régularisation",
            produits_ventes_prestations_services: "Ventes et prestations<br> de services",

            clients411VentesNonAffecte: "Compte client non affecté",
            clients_411_journal_ventes_soumis_tva: "Compte client soumis à la TVA",
            clients_411_journal_ventes_non_soumis_tva: "Compte client non soumis à la TVA",
            clients_411_journal_ventes_mixtes_tva: "Compte client mixte (soumis et non soumis à la TVA)",

            chargeNonAffecte: "Charge non affectée",
            charges_soumises_tva_deductible: "Charge dont la TVA est déductible",
            charges_non_soumises_tva_deductible: "Charge dont la TVA n'est pas déductible ou sans TVA",
            charges_mixtes_tva_deductible: "Charge mixte (avec et sans TVA, déductible et non déductible",

            fournisseur_non_affecte: "Fournisseur non affecté",
            fournisseurs_biens: "Achat de biens",
            fournisseurs_services: "Achat de services",
            fournisseurs_mixtes: "Achat mixte (biens et services)",

            affectation_comptes_par_taux_tva: "Affectation par taux de TVA"
        },
        many: {
            affectation_soumis_non_soumis_mixte: "Affectation des comptes de produits",
            affectation_par_type_operation: "Affectation par type d'opération",
            affectation_comptes_produits_non_soumis_tva: "AFFECTATION DES COMPTES DE PRODUITS NON SOUMIS A LA TVA",
            Affectation_comptes_clients: "Affectation des comptes clients",
            Affectation_comptes_charges: "Affectation des comptes de charges",
            Affectation_comptes_fournisseur: "Affectation des comptes fournisseurs",
            nonAffecte: "Produits non affectés",
            produits_soumis_tva: "Produits soumis à la TVA",
            produits_non_soumis_tva: "Produits non soumis à la TVA",
            produits_mixtes_tva: "Produits mixtes (soumis et non soumis à la TVA)",
            produits_autres_operations_imposables: "Autres opérations imposables",
            produits_regularisations: "Régularisations",
            produits_ventes_prestations_services: "Ventes et prestations<br> de services",

            clients411VentesNonAffecte: "Comptes clients non affectés",
            clients_411_journal_ventes_soumis_tva: "Comptes clients soumis à la TVA",
            clients_411_journal_ventes_non_soumis_tva: "Comptes clients non soumis à la TVA",
            clients_411_journal_ventes_mixtes_tva: "Comptes clients mixtes (soumis et non soumis à la TVA)",

            chargeNonAffecte: "Charges non affectées",
            charges_soumises_tva_deductible: "Charges dont la TVA est déductible",
            charges_non_soumises_tva_deductible: "Charges dont la TVA n'est pas déductible ou sans TVA",
            charges_mixtes_tva_deductible: "Charges mixtes (avec et sans TVA, déductible et non déductible",

            fournisseur_non_affecte: "Fournisseurs non affectés",
            fournisseurs_biens: "Achats de biens",
            fournisseurs_services: "Achats de services",
            fournisseurs_mixtes: "Achats mixtes (biens et services)",

            affectation_comptes_par_taux_tva: "Affectation par taux de TVA"
        },
    },
    subTitle: {
        one: {
            nonAffecte: "Veuillez affecter le produit ci-dessous",
            produits_soumis_tva: "",
            produits_non_soumis_tva: "",
            produits_mixtes_tva: "",
            produits_autres_operations_imposables: "",
            produits_regularisations: "",
            produits_ventes_prestations_services: "",

            clients411VentesNonAffecte: "Veuillez affecter le compte client ci-dessous",
            clients_411_journal_ventes_soumis_tva: "",
            clients_411_journal_ventes_non_soumis_tva: "",
            clients_411_journal_ventes_mixtes_tva: "",

            chargeNonAffecte: "Veuillez affecter la charge ci-dessous",
            charges_soumises_tva_deductible: "",
            charges_non_soumises_tva_deductible: "",
            charges_mixtes_tva_deductible: "",

            fournisseur_non_affecte: "Veuillez affecter le fournisseur ci-dessous",
            fournisseurs_biens: "",
            fournisseurs_services: "",
            fournisseurs_mixtes: "",

            affectation_comptes_par_taux_tva: "Veuillez affecter le compte ci-dessous"
        },
        many: {
            nonAffecte: "Veuillez affecter les produits ci-dessous",
            produits_soumis_tva: "",
            produits_non_soumis_tva: "",
            produits_mixtes_tva: "",
            produits_autres_operations_imposables: "",
            produits_regularisations: "",
            produits_ventes_prestations_services: "",

            clients411VentesNonAffecte: "Veuillez affecter les comptes clients ci-dessous",
            clients_411_journal_ventes_soumis_tva: "",
            clients_411_journal_ventes_non_soumis_tva: "",
            clients_411_journal_ventes_mixtes_tva: "",

            chargeNonAffecte: "Veuillez affecter les charges ci-dessous",
            charges_soumises_tva_deductible: "",
            charges_non_soumises_tva_deductible: "",
            charges_mixtes_tva_deductible: "",

            fournisseur_non_affecte: "Veuillez affecter les fournisseurs ci-dessous",
            fournisseurs_biens: "",
            fournisseurs_services: "",
            fournisseurs_mixtes: "",

            affectation_comptes_par_taux_tva: "Veuillez affecter les comptes ci-dessous"
        }
    },
    mainContent: {
        table: {
            headers: {
                compteNum: "N° Compte",
                compteLib: "Intitulé du compte",
            },
            actionMenu: {
                produits_soumis_tva: "Produit soumis à la TVA",
                produits_non_soumis_tva: "Produit non soumis à la TVA",
                produits_mixtes_tva: "Produit mixte",

                produits_autres_operations_imposables: "Autres opérations imposables",
                produits_regularisations: "Régularisations",
                produits_ventes_prestations_services: "Ventes et prestations de services",

                clients_411_journal_ventes_soumis_tva: "Compte client soumis à la TVA",
                clients_411_journal_ventes_non_soumis_tva: "Compte client non soumis à la TVA",
                clients_411_journal_ventes_mixtes_tva: "Compte client mixte (soumis et non soumis à la TVA)",

                charges_soumises_tva_deductible: "Charge avec écritures de TVA déductible",
                charges_non_soumises_tva_deductible: "Charge sans écriture de TVA déductible",
                charges_mixtes_tva_deductible: "Charge mixte (avec et sans écritures de TVA déductible)",

                fournisseurs_biens: "Achat de biens",
                fournisseurs_services: "Achat de services",
                fournisseurs_mixtes: "Achat mixte (biens et services)",

                produits_non_soumis_LIC_Biens: "Livraisons Intracommunautaires de Biens",
                produits_non_soumis_LIC_Services: "Livraisons Intracommunautaires de Services",
                produits_non_soumis_export: "Exportations",
                produits_non_soumis_autres: "Autres",

            },
            graph: {
                produits_soumis_tva: " % soumis à la TVA",
                produits_non_soumis_tva: " % non soumis à la TVA",

                clients_411_journal_ventes_soumis_tva: " % soumis à la TVA",
                clients_411_journal_ventes_non_soumis_tva: " % non soumis à la TVA",

                charges_soumises_tva_deductible: " % avec TVA déductible",
                charges_non_soumises_tva_deductible: " % sans TVA déductible",

                fournisseurs_biens: " % d'achats de biens",
                fournisseurs_services: " % d'achats de services"
            },
            exportLabel: "Voir les écritures",
            exportLoadingLabel: "Cette opération peut durer plusieurs minutes"
        },
        finished: {
            label: "Affectation terminée"
        }
    },
    automaticSection: {
        slider: {
            label: "Aide à  <br>l'affectation"
        },
        noAffectationLabel: `Pas d'aide à l'affectation`,
        content: {
            label: "Veuillez saisir le pourcentage à partir duquel l'affectation sera effectuée en fonction de la proportion des écritures répondant aux critères soumis à la TVA ou non soumis à la TVA",
            charge: `Veuillez saisir le pourcentage à partir duquel l'affectation sera effectuée en fonction de la proportion des écritures répondant aux critères avec ou sans TVA déductible`,
            fournisseur: "Veuillez saisir le pourcentage à partir duquel l'affectation sera effectuée en fonction de la proportion des écritures répondant aux critères",
            affectation_comptes_par_taux_tva: "Veuillez saisir le pourcentage à partir duquel l'affectation sera effectuée en fonction de la proportion des écritures répondant aux critères"
        }
    },
    lots: {
        clean: "Vider",
        lot: {
            title: {
                one: {
                    produits_non_soumis_LIC_Biens: "Livraisons Intracommunautaires de Biens",
                    produits_non_soumis_LIC_Services: "Livraisons Intracommunautaires de Services",
                    produits_non_soumis_export: "Exportation",
                    produits_non_soumis_autres: "Autre"
                },
                many: {
                    produits_non_soumis_LIC_Biens: "Livraisons Intracommunautaires de Biens",
                    produits_non_soumis_LIC_Services: "Livraisons Intracommunautaires de Services",
                    produits_non_soumis_export: "Exportations",
                    produits_non_soumis_autres: "Autres"
                }
            },
            label: {
                one: {
                    nonAffecte: "Produit non affecté",
                    produits_soumis_tva: "Produit soumis <br>à la TVA",
                    produits_non_soumis_tva: "Produit non soumis<br>à la TVA",
                    produits_mixtes_tva: "Produit mixte <span>(soumis et non soumis à la TVA)</span>",
                    produits_autres_operations_imposables: "Autres opérations<br>imposables",
                    produits_regularisations: "Régularisations",
                    produits_ventes_prestations_services: "Ventes et prestations<br>de services",

                    clients411VentesNonAffecte: "Compte client non affecté",
                    clients_411_journal_ventes_soumis_tva: "Compte client soumis <br>à la TVA",
                    clients_411_journal_ventes_non_soumis_tva: "Compte client non<br>soumis à la TVA",
                    clients_411_journal_ventes_mixtes_tva: "Compte client mixte <span>(soumis et non soumis à la TVA)</span>",

                    chargeNonAffecte: "Charge non affectée",
                    charges_soumises_tva_deductible: "Charge avec écritures de TVA déductible",
                    charges_non_soumises_tva_deductible: "Charge sans écriture de TVA déductible",
                    charges_mixtes_tva_deductible: "Charge mixte <span>(avec et sans écritures de TVA déductible)</span>",

                    fournisseur_non_affecte: "Fournisseur non affecté",
                    fournisseurs_biens: "Achat de biens",
                    fournisseurs_services: "Achat de services",
                    fournisseurs_mixtes: "Achat mixte <br/>(biens et services)",

                    affectation_comptes_par_taux_tva_non_affecte: "Compte non affecté",

                    produits_non_soumis_LIC_Biens: "LIC de Biens",
                    produits_non_soumis_LIC_Services: "LIC de Services",
                    produits_non_soumis_export: "Export...",
                    produits_non_soumis_autres: "Autre",
                },
                many: {
                    affectation_soumis_non_soumis_mixte: "Affectation des comptes de produits",
                    affectation_par_type_operation: "Affectation par type d'opération",
                    nonAffecte: "Produits non affectés",
                    produits_soumis_tva: "Produits soumis <br>à la TVA",
                    produits_non_soumis_tva: "Produits non soumis <br>à la TVA",
                    produits_mixtes_tva: "Produits mixtes <span>(soumis et non soumis à la TVA)</span>",
                    produits_autres_operations_imposables: "Autres opérations<br> imposables",
                    produits_regularisations: "Régularisations",
                    produits_ventes_prestations_services: "Ventes et prestations<br> de services",

                    clients411VentesNonAffecte: "Comptes clients non affectés",
                    clients_411_journal_ventes_soumis_tva: "Comptes clients soumis <br>à la TVA",
                    clients_411_journal_ventes_non_soumis_tva: "Comptes clients non<br>soumis à la TVA",
                    clients_411_journal_ventes_mixtes_tva: "Comptes clients mixte <span>(soumis et non soumis à la TVA)</span>",

                    chargeNonAffecte: "Charges non affectées",
                    charges_soumises_tva_deductible: "Charges avec écritures de TVA déductible",
                    charges_non_soumises_tva_deductible: "Charges sans écriture de TVA déductible",
                    charges_mixtes_tva_deductible: "Charges mixtes <span>(avec et sans écritures de TVA déductible)</span>",

                    fournisseur_non_affecte: "Fournisseurs non affectés",
                    fournisseurs_biens: "Achats de biens",
                    fournisseurs_services: "Achats de services",
                    fournisseurs_mixtes: "Achats mixtes <br/>(biens et services)",

                    affectation_comptes_par_taux_tva_non_affecte: "Comptes non affectés",

                    produits_non_soumis_LIC_Biens: "LIC de Biens",
                    produits_non_soumis_LIC_Services: "LIC de Services",
                    produits_non_soumis_export: "Export...",
                    produits_non_soumis_autres: "Autres",
                }
            }
        }

    }
};
