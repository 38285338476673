import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import _ from 'lodash';
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";
import {Cookie, User} from "../../class/user/user";


@Injectable()
export class UserService extends ComplianceApiService{


    constructor(private restService: RestService) {
        super('user');
    }

    setTarget(target:string):void{
        this.setApp(target);
    }

    findAllusers(): Promise<User[]> {
        let users: User[];
        return this.restService.get({
            url: this.baseUri,
            relativeUri: 'adminRole',
            arg: users
        });

    }

    findAllUsersByAccount(accountId: string): Promise<User[]> {
        let users: User[];
        return this.restService.get({
            url: this.baseUri,
            relativeUri: 'userRole/byAccount',
            parameters:[accountId],
            arg: users
        });

    }

    findUser(id: string): Promise<User> {
        let users: User;
        return this.restService.get({
            url: this.baseUri,
            parameters:[id],
            arg: users
        });

    }

    saveUser(user: User): Promise<User> {
        if(user.id){
            return this.updateUser(user);
        }else{
            return this.createUser(user);
        }
    }

    createUser(user: User): Promise<User> {
        return this.restService.post({
            url: this.baseUri,
            data: user,
            arg: user
        })
    }

    updateUser(user: User): Promise<User> {
        return this.restService.put({
            url: this.baseUri,
            data: user,
            arg: user
        })
    }

    checkEmailNotTaken(email: string):Observable<any> {
        return this.restService.getObservable({
            url: this.baseUri,
            relativeUri: 'exist/mail',
            parameters:['email='+encodeURI(email)],
            parameterType:'urlParam',
            arg: {}
        });

    }

    deleteUsers(usersToDelete: User[]):Promise<any> {
        let userIds = _.map(usersToDelete, 'id');
        return this.restService.delete({
            url: this.baseUri,
            parameters:[userIds.join(',')],
            arg:{}
        })
    }
    disableUsers(usersToDisable: User[]):Promise<any> {
        let userIds = _.map(usersToDisable, 'id');
        return this.restService.put({
            url: this.baseUri,
            relativeUri: 'disable',
            data:userIds,
            arg:{}
        })
    }

    updatePassword(userId:string, oldPassword:string, newPassword:string):Promise<any> {
        return this.restService.post({
            url: this.baseUri,
            relativeUri: 'updatePassword',
            data: {oldPassword: oldPassword, newPassword: newPassword},
            parameters: [userId],
            arg: null
        })
    }

    updateHasCguCgvRead():Promise<any> {
        return this.restService.put({
            url: this.baseUri,
            relativeUri: 'updateCgvu',
            data: {},
            parameters: [],
            arg: null
        })
    }


    updateHasReadLightPage() {

        return this.restService.put({
            url: this.baseUri,
            relativeUri: 'updateInformationPage',
            data: {},
            parameters: [],
            arg: null
        })
    }

    saveCookiePreference(cookie: Cookie){
        return this.restService.put({
            url: this.baseUri,
            relativeUri: 'saveCookie',
            data: cookie,
            arg: null as User
        })
    }

    getAdmin(id) {

        let users: User;
        return this.restService.get({
            url: this.baseUri,
            relativeUri:'admin',
            parameters:[id],
            arg: users
        });
    }
}
