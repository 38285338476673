import {Directive, ElementRef, forwardRef, HostListener, Input, OnDestroy} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Directive({
  selector: '[complianceLocalizedNumericInput]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LocalizedNumericInputDirective),
            multi: true
        }
    ]
})
export class LocalizedNumericInputDirective implements ControlValueAccessor, OnDestroy {
    public _value: string;
    onlyInteger: boolean = true;
    decimalMarker: string = ".";
    thousandSeparator: string = " ";

    constructor(private element: ElementRef<HTMLInputElement>) {
    }

    @HostListener('input', ['$event.target.value'])
    input(value) {
        this.updateValue(value);
        this.formatValue(this._value);
    }

    private formatValue(value) {
        if (value === null) {
            this.element.nativeElement.value = '';
            return;
        }

        const [integer, decimal] = value.split('.');

        this.element.nativeElement.value = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSeparator);

        if (decimal) {
            this.element.nativeElement.value = this.element.nativeElement.value.concat(this.decimalMarker, decimal);
        }
    }

    private updateValue(value: string | null) {
        if (this.onlyInteger) {
            value = value.replace(/[^0-9]*/g, '');
        }
        const [integer, decimal] = value.replace(new RegExp(`[^\\d${this.decimalMarker}-]`, 'g'), '').split(this.decimalMarker);

        this.value = decimal ? integer.concat('.', decimal) : integer;
    }

    public get value(){
        return this._value;
    }

    @Input('value')
    public set value(value: string | null){
        this._value = value;
        this.propagateChange(this._value);
        this.propagateTouched();
    }

    writeValue(value: string): void {
        this._value = value;
        if (value) {
            this.formatValue(value.toString());
        }
    }

    propagateChange = (_: any) => {};
    propagateTouched: any = () => {};

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.element.nativeElement.disabled = isDisabled;
    }

    @HostListener('click')
    click() {
        this.element.nativeElement.select();
    }

    ngOnDestroy(): void {
    }

}
