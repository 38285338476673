import {map} from "rxjs/operators";
import {HttpErrorResponse, HttpEventType, HttpResponse} from "@angular/common/http";
import {environment} from "../../compliance-front-office/src/environments/environment";

export class ComplianceApiService {
    private base: string = environment.apiUrl;
    public baseUri: string;
    public app: string;
    public uriToken: string;

    constructor(uriToken: string, app: string = 'api') {
        this.setBaseUrl(uriToken, app);
    }

    public setApp(app: string = 'api') {
        this.app = app;
        this.constructUBaseUri();
    }

    public setBaseUrl(uriToken: string, app: string = 'api') {
        this.app = app;
        this.uriToken = uriToken;
        this.constructUBaseUri();
    }

    public setUriToken(uriToken: string) {
        this.uriToken = uriToken;
        this.constructUBaseUri();
    }

    public setUri(uriToken: string, app: string = 'api') {
        this.app = app;
        this.uriToken = uriToken;
        this.constructUBaseUri();
    }

    public constructUBaseUri() {
        this.baseUri = `${this.base}/${this.app}`;
        if (this.uriToken && this.uriToken !== '') {
            this.baseUri = `${this.baseUri}/${this.uriToken}`;
        }
    }

    protected uploadProgressEventMap() {
        return map((event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                const percentDone = Math.ceil(100 * (event.loaded / event.total)/3);
                return {
                    progress: percentDone,
                    error: false,
                    value: event.body
                };
            } else if (event instanceof HttpResponse) {
                return {
                    progress: 33,
                    error: false,
                    value: event.body,
                    uploaded: true
                };

            } else if (event instanceof HttpErrorResponse) {
                return {
                    progress: 100,
                    error: true,
                    value: {},
                    uploaded: true
                };
            } else {
                return {
                    error: false,
                    value: {},
                    uploaded: false
                };
            }
        })
    }

    protected computeFormData(file: File, anneeExercice?: any, projectName?: string, projectCode?: string) {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        if(anneeExercice)
        formData.append('anneeExercice', JSON.stringify(anneeExercice));
        if(projectName)
        formData.append('projectName', projectName);
        if(projectCode)
        formData.append('projectCode', projectCode);
        return formData;
    }


}
