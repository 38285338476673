import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Exercice} from "../../../../../../../common/class/exercice/exercice";
import {FormControl} from "@angular/forms";
import {DATE_FORMAT} from "../../../../../../../common/class/compliance-constant";
import {Dossier} from "../../../../../../../common/class/dossier/dossier";
import {ActivatedRoute, Router} from "@angular/router";
import moment from 'moment';
import {ComplianceI18nLoader} from "../../../../../../../common/class/i18n/compliance-i18n-loader";
import {ConfirmationService} from "primeng/api";
import {ExerciceService} from "../../../../../../../common/service/exercice/exercice.service";

@Component({
    selector: 'compliance-legal-entity',
    templateUrl: './legal-entity.component.html',
    styleUrls: ['./legal-entity.component.scss']
})
export class LegalEntityComponent extends ComplianceI18nLoader {
    @Input() project: Dossier;
    private _exercices: Exercice[];
    @Input() set exercices(exercices: Exercice[]) {
        this._exercices = exercices.sort((a, b) => -a.annee.localeCompare(b.annee, undefined, {numeric: true}));
    }
    get exercices(): Exercice[] {
        return this._exercices;
    }
    @Input() set selectedExercice(exercice: Exercice) {
        this.formControl.setValue(exercice);
    }
    @Input() paramsSubscription: boolean;
    @Output() selectedExerciceChange = new EventEmitter<Exercice>();
    formControl = new FormControl();
    DATE_FORMAT = DATE_FORMAT;
    private _domain: string;
    private _category: string;

    get currentExercice(): Exercice {
        return this.formControl.value;
    }

    constructor( private _route: ActivatedRoute, private _router: Router,
                private _confirmationService: ConfirmationService,
                private _exerciceService: ExerciceService) {
        super();
        this._domain = this._route.snapshot.paramMap.get('domain');
        this._category = this._route.snapshot.paramMap.get('category');

        this.formControl.valueChanges.subscribe((exercice) => {
            if (exercice === 'create') {
                this.createExercice();
            } else {
                this.selectedExerciceChange.emit(exercice);
            }
        });
    }

    goToGestionFile() {
        this._router.navigate(
            ['/compliance', this._domain, this._category, 'project', this.project.id, this.currentExercice.id, 'file-list']);
    }

    goToGlobalParams() {
        if (this.paramsSubscription) {
            this._router.navigate(
                ['/compliance', this._domain, this._category, 'project', this.project.id, this.currentExercice.id, 'globalParams', 'graph']);
        }
    }

    goToExerciceSupervision() {
        this._router.navigate(
            ['/compliance', this._domain, this._category, 'project', this.project.id, this.currentExercice.id, 'supervision']);
    }

    createExercice() {
        this._router.navigate(
            ['/compliance', this._domain, this._category, 'project', this.project.id, 'exercice', 'create']);
    }

    editExercice() {
        this._router.navigate(
            ['/compliance', this._domain, this._category, 'project', this.project.id, 'exercice', this.currentExercice.id, 'edit']);
    }

    deleteExercice() {
        let i18n = super.getTranslation();
        let exerciceI18n = i18n.dashboard.exercice;

        let dateFinExerciceFr = moment(this.currentExercice.dateFin).format(DATE_FORMAT['DD/MM/YYYY'].moment);
        this._confirmationService.confirm({
            acceptVisible: true,
            acceptLabel: i18n.buttons.confirm,
            rejectVisible: true,
            rejectLabel: i18n.buttons.cancel,
            message: `${exerciceI18n.deleteModal.content} ${dateFinExerciceFr} ?`,
            header: `${exerciceI18n.deleteModal.title}`,
            accept: () => {
                // Actual logic to perform a confirmation
                this._exerciceService.deleteExercice(this.currentExercice.id).then(() => {
                    this.selectedExerciceChange.emit(null);
                });
            },
        });
    }
}
