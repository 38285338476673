import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UploadActionType} from "../../../../../../common/class/upload/upload-constantes";
import {COMPLIANCE_TYPE_EXCEPTION, FileStatus, FileType} from "../../../../../../common/class/compliance-constant";
import {UploadService} from "./shared/upload.service";
import {interval, Subscription} from "rxjs";
import _ from 'lodash';
import {FileUtilsService} from "../../../../../../common/service/file/file-utils.service";
import {NotificationService} from "../../../../../../common/service/push/notification.service";
import {UploadInfo} from "../../../../../../common/class/upload/upload-info";
import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";
import {FileService} from "../../../../../../common/service/file/file.service";
import {ComplianceI18nLoader} from "../../../../../../common/class/i18n/compliance-i18n-loader";
import {ExerciceService} from "../../../../../../common/service/exercice/exercice.service";
import {Exercice} from "../../../../../../common/class/exercice/exercice";
import {AUTH_EXT, UNAUTH_EXT} from "../../../../../../common/class/upload/file-constant";
import {ComplianceToastrService} from "../../../../../../common/service/toastr/compliance-toastr.service";
import {ImportError} from "../../../../../../common/class/upload/import-error";
import {HttpErrorResponse} from "@angular/common/http";
import moment from "moment";
import {ComplianceLoaderService} from "../../../../../../common/service/loader/compliance-loader.service";
import {DossierService} from "../../../../../../common/service/dossier/dossier.service";
import {NgxFileDropEntry} from "ngx-file-drop";

@Component({
    selector: 'compliance-drag-n-drop',
    templateUrl: './drag-n-drop.component.html',
    styleUrls: ['./drag-n-drop.component.scss'],
    animations: [
        trigger('showQuestion', [
            state('true', style({transform: 'translateY(0)', opacity: '1'})),
            state('false', style({transform: 'translateY(-20%)', opacity: '0'})),
            transition('true => false', [
                // animate('300ms ease-out'),

                animate(300, keyframes([
                    style({opacity: 1, transform: 'translateX(0)', offset: 0}),
                    style({opacity: 0.5, transform: 'translateY(-40px)', offset: 0.7}),
                    style({opacity: 0, transform: 'translateY(-60px)', offset: 1.0})
                ])),
                style({display: 'none'})
            ]),
            transition('false => true', [
                style({display: 'block'}),
                animate(300, keyframes([
                    style({opacity: 0, transform: 'translateY(-60px)', offset: 0}),
                    style({opacity: 0.5, transform: 'translateY(-40px)', offset: 0.7}),
                    style({opacity: 1, transform: 'translateX(0)', offset: 1.0})
                ]))
            ])
        ])
    ]
})
export class DragNDropComponent extends ComplianceI18nLoader implements OnInit, OnDestroy {

    @Input() uploadActionType: UploadActionType;
    @Input() fileType: FileType[];
    @Input() projectId: string;
    @Input() exerciceId: string;
    @Input() whiteList: string[] = [];
    @Input() blackList: string[] = [];


    typeFileLabel: string;
    progress = 0;
    progressType = "success";
    fileStatus = FileStatus;

    private subscriptions: Subscription[] = [];
    private progressSub: Subscription;

    @Output() fileProgressEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() errorEvent: EventEmitter<any> = new EventEmitter<boolean>();
    @Output() beginEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    // @Output() loadingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    public uploadInfo: UploadInfo;
    action: string;
    question = false;
    private i18n: any;
    exercice: Exercice;
    public fileTypeStr: string;
    errorMessage: any;


    constructor( private fileUtilsService: FileUtilsService, private uploadService: UploadService,
                private notificationService: NotificationService, private fileService: FileService, private exerciceService: ExerciceService,
                private toastr: ComplianceToastrService, private dossierService: DossierService, private _complianceLoaderService:ComplianceLoaderService) {
        super();
    }

    ngOnInit() {
        this.i18n = super.getTranslation().uploadFile;
        this.fileTypeStr = ['FEC', 'CA3', 'DEB_INTRODUCTION', 'DEB_EXPEDITION', 'DES'].filter(value => this.fileType.includes(value as FileType)).join(', ').replace('DEB_INTRODUCTION, DEB_EXPEDITION', 'DEB');
        this.whiteList = [...this.whiteList, ...AUTH_EXT];
        this.blackList = [...this.blackList, ...UNAUTH_EXT];
        this.subscriptions.push(this.notificationService.uploadNotificationObservable$.subscribe((message: UploadInfo) => {
            this.manageMessage(message);
        }));

        if (this.exerciceId) {
            this.exerciceService.findExercicesById(this.exerciceId).then((exercice) => {
                this.exercice = exercice;
            });
        }
    }

    private manageMessage(message: UploadInfo) {
        console.log("manageMessage", message);
        this.uploadInfo = message;
        switch (message.status) {
            case FileStatus.EXTRACTING:
                this.action = this.i18n.uploadDnD.actions.extracting;
                if (this.progressSub) {
                    this.progressSub.unsubscribe();
                }
                this.progressSub = interval(1000).subscribe(() => {
                    let p = this.progress;
                    if (p < 95) {
                        this.progress++;
                    } else {
                        this.progress = 96;
                    }
                });
                break;
            case FileStatus.EXTRACTED:
                this.action = this.i18n.uploadDnD.actions.extracted;
                this.progress = 100;
                if (this.progressSub) {
                    this.progressSub.unsubscribe();
                }
                this.fileProgressEvent.emit(this.uploadInfo);
                setTimeout(() => this.progress = 0, 300);
                break;
            case FileStatus.QUESTION_SAME_PERIODE:
            case FileStatus.QUESTION_WRONG_EXERCICE:
            case FileStatus.QUESTION_WRONG_SIREN:
                if (this.progressSub) {
                    this.progressSub.unsubscribe();
                }
                this.action = this.i18n.uploadDnD.actions.waiting;
                this.question = true;
                break;
            case FileStatus.NOT_AUTHORIZED:
                this.progress = 0;
                if (this.progressSub) {
                    this.progressSub.unsubscribe();
                }
                this.fileProgressEvent.emit(this.uploadInfo);
                this.setError(`${super.getValue('upload.error.type.many')}${this.fileTypeStr}`);
                break;
            case FileStatus.ERROR:
                console.log("j'ai une erreur lors de l'upload", message);
                this.progress = 0;
                if (this.progressSub) {
                    this.progressSub.unsubscribe();
                }
                this.fileProgressEvent.emit(this.uploadInfo);
                this.setError(`${super.getValue('upload.error.part1')}${message.fileName}${super.getValue('upload.error.part2')}`);
                break;
            case FileStatus.ERROR_FEC:
                console.log("j'ai une erreur lors de l'upload du fec", message);
                this.gestionErrorFec();
                break;
        }
    }


    private gestionErrorFec() {
        this.progress = 0;
        if (this.progressSub) {
            this.progressSub.unsubscribe();
        }

        this.question = true;
    }

    ngOnDestroy(): void {
        _.each(this.subscriptions, sub => {
            sub.unsubscribe();
        });
        this.subscriptions = [];
    }

    onFilesChange(fileList: NgxFileDropEntry[]) {
        this.fileUtilsService.ngxFileChange({
            toastr: this.toastr,
            translate: this.translate,
            fileList: fileList,
            upload: (file:File)=>this.upload(file)
        })

    }
    uploadHandler(event) {
        this.fileUtilsService.uploadHandler({
            toastr: this.toastr,
            translate: this.translate,
            fileList: event.target.files,
            upload: (file:File)=>this.upload(file)
        });
        if (event.target) {
            event.target.value = null;
            event.files = [];
        }
    }
    /*
    onFilesChange(event) {
        this.question = false;
        this.progress = 5;

        let fileList = event.files || event.target.files;
        let file;
        if (fileList.length > 0) {
            file = fileList[0];
        }
        if (file instanceof File) {
            this.upload(file);
        } else if (file.fileEntry.file) {
            file.fileEntry.file(fileData => {
                this.upload(fileData);
            });
        }
        if (event.target) {
            event.target.value = null;
            event.files = [];
        }
    }
    */


    private async upload(file) {
        console.log('upload extension');
        this.errorMessage = null;
        if (this.fileUtilsService.isExtensionAuthorized(file, this.whiteList)) {
            this.beginEvent.emit(true);
            this.action = this.i18n.uploadDnD.actions.depots;
            this.uploadInfo = await this.fileUtilsService.fileToUploadInfo(file, this.projectId);
            if (this.uploadInfo.status === FileStatus.ERROR_FEC) {
                this.gestionErrorFec();
            } else {
                let project = await this.dossierService.findById(this.projectId);
                this.question = false;

                if (this.fileUtilsService.isTypeAuthorized(this.uploadInfo, this.fileType)) {
                    if (this.uploadActionType === UploadActionType.EXTRACT_SIREN) {
                        let sub = this.dossierService.upload(this.uploadInfo).subscribe(val => {
                            console.log(val);
                            this.progress = val.progress;
                            if (val.uploaded) {
                                this.fileProgressEvent.emit(val);
                                this.progress = 90;
                            }
                        });
                        this.subscriptions.push(sub);
                    } else {
                        let sub = this.uploadService.upload({
                            uploadInfo: this.uploadInfo,
                            uploadActionType: this.uploadActionType,
                            anneeExercice: this.exercice ? {
                                id: this.exercice.id,
                                annee: this.exercice.annee,
                                dateDebutExercice: this.exercice.dateDebutExercice,
                                dateFinExercice: this.exercice.dateFinExercice,
                                validation: this.exercice.validation
                            } : null,
                            projectName: project.name,
                            projectCode: project.code,
                            authorizedFilesControl: this.fileType
                        }).subscribe(val => {
                                console.log("dragnDropComponant, upload", val);
                                this.progress = val.progress;
                            },
                            (err: HttpErrorResponse) => {
                                if (err.error.type === COMPLIANCE_TYPE_EXCEPTION.FUNCTIONAL) {
                                    this.errorMessage = "upload.error.validatedExercice";
                                    this.progress = 0;
                                } else {
                                    let date = moment(err.error.timestamp);
                                    this.toastr.error(err.error.message || err.error.error_description, date.format("DD/MM/YYYY HH:mm:ss.SSS"));
                                }
                            });
                        this.subscriptions.push(sub);
                    }
                } else {
                    const errorType = super.getValue('upload.error.type');
                    const nbType = this.fileType.length > 1 ? 'many' : 'one';
                    this.setError(`${errorType[nbType]}${this.fileTypeStr}`);
                }
            }
        } else {
            this.uploadInfo = this.fileUtilsService.fileToUploadInfoSimple(file, this.projectId);
            this.setError(super.getValue('upload.error.extension'));
        }
    }

    private setError(message) {
        this.errorMessage = message;
        this.question = true;
        this.progress = 0;
    }

    private setErrors(message, errors: ImportError[]) {
        if (errors && errors.length) {
            message = `${message}
                <ul>`;
            errors.forEach((error: ImportError) => message += `<li>${error.line}[${error.filed}]: ${error.message}</li>`);
            message += `</ul>`;
        }
        this.setError(message);
    }

    isFec() {
        return this.uploadInfo.type === FileType.FEC;
    }

    isDEB() {
        return (this.uploadInfo.type === FileType.DEB_EXPEDITION || this.uploadInfo.type === FileType.DEB_INTRODUCTION);
    }

    // le fec est deja uploadé en base est extrait faut just enlever les erreurs et mettre a jours le status
    async addFecToOthers() {
        let project = await this.dossierService.findById(this.projectId);
        await this.fileService.mergeFec(this.projectId, project.name, this.exerciceId, this.uploadInfo.fileId, FileStatus.EXTRACTED);
    }

    async addDebToSameMonth() {
        let project = await this.dossierService.findById(this.projectId);
        let anneeExercice = this.exercice ? {
            id: this.exercice.id,
            annee: this.exercice.annee,
            dateDebutExercice: this.exercice.dateDebutExercice,
            dateFinExercice: this.exercice.dateFinExercice
        } : null;
        this.fileService.continueUploadDebWithSamePeriodThenExistantOne(this.projectId, project.name, project.code, this.exerciceId, this.uploadInfo.fileId, anneeExercice);
    }

    async deleteActualFile() {
        this._complianceLoaderService.sendLoaderShow();
        let ids: string[] = new Array(this.uploadInfo.fileId);
        await this.fileService.deleteNewfiles(this.projectId, this.exerciceId, ids);
        this.uploadInfo.status = FileStatus.EXTRACTED;
        this.uploadInfo.errors[0].message = "Ce fichier a été supprimé !";
        this.question = false;
        this.progress = 0;
        this._complianceLoaderService.sendLoaderHide();

    }

    async deleteOldFile() {
        this._complianceLoaderService.sendLoaderShow();
        let oldIds: string[] = [];
        let ids: any = this.uploadInfo.errors[this.uploadInfo.errors.length - 1].rejectedValue;

        if (ids instanceof Array) {
            for (const value of ids) {
                oldIds.push(value.id);
            }
        } else {
            oldIds.push(ids.id);
        }
        await this.fileService.deleteOldFileAndExtractNewOne(this.projectId, this.exerciceId, oldIds, this.uploadInfo.fileId);
        this._complianceLoaderService.sendLoaderHide();
    }

    async ContinueUpload() {
        let project = await this.dossierService.findById(this.projectId);
        let anneeExercice = this.exercice ? {
            id: this.exercice.id,
            annee: this.exercice.annee,
            dateDebutExercice: this.exercice.dateDebutExercice,
            dateFinExercice: this.exercice.dateFinExercice
        } : null;
        this.fileService.continueUploadWithDifferentExercice(this.projectId, project.name, project.code, this.exerciceId, this.uploadInfo.fileId, anneeExercice);
    }

    async continueUploadWithWrongSiren() {
        let project = await this.dossierService.findById(this.projectId);
        let anneeExercice = this.exercice ? {
            id: this.exercice.id,
            annee: this.exercice.annee,
            dateDebutExercice: this.exercice.dateDebutExercice,
            dateFinExercice: this.exercice.dateFinExercice
        } : null;
        this.fileService.continueUploadWithWrongSiren(this.projectId, project.name, project.code, this.exerciceId, this.uploadInfo.fileId, anneeExercice);
    }
}
