export const pageInformationLight = {
    title:`Bienvenue sur Francis Lefebvre e&#8209;Compliance&nbsp;TVA`,
    paragraphes: {
        1:`La version e-Compliance TVA Flash Intracom vous permet de contrôler la cohérence de vos Acquisitions et Livraisons Intracommunautaires entre vos déclarations de TVA (CA3) et vos Déclarations d'Echanges de Biens (DEB).`,
        2:`Pour découvrir la version complète, connaître les fonctionnalités et contrôles supplémentaires, vous pouvez vous inscrire à l'une de <a href="{{link}}" target="_blank">nos sessions de démonstration de l'application</a>.`,
        divers_2: `Pour découvrir la version complète et ses fonctionnalités, nous vous invitons à vous inscrire à l’une des sessions de démonstration de notre logiciel depuis le lien suivant, personnalisé selon votre activité :
                <ul style="margin: 0 20px;"><li>Si vous êtes une entreprise : <a href="{{lienEntreprise}}" target="_blank">cliquez ici</a></li>
                <li>Si vous êtes un cabinet d’expertise comptable ou d’avocat : <a href="{{lienExpCompEtAvocat}}" target="_blank">cliquez ici</a></li></ul>`,
        3:`Pour toutes questions, vous pouvez nous contacter au 01.41.05.57.88 ou par e-mail à <a href="mailto:assistance@fl-ecompliance.com">assistance@fl&#8209;ecompliance.com</a>.`,
        4:`En vous souhaitant une bonne navigation.`,
    }
};
