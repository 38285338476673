<div id="" class="align-items-center page-folder-creation d-flex h-100 slimscroll" #projectCreate>

    <div class="container my-auto">
        <div class="row">

            <div class="col-12">
                <div class="block-group-title">
                    <h2 class="title">{{'project.create.title' | translate}}</h2>
                    <p class="sub-title">{{'project.create.subtitle' | translate}}</p>
                </div>
            </div>
            <div class="col-12 col-lg-6 input-content">
                <form class="form-basic form-folder-creation" [formGroup]="projectForm" novalidate>
                    <div class="form-group">
                        <label for="siren" [innerHtml]="'project.create.form.manual' | translate | safe:'html'"></label>
                        <input type="text" id="siren" class="form-control  form-control-lg" autofocus=""
                               autocomplete="off" formControlName="code"
                               placeholder="SIREN, SIRET ou numéro de TVA intracommunautaire."/>
                        <!--<input class="form-control" type="text" id="number" name="number" placeholder="SIREN, SIRET ou numéro de TVA intracommunautaire.">-->

                        <div *ngIf="projectForm.controls['code'].errors  && projectForm.dirty" class="error-alert mt-2">
                            <div *ngIf="hasError(projectForm.controls['code'].errors)">
                                {{getErrorLabel(projectForm.controls['code'].errors)}}
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <p class="label" [innerHtml]="'project.create.form.auto' | translate | safe:'html'"></p>


                        <compliance-drag-n-drop [fileType]="fileType" [uploadActionType]="uploadActionType"
                                                (fileProgressEvent)="uploadFile($event)"></compliance-drag-n-drop>

                        <!-- Debug item template -->
                        <!-- <script type="text/html" id="debug-template">
                            <li class=""><strong>%%date%%</strong>: %%message%%</li>
                        </script> -->
                    </div>

                </form>
            </div>

            <div class="col-12 col-lg-6 d-flex align-items-center flex-column justify-content-center">
                <p *ngIf="display !== displayType.block" class="message-start"
                   [innerHtml]="'project.create.form.result.'+display | translate:{siren:projectObject?.code} | safe:'html'"></p>
                <ng-container *ngIf="display === displayType.block">
                    <div class="block-infos w-100">
                        <compliance-project-block [manualEdit]="!existingSiren && !existingProject"
                                                  [(project)]="projectObject"
                                                  (validProject)="enableCreation = $event"
                                                  [refs]="dataCreationDossier">
                        </compliance-project-block>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="block-bottom-actions">
        <div class="inner text-center d-flex justify-content-between">
            <a [routerLink]="['/compliance', domain, category, 'project', 'list']"
               class="btn btn-primary btn-back"><span>{{'buttons.back' | translate}}</span></a>
            <ng-container *ngIf="display">
                <a *ngIf="!existingProject" href="#" [ngClass]="{'disabled': !enableCreation}"
                   class="btn btn-primary" (click)="createProject()">
                    {{'project.create.buttons.create' | translate}}
                </a>
                <a *ngIf="existingProject" href="#" class="btn btn-primary" (click)="goToProject(projectObject.id)">
                    {{'project.create.buttons.open' | translate}}
                </a>
            </ng-container>
        </div>
    </div>

</div>
