<div>
    <div>
        <ngx-file-drop (onFileDrop)="onFilesChange($event)" dropZoneClassName="project-drop-zone">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <div class=" input-file">
                    <span class="label span-1" [innerHtml]="'upload.dragNDrop.title' | translate | safe:'html'"></span>
                    <span class="icon">
                    <input type="file" id="file1" name="file1" title='Ajouter un fichier' (change)="uploadHandler($event)"/>
                </span>
                    <label for="file1" class="label span-2">{{'upload.dragNDrop.fileChoice' | translate}} <br><span>({{fileTypeStr}})</span></label>

                    <ul class="" id="files">
                        <li class="text-muted text-center empty"></li>
                    </ul>
                    <!-- <ul class="" id="debug">
                        <li class="list-group-item text-muted empty">Loading plugin....</li>
                    </ul> -->
                    <div *ngIf="progress > 0 && uploadInfo" class="position-absolute w-100 progress-warp progress-value">
                        {{uploadInfo.fileName}} - {{action}} - {{progress}} %
                    </div>
                    <div *ngIf="progress > 0" class="position-absolute w-100 progress-warp">
                        <ngb-progressbar type="{{progressType}}" [value]="progress" [striped]="true" [height]="'1.4rem'"></ngb-progressbar>
                    </div>
                </div>
            </ng-template>
        </ngx-file-drop>

    </div>

    <div class="pt-4 px-4 text-center question mb-4" style="color: #fff" [@showQuestion]="question">
        <ng-container *ngIf="question">
            <div class="mr-2 text-center labels">
                <ng-container [ngSwitch]="uploadInfo.status">
                    <ng-container *ngSwitchCase="fileStatus.QUESTION_SAME_PERIODE">
                        <span>{{'upload.question.alreadyExist' | translate}}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="fileStatus.QUESTION_WRONG_EXERCICE">
                        <span>{{'upload.question.differentExercice.p1' | translate}}{{exercice.annee}}{{'upload.question.differentExercice.p2' | translate}}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="fileStatus.QUESTION_WRONG_SIREN">
                        <span>{{'upload.question.NotTheSameSiren' | translate}}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="fileStatus.ERROR_FEC">
                        <span [innerHTML]="'upload.question.fecError' | translate | safe:'html'"></span>
                    </ng-container>
                </ng-container>
            </div>
            <div class="d-flex justify-content-around actions" style="color: #fff">
                <ng-container [ngSwitch]="uploadInfo.status">
                    <ng-container *ngSwitchCase="fileStatus.QUESTION_SAME_PERIODE">
                        <button class="btn-primary btn mx-2" (click)="deleteActualFile()">{{'upload.question.buttons.deleteCurent' | translate}}</button>
                        <button class="btn-primary btn mx-2" (click)="deleteOldFile()">{{'upload.question.buttons.deleteOld' | translate}}</button>
                        <ng-container *ngIf="uploadInfo">
                            <button *ngIf="isFec()" class="btn-primary btn mx-2" (click)="addFecToOthers()">{{'upload.question.buttons.merge' | translate}}</button>
                        </ng-container>
                        <ng-container *ngIf="uploadInfo">
                            <button *ngIf="isDEB()" class="btn-primary btn mx-2" (click)="addDebToSameMonth()">{{'upload.question.buttons.mergeDeb' | translate}}</button>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="fileStatus.QUESTION_WRONG_EXERCICE">
                        <button class="btn-primary btn mx-2" (click)="deleteActualFile()">{{'buttons.cancel' | translate}}</button>
                        <button class="btn-primary btn mx-2" (click)="ContinueUpload()">{{'buttons.continue' | translate}}</button>
                    </ng-container>
                    <ng-container *ngSwitchCase="fileStatus.QUESTION_WRONG_SIREN">
                        <button class="btn-primary btn mx-2" (click)="deleteActualFile()">{{'buttons.cancel' | translate}}</button>
                        <button class="btn-primary btn mx-2" (click)="continueUploadWithWrongSiren()">{{'buttons.continue' | translate}}</button>
                    </ng-container>
                    <ng-container *ngSwitchCase="fileStatus.ERROR_FEC">
                        <ul class="mt-2">
                            <li *ngFor="let error of uploadInfo.errors" class="text-left">Ligne {{error.line}} : {{error.message}}</li>
                        </ul>
                    </ng-container>
                </ng-container>

            </div>
        </ng-container>

    </div>

    <div class="error-alert mt-2" *ngIf="errorMessage">
        <div [innerHTML]="errorMessage | translate | safe:'html'"></div>
    </div>
</div>
