import {Component, OnInit} from '@angular/core';
import {BgSpaceComponent} from "../../../../../common/class/background/bg-compliance";
import {HabilitationService} from "../../../../../common/service/auth/habilitation.service";
import {User} from "../../../../../common/class/user/user";
import {Location} from "@angular/common";
import {ContactForPremiumService} from "./shared/contact-for-premium.service";
import {ComplianceToastrService} from "../../../../../common/service/toastr/compliance-toastr.service";
import {ComplianceLoaderService} from "../../../../../common/service/loader/compliance-loader.service";

@Component({
    selector: 'compliance-version-complete',
    templateUrl: './version-complete.component.html',
    styleUrls: ['./version-complete.component.scss']
})
export class VersionCompleteComponent extends BgSpaceComponent implements OnInit {

    public datas = [
        {
            bloc:"Compte",
            contents:[
                {
                    label:`Nombre de dossier`,
                    premium:`Par tranche`,
                    light_navis:`3`,
                    light_inneo:`1`,
                    light_boutique:`3`,
                },
                {
                    label:`Accès utilisateur`,
                    premium:`Illimité`,
                    light_navis:`Illimité`,
                    light_inneo:`Illimité`,
                    light_boutique:`Illimité`,
                },
                {
                    label:`Accès module de e-learning`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`1`,
                    light_inneo:`<i class="fal fa-times text-primary">`,
                    light_boutique:`1`,
                },
                {
                    label:`Assistance métier`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-times text-primary">`,
                    light_inneo:`<i class="fal fa-times text-primary">`,
                    light_boutique:`<i class="fal fa-times text-primary">`,
                }
            ]
        },
        {
            bloc:"Contrôles TVA Intracommunautaires",
            contents:[
                {
                    label:`Contrôle de la cohérence, pour les AIC, entre les CA3 et les DEB à l'introduction`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-check text-secondary">`,
                    light_inneo:`<i class="fal fa-check text-secondary">`,
                    light_boutique:`<i class="fal fa-check text-secondary">`,
                },
                {
                    label:`Contrôle de la cohérence, pour les LIC, entre les CA3 et les DEB à l’expédition`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-check text-secondary">`,
                    light_inneo:`<i class="fal fa-check text-secondary">`,
                    light_boutique:`<i class="fal fa-check text-secondary">`,
                },
                {
                    label:`Contrôle de la cohérence, pour les LIC, entre les déclarations (CA3 et DEB) et leur comptabilisation (FEC)`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-times text-primary">`,
                    light_inneo:`<i class="fal fa-times text-primary">`,
                    light_boutique:`<i class="fal fa-times text-primary">`,
                },
                {
                    label:`Contrôle de la validité du numéro de TVA intracommunautaire dans la base VIES (ProDouane)`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-times text-primary">`,
                    light_inneo:`<i class="fal fa-times text-primary">`,
                    light_boutique:`<i class="fal fa-times text-primary">`,
                },
            ]
        },
        {
            bloc:"Contrôles TVA collectée",
            contents:[
                {
                    label:`Présence ou absence de la TVA collectée dans les écritures mouvementant des comptes de produits`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-times text-primary">`,
                    light_inneo:`<i class="fal fa-times text-primary">`,
                    light_boutique:`<i class="fal fa-times text-primary">`,
                },
                {
                    label:` Conformité des montants de TVA collectée comptabilisés en fonction des taux appliqués`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-times text-primary">`,
                    light_inneo:`<i class="fal fa-times text-primary">`,
                    light_boutique:`<i class="fal fa-times text-primary">`,
                },
                {
                    label:`Contrôle des bases imposables de biens et services (avec option pour les débits)`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-times text-primary">`,
                    light_inneo:`<i class="fal fa-times text-primary">`,
                    light_boutique:`<i class="fal fa-times text-primary">`,
                },
                {
                    label:`Conformité pour les prestations de services entre les encaissements comptabilisés et les bases déclarées sur les CA3`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-times text-primary">`,
                    light_inneo:`<i class="fal fa-times text-primary">`,
                    light_boutique:`<i class="fal fa-times text-primary">`,
                },
            ]
        },
        {
            bloc:"Contrôles TVA déductible",
            contents:[
                {
                    label:`Présence ou absence de la TVA déductible sur les achats de biens et services dans les écritures mouvementant des comptes de charges`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-times text-primary">`,
                    light_inneo:`<i class="fal fa-times text-primary">`,
                    light_boutique:`<i class="fal fa-times text-primary">`,
                },
                {
                    label:`Contrôle de la déduction anticipée de la TVA sur les achats de services`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-times text-primary">`,
                    light_inneo:`<i class="fal fa-times text-primary">`,
                    light_boutique:`<i class="fal fa-times text-primary">`,
                },
            ]
        },
        {
            bloc:"Fichiers",
            contents:[
                {
                    label:`Import de fichiers (DEB Introduction, DEB Expédition, CA3)`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-check text-secondary">`,
                    light_inneo:`<i class="fal fa-check text-secondary">`,
                    light_boutique:`<i class="fal fa-check text-secondary">`,
                },
                {
                    label:`Import du Fichier des Ecritures Comptables (FEC)`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-times text-primary">`,
                    light_inneo:`<i class="fal fa-times text-primary">`,
                    light_boutique:`<i class="fal fa-times text-primary">`,
                },
                {
                    label:`Rapprochement et cohérence avec les données comptables`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-times text-primary">`,
                    light_inneo:`<i class="fal fa-times text-primary">`,
                    light_boutique:`<i class="fal fa-times text-primary">`,
                },
            ]
        },
        {
            bloc:"Navigation",
            contents:[
                {
                    label:`Lancement des contrôles en pas à pas`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-check text-secondary">`,
                    light_inneo:`<i class="fal fa-check text-secondary">`,
                    light_boutique:`<i class="fal fa-check text-secondary">`,
                },
                {
                    label:`Lancement des contrôles en automatique`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-times text-primary">`,
                    light_inneo:`<i class="fal fa-times text-primary">`,
                    light_boutique:`<i class="fal fa-times text-primary">`,
                },
                {
                    label:`Personnalisation des paramétrages`,
                    premium:`<i class="fal fa-check text-secondary">`,
                    light_navis:`<i class="fal fa-times text-primary">`,
                    light_inneo:`<i class="fal fa-times text-primary">`,
                    light_boutique:`<i class="fal fa-times text-primary">`,
                },
            ]
        },
    ];
    public connectedUser: User;

    constructor(private _habilitationService: HabilitationService, private _location: Location,
                private _contactForPremiumService: ContactForPremiumService,
                private _toastr: ComplianceToastrService, private _complianceLoaderService: ComplianceLoaderService) {
        super();
    }

    ngOnInit() {
        this.connectedUser = this._habilitationService.getConnectedUser();
        this._complianceLoaderService.sendLoaderHide();

    }

    goBack() {
        this._location.back();
    }

    contact() {
        this._contactForPremiumService.sendMail().then(()=>{
            this._toastr.successI18n("Votre demande a bien été envoyée.");
            this.goBack();
        });
    }
}
