import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlForDashboard} from "../../../../../../../../../common/class/control/control-descriptor";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ComplianceI18nLoader} from "../../../../../../../../../common/class/i18n/compliance-i18n-loader";
import {ReportingService} from "../../reporting.service";

@Component({
    selector: 'compliance-report-modal',
    templateUrl: './report-modal.component.html',
    styleUrls: ['./report-modal.component.scss']
})
export class ReportModalComponent extends ComplianceI18nLoader implements OnInit {

    display: boolean;
    reportElement;
    @Output() exportEvent = new EventEmitter<{
        control: ControlForDashboard,
        typeExport: string;
        selectedElements: string[]
    }>();

    private _control: ControlForDashboard;
    formGroup: FormGroup;

    @Input() set control(control: ControlForDashboard) {
        this._control = control;
    }

    get control(): ControlForDashboard {
        return this._control;
    }

    constructor(private _fb: FormBuilder, private _reportingService: ReportingService) {
        super();
    }

    ngOnInit() {
        this.getReportElements();
    }

    showDialog() {
        this.display = true;
    }

    async getReportElements() {
        this.reportElement = await this._reportingService.getReportElements();
        this._generateForm();
    }

    export(typeExport: string) {
        this.display = false;
        let selectedElements = [];
        let rawValue = this.formGroup.getRawValue();
        Object.keys(rawValue).forEach(key => {
            if (rawValue[key]) {
                selectedElements.push(key);
            }
        });
        this.exportEvent.emit({
            control: this._control,
            typeExport: typeExport,
            selectedElements: selectedElements
        });
    }

    activateButtons() {
        let activate = false;
        if(this.formGroup) {
            let rawValue = this.formGroup.getRawValue();
            Object.keys(rawValue).forEach(key => {
                if (rawValue[key]) {
                    activate = true;
                }
            });
        }
        return activate;
    }

    private _generateForm() {
        let form = {};
        this.reportElement.forEach(value => {
            form[value.code] = [true];
        });
        this.formGroup = this._fb.group(form);
    }
}
