export const supervision = {
    exercice: {
        title: 'Note de synthèse générale',
        subtitle: 'Veuillez rédiger vos observations et conclusion générales',
        validation: 'VISA',
        errorMessage: 'Veuillez valider tous vos contrôles réalisés'
    },
    control: {
        title: 'Notes et Supervision',
        subtitle: 'Veuillez rédiger vos observations, corrections et conclusion',
        visa: 'VISA',
    },
    observations: 'Observations',
    fixes: 'Corrections à apporter',
    conclusion: 'Conclusion',
    amountRisk: {
        title: 'Risque',
        calculated: 'Calculé',
        accepted: 'Accepté',
        denied: 'Refusé'
    }
};
