<div id="content" class="page-cgvu">
    <div class="container">
        <div class="row height-100">
            <div class="col col-xl-12">
                <div class="block-group-title">
                    <h2 class="title big">{{'cgvu.title' | translate}}</h2>
                </div>
            </div>
        </div>

        <div class="cgvu text-justify text-white text-size-16" *ngIf="cgvuText">
            <div [innerHTML]="cgvuText | safe:'html'"></div>
            <!--<div class="donwload-info">-->
                <!--{{'cgvu.donwloadInfo.text' | translate}}<a href="#" (click)="download()">{{'cgvu.donwloadInfo.link' | translate}}</a>.-->
            <!--</div>-->
            <!--<div class="confidentialite-info">-->
                <!--{{'cgvu.confidentialite.text' | translate}}<a href="#" (click)="download()">{{'cgvu.confidentialite.link' | translate}}</a>.-->
            <!--</div>-->
            <div class="accept-cgvu">
                <div class="form-check abc-checkbox abc-checkbox-info abc-checkbox-circle">
                    <input class="form-check-input" type="checkbox" id="cgu-accept"
                           value="option2"
                           (change)="update($event)">
                    <label class="form-check-label" for="cgu-accept">
                        {{'cgvu.acceptlabel.part1' | translate}}<a href="#" (click)="downloadCgu()">{{'cgvu.acceptlabel.linkCgu' | translate}}</a>{{'cgvu.acceptlabel.part2' | translate}}<a href="#" (click)="downloadPolitique()">{{'cgvu.acceptlabel.linkConf' | translate}}</a><span [innerHTML]="'cgvu.acceptlabel.part3' | translate"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>

</div>

<footer id="footer">
    <div class="inner">
        <button class="btn btn-primary btn-next" [disabled]="!hasCguCgvRead" (click)="save()"><span>{{'buttons.next' | translate}}</span></button>
    </div>
</footer>
