<header id="header">
    <div class="inner d-flex justify-content-between align-items-center">
        <div>
            <a href="#" [routerLink]="'/compliance/home'" class="logo">Francis Lefebvre e-compliance</a>
        </div>
        <!--<compliance-menu *ngIf="contentType!='login'" [menus]="menus" [uploadStatus]="true"></compliance-menu>-->

        <div *ngIf="typeContent!='login'">
            <compliance-breadcrumb></compliance-breadcrumb>
        </div>
        <!--<div class="float-right" *ngIf="typeContent!='login'">-->
        <div class="d-flex justify-content-end align-items-center" *ngIf="typeContent!='login'">
            <div class="d-flex align-items-center mr-5" *ngIf="connectedUser.account.subscription.id !== 'premium'">

                <ng-container >
                    <a class=" btn btn-primary btn-sm mr-2 ml-2" [routerLink]="'/compliance/version-complete'" >
                        <span>Version complète</span><i class=" ml-2 fal fa-sunrise"></i>
                    </a>
                </ng-container>
            </div>
            <div class="d-flex align-items-center">

                <a class="mr-3 ml-2" [routerLink]="'/compliance/FISCAL/TVA/video'">
                    <i class="fal fa-film-alt text-primary fa-2x" title="{{'menu.video' | translate}}"></i>
                </a>
                <ng-container *ngIf="commonSubscription?.elearningActivated; else lightELearning">
                    <a class="mr-2 ml-2" href="https://elearning-flf.fr" target="_blank">
                        <i class="fal fa-graduation-cap text-secondary fa-2x" title="{{'menu.elearning' | translate}}"></i>
                    </a>
                </ng-container>
                <ng-template #lightELearning>
                    <compliance-light-subscription-button class="mr-2 ml-2"
                                                          pTooltip="{{'menu.elearning' | translate}}" tooltipPosition="bottom"
                                                          [disabled]="true">
                        <i class="fal fa-graduation-cap text-secondary fa-2x"></i>
                    </compliance-light-subscription-button>
                </ng-template>
                <a class="mr-3 ml-2" [routerLink]="'/compliance/aide'">
                    <i class="fal fa-question-circle text-primary fa-2x" title="{{'menu.help' | translate}}"></i>
                </a>
            </div>
            <div class="account">
                <p class="name">{{connectedUser.firstName}} <span>{{connectedUser.lastName}}</span></p>
                <a href="#" (click)="logout()" class="log-out">{{'menu.logout' | translate}}</a>
            </div>
            <div class="account " ngbDropdown placement="bottom-right">
                <span class="nav-link burger action-hover" href="#" id="dropdownBasic1" role="button" ngbDropdownToggle><i
                    class="fas fa-bars fa-2x"></i></span>
                <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <a class="dropdown-item mt-4" [routerLink]="'/compliance/user/compte'">
                        {{'menu.myAccount' | translate}}
                    </a>
                </div>
            </div>
        </div>
        <!--<span class="badge badge-dark mr-2">{{connectedUser.firstName}} {{connectedUser.lastName}}</span>-->
        <!--<button class="btn btn-danger" (click)="logout()"><i class="fas fa-sign-out-alt mr-1" aria-hidden="true"></i>{{'menu.logout' | translate}}</button>-->
        <!--</div>-->
    </div>
</header>


