<div id="content" class="page-gestion-file page-botom-transparent">

    <div class="container-fluid mx-auto" *ngIf="uploadFiles && exercice">
        <div class="block-group-title ">
            <h2 class="title col-12 position-relative">
                <span class="position-relative">
                    {{'gestionFile.title' | translate}}
                    <!--                    <i class="fal fa-info-circle bulle-info" [ngbTooltip]="tipAsterix" [closeDelay]="1000">-->
                    <!--                        <ng-template #tipAsterix class="asterix">-->
                    <!--                            -->
                    <!--                        </ng-template>-->
                    <!--                    </i>-->
                </span>


            </h2>

            <h2 class="sub-title position-relative " (click)="showHelp()"><span class="action" (click)="showHelp()">Vous avez besoin d'aide ? <u>Cliquez ici</u></span></h2>
        </div>
        <div class="row">
            <div class="col-xl-7 col-md-12">
                <div class="row head mr-5 ">
                    <div class="col-12 row m-0 p-0 ">
                        <div class="col-2 head">{{'gestionFile.head.type' | translate}}</div>
                        <div class="col-5 label">
                            <div>{{'gestionFile.head.nbFile' | translate}}</div>
                        </div>
                        <div class="col-3 label">
                            <div>{{'gestionFile.head.exercice' | translate}}</div>
                        </div>
                        <div class="col-2 action">&nbsp;</div>
                    </div>
                </div>
            </div>
            <hr class="col-12">
            <div class="col-xl-7 file-list  col-md-12">
                <div *ngFor="let type of types; let i = index" class="row file my-3 mr-5 bg-white" [ngClass]="{'disabled': isFilesDisabled(type)}">
                    <div class="col-12 row m-0 p-0 file-type-header" [ngClass]="{'open':type.show, 'new':type.newFile}" (click)="showFiles($event, i)">
                        <div class="col-2 resum show head">{{fileTypes[type.type]}}</div>
                        <ng-container *ngIf="!isFilesDisabled(type); else premium">
                            <div class="col-5 resum show label">
                                <div>{{fileByType[type.type].length}}</div>
                            </div>
                            <div class="col-3 resum show label">
                                <div>{{exercice.annee}}</div>
                            </div>
                            <div class="col-2 resum action">
                                <div *ngIf="type.type === fileTypeEnum.CA3" (click)="editCa3()">
                                    <i class="far fa-plus-circle text-secondary font-weight-bold"></i>
                                </div>
                                <div *ngIf="type.type === fileTypeEnum.DEB_EXPEDITION" (click)="exportViesAll($event, type)"
                                     [pTooltip]="toolTipVie" tooltipPosition="top" disabled="" [escape]="false">
                                    <i *ngIf="!extractingViesAll" class="fas fa-file-download text-secondary font-weight-bold"
                                       [ngClass]="{'disabled': !fileByType[type.type].length || !hasVieControl}"></i>
                                    <i *ngIf="extractingViesAll" class="fas fa-spinner fa-spin"></i>
                                </div>
                                <div *ngIf="!deletingByType[type.type]" (click)="deleteAll($event, type)"><i class="far fa-trash-alt text-primary"></i></div>
                                <div class="action position-relative" *ngIf="deletingByType[type.type]">
                                    <i class="fas fa-spinner fa-spin"></i>
                                </div>
                                <div (click)="showFiles($event, i)"><i class="fas " [ngClass]="{'fa-caret-up':type.show,'fa-caret-down':!type.show}"></i></div>
                            </div>
                        </ng-container>
                        <ng-template #premium>
                            <div class="col-10 resum show label">
                                <div>{{'gestionFile.table.premium' | translate}}</div>
                            </div>
                        </ng-template>
                    </div>
                    <div class="row col-12  m-0 p-0" *ngIf="type.show">
                        <div class="col-12 bg-white">
                            <div *ngIf="!fileByType[type.type].length">{{'gestionFile.table.noFile.p1' | translate}}{{fileTypes[type.type]}}{{'gestionFile.table.noFile.p2' | translate}}{{exercice.annee}}.</div>
                            <!--<compliance-table-file-fec [files]="fileByType[type.type]" (deleteEvent)="deleteFile($event)" *ngIf="type.type === 'FEC'"></compliance-table-file-fec>-->
                            <compliance-table-file-date [files]="fileByType[type.type]" [hasVieControl]="hasVieControl" [toolTipVie]="toolTipVie"
                                                        (deleteEvent)="deleteFile($event)" (editEvent)="editFile($event)"
                                                        (exportViesEvent)="exportViesFile($event)" [type]="type.type">
                            </compliance-table-file-date>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 file-upload  col-md-12">
                <form class="form-basic form-import-file">
                    <div class="form-group">
                        <!--<p class="label"><span *ngIf="!uploadFiles || !uploadFiles.length">{{getSubTitle()}}</span>{{getSubTitle2()}}</p>-->
                        <!--<div class="upload-info text-white text-center text-size-12" [innerHtml]="getInfoLabels() | safe:'html'"></div>-->
                        <div class="">
                            <compliance-drag-n-drop [uploadActionType]="uploadAction" [fileType]="userSubscription.authorizedFiles"
                                                    [projectId]="projectId" [exerciceId]="exerciceId"
                                                    (fileProgressEvent)="uploadEnd($event)"></compliance-drag-n-drop>
                            <!--(fileProgressEvent)="end($event)" (loadingEvent)="showLoading($event)"-->
                            <!--(errorEvent)="manageError($event)" (beginEvent)="manageBeginUpload($event)"></compliance-drag-n-drop>-->
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>

    <!--    <footer id="footer">-->
    <!--        <div class="inner">-->
    <!--            <button href="#" class="btn btn-primary btn-back" (click)="goBack()"><span>{{'buttons.back' | translate}}</span></button>-->

    <!--        </div>-->
    <!--    </footer>-->

    <div class="block-bottom-actions">
        <div class="inner text-center d-flex justify-content-between">
            <button href="#" class="btn btn-primary btn-back" (click)="goBack()"><span>{{'buttons.back' | translate}}</span></button>
        </div>
    </div>

    <p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [resizable]="false" [draggable]="false"
              [minY]="70" [style]="{width: '60%'}" [styleClass]="'help-dialog'"
              [maximizable]="false" [baseZIndex]="10000">
        <p-header>
            <span class="modal-head">Aide | Gestion des fichiers

                    <i class="fal fa-film-alt  text-size-14 action ml-3" pTooltip="{{'buttons.camera' | translate}}" tooltipPosition="bottom" [routerLink]="['/compliance', domain, category, 'video', 'gestion_des_fichiers']"></i>

            </span>
        </p-header>
        <div class="slimscroll content">
            <div class="card-body shadow ">
                <h5 class="card-title">Fichier des Ecritures Comptables (FEC)</h5>
                <div>
                    <p class="card-text">C’est un fichier normé et obligatoire qui doit être transmis à l’administration en cas de demande de vérification et de contrôle fiscal. Ce fichier est au format .txt ou sans extension. Nous
                        acceptons les fichiers qui sont conformes aussi bien au niveau de la forme que du fond.</p>
                </div>
            </div>
            <div class="card-body shadow ">
                <h5 class="card-title"> Les déclarations de TVA mensuelles (CA3)</h5>
                <div>
                    <p>Nous acceptons 3 types de formats</p>
                    <ul>
                        <li>Déclaration de TVA de type EDI (Echange de Données Informatisé), qui a été générée par votre logiciel de production comptable et qui a été automatiquement télétransmise à l’administration. Extension .edi</li>
                        <li>Déclaration faite en EFI (Echange de Formulaires Informatisé) pour le mois en cours sur le site <a href="www.impots.gouv.fr">www.impots.gouv.fr</a>. Vous pouvez déposer le fichier qui a été généré au moment où vous avez enregistré en pdf.
                            Extension .pdf
                        </li>
                        <li>Déclaration enregistrée au format « Archives » : Ce format, est universel et quel que soit votre mode de déclaration, il est gardé en historique sur le compte <a href="www.impots.gouv.fr">www.impots.gouv.fr</a>. C’est pourquoi, il s’agit du
                            format que nous vous recommandons.<br/>
                            <span class="action" (click)="downloadCa3Help()">Vous pouvez télécharger les instructions ici <i class="fal fa-file-pdf ml-1"></i></span>
                        </li>
                    </ul>

                    <p>Millésimes : de janvier 2016 jusqu’à aujourd’hui (Attention, pour les déclarations réalisées en EFI : seules les déclarations datant d’après novembre 2016 sont acceptées dans le logiciel.)</p>
                </div>
            </div>
            <div class="card-body shadow ">
                <h5 class="card-title"> Les déclarations d’échanges de biens à l'introduction et à l'expédition (DEB)</h5>
                <div>
                    <p>Nous acceptons 2 types de fichiers</p>
                    <ul>
                        <li>
                            Les déclarations de biens à l'introduction au format pdf<br/>
                            <span class="action" (click)="downloadDebIntroHelp()">Vous pouvez télécharger les instructions ici <i class="fal fa-file-pdf ml-1"></i></span>
                        </li>
                        <li>
                            Les déclarations de biens à l'expédition au format pdf<br/>
                            <span class="action" (click)="downloadDebExpeHelp()">Vous pouvez télécharger les instructions ici <i class="fal fa-file-pdf ml-1"></i></span><span> et contrôler les numéros de TVA intracommunautaires présents sur vos DEB, en cliquant sur l’icône téléchargement à droite de vos DEB.</span>
                        </li>
                    </ul>

                    <p>Millésimes : de janvier 2016 jusqu’à aujourd’hui</p>
                </div>
            </div>
            <div class="card-body shadow ">
                <h5 class="card-title">Attention</h5>
                <div>
                    <p>Pour l’ensemble des déclarations, nous n’acceptons pas dans notre application, les documents que vous avez scannés car ils sont traités comme des copies.</p>
                </div>
            </div>
        </div>
        <p-footer>
            <button type="button" class="btn btn-primary btn-sm" (click)="display=false"><i class="fal fa-check mr-2"></i>{{'buttons.close' | translate}}</button>
        </p-footer>
    </p-dialog>
</div>
