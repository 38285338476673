import {Injectable} from '@angular/core';
import {RestService} from "../../service/rest.service";
import {User} from "../../class/user/user";
import {ComplianceApiService} from "../../class/compliance-api.service";

;

@Injectable()
export class PasswordService extends ComplianceApiService{

    // public constructUrl(uriToken: string): string {
    //     return `${this.baseUri}${uriToken}`;
    // }

    constructor(private restService: RestService) {
        super('', 'guest')
    }

    public specifyPassword(tempUserIdToSetPassword: string, password: string): Promise<any> {
        let user:User = new User();
        user.tempUserIdToSetPassword = tempUserIdToSetPassword;
        user.password = password;
        return this.restService.post({
            url: this.baseUri,
            relativeUri: 'specifyPassword',
            data: user,
            arg: {}
        });
    }

    public forgottenPassword(email: string): Promise<any> {
        return this.restService.post({
            url: this.baseUri,
            relativeUri: 'forgottenPassword',
            data: email,
            arg: {}
        });
    }
}
