export const user = {
    title: "Gestion des utilisateurs",
    list: {
        headers: {
            name: "Prénom",
            lastName: "Nom",
            email: "Email",
            role: "Rôle",
            codeIse: "Code ISE",
            rasisonSocial: "Raison Sociale",
            accountProjectNumber: "Nombre dossiers",
            lastConnectionDate: "Dernière connexion",
            validPassword: "Mot de passe valide",
            subscription: "Abonnement"
        },
        filter: {
            roles: {
                all: "Tous les rôles",
                AdminEFL: "Administrateur EFL",
                AdminEFLParametrage: "Administrateur back office",
                Admin: "Administrateur de compte",
                user: "Utilisateur",
            }
        }
    },
    edit: {
        buttons: {
            dropdown: "Créer un nouvel utilisateur",
            createAdminEFL: "Administrateur EFL",
            createAdminEFLParametrage: "Administrateur back office",
            createAdmin: "Administrateur de compte",
        },
        tabs: {
            userTitle: 'Nouvel utilisateur',
            userListTitle: 'Utilisateurs du compte'
        },
        title: "Création/Modification d'utilisateur",
        user: {
            title: "Information de l'utilisateur",
            nom: "Nom",
            prenom: "Prénom",
            role: "Rôle",
            mail: "Email",
            phone: "Téléphone",
            disabled: "Désactivé",
            deleted: "Supprimé",
            account: "Compte client",
            subscription: "Abonnement",
            accountType: "Type de client",
            origin: "CGVU à accepter",
            lastConnexions:"5 dernières connexions",
            subscriptionEndDate: "Date de fin de subcription",

            error: {
                nom: {
                    required: "Le nom est obligatoire"
                },
                prenom: {
                    required: "Le prénom est obligatoire"
                },
                role: {
                    required: "Le rôle est obligatoire"
                },
                mail: {
                    required: "L'email est obligatoire",
                    pattern: "L'adresse email n'est pas valide",
                    emailTaken: "L'adresse email est déja utilisée"

                },
                phone: {
                    pattern: "Le numéro de téléphone n'est pas valide",

                },
                account: {
                    required: "Le compte client est obligatoire"
                },
                delete: {
                    sameIn: "Vous ne pouvez pas supprimer votre compte.<br>Veuillez le déselectionner.",
                    same: "Vous ne pouvez pas supprimer votre compte.<br>Veuillez le déselectionner."
                }
            }
        },
        account: {
            title: "Information du compte",
            codeIse: "Code ISE",
            accountName: "Raison sociale",
            accountProjectNumber: "Nombre de dossiers du compte",
            accountProjectCreated: "Nombre de dossiers crées",
            subscriptionEndDate: "Date de fin de subcription",
            disabled: "Désactivé",
            deleted: "Supprimé",
            error: {
                codeIse: {
                    required: "Le code ISE est obligatoire",
                    codeIseTaken: "Ce code ISE existe déjà, veuillez en saisir un autre"
                },
                accountName: {
                    required: "La raison sociale est obligatoire"
                },
                accountProjectNumber: {
                    required: "Le nombre de dossiers du compte est obligatoire",
                    pattern: "Veuillez saisir uniquement des nomrbes"
                }
            },
            subscription: {
                label: {
                    all: "Tous les abonnements",
                    premium: "Premium",
                    light_navis: "Light Navis",
                    light_inneo: "Light Inneo",
                    light_boutique: "Light Boutique"
                }
            },
            origin: {
                label: {
                    FLEC: "FLEC",
                    EFL: "EFL",
                    empty:'Veuillez choisir une origine'
                }
            },
            accountType: {
                label: {
                    expertComptable: "Expert Comptable",
                    entreprise: "Entreprise",
                    cabinetAvocat: "Cabinet d'avocat",
                    interne: "Interne",
                    divers: "Divers",
                    empty:'Veuillez choisir un type de client'
                }
            }
        },
        success: {
            create: "L'utilisateur a bien été créé",
            save: "L'utilisateur a bien été sauvegardé",
            createAccount: "Le compte a bien été créé",
            deletes: "Les utilisateurs ont bien été supprimés",
            disables: "Les utilisateurs ont bien été désactivés",
            delete: "L'utilisateur a bien été supprimé",
            disable: "L'utilisateur a bien été désactivé",
            completeSurvey: "Vos réponses ont bien été sauvegardées",
            resetPassword: {
                title: "Réinitialisation de mot de passe",
                msg: "Le mot de passe a été réinitialisé"
            },
            account:{
                disables:`Les comptes ont bien été désactivés`
            }
        },
        deleteComfirm:{
            header:`Attention`,
            message:`Vous êtes sur le point de supprimer cet utilisateur. Voulez-vous continuer ?`,
            messageAdmin:`Vous êtes sur le point de supprimer cet administrateur. Voulez-vous continuer ?`
        }
    },
    account: {
        notPremium: "Uniquement disponible sur la version complète"
    }
};
