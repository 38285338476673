import {Component, Input, OnInit} from '@angular/core';
import {AffectationTitle} from "../shared/lot-affectation";
import {ComplianceI18nLoader} from "../../../../../../../../common/class/i18n/compliance-i18n-loader";
import {LotUtilsService} from "../shared/lot-utils.service";

@Component({
    selector: 'compliance-affectation-title',
    templateUrl: './affectation-title.component.html',
    styleUrls: ['./affectation-title.component.scss']
})
export class AffectationTitleComponent extends ComplianceI18nLoader implements OnInit {

    @Input() lotAffectation: AffectationTitle;
    constructor( private lotUtils: LotUtilsService) {
        super()
    }

    ngOnInit() {
    }

    getSizeAsString(){
        return this.lotUtils.getOneOrManyFromLotData(this.lotAffectation.data)
    }

}
