import {Component, Input, OnInit} from '@angular/core';
import {BgSpaceComponent} from "../../../../../common/class/background/bg-compliance";
import {FormBuilder} from "@angular/forms";
import {HabilitationService} from "../../../../../common/service/auth/habilitation.service";
import {Cookie, User} from "../../../../../common/class/user/user";
import {LegalService} from "../../shared/export-file/legal.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {UserService} from "../../../../../common/service/user/user.service";
import {ComplianceToastrService} from "../../../../../common/service/toastr/compliance-toastr.service";
import {LoginService} from "../../../../../common/service/auth/login.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'compliance-gestion-cookies',
    templateUrl: './gestion-cookies.component.html',
    styleUrls: ['./gestion-cookies.component.scss']
})
export class GestionCookiesComponent extends BgSpaceComponent implements OnInit {
    @Input() showForm = false;
    private _user: User;
    private conf: boolean;

    constructor(private _fb: FormBuilder, private _exportFileService: LegalService, private _habilitationService: HabilitationService,
                private _deviceService: DeviceDetectorService, private _userService: UserService, private _toastr: ComplianceToastrService,
                private _loginService: LoginService, private _router: Router, private _route: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this._user = this._habilitationService.getConnectedUser();
        this.conf = this._route.snapshot.data.conf
    }

    downloadPolitique() {
        this._exportFileService.donwloadPolitiqueConfidentialite();
    }

    showOption() {
        this.showForm = true;
    }


    acceptCookiePubPerso() {

    }

    acceptCookieStat() {

    }

    acceptAllCookie() {
        this.saveCookie({
            cookiePubPerso: true,
            cookieStat: true,
            navigators: this._user.cookie ? this._user.cookie.navigators : []
        })
    }


    public async saveCookie(cookie: any) {
        let cookieUser = new Cookie(cookie);
        cookieUser.addNavigator(this._deviceService.browser);
        await this._userService.saveCookiePreference(cookieUser);
        await this._loginService.refreshTokenBasic();
        this._toastr.successI18n("gestionCookie.successMessage");
        this._router.navigate(['compliance', 'home']);
    }
}
