import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../../../../common/class/compliance-api.service";
import {TYPE_EXPORT, TypeExport} from "./export-file";
import {RestService} from "../../../../../common/service/rest.service";
import {FileUtilsService} from "../../../../../common/service/file/file-utils.service";

@Injectable({
    providedIn: 'root'
})
export class LegalService extends ComplianceApiService {
    constructor(private restService: RestService, private fileSaverService: FileUtilsService) {
        super('legal', 'api');
    }

    async downloadCgu() {
        let blob: Blob = await this.restService.getFile({
            url: this.baseUri,
            relativeUri: 'cgu',
        });
        this.fileSaverService.saveFile("cgvu", blob, new TypeExport(TYPE_EXPORT.pdf));
    }

    async donwloadPolitiqueConfidentialite() {
        let blob: Blob = await this.restService.getFile({
            url: this.baseUri,
            relativeUri: 'politique-confidentialite',
        });
        this.fileSaverService.saveFile("politique-confidentialite", blob, new TypeExport(TYPE_EXPORT.pdf));
    }

    cgvuText():Promise<string> {
        return this.restService.getString({
            url: this.baseUri,
            relativeUri: 'cgvuText',
            parameters: []
        });
    }
}
