import {Component, OnInit} from '@angular/core';
import {NotificationService} from "../../../../../../../common/service/push/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationMessage} from "../../../../../../../common/class/polling/notification-message";
import {BackgroungType} from "../../../../../../../common/class/background/bg-compliance";
import {
    CategoryControl,
    ControlDescriptor,
    DomaineControl, StatusControl
} from "../../../../../../../common/class/control/control-descriptor";
import {ControlRoutesFlux} from "../../../../../../../common/class/control/control-flux";
import {CalculatedValueService} from "../../../../../../../common/service/control/calculated-value.service";
import {AbstractControl} from "../../../../../../../common/component/control/abstract-control";
import {OriginPage} from "../../../../../../../common/component/control/control-message-handler";
import {TvaSubscriptionService} from "../../../../../../../common/service/subscriptions/tva-subscription.service";
import {FlecTVASubscription} from "../../../../../../../common/class/subscription/subscription";
import {VideoService} from "../../../../../../../common/service/video/video.service";
import {FileService} from "../../../../../../../common/service/file/file.service";
import {CONTROL_FILE_VIE_ID, DATE_FORMAT, FileType} from "../../../../../../../common/class/compliance-constant";
import {UploadFile} from "../../../../../../../common/class/upload/upload-file";
import {ViesService} from "../../../../shared/upload-file/drag-n-drop/shared/vies.service";
import {FileSaverService} from "ngx-filesaver";
import {FileUtilsService} from "../../../../../../../common/service/file/file-utils.service";
import moment from "moment";
import {takeUntil} from "rxjs/operators";
import {HabilitationService} from "../../../../../../../common/service/auth/habilitation.service";

@Component({
    selector: 'compliance-launch',
    templateUrl: './launch.component.html',
    styleUrls: ['./launch.component.scss']
})
export class LaunchComponent extends AbstractControl implements OnInit {
    DATE_FORMAT = DATE_FORMAT;
    controls: ControlDescriptor[];
    controlSelected: ControlDescriptor;

    userSubscription: FlecTVASubscription;
    private _videoList: Array<any>;
    private files: UploadFile[];
    controlVie: ControlDescriptor;
    controlVIesDisabled: boolean;
    controlViesCsvDisabled: boolean;
    tooltipViesDisabled: string;
    controlResults = new Map();

    constructor(private _notificationService: NotificationService, private _calculatedValueService: CalculatedValueService,
                private _router: Router, private _route: ActivatedRoute, private _userSubscriptionservice: TvaSubscriptionService,
                private _videoService: VideoService, private _fileService: FileService, private _viesService: ViesService,
                private _fileSaverService: FileSaverService, private _fileUtils: FileUtilsService, private _habilitationService: HabilitationService) {
        super(BackgroungType.BG_SPACE);
    }

    ngOnInit() {
        super.setContextWithRoute(this._route, (params) => {
            this.originPage = OriginPage.LISTCONTROL;
        });
        super.subscribeToNotificationControl({
            notificationService: this._notificationService
        });
        this.getUserSubscription();
        this.getVideoList();
        this.findControl();
        this.getControlForDashboard();
        this._findFiles();
        this._notificationService.fileNotificationObservable$
            .pipe(takeUntil(this.destroy$))
            .subscribe((msg) => {
                const fileName = moment().format("YYYYMMDD_HHMMSS") + "_vies.xlsx";
                this._fileSaverService.save(this._fileUtils.blobPdfFromBase64String(msg.blob), fileName);
                this._complianceLoaderService.sendLoaderHide();
            });
        this.controlViesCsvDisabled = !this._habilitationService.hasAccessControlVie();
    }

    protected manageDataEvent(notification: NotificationMessage) {
    }

    async launchControl(control: ControlDescriptor, stepByStep: boolean) {
        if((stepByStep && this.isControlActionDisabled(control.id, 'executionStepByStep'))
            || (!stepByStep && this.isControlActionDisabled(control.id, 'executionAutomatic'))) {
            this._complianceLoaderService.sendLoaderShow();
            this.controlSelected = control;
            super.updateContext({
                stateMachineId: this.controlSelected.stateMachineId,
                controlId: this.controlSelected.id,
                stepByStep: stepByStep
            });
            await this._controlService.launchExecutionControl(this.projectId, this.exerciceId, control.stateMachineId, stepByStep);
        }
    }

    async getUserSubscription() {
        this.userSubscription = await this._userSubscriptionservice.getUserSubscription();
    }

    async getVideoList() {
        this._videoList = await this._videoService.getVideoList();
    }

    isControlActionDisabled(controlId, action) {
        if (this.userSubscription && this.userSubscription.controlsMetadatas) {
            return this.userSubscription.controlsMetadatas.find((data) => data.controlId === controlId)[action];
        }
        return false;
    }

    async findControl() {
        this.controls = await this._controlService.findControlByDomainAndCategory(this.domain, this.category);
        this._complianceLoaderService.sendLoaderHide();
        this.controlVie = await this._controlService.findControlById(CONTROL_FILE_VIE_ID);
    }

    async getControlForDashboard() {
        let controlForDashboards = await this._controlService.findControlForDashboard(this.projectId, this.exerciceId);
        controlForDashboards.forEach((control) => {
            if (control.status && control.status !== StatusControl.NONE) {
                this.controlResults.set(control.id, control);
            }
        });
    }

    private async _findFiles() {
        this.files = await this._fileService.findFilesByType(this.projectId, this.exerciceId, FileType.DEB_EXPEDITION);
        this.isControlViesDisabled();
    }

    showPresentation(control: ControlDescriptor) {
        this.navigate(control, ControlRoutesFlux.presentation);
    }

    goToVisu(control: ControlDescriptor) {
        let link = this._notificationService.generateControlRoute(this.projectId, control.domaine as DomaineControl, control.categorie as CategoryControl, control.id, ControlRoutesFlux["compte-rendu-general"], this.exerciceId);
        this._router.navigate([link], {queryParams: {view: 'visualisation', from:'controlList'}});
    }

    hasVideo(controlId: string) {
        return this._videoList && this._videoList.find((video) => video.code === controlId || `${video.code}_tva` === controlId);
    }

    showVideo(controlId: string) {
        const video = this.hasVideo(controlId);
        this._router.navigate(['/compliance', this.domain, this.category, 'video', video.code]);
    }

    private navigate(control: ControlDescriptor, routesFlux: ControlRoutesFlux) {
        this._router.navigate([this._notificationService.generateControlRoute(this.projectId, this.domain, this.category, control.id, routesFlux, this.exerciceId)]);
    }

    goBack() {
        this._router.navigate(['/compliance', this.domain, this.category, 'project', this.projectId, this.exerciceId, 'dashboard']);
    }

    showPresentationVies() {
        let control = new ControlDescriptor();
        control.id = CONTROL_FILE_VIE_ID;
        this.navigate(control, ControlRoutesFlux.presentation);
    }

    launchViesControl() {
        if(!this.controlVIesDisabled) {
            this._complianceLoaderService.sendLoaderShow();
            this._viesService.export(this.projectId, this.exerciceId, this.files.map(value => value.id))
        }
    }

    launchViesCsvControl() {
        if(!this.controlViesCsvDisabled) {
            this._router.navigate([this._notificationService.generateControlRoute(this.projectId, this.domain, this.category, null, 'uploadViesCsv', this.exerciceId)]);
        }
    }

    isControlViesDisabled() {
        if(!this._habilitationService.hasAccessControlVie()){
            this.tooltipViesDisabled = null;
        }else if (!this.files || !this.files.length){
            this.tooltipViesDisabled = super.getValue("control.vies.nofFileTooltipText")
        }
        this.controlVIesDisabled = !this.files || !this.files.length || !this._habilitationService.hasAccessControlVie();
    }
}
