export const choixTva = {
    title:"Sélectionner le ou les taux de TVA <br>collectée utilisés par l'entité juridique",
    titleOne:"Sélectionner le taux de TVA <br>collectée utilisés par l'entité juridique",
    other:'Autre',
    buttons:{
        addNew:"Ajouter un taux"
    },
    errors: {
        existRate: "Ce taux existe déjà.",
        multipleModal:{
            header:`Attention`,
            content:`<div class="text-size-13">Pour la réalisation de ce contrôle, un seul et unique taux de TVA collectée doit être appliqué.<br> Si votre entité juridique réalise des opérations multi-taux de TVA, vous ne pourrez pas effectuer ce contrôle.</div>`
        }
    }

};
