import {BlocText} from "../text/bloc-text";
import {Expression} from "../meta/meta-expression";

export const PIE_OPTION: any = {
    responsive: true,
    plugins: {
        // Change options for ALL labels of THIS CHART
        datalabels: {
            display: false
        }
    },
    title: {
        fontColor: '#ffffff',
        display: false
    },
    elements: {
        arc: {
            borderWidth: 1,
            borderColor: '#fff'
        }
    },
    tooltips: {
        enabled: false
    },
    legend: false,
};

export enum TypeGraphe {
    pie = 'pie',
    horizontalBar = 'horizontalBar',
    bar = 'bar'
}

export class Dataset {
    display: Expression;
    color: string[];
    values: any[];
    currency: boolean;
}

export class GraphOptions {
    displayDatasetValues?: boolean;
    displayLegend?: boolean;
    displayStepX?: boolean;
    displayStepY?: boolean;
    stacked?: boolean;
}

export class Element {
    categories?: BlocText[];
    datasets: Dataset[];
}

export interface Legend {
    color: string;
    display?: Expression;
    blocText: BlocText;
}

export class Graphe {
    showGraph?: boolean;
    display?:Expression;
    titre: string;
    typeGraphe: TypeGraphe;
    legends?: Legend[];
    element: Element;
    options?: GraphOptions;

}

export interface GrapheContainer {
    titre: string;
    graphes: Graphe[];
}
