<div id="content" class="page-check page-control-list">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="block-group-title">
                    <h2 class="title">{{'control.list.title' | translate}}</h2>
                </div>
                <div class="content">
                    <div class="control-subtitle">{{'control.list.bloc.tva' | translate}}</div>
                    <ng-container  *ngFor="let control of controls">
                        <div *ngIf="!isControlActionDisabled(control.id, 'hide')">
                            <ul class="list">
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="btn-camera">
                                            <button class="btn btn-heroic-secondary"
                                                    pTooltip="{{'buttons.camera' | translate}}" tooltipPosition="bottom"
                                                    [disabled]="!hasVideo(control.id)"
                                                    (click)="showVideo(control.id)">
                                                <i class="fal fa-film-alt text-white text-size-18"></i>
                                            </button>
                                        </div>

                                        <p class="flex-fill col-12">
                                            <span [innerHtml]="control.nom | safe:'html'"></span><br/>
                                            <span class="text-primary mt-3 text-size-12 d-inline-block" *ngIf="control.sousTitre" [innerHtml]="control.sousTitre | safe:'html'"></span>
                                            <span class="last-update font-italic" *ngIf="controlResults.has(control.id)">
                                                {{'dashboard.tvaDashboard.controlData.lastUpdate' | translate}}
                                                {{controlResults.get(control.id).lastUpdate | date:DATE_FORMAT['DD/MM/YYYY à HH:mm'].datePipe}}
                                            </span>
                                        </p>

                                        <div class="d-flex justify-content-center actions">
                                            <compliance-light-subscription-button class="btn btn-heroic-secondary btn-circle btn-xl mx-2"
                                                    pTooltip="{{'buttons.documentation' | translate}}" tooltipPosition="bottom"
                                                    [disabled]="!isControlActionDisabled(control.id, 'displayPresentation')"
                                                    (click)="showPresentation(control)">
                                                <i class="fal fa-books text-white text-size-18"></i>
                                            </compliance-light-subscription-button>
                                            <button class="btn btn-heroic-secondary btn-circle btn-xl mx-2 show-control"
                                                    pTooltip="{{'dashboard.tvaDashboard.actions.show' | translate}}" tooltipPosition="bottom"
                                                    [disabled]="!controlResults.has(control.id)"
                                                    (click)="goToVisu(control)">
                                                <i class="far fa-eye text-white text-size-18"></i>
                                            </button>
                                            <compliance-light-subscription-button class="btn btn-heroic-secondary btn-circle btn-xl mx-2 step-by-step"
                                                    pTooltip="{{'buttons.launchControl' | translate}}" tooltipPosition="bottom"
                                                    [disabled]="!isControlActionDisabled(control.id, 'executionStepByStep')"
                                                    (click)="launchControl(control, true)">
                                                <img class="m-auto" src="assets/images/skin/dashboard/stepByStep.svg" />
                                            </compliance-light-subscription-button>
                                            <compliance-light-subscription-button class="btn btn-primary btn-circle btn-xl mx-2"
                                                    pTooltip="{{'buttons.launchControl' | translate}}" tooltipPosition="bottom"
                                                    [disabled]="!isControlActionDisabled(control.id, 'executionAutomatic')"
                                                    (click)="launchControl(control, false)">
                                                <i class="fas fa-play text-white text-size-18"></i>
                                            </compliance-light-subscription-button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </ng-container>

                    <div class="mt-4 pt-3 border-top control-vies">

                        <div class="control-subtitle">{{'control.list.bloc.vies' | translate}}</div>
                        <ul class="list">
                            <li >
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="btn-camera">
                                        <button class="btn btn-heroic-secondary"
                                                [disabled]="!hasVideo('control_file_vies')"
                                                (click)="showVideo('control_file_vies')">
                                            <i class="fal fa-film-alt text-white text-size-18"></i>
                                        </button>
                                    </div>
                                    <p class="flex-fill col-12">
                                        <span >{{controlVie?.nom}}</span>
                                    </p>

                                    <div class="d-flex justify-content-center actions">
                                        <compliance-light-subscription-button class="btn btn-heroic-secondary btn-circle btn-xl mx-2"
                                                                              pTooltip="{{'buttons.documentation' | translate}}" tooltipPosition="bottom" (click)="showPresentationVies()">
                                            <i class="fal fa-books text-white text-size-18"></i>
                                        </compliance-light-subscription-button>
                                        <compliance-light-subscription-button class="btn btn-heroic-secondary btn-circle btn-xl mx-2"
                                                pTooltip="{{'buttons.vies.launchCsv' | translate}}" tooltipPosition="bottom"
                                                [tooltipDisabledText]="tooltipViesDisabled"
                                                [disabled]="controlViesCsvDisabled" (click)="launchViesCsvControl()">
                                            <i class="fal fa-file-csv text-white text-size-18"></i>
                                        </compliance-light-subscription-button>
                                        <compliance-light-subscription-button class="btn btn-primary btn-circle btn-xl mx-2"
                                                pTooltip="{{'buttons.vies.launch' | translate}}" tooltipPosition="bottom"
                                                [tooltipDisabledText]="tooltipViesDisabled"
                                                [disabled]="controlVIesDisabled" (click)="launchViesControl()">
                                            <i class="fal fa-file-alt text-white text-size-18"></i>
                                        </compliance-light-subscription-button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="block-bottom-actions">
                    <div class="inner">
                        <a href="#" class="btn btn-primary btn-back" (click)="goBack()"><span>{{'buttons.back' | translate}}</span></a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
