<div id="content" class="page-focus" *ngIf="control">
    <div class="container">
        <div class="row">

            <div class="col-xl-12">
                <div class="block-group-title">
                    <h2 class="title">{{'control.focus.title' | translate}}</h2>
                    <p class="sub-title">{{control.nom}}</p>
                </div>
            </div>


            <div class="col-xl-6">
                <div class="block-text-content content-twins">
                    <div class="head">
                        <h3 class="title">{{'control.focus.docTitle' | translate}}</h3>
                    </div>
                    <div class="core">
                        <div class="row">
                            <div class="picture col-xl-2">
                                <img src="./assets/images/data/logo-1.png" alt="">
                            </div>
                            <div class="col-xl-10">
                                <div class="content" *ngFor="let lien of control.lienDocumentaire">
                                    <p>{{lien.text}} <br><a [attr.href]="lien.lien"
                                                            target="_blank">{{lien.textLien}}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="block-text-content content-twins">
                    <div class="head">
                        <h3 class="title">{{'control.focus.formationTitle' | translate}}</h3>
                    </div>
                    <div class="core">
                        <div class="row">
                            <div class="picture col-xl-2">
                                <img src="./assets/images/data/logo-2.png" height="74" width="74" alt="">
                            </div>
                            <div class="col-xl-10">
                                <div class="content" *ngFor="let formation of control.lienFormation">
                                    <p>{{formation.text}} <br><a [attr.href]="formation.lien" target="_blank">{{formation.textLien}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="block-text-content">
                    <div class="head">
                        <h3 class="title">{{'control.focus.blocFocusTitle' | translate}}</h3>
                    </div>
                    <div class="core">
                        <div *ngFor="let focus of control.focus" [innerHtml]="focus | safe:'html'"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="block-bottom-actions">
                    <div class="inner">
                        <button href="#" class="btn btn-primary btn-back" (click)="goBackPresentation()"><span>{{'buttons.returnPresentation' | translate}}</span></button>
                        <div class="btn-next">

                            <compliance-light-subscription-button class="btn btn-primary"
                                                                  pTooltip="{{'buttons.launchControl' | translate}}" tooltipPosition="bottom"
                                                                  [disabled]="launchDisabled" [tooltipDisabledText]="tooltipViesDisabled"
                                                                  (click)="launchControl()">
                                <span>{{'buttons.beginControl' | translate}} </span>
                            </compliance-light-subscription-button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
