export enum ControlRoutesFlux {
    presentation = 'presentation',
    video = 'video',
    list = 'list',
    affectation = 'affectation',
    question = 'question',
    focus = 'focus',
    "choix-tva" = 'choix-tva',
    'compte-rendu-detaille' = 'compte-rendu-detaille-',
    'compte-rendu-general' = 'compte-rendu-general',
    'supervision' = 'supervision',
    'code-journaux' = 'appareillage-codes-journaux',
    balance = 'balance',
}
