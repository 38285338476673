export const project = {
    list: {
        title: "Mes Dossiers",
        // useFile: "{{used}} dossiers existants sur {{max}} dossiers autorisés",
        useFile: {
            used:{
                oneOrLess: "{{used}} dossier existant ",
                many:"{{used}} dossiers existants "
            },
            max:{
                oneOrLess: "sur {{max}} dossier autorisé",
                many:"sur {{max}} dossiers autorisés"
            }
        },
        create: "Créer un dossier",
        search: {
            button: "Rechercher",
            placeholder: "Rechercher un dossier"
        }
    },
    create: {
        title: "Création d’un nouveau dossier",
        subtitle: "Vous pouvez créer un nouveau dossier de deux façons :",
        form: {
            manual: '<span>Soit manuellement,</span> <br>en saisissant un des numéros suivants :',
            auto: '<span>Soit AUTOMATIQUEMENT, </span><br>en déposant un fichier contenant le SIREN tel une CA3 ou une DEB.',
            result: {
                none: "commencez en choisissant <br>une des deux options à gauche",
                noSiren: `<i class="fal fa-exclamation-triangle fa-3x mb-3"></i><p class="mb-3">Une erreur a eu lieu. Nous n'avons pas identifié le siren du fichier déposé.</p><p>Veuillez le renseigner manuellement dans le champ de saisie prévu à cet effet.</p>`,
                invalidSiren: `<i class="fal fa-exclamation-triangle fa-3x mb-3"></i><p class="mb-3">Le siren identifié dans le fichier déposé n'est pas valide ({{siren}}).</p><p>Veuillez renseigner un numéro de siren valide.</p>`,
                name: "Nom de l'entité juridique (requis)",
                address: "Adresse :",
                formeJuridique: "Forme juridique :",
                activite: "Activité :",
                category: "Catégorie :",
                siren: "SIREN :",
            }
        },
        buttons: {
            create: "Créer le dossier",
            open: "Ouvrir le dossier existant"
        }
    },
    dialogDelete: {
        title: "Confirmation de la suppression du dossier",
        text: "Attention, cette action entraîne la suppression des fichiers et des données liés au dossier. Etes-vous sûr de vouloir supprimer définitivement le dossier "
    },
    dialogNoDelete: {
        title: "Attention",
        // text: "Vous ne pouvez pas supprimer de dossier. Seul votre administrateur a la possibilité de le faire.",
        text: "Vous n'avez pas l'autorisation de supprimer les dossiers. <br>Veuillez vous rapprocher de votre administrateur de compte pour effectuer cette action.",
        accept: "Fermer"
    },
    alerteProjectNumberMax:{
        title: "Information",
        text: "Vous avez atteint la limite de dossiers prévue par votre contrat. Nous sommes heureux de voir que notre solution vous plait. Un de nos conseillers vous contactera afin de vous proposer une formule adaptée à vos besoins."
    }
};
