<div class="container-fluid height-100">
    <div class="row p-0 height-100">
        <div class="col p-0 d-flex flex-row">
            <div class="col p-0 m-auto">
                <div class="px-4 d-flex flex-column justify-content-center align-items-center">
                    <i class="far fa-check-circle text-size-32 mb-2"
                       *ngIf="balanceData?.fecExist"></i>
                    <span class="font-weight-bold text-size-14">{{balanceTexts?.fecFile | translate}}</span>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-around actions">
                <button class="p-0" *ngIf="false">
                    <i class="far fa-eye text-size-20"></i>
                </button>
                <compliance-light-subscription-button *ngIf="!exercice.validation"
                                                      class="p-0"
                                                      pTooltip="{{'dashboard.fecManagement.accessFiles' | translate}}" tooltipPosition="bottom"
                                                      [disabled]="disabled"
                                                      (click)="goToGestionFile()">
                    <i class="fas fa-file-plus text-size-20"></i>
                </compliance-light-subscription-button>
            </div>
            <div class="col p-0 h-100 d-flex justify-content-center align-items-center">
                <div class="balance-data col-8 p-0 d-flex flex-column h-100"
                     [ngClass]="{'not-balanced': !balanceData?.balanceResult?.equilibre, 'not-calculated': !balanceData?.balanceResult?.result}"
                     *ngIf="balanceData?.fecExist" (click)="launchBalance()">
                    <div class="result d-flex flex-column justify-content-center align-items-center px-3 py-2">
                        <span class="text-size-12">{{'dashboard.fecManagement.result' | translate}}</span>
                        <span class="font-weight-bold text-size-14" [innerHTML]="balanceTexts?.result">
                        </span>
                    </div>
                    <div class="is-balanced col d-flex justify-content-center align-items-center text-center p-2">
                        <span class="font-weight-bold text-size-11">{{balanceTexts?.isBalanced | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
