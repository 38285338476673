export const menu_FR_fr = {
    logout: 'Déconnexion',
    compliance: "Ma compliance",
    upload: "Déposez vos documents",
    displayCalculatedValue: "Voir les valeurs calculées",
    displayFecFiles: "Voir les FEC déposés",
    config: "Configuration",
    users: "Gestion des utilisateurs",
    myAccount: "Mon compte",
    dossier: "Mes dossiers",
    scripts: "Script",
    cache: "Gestion du cache",
    calculatedRule: "Gestion des règles de calcul",
    ComplianceRule: "Gestion des règles de compliance",
    formEdiConfig: "Gestion des formulaires et EDI",
    cciCavConfig: "Gestion des CCI/CAV",
    help: "Besoin d'aide?",
    elearning: "e-learning",
    video: "Vidéos explicatives",
    exportAnalytics: "Export des données d'utilisation",
    fichierSig: "Import fichier SIG"
};
