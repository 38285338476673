import {Injectable} from '@angular/core';
import {CompteRenduDetaille, DataBlock, DispositionBlock, DispositionType} from "../../shared/compte-rendu-detaille";
import _ from 'lodash';
import {TableItem} from "../../../../../../shared/table/shared/table-item";

@Injectable()
export class CompteRenduDetaillUtilsService {

    private _CRDData :any;
    private _compteRenduDetaille:CompteRenduDetaille;

    constructor() {
    }


    getDispostionBlockStyle(dispositionBlock: DispositionBlock) {
        return {
            'flex-direction': dispositionBlock.disposition || DispositionType.ROW,
            'flex': `${dispositionBlock.ratio || 1}`
        }
    }

    isDataTableBlock(crdDataBlock: DataBlock) {
      return !_.isNil(crdDataBlock.tableBlock)
    }
    isDataGraphBlock(crdDataBlock: DataBlock) {
      return !_.isNil(crdDataBlock.graphe)
    }
    isDataTextBlock(crdDataBlock: DataBlock) {
      return !_.isNil(crdDataBlock.blocText)
    }
    isDataKeyNumberBlock(crdDataBlock: DataBlock) {
      return !_.isNil(crdDataBlock.keyNumberBlock)
    }
    isDataExportBlock(crdDataBlock: DataBlock) {
      return !_.isNil(crdDataBlock.exportBlock)
    }

    findTableItems(tableField:string):TableItem[]{
        return this._compteRenduDetaille.describer.tableItems[tableField.replace(/[.]/, '_')];
    }

    get CRDData(): any {
        return this._CRDData;
    }

    set CRDData(value: any) {
        this._CRDData = value;
    }


    get compteRenduDetaille(): CompteRenduDetaille {
        return this._compteRenduDetaille;
    }

    set compteRenduDetaille(value: CompteRenduDetaille) {
        this._compteRenduDetaille = value;
    }
}
