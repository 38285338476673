import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";
import {FECInfos} from "../../class/globalParams/fecInfos";

@Injectable({
    providedIn: 'root'
})
export class GlobalParamsService extends ComplianceApiService {

    constructor(private _restService: RestService) {
        super('exerciceParameter', 'api');
    }

    getGlobalParamsData(projectId:String, exerciceId:String): Promise<any> {
        return this._restService.get({
            url: this.baseUri,
            parameters:[projectId, exerciceId],
            arg: null as FECInfos
        });
    }
}
