import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CategoryControl, DomaineControl} from "../../../../../../common/class/control/control-descriptor";
import {VideoService} from "../../../../../../common/service/video/video.service";
import {Location} from "@angular/common";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'compliance-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
    private _domain: DomaineControl;
    private _category: CategoryControl;
    videoId: string;
    video: any;

    private _videoList: Array<any>;

    constructor(private _videoService: VideoService, private _sanitizer: DomSanitizer,
                private _route:ActivatedRoute, private _location: Location) {
    }

    ngOnInit() {
        this._domain = this._route.snapshot.paramMap.get('domain') as DomaineControl;
        this._category = this._route.snapshot.paramMap.get('category') as CategoryControl;
        this.videoId = this._route.snapshot.paramMap.get('videoId');
        this.getData();
    }

    async getData(){
        this._videoList = await this._videoService.getVideoList();
        this.video = this._videoList.find(video => video.code === this.videoId);
        if (this.video && this.video.url) {
            this.video.url = this._sanitizer.bypassSecurityTrustResourceUrl(this.video.url);
        }
    }

    goBack() {
        this._location.back();
    }
}
