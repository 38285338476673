import {Injectable} from '@angular/core';
import {UploadActionService} from "../upload-action.service";
import {UploadInfo} from "../../../../../../../../common/class/upload/upload-info";
import {Observable} from "rxjs";
import {UploadComplianceFileDirectService} from "./upload-compliance-file-direct.service";
import {UploadComplianceFileResumableService} from "./upload-compliance-file-resumable.service";

@Injectable({
  providedIn: 'root'
})
export class UploadComplianceFileService extends UploadActionService {
    constructor(private uploadComplianceFileDirectService: UploadComplianceFileDirectService, private uploadComplianceFileResumableService: UploadComplianceFileResumableService) {
        super('upload');
    }


    upload(uploadInfo: UploadInfo, anneeExercice: any, projectName: string, projectCode: string, authorizedFilesControl: string[]): Observable<any> {
        if(uploadInfo.isResumable){
            return this.uploadComplianceFileResumableService.upload(uploadInfo, anneeExercice, projectName, projectCode, authorizedFilesControl);
        }else {

            return this.uploadComplianceFileDirectService.upload(uploadInfo, anneeExercice, projectName, projectCode, authorizedFilesControl);
        }
    }
}
