import {Component, OnInit, ViewChild} from '@angular/core';
import {BackgroungType} from "../../../../../../../common/class/background/bg-compliance";
import {ActivatedRoute, Router} from "@angular/router";
import {DossierService} from "../../../../../../../common/service/dossier/dossier.service";
import {Dossier} from "../../../../../../../common/class/dossier/dossier";
import {Exercice} from "../../../../../../../common/class/exercice/exercice";
import {ExerciceService} from "../../../../../../../common/service/exercice/exercice.service";
import _ from 'lodash';
import moment from 'moment';
import {ConfirmationService} from "primeng/api";
import {DATE_FORMAT, FileType} from "../../../../../../../common/class/compliance-constant";
import {ControlService} from "../../../../../../../common/service/control/control.service";
import {CategoryControl, ControlForDashboard, DomaineControl} from "../../../../../../../common/class/control/control-descriptor";
import {UIChart} from "primeng/chart";
import {VISA_TYPE} from "../../../../../../../common/class/control/supervision";
import {ComplianceLoaderService} from "../../../../../../../common/service/loader/compliance-loader.service";
import {ControlMessageHandler, OriginPage} from "../../../../../../../common/component/control/control-message-handler";
import {NotificationService} from "../../../../../../../common/service/push/notification.service";
import {TvaSubscriptionService} from "../../../../../../../common/service/subscriptions/tva-subscription.service";
import {FlecCommonSubscription, FlecTVASubscription} from "../../../../../../../common/class/subscription/subscription";
import {CommonSubscriptionService} from "../../../../../../../common/service/subscriptions/common-subscription.service";

interface DataSet {
    backgroundColor: string[];
    data: any[];

    [properties: string]: any;
}

@Component({
    selector: 'compliance-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends ControlMessageHandler implements OnInit {

    domain: DomaineControl;
    category: CategoryControl;
    private _projectId: string;
    project: Dossier;
    private _exerciceId: string;
    exercices: Exercice[];
    public index;
    currentExercice: Exercice;
    loading = this._complianceLoaderService.$loader;
    commonSubscription: FlecCommonSubscription;
    tvaSubscription: FlecTVASubscription;

    @ViewChild('chartPie') pChartPie: UIChart;
    @ViewChild('chartRadar') pChartRadar: UIChart;
    private _controlList: ControlForDashboard[];
    controlListFiltered: ControlForDashboard[];
    private cas0 = true;

    constructor(private _route: ActivatedRoute, private _router: Router, private _dossierService: DossierService,
                private _exerciceService: ExerciceService, private _confirmationService: ConfirmationService,
                private _controlService: ControlService, private _complianceLoaderService: ComplianceLoaderService,
                private _notificationService: NotificationService,
                private _commonSubscriptionservice: CommonSubscriptionService, private _tvaSubscriptionservice: TvaSubscriptionService) {
        super();
        // Replace "extend BgDashboardComponent"
        document.body.className = BackgroungType.BG_DASHBOARD.toString();

        this.domain = this._route.snapshot.paramMap.get('domain') as DomaineControl;
        this.category = this._route.snapshot.paramMap.get('category') as CategoryControl;
        this._route.params.subscribe((routeParam: { projectId: string, exerciceId: string }) => {
            if (this._projectId === routeParam.projectId) {
                this._exerciceId = routeParam.exerciceId;
                this.getExerciceDatas();
            } else {
                this._projectId = routeParam.projectId;
                this._exerciceId = routeParam.exerciceId;
                this.getDashboardDatas();
            }
        });

        super.setMessageContext({
            projectId: this._projectId,
            domain: this.domain,
            category: this.category,
            stateMachineId: null,
            exerciceId: this._exerciceId,
            messageParams: {
                originPage: OriginPage.DASHBOARD
            }
        });
        super.subscribeToNotificationControl({
            notificationService: this._notificationService,
        });
    }

    ngOnInit() {
    }

    async getUserSubscriptionAndFilterControl() {
        this.commonSubscription = await this._commonSubscriptionservice.getUserSubscription();
        this.tvaSubscription = await this._tvaSubscriptionservice.getUserSubscription();
        this._filterControlList();
    }

    private _filterControlList() {
        if (this.tvaSubscription && this.tvaSubscription.controlsMetadatas && this._controlList) {
            this.controlListFiltered = this._controlList.filter(control => {
                let controlSubscription = this.tvaSubscription.controlsMetadatas.find(meta => meta.controlId === control.id);
                return controlSubscription.controlActivated && !controlSubscription['hide']
            });
        } else {
            this.controlListFiltered = this._controlList;
        }
        console.log("list filtered", this.controlListFiltered);
        console.log("list filtered", this.controlListFiltered);
        this.cas0 = !this.controlListFiltered.find((control: ControlForDashboard) => control.amountRisk !== null);
    }

    get isFecUsable() {
        if (this.tvaSubscription && this.tvaSubscription.authorizedFiles) {
            return this.tvaSubscription.authorizedFiles.find((file) => file === FileType.FEC);
        }
        return false;
    }

    async getDashboardDatas() {
        this.project = await this._dossierService.findById(this._projectId);
        await this.getExercices();
        await this.getExerciceDatas();
    }

    private async getExerciceDatas() {
        this._complianceLoaderService.sendLoaderShow();
        this._controlList = await this._controlService.findControlForDashboard(this._projectId, this._exerciceId);

        this._controlList.forEach((control) => {
            if (control.period) {
                const format = DATE_FORMAT[control.period.format].moment;
                control.period.dateFin = moment(control.period.dateFin, format).toDate();
                control.period.dateDebut = moment(control.period.dateDebut, format).toDate();
            }
            control.visa = VISA_TYPE.find((visaType) => visaType.value === control.visa);
        });
        this.getUserSubscriptionAndFilterControl();
        this._complianceLoaderService.sendLoaderHide();
    }

    private async getExercices() {
        this.exercices = _.sortBy(await this._exerciceService.findExercicesByProject(this._projectId), (ex: Exercice) => ex.annee);
        _.each(this.exercices, (exercice: Exercice) => {
            exercice.dateFin = moment(exercice.dateFinExercice, DATE_FORMAT.YYYYMMDD.moment).toDate();
            exercice.dateDebut = moment(exercice.dateDebutExercice, DATE_FORMAT.YYYYMMDD.moment).toDate();
        });
        if (!this.exercices.length) {
            this.gotToCreateExercice(true);
        } else {
            this.index = this.exercices.length - 1;
            if (this._exerciceId) {
                let index = _.findIndex(this.exercices, (ex: Exercice) => ex.id === this._exerciceId);
                this.index = index >= 0 ? index : this.index;
            }
            this.setCurrentExercice();
        }
    }

    gotToCreateExercice(redirect: boolean = false) {
        if (redirect) {
            this._router.navigate(
                ['/compliance', this.domain, this.category, 'project', this._projectId, 'exercice', 'create'],
                {queryParams: {redirect: true}}
            );
        } else {
            this._router.navigate(['/compliance', this.domain, this.category, 'project', this._projectId, 'exercice', 'create']);
        }
    }

    goToProject(): void {
        this._router.navigate(['/compliance', 'control', this.domain, this.category, this._projectId, this.currentExercice.id, 'list']);
    }

    goToGestionFile() {
        this._router.navigate(
            ['/compliance', this.domain, this.category, 'project', this._projectId, this._exerciceId, 'file-list']);
    }

    goToGlobalParams() {
        if (this.commonSubscription.parametersActivated) {
            this._router.navigate(
                ['/compliance', this.domain, this.category, 'project', this._projectId, this._exerciceId, 'globalParams', 'graph']);
        }
    }

    goBack() {
        this._router.navigate(['/compliance', this.domain, this.category, 'project', 'list']);
    }

    setCurrentExercice() {
        this.currentExercice = this.exercices[this.index];
        this._router.navigate(['/compliance', this.domain, this.category, 'project', this._projectId, this.currentExercice.id, 'dashboard'], {replaceUrl: true});
    }

    setExercice(exercice: Exercice) {
        if (!exercice) {
            this.getExercices();
        } else {
            this.currentExercice = exercice;
            super.updateContext({exerciceId: exercice.id});
            this._router.navigate(['/compliance', this.domain, this.category, 'project', this._projectId, exercice.id, 'dashboard'], {replaceUrl: true});
        }
    }

    launchStateMachine(data: { stateMachineId: string, stepByStep: boolean, controlId?: string }) {
        super.updateContext({...data});
        this._controlService.launchExecutionControl(this._projectId, this._exerciceId, data.stateMachineId, data.stepByStep);
    }
}
