import {Expression, MetaExpression} from "../../../../../../../../common/class/meta/meta-expression";
import {TableItem} from "../../../../../shared/table/shared/table-item";
import {FormInput} from "../../../../../../../../common/class/meta/meta-formulaire";

export const AFFECTATION_COMPTE = {
    nonAffecte:"nonAffecte",
    compteProduit: {
        produitSoumisTva : "produits_soumis_tva",
        produitNonSoumisTva : "produits_non_soumis_tva",
        produitSoumisEtNonSoumisTva : "produits_mixtes_tva",
    },
    NO_AFFECTATION_AUTO:"NO_AFFECTATION_AUTO"
};

export interface AffectationAutoAction{
    type:string;
    expression:Expression;
}
export interface AffectationAutoDescriber{
    text:string;
    formulaire:FormInput[];
    action?: AffectationAutoAction[];
    activate?: Expression;
}

export interface Calcul {
    expression:Expression;
    name:string;
}
export interface CalculatedData {
    [keys:string]:number | boolean;
}

export interface AffectationCompteData {

    [keys:string]:any;

    active?:boolean;
    menuActive?:boolean;
    calculatedData?:CalculatedData;
    affectAutoType?:string;
    headerStyle?:{[keys:string]:string};
}

interface LabelByNombre {
    one:string;
    many:string;
}

export interface LotDisplay{
    type:string;
    lotLabel?:LabelByNombre
    lotTitle?:LabelByNombre
    title?:LabelByNombre;
    subTitle?:LabelByNombre;
    order:number;
    color?:string;
}

export interface LotAffectation {
    display:LotDisplay;
    processDataPipe?:Calcul[];
    tableItems?:TableItem[];
    active?:boolean;
}


export interface AffectationDescriber {
    affectationAuto?: AffectationAutoDescriber;
    tableItems:TableItem[];
    processDataPipe?:Calcul[];
    nonAffecte:LotAffectation;
    lots:{[keys:string]:LotAffectation};
    display: LotDisplay;
}

export interface AffectationData {
    nonAffecte: AffectationCompteData[];
    lots: { [keys: string]: AffectationCompteData[] };
    formValues?:{ [keys: string]: any[] };
}




export interface AffectationWrapper{
    data: AffectationData;
    describer: AffectationDescriber;
    saveFields:string[];
    targetObjectName:string;
    action:MetaExpression<LotAffectation>;
}

export class Lot {
    lot: LotAffectation;
    data: AffectationCompteData[];
}

export class AffectationTitle {
    display: LotDisplay;
    data: AffectationCompteData[];
}
