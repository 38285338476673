import {Component, Input, OnInit} from '@angular/core';
import {ComplianceI18nLoader} from "../../../../../../../../../../common/class/i18n/compliance-i18n-loader";
import {CompteRenduDetaillUtilsService} from "../../shared/compte-rendu-detaill-utils.service";
import {ExportBlock} from "../../../shared/compte-rendu-detaille";
import _ from 'lodash';
import {Angular5Csv} from "angular5-csv/dist/Angular5-csv";
import {TableItem, TableItemType} from "../../../../../../../shared/table/shared/table-item";
import {ExportType, TYPE_EXPORT, TypeExport} from "../../../../../../../shared/export-file/export-file";
import {ActivatedRoute} from "@angular/router";
import {ComplianceDatePipe} from "../../../../../../../../../../common/component/pipe/compliance-date.pipe";
import {ExportFileControlService} from "../../../../../../../shared/export-file/export-file-control.service";
import {MetaExpressionService} from "../../../../../../../../../../common/service/meta/meta-expression.service";
import {ComplianceBinaryPipe} from "../../../../../../../../../../common/component/pipe/compliance-binary.pipe";
import {SESSION_TAB_ID} from "../../../../../../../../../../common/class/compliance-constant";
import {takeUntil} from "rxjs/operators";
import {NotificationService} from "../../../../../../../../../../common/service/push/notification.service";
import {FileUtilsService} from "../../../../../../../../../../common/service/file/file-utils.service";
import {Subject} from "rxjs";

@Component({
    selector: 'compliance-export-block',
    templateUrl: './export-block.component.html',
    styleUrls: ['./export-block.component.scss']
})
export class ExportBlockComponent extends ComplianceI18nLoader implements OnInit {
    private readonly sessionTabId: string;
    public exportLoading: boolean = false;

    @Input() exportBlock: ExportBlock;
    @Input() destroy$: Subject<boolean>;
    csvOptions: any = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalseparator: ',',
        showLabels: true
    };
    private projectId: string;
    private exerciceId: string;

    constructor(private compteRenduDetaillUtilsService: CompteRenduDetaillUtilsService, private exportFileControlService: ExportFileControlService,
                private route: ActivatedRoute, private complianceDatePipe: ComplianceDatePipe, private complianceBinaryPipe: ComplianceBinaryPipe,
                private expressionService: MetaExpressionService, private _notificationService: NotificationService, private _fileUtils: FileUtilsService) {
        super();
        this.sessionTabId = sessionStorage.getItem(SESSION_TAB_ID);
    }

    ngOnInit() {
        this.projectId = this.route.snapshot.paramMap.get("projectId");
        this.exerciceId = this.route.snapshot.paramMap.get("exerciceId");
        this._notificationService.fileNotificationObservable$
            .pipe(takeUntil(this.destroy$))
            .subscribe((msg) => {
                if (this.exportBlock && _.isEqual(this.exportBlock.code, msg.sourceId)) {
                    this._fileUtils.saveFile(this.exportBlock.fileName, this._fileUtils.blobPdfFromBase64String(msg.blob), new TypeExport(TYPE_EXPORT[this.exportBlock.type[0]]));
                    this.exportLoading = false;
                }
            });
    }

    export(type) {
        if (this.exportBlock.tableOption) {
            this.exportFromArray(this.exportBlock, _.get(this.compteRenduDetaillUtilsService.CRDData, this.exportBlock.tableOption.tableField), _.cloneDeep(this.csvOptions));
        } else if (this.exportBlock.code) {
            this.exportFromService(type, this.projectId, this.exerciceId, this.exportBlock);
        }
    }

    exportFromArray(exportBlock: ExportBlock, data: any[], csvOptions) {
        let headers = [];
        this.compteRenduDetaillUtilsService.findTableItems(exportBlock.tableOption.metaDataRef).forEach((tableItem: TableItem) => {
            console.log(tableItem);
            if (!tableItem.hide) {
                headers.push(super.getValue(this._stripHtml(tableItem.header)));
            }
        });
        let csvDatas = [];
        if(data) {
            data.forEach((val) => {
                let obj: any = {};
                this.compteRenduDetaillUtilsService.findTableItems(exportBlock.tableOption.metaDataRef).forEach((tableItem: TableItem) => {
                    if (!tableItem.hide) {
                        let value = val[tableItem.field];
                        switch (tableItem.type) {
                            case TableItemType.date:
                                value = this.complianceDatePipe.transform(value, tableItem.dateFormat.inFormat, tableItem.dateFormat.outFormat);
                                break;
                            case TableItemType.binary:
                                value = this.complianceBinaryPipe.transform(value, tableItem.formatObject.firstValue, tableItem.formatObject.secondValue);
                                break;
                        }
                        obj[tableItem.field] = value;
                    }
                });
                csvDatas.push(obj);
            });
        }

        if(exportBlock.tableOption.footer){
            let keys;
            if (csvDatas.length) {
                keys = Object.keys(csvDatas[0]);
            } else {
                keys = Object.keys(exportBlock.tableOption.footer);
            }
            csvDatas.push(this._computeFooter(exportBlock.tableOption.footer, keys));
        }
        csvOptions.headers = headers;
        new Angular5Csv(csvDatas, exportBlock.fileName, csvOptions);
    }

    exportFromService(type: ExportType, projectId: string, exerciceId: string, exportBlock: ExportBlock) {
        this.exportLoading = true;
        this.exportFileControlService.exportXlsxFile([projectId, exerciceId, exportBlock.code]);
    }

    private _stripHtml(html: string) {
        return html ? html.replace(/<[^>]+>/g, '') : html;
    }

    private _computeFooter(footer, footerKeys: any) {
        let footerComputed = {};
        _.forIn(footerKeys, key => {
            let val = _.get(this.compteRenduDetaillUtilsService.CRDData, footer[key], footer[key]);
            footerComputed[key] = val !== undefined ? val : "";
        });

        return footerComputed;
    }
}
