import {AfterViewInit, Component, OnInit} from "@angular/core";
import * as shape from 'd3-shape';
import {ActivatedRoute, Router} from "@angular/router";
import {GlobalParamsService} from "../../../../../../common/service/globalParams/globalParams.service";
import {ControlMessageHandler, OriginPage} from "../../../../../../common/component/control/control-message-handler";
import {ComplianceLoaderService} from "../../../../../../common/service/loader/compliance-loader.service";
import {CategoryControl, DomaineControl} from "../../../../../../common/class/control/control-descriptor";
import {ControlService} from "../../../../../../common/service/control/control.service";
import {NotificationService} from "../../../../../../common/service/push/notification.service";

@Component({
    selector: 'app-graph-d3',
    templateUrl: './graph-d3.component.html',
    styleUrls: ['./graph-d3.component.scss']
})
export class GraphD3Component extends ControlMessageHandler implements OnInit, AfterViewInit {
    private _pageInit = false;

    private _domain: DomaineControl;
    private _category: CategoryControl;
    private _projectId: string;
    private _exerciceId: string;

    layoutOption = {
        orientation: 'TB'
    };
    hierarchialGraph = {nodes: [], links: []};
    curve = shape.curveBundle.beta(0.75);
    treeDepth = 1;

    paramStatusArray = [
        {key: 'DONE', value: '#51e283'},
        {key: 'TO_REVIEW', value: '#ffc95f'},
        {key: 'TO_DO', value: '#ed1c24'},
        {key: 'NOT_FEASIBLE', value: '#707070'},
    ];
    paramStatusObject = this.paramStatusArray.reduce((obj, item) => {
        return {...obj, [item.key]: item.value};
    }, {});

    // curve = shape.curveLinear;

    constructor(private _route: ActivatedRoute, private _router: Router, private _globalParamsService: GlobalParamsService,
                private _controlService: ControlService, private _complianceLoaderService:ComplianceLoaderService,
                private _notificationService: NotificationService) {
        super();
        this._domain = this._route.snapshot.paramMap.get('domain') as DomaineControl;
        this._category = this._route.snapshot.paramMap.get('category') as CategoryControl;
        this._projectId = this._route.snapshot.paramMap.get('projectId');
        this._exerciceId = this._route.snapshot.paramMap.get('exerciceId');

        super.subscribeToNotificationControl({
            notificationService: this._notificationService
        });
    }

    public ngOnInit(): void {
        this.showGraph();
    }

    ngAfterViewInit(): void {
        this._pageInit = true;
        this.plantPlacement();
    }

    plantPlacement(): void {
        // Waiting to have data and init page.
        if (this._pageInit && this.hierarchialGraph.nodes.length > 0) {
            setTimeout(() => {
                let elem = document.querySelector('ngx-graph svg') as SVGElement,
                    divPlant = document.querySelector('.plant') as HTMLElement,
                    divSeed = document.querySelector('ngx-graph svg .nodes .nodePlant') as HTMLElement,
                    mousedown = false;

                divPlant.style.left = divSeed.getBoundingClientRect().left - 55 + 'px';

                // div event mousedown
                elem.addEventListener('mousedown', function (e) {
                    // mouse state set to true
                    mousedown = true;
                }, true);

                // div event mouseup
                elem.addEventListener('mouseup', function (e) {
                    // mouse state set to false
                    mousedown = false;
                }, true);

                // element mousemove to stop
                document.addEventListener('mousemove', function (e) {
                    // Is mouse pressed
                    if (mousedown) {
                        // Now we calculate the difference upwards
                        divPlant.style.left = divSeed.getBoundingClientRect().left - 55 + 'px';
                    }
                }, true);
            }, 0);
            this._complianceLoaderService.sendLoaderHide();
        }
    }

    showGraph() {
        this._globalParamsService.getGlobalParamsData(this._projectId, this._exerciceId).then(data => {
            this.hierarchialGraph.nodes = [{id: 'plant', label: ['']}, ...data];
            this.hierarchialGraph.links = [];
            data.forEach(node => {
                if (node.parentId && node.parentId.length > 0) {
                    node.parentId.forEach(parentId => {
                        this.hierarchialGraph.links.push({
                            source: parentId,
                            target: node.id,
                        });
                    });
                } else {
                    this.hierarchialGraph.links.push({
                        source: 'plant',
                        target: node.id,
                    });
                }
            });
            this.searchDepth();
            this.plantPlacement();
        });
    }

    searchDepth() {
        let treeDepth = [];
        treeDepth[0] = ['plant'];
        do {
            let depth = treeDepth.length;
            let newDepth = this.hierarchialGraph.links.filter(link => treeDepth[(depth - 1)].find(node => node === link.source)).map(link => link.target);
            treeDepth[depth] = newDepth.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
        } while (treeDepth[(treeDepth.length - 1)].length !== 0);
        this.treeDepth = treeDepth.length - 1;
    }

    goBack() {
        this._router.navigate(['/compliance', this._domain, this._category, 'project', this._projectId, this._exerciceId, 'dashboard']);
    }

    goToProject(): void {
        this._router.navigate(['/compliance', 'control', this._domain, this._category, this._projectId, this._exerciceId, 'list']);
    }

    selectParam(node) {
        if (node.status === 'NOT_FEASIBLE') {
            return;
        }
        this._complianceLoaderService.sendLoaderShow();
        super.setMessageContext({
            projectId: this._projectId,
            domain: this._domain,
            category: this._category,
            stateMachineId: node.stateMachineId,
            exerciceId: this._exerciceId,
            messageParams: {
                originPage: OriginPage.GLOBALPARAMS,
                stepByStep: true
            }
        });
        this._controlService.launchExecutionControl(this._projectId, this._exerciceId, node.stateMachineId);
    }

    edgeClass(link) {
        return this.hierarchialGraph.nodes.find(node => node.id === link.target).status;
    }
}
