

export enum ExportType{
    xlsx = "xlsx",
    pdf = "pdf",
    docx = "docx",
    csv = "csv"
}
let exports:{[key:string]:Type} = {};

exports[ExportType.xlsx] = {
    realtiveUri:"exportXlsx",
    extention:'xlsx',
    class:'excel'
};
exports[ExportType.pdf] = {
    realtiveUri:"exportPdf",
    extention:'pdf',
    class:'pdf'
};

exports[ExportType.docx] = {
    realtiveUri:"exportDocx",
    extention:'docx',
    class:'word'
};

exports[ExportType.csv] = {
    realtiveUri:"exportCsv",
    extention:'csv',
    class:'csv'
};

export const TYPE_EXPORT:{[key:string]:Type} = exports;

export class Type{
    realtiveUri:string;
    extention:string;
    class:string;
}

export class TypeExport {
    type:Type;

    constructor(type) {
        this.type = type;

    }

}

export class Export {
    export: {[key:string]:{
            export:(parameters:string[]) => Promise<Blob>
        }};
}

export interface ExportFile {

    exportFile(type:TypeExport, fileName:string, parameters:string[]);
}
