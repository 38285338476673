<ng-container>
    <p class="paragraphe" [innerHTML]="'gestionCookie.cookieObligatoire' | translate | safe:'html'"></p>
    <div class="paragraphe card-body shadow ">
        <form action="#" class="form-basic form-cookie " [formGroup]="cookieFormGroup">
            <div class="form-group form-switch d-flex align-items-center mb-0 justify-content-between" >
                <p class="paragraphe text-center" [innerHTML]="'gestionCookie.personnalPub' | translate | safe:'html'"></p>
                <label class="switch" for="cookiePubPerso">
                    <input type="checkbox" id="cookiePubPerso" formControlName="cookiePubPerso" >
                    <span class="slider round"></span>
                </label>
            </div>
            <div class="form-group form-switch d-flex align-items-center justify-content-between">
                <p class="paragraphe text-center" [innerHTML]="'gestionCookie.statistic' | translate | safe:'html'"></p>
                <label class="switch" for="cookieStat">
                    <input type="checkbox" id="cookieStat" formControlName="cookieStat" >
                    <span class="slider round"></span>
                </label>
            </div>

        </form>
    </div>

    <button class="btn btn-primary btn-cookie w-100" (click)="validateChoice()">{{'gestionCookie.buttons.saveCookie' | translate}}</button>
</ng-container>
