import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../../../../../common/class/compliance-api.service";
import {RestService} from "../../../../../../common/service/rest.service";

@Injectable({
  providedIn: 'root'
})
export class ContactForPremiumService extends ComplianceApiService{

    constructor(private _restService: RestService) {
        super('contact-premium');
    }

    public sendMail(){
        return this._restService.get({
            url: this.baseUri,
            arg:null
        })
    }
}
