<ng-container *ngIf="formInput.formType === 'input'" [formGroup]="parentFormGroup">
    <label *ngIf="formInput.label" [attr.for]="formInput.id" class="mb-2">{{formInput.label | translate}}</label>
    <input *ngIf="!formInput.onlyInt; else inputOnlyInteger"
           [attr.type]="formInput.inputType"  [formControlName]="formInput.id"
           class="form-control" complianceDynamicForm [formInput]="formInput"
           (click)="click($event)" (change)="change($event)"
           (blur)="blur($event)" (keyup)="keyup($event)"
    />
    <ng-template #inputOnlyInteger>
        <input complianceLocalizedNumericInput
               [attr.type]="formInput.inputType"  [formControlName]="formInput.id"
               class="form-control compliance-localized-numeric-input" complianceDynamicForm [formInput]="formInput"
               (click)="click($event)" (change)="change($event)"
               (blur)="blur($event)" (keyup)="keyup($event)"
        />
    </ng-template>
</ng-container>


