<!-- START : content -->

<div id="content" class="">
    <div class="container-fluid w-80">
        <div *ngIf="balance && balance.length" class="row">

            <div class="col">
                <div class="block-group-title">
                    <h2 class="title big">{{'balance.title' | translate}}</h2>
                </div>
                <div class="block-table scroll-table block-table--control position-relative" >
                    <compliance-table [datas]="balance" [footer]="sumTotal" [items]="metaBalance" #ct [tableOption]="{exportFileName:'Balance'}" [destroy$]="destroy$"></compliance-table>
                </div>
            </div>

            <div class="d-flex flex-column justify-content-center pl-4 side-result" *ngIf="balanceData && balanceData.balanceResult">
                <div class="block-table-results mb-4" [ngClass]="{'balance-warning':!balanceResult.isBalanced, 'balance-ok':balanceResult.isBalanced}">
                    <div class="results">
                        <p class="label">{{'balance.result.title' | translate}}</p>
                        <p class="data">{{balanceResult.result}}</p>
                    </div>
                    <div class="ccl d-flex justify-content-around align-items-center">
                        <div class="mr-2"><i class="far fa-times-circle" [ngClass]="{'fa-times-circle': !balanceResult.isBalanced, 'fa-check-circle': balanceResult.isBalanced}"></i></div>
                        <div class="ml-2">{{balanceResult.isBalancedText | translate}}</div>
                    </div>
                </div>

                <div class="block-table-actions d-flex justify-content-center no-print">
                    <ul>
                        <li><a href="#" class="text-white" (click)="exportBalance()">
                            <!--<i class="fal fa-file-download fa-4x pt-2"></i>-->
                            <i class="fal fa-file-spreadsheet fa-4x pt-2"></i>
                            <div class="text-size-16">Excel</div>
                        </a></li>
                        <!--<li><a href="#" class="pdf"><span>PDF</span></a></li>-->
                        <!--<li><a href="#" class="print"><span>Print</span></a></li>-->
                    </ul>
                </div>

                <!--<a href="#" class="scroll-down">Descendre en <br>bas du tableau</a>-->
            </div>
        </div>
    </div>
</div>
<!-- STOP : content -->

<!-- START : footer -->
<footer id="footer">
    <div class="inner">
        <a href="#" class="btn btn-primary btn-back" (click)="goBack()" *ngIf="controlId || isFrom">
            <span>{{'buttons.back' | translate}}</span>
        </a>
        <a href="#" class="btn btn-primary btn-next" (click)="launchNext()">
            <span>{{(controlId ? 'buttons.next' : 'buttons.finished') | translate}}</span>
        </a>
    </div>
</footer>
<!-- STOP : footer -->
