import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {NotificationService} from "../push/notification.service";
import {CategoryControl, DomaineControl} from "../../class/control/control-descriptor";
import {RouteCode} from "../../class/meta/question/meta-question";
import {ControlRoutesFlux} from "../../class/control/control-flux";

export interface Context {
    projectId: string;
    exerciceId: string;
    stateMachineId: string;
    domain: DomaineControl;
    category: CategoryControl;
}

@Injectable({
    providedIn: 'root'
})
export class ComplianceRouteService {

    private routeGenerator: { [keys: string]: (context: Context, options?: any) => any };

    constructor(private router: Router, private notificationService: NotificationService) {
        this.routeGenerator = {};
        this.routeGenerator[RouteCode.LIST_CONTROL] = this.routeListControl.bind(this);
        this.routeGenerator[RouteCode.GRAPH_GLOBAL_PARAMS] = this.routeGlobalParams.bind(this);
    }

    navigate(routeCode: RouteCode, context: Context, options?: any) {
        this.routeGenerator[routeCode](context, options);
    }

    private routeListControl(context: Context, options?: any) {
        let s = this.notificationService.generateControlRoute(context.projectId, context.domain, context.category, null, ControlRoutesFlux.list, context.exerciceId);
        this.router.navigate([s]);
    }

    private routeGlobalParams(context: Context, options?: any) {
        this.router.navigate(['compliance', context.domain, context.category, 'project', context.projectId, context.exerciceId, 'globalParams', 'graph']);
    }
}
