import {Component, OnDestroy, OnInit} from '@angular/core';
import {Dossier} from "../../../../../../common/class/dossier/dossier";
import {FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {ComplianceValidator} from "../../../../../../common/validator/compliance-validator";
import {BaseSirenService} from "../../../../../../common/service/siren/base-siren.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DossierService} from "../../../../../../common/service/dossier/dossier.service";
import {UploadActionType} from "../../../../../../common/class/upload/upload-constantes";
import {UploadService} from "../../../shared/upload-file/drag-n-drop/shared/upload.service";
import {BgForestComponent} from "../../../../../../common/class/background/bg-compliance";
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {ConfirmationService} from "primeng/api";
import {User} from "../../../../../../common/class/user/user";
import {AuthService} from "../../../../../../common/service/auth/auth.service";
import {ComplianceLoaderService} from "../../../../../../common/service/loader/compliance-loader.service";
import {FileType} from "../../../../../../common/class/compliance-constant";

enum DisplayType{
    none="none",
    noSiren="noSiren",
    invalidSiren="invalidSiren",
    block="block"
}

@Component({
    selector: 'compliance-project-form',
    templateUrl: './project-form.component.html',
    styleUrls: ['./project-form.component.scss']
})
export class ProjectFormComponent extends BgForestComponent implements OnInit, OnDestroy {
    private _connectedUser: User;
    private _nbProjects: number;

    displayType = DisplayType;
    display = DisplayType.none;
    projectObject: any;
    existingProject = false;
    existingSiren: boolean;
    projectForm: FormGroup;
    uploadActionType: UploadActionType = UploadActionType.EXTRACT_SIREN;
    fileType = [FileType.CA3, FileType.DEB_EXPEDITION, FileType.DEB_INTRODUCTION];
    private _errors: any = {};
    dropBoxLabel: string;
    destroy$: Subject<boolean> = new Subject<boolean>();

    domain: string;
    category: string;
    dataCreationDossier: Promise<any>;
    enableCreation = false;

    constructor(private _fb: FormBuilder, private _confirmationService: ConfirmationService,
                private _authService: AuthService, private _complianceLoaderService:ComplianceLoaderService,
                private _baseSirenService: BaseSirenService,
                private _router: Router, private _route: ActivatedRoute,
                private _uploadService: UploadService, private _dossierService: DossierService) {
        super();
        this._connectedUser = this._authService.getConnectedUser();
        this._errors = super.getValue("dossier.forms.error.code");
        this.dropBoxLabel = super.getValue("dossier.create.dropBoxLabel");

        this._dossierService.findByAccount().then((dossiers) => {
            this._nbProjects = dossiers.length;
        });
    }

    ngOnInit() {
        this.domain = this._route.snapshot.paramMap.get('domain');
        this.category = this._route.snapshot.paramMap.get('category');
        this._dossierService.getDataCreationDossier()
            .pipe(
                map(data => {
                    data.activites.map(activite => {
                        activite.activite = `${activite.label} (${activite.code})`;
                        return activite;
                    });
                    data.categories.map(categories => {
                        categories.categories = `${categories.label} (${categories.code})`;
                        return categories;
                    });
                    return data;
                })
            )
            .subscribe(data => this.dataCreationDossier = data);
        this.createForm();
    }

    private createForm() {
        this.projectForm = this._fb.group(
            {
                code: [
                    "",
                    [
                        Validators.required,
                        ComplianceValidator.createSiretSirenAndTvaValidator('code')
                    ]
                ]
            });
        this.projectForm.valueChanges.pipe(
            takeUntil(this.destroy$),
            debounceTime(200),
            distinctUntilChanged(),
            map(code => {
                code = code.code.replace(/\s/g, "");
                if (ComplianceValidator.checkSiren(code)) {
                    return code;
                } else if (ComplianceValidator.checkSiret(code)) {
                    return code.substr(0, 9);
                } else if (ComplianceValidator.checkTvaNum(code)) {
                    return code.substr(4, 9);
                } else {
                    return null;
                }
            }),
            filter(siren => siren != null)
        ).subscribe(siren => {
            this.findBySiren(siren);
        });
    }

    findBySiren(code: string) {/*414740852*/
        // let type = REGEX_SIREN.test(code) ? 'siren' : 'siret';
        this._dossierService.findBySiren(code).then((data: any) => {
            this.extractProject(data);
        });
    }

    async uploadFile(event) {
        if (event.uploaded && event.value) {
            let value = event.value;
            this.extractProject(value);
        }
    }

    private extractProject(data: any) {
        if (data) {
            this.existingProject = data.existingProject;
            this.existingSiren = !!data.siren.code;
            if (this.existingProject) {
                this.projectObject = {
                    ...data.project,
                    code: data.searchedSiren,
                    name: data.project.name || data.siren.name,
                    adresse: data.project.adresse || data.siren.adress,
                    codeApe: data.project.codeApe || data.siren.codeApe,
                    categorie: data.project.categorieEntreprise || data.siren.categorieEntreprise,
                    formeJuridique: data.project.libelleNatureJuridique || data.siren.libelleNatureJuridique,
                };
                this.display = this.extractDisplayType(this.projectObject, data.sirenValid);
            } else if (data.siren) {
                this.projectObject = {
                    ...data.project,
                    code: data.searchedSiren,
                    name: data.siren.name,
                    adresse: data.siren.adress,
                    codeApe: data.siren.codeApe,
                    categorie: data.siren.categorieEntreprise,
                    formeJuridique: data.siren.libelleNatureJuridique,
                };
                this.display = this.extractDisplayType(this.projectObject, data.sirenValid);
            } else {
                this.display = DisplayType.none;
            }
            if (this.projectObject.adresse === 'null null null null null null null') {
                this.projectObject.adresse = "";
            }
        }
    }

    private extractDisplayType(projectObject, sirenValid) {
        if(!sirenValid){
            return DisplayType.invalidSiren;
        }else if(projectObject.code){
            return DisplayType.block
        }else{
            return DisplayType.noSiren
        }
    }

    getErrorLabel(errors: ValidationErrors | null) {
        let sirenError = errors.siren;
        let siretError = errors.siret;
        let tvaError = errors.tva;
        if (sirenError && !siretError && !tvaError) {
            return this._errors.siren;
        }
        if (!sirenError && siretError && !tvaError) {
            return this._errors.siret;
        }
        if (!sirenError && !siretError && tvaError) {
            return this._errors.tva;
        }
        return this._errors.unknown;
    }

    hasError(errors: ValidationErrors | null) {
        return errors.siren || errors.siret || errors.tva;
    }


    goToProject(id: string) {
        this.display = DisplayType.none;
        this._router.navigate(['/compliance', this.domain, this.category, 'project', id, 'dashboard']);
    }

    createProject() {
        if (this._nbProjects >= this._connectedUser.account.accountProjectNumber) {
            let i18n = super.getTranslation();
            let projectI18n = i18n.project;
            this._confirmationService.confirm({
                acceptVisible: true,
                acceptLabel: i18n.buttons.confirm,
                rejectVisible: false,
                message: projectI18n.alerteProjectNumberMax.text,
                header: projectI18n.alerteProjectNumberMax.title,
                accept: () => {
                    this._complianceLoaderService.sendLoaderShow();
                    this._dossierService.createProject(this.projectObject).then((dossier: Dossier) => {
                        this.goToProject(dossier.id);
                    });
                }
            });
        } else {
            this._complianceLoaderService.sendLoaderShow();
            this._dossierService.createProject(this.projectObject).then((dossier: Dossier) => {
                this.goToProject(dossier.id);
            });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
