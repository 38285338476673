import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ComplianceValidator, REGEX_PASSWORD} from "../../../validator/compliance-validator";

@Component({
    selector: 'create-password-form',
    templateUrl: './create-password-form.component.html',
    styleUrls: ['./create-password-form.component.scss']
})
export class CreatePasswordFormComponent implements OnInit {
    form: FormGroup;
    @Input() i18n:any;
    @Input() tempId:string;
    @Output() saveEmitter:EventEmitter<any> = new EventEmitter();

    constructor(private fb: FormBuilder) {
    }

    ngOnInit() {
        this.createForm();
    }

    private createForm() {
        this.form = this.fb.group({
            password: ['', [
                Validators.required,
                Validators.pattern(REGEX_PASSWORD)
            ]],
            confirmPassword: ''
        }, {
            validator: ComplianceValidator.createMatchTwoField('password', 'confirmPassword')
        });
    }

    public specifyPassword(event:any):void{
        event.stopPropagation();
        event.preventDefault();
        if(!this.form.invalid){
            this.saveEmitter.emit({
                tempId: this.tempId,
                password:this.form.value.password
            });
        }
    }

    public validate() {
        this.form.controls['confirmPassword'].updateValueAndValidity();
    }

}
