import {Component, OnDestroy, OnInit} from '@angular/core';
import {BackgroungType} from "../../../../../../../../common/class/background/bg-compliance";
import {CategoryControl, ControlDescriptor, DomaineControl} from "../../../../../../../../common/class/control/control-descriptor";
import {NotificationService} from "../../../../../../../../common/service/push/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ControlRoutesFlux} from "../../../../../../../../common/class/control/control-flux";
import {AbstractControl} from "../../../../../../../../common/component/control/abstract-control";
import {CONTROL_FILE_VIE_ID} from "../../../../../../../../common/class/compliance-constant";

@Component({
    selector: 'compliance-control-presentation',
    templateUrl: './control-presentation.component.html',
    styleUrls: ['./control-presentation.component.scss']
})
export class ControlPresentationComponent extends AbstractControl implements OnInit, OnDestroy {

    control: ControlDescriptor;
    controlFileVieId = CONTROL_FILE_VIE_ID;

    context: any;

    constructor(private notificationService: NotificationService,
                private route: ActivatedRoute, private router: Router,) {
        super(BackgroungType.BG_SPACE);
    }

    ngOnInit() {
        this.domain = this.route.snapshot.paramMap.get('domain') as DomaineControl;
        this.category = this.route.snapshot.paramMap.get('category') as CategoryControl;
        this.controlId = this.route.snapshot.paramMap.get('controlId');
        this.projectId = this.route.snapshot.paramMap.get('projectId');
        this.exerciceId = this.route.snapshot.paramMap.get('exerciceId');
        this.context = {
            exerciceId: this.exerciceId,
            controlId: this.controlId,
            projectId: this.projectId,
            domain: this.domain,
            category: this.category,
        }
        this.getData();
        super.subscribeToNotificationControl({
            notificationService: this.notificationService
        });
    }

    async getData() {
        this.control = await this._controlService.findControlById(this.controlId);
    }

    async showFocus() {
        this.router.navigate([this.notificationService.generateControlRoute(this.projectId, this.domain, this.category, this.control.id, ControlRoutesFlux.focus, this.exerciceId)]);
    }

    goBack() {
        this.router.navigate([this.notificationService.generateControlRoute(this.projectId, this.domain, this.category, null, ControlRoutesFlux.list, this.exerciceId)]);
    }
}
