import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {REGEX_MAIL} from "../../../validator/compliance-validator";
import {ComplianceI18nLoader} from "../../../class/i18n/compliance-i18n-loader";
import {ComplianceLoaderService} from "../../../service/loader/compliance-loader.service";

@Component({
    selector: 'forgotten-password-form',
    templateUrl: './forgotten-password-form.component.html',
    styleUrls: ['./forgotten-password-form.component.scss']
})
export class ForgottenPasswordFormComponent extends ComplianceI18nLoader implements OnInit {
    form: FormGroup;
    @Input() i18n: any;
    @Output() saveEmitter: EventEmitter<any> = new EventEmitter();
    @Output() resetEmitter: EventEmitter<any> = new EventEmitter();
    private clicked: boolean;


    constructor(private fb: FormBuilder) {
        super();
    }

    ngOnInit() {
        console.log('ForgottenPasswordFormComponent', this.i18n);
        this.createForm();
        this.clicked = false;
    }

    private createForm() {
        this.form = this.fb.group({
            email: ['', [
                Validators.required,
                Validators.pattern(REGEX_MAIL)
            ]]
        });
    }

    public submit(event: any): void {
        this.clicked = true;
        event.stopPropagation();
        event.preventDefault();
        if (!this.form.invalid) {
            this.saveEmitter.emit(this.form.value.email);
        }
    }

    public reset(event: any): void {
        event.stopPropagation();
        event.preventDefault();
        this.resetEmitter.emit();
    }

    oneClickButton(): boolean {
        return this.form.invalid || this.clicked;
    }

    reactivateButton() {
        this.clicked = false;
    }
}
