import {AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild} from '@angular/core';
import {ActionQuestion, ActionServiceType, MetaModal, MetaQuestion, QuestionEvent, QuestionEventType} from "../../class/meta/question/meta-question";
import {QuestionDirective} from "../directives/question.directive";
import {QUESTION_COMPONANT_BY_TYPE, QUESTION_MOCK} from "../../class/meta/question/meta-question-const";
import {BackgroungType} from "../../class/background/bg-compliance";
import {QuestionService} from "../../service/question/question.service";
import {QuestionPeriodService} from "../../service/question/question-period.service";
import {AbstractControl} from "../control/abstract-control";
import {ActivatedRoute, Router} from "@angular/router";
import * as _ from "lodash";
import {MetaQuestionService} from "../../service/meta/meta-question.service";
import {NotificationService} from "../../service/push/notification.service";
import {NotificationMessage} from "../../class/polling/notification-message";
import {ComplianceRouteService} from "../../service/route/compliance-route.service";
import {MetaExpressionService} from "../../service/meta/meta-expression.service";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'compliance-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss']
})
export class QuestionComponent extends AbstractControl implements OnInit, AfterViewInit {

    metaQuestion: MetaQuestion<any>;
    @ViewChild(QuestionDirective) questionRef: QuestionDirective;
    private questionService: { [keys: string]: QuestionService } = {};
    display: boolean = false;
    modalData: MetaModal = new MetaModal();

    constructor( private componentFactoryResolver: ComponentFactoryResolver, private periodService: QuestionPeriodService, private route: ActivatedRoute,
                private  metaQuestionService: MetaQuestionService, private notificationService: NotificationService, private router: Router,
                private routeService: ComplianceRouteService, private expressionService: MetaExpressionService) {
        super(BackgroungType.BG_SPACE);
        // this.questionService[QuestionType.PERIODE] = periodService;
    }

    ngOnInit() {
        super.setContextWithRoute(this.route, this.findData.bind(this));
        super.subscribeToNotificationControl({
            notificationService: this.notificationService,
            manageDataEvent: (n: NotificationMessage) => this.manageDataEvent(n)
        });
    }

    ngAfterViewInit(): void {
        if (this.event.indexOf('MOCK') >= 0) {
            this.metaQuestion = QUESTION_MOCK[this.event];
            this.loadComponent();
        }
    }

    async findData() {
        this._controlService.dataControl(this.projectId, this.stateMachineId, this.exerciceId, this.event, this.stepByStep);
    }

    protected manageDataEvent(notification: NotificationMessage) {
        this.metaQuestion = notification.corp;
        if (!this.metaQuestion.question.hasOwnProperty("data") || this.metaQuestion.question.data === undefined) {
            this.metaQuestion.question.data = null;
        }
        this.loadComponent();
    }

    loadComponent() {
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(QUESTION_COMPONANT_BY_TYPE[this.metaQuestion.code]);

        let viewContainerRef = this.questionRef.viewContainerRef;
        viewContainerRef.clear();

        let componentRef = viewContainerRef.createComponent(componentFactory);
        componentRef.instance.metaQuestion = this.metaQuestion;

        componentRef.instance.actionEvent
            .pipe(takeUntil(this.destroy$))
            .subscribe((actionEvent: QuestionEvent) => {
                let data = actionEvent.data, actions = [];
                if (data && data.code === 'back') {
                    // Force 'constrolService.backControl'
                    actions = [{service: {type: ActionServiceType.BACK_CONTROL}}];
                } else if (actionEvent.type === QuestionEventType.GLOBAL) {
                    actions = this.metaQuestion.question.buttons[data.code].actions || [];
                } else if (actionEvent.type === QuestionEventType.ITEM) {
                    actions = data.actions || [];
                }
                this.processActions(actions, data.data);
            });
        this._complianceLoaderService.sendLoaderHide();
    }


    private processActions(actions, data) {
        _.each(actions, (action: ActionQuestion) => {
            console.log(action);
            this.processAction(action, data);
        });
    }

    private processAction(action: ActionQuestion, data) {
        if (!action.executeIf || this.expressionService.computeExpression(action.executeIf, this.metaQuestion.question)) {
            if (action.service) {
                this._complianceLoaderService.sendLoaderShow();
                this.metaQuestionService.procesQuestionServiceAction(action, this.projectId, this.stateMachineId, this.exerciceId, this.metaQuestion.question, this.event, data, this.metaQuestion.dataSaveTarget, this.stepByStep);
            } else if (action.routing) {
                this._complianceLoaderService.sendLoaderShow();
                this.routeService.navigate(action.routing, {
                    exerciceId: this.exerciceId,
                    stateMachineId: this.stateMachineId,
                    category: this.category,
                    domain: this.domain,
                    projectId: this.projectId,
                });
            } else if (action.modal) {
                this.showModal(action.modal);
            }
        }
    }

    private showModal(data: MetaModal) {
        this.modalData = data;
        this.display = true;
    }

    actionModal(actions: ActionQuestion[]) {
        console.log(actions);
        if (actions && actions.length) {
            this.processActions(actions, this.metaQuestion.question.data);
        }
        this.display = false;
    }
}
