import {Component, Input, OnInit} from '@angular/core';
import {ComplianceI18nLoader} from "../../../../../../../../../../common/class/i18n/compliance-i18n-loader";
import {BlocText} from "../../../../../../../../../../common/class/text/bloc-text";

@Component({
  selector: 'compliance-key-number-block',
  templateUrl: './key-number-block.component.html',
  styleUrls: ['./key-number-block.component.scss']
})
export class KeyNumberBlockComponent extends ComplianceI18nLoader implements OnInit {

    @Input() keyNumberBlock: BlocText;
    constructor() {
        super();
    }
  ngOnInit() {
  }

}
