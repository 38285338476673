import {NgModule} from '@angular/core';
import {AuthGuard} from "../../shared/auth/auth.guard";
import {RouterModule, Routes} from "@angular/router";
import {DomaineChoiceComponent} from "./domaine-choice.component";
import {ComplianceTreeNavComponent} from "../compliance-tree-nav/compliance-tree-nav.component";


const routes: Routes = [
    {
        path: 'compliance',
        component: ComplianceTreeNavComponent,
        canActivateChild: [AuthGuard],
        children:
            [
                {
                    path: 'home',
                    component: DomaineChoiceComponent
                }
            ]
    }]
;

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DomaineChoiceRoutingModule { }
