<div class="chart chart-doughnut d-flex justify-content-between align-items-center" *ngIf="!data.calculatedData[affectationCompte.NO_AFFECTATION_AUTO]; else noAffectation">
    <div class="height-100">
        <svg width="24px" height="24px" viewBox="0 0 42 42" class="donut">
            <circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="transparent"></circle>
            <circle class="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#d2d3d4" stroke-width="10"></circle>

            <ng-container *ngFor="let stroke of svgStroke">
                <circle class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent"
                        [attr.stroke]="stroke.color" stroke-width="10" [attr.stroke-dasharray]="stroke.value + ' ' + (100 - stroke.value)" [attr.stroke-dashoffset]="stroke.offset"></circle>
            </ng-container>
        </svg>
    </div>
    <div class="chart-legend flex-fill">
        <ul class="0-legend">
            <li>
                <span class="label px-1" [ngStyle]="{'color':affectToShow.color}">
                    <span class="data">{{affectToShow.value | number:'1.0-2'}}</span>
                    <span [innerHtml]="affectToShow.label | safe:'html'"> </span>
                </span>
                <i *ngIf="export && !exportLoading" class="fal fa-search export" [ngStyle]="{'color':affectToShow.color}" [ngbTooltip]="tooltip" (click)="exportAction($event)">
                    <ng-template #tooltip>
                        <div [innerHtml]="'affectation.mainContent.table.exportLabel' | translate | safe:'html'"></div>
                    </ng-template>
                </i>
                <i *ngIf="export && exportLoading" class="fas fa-spinner fa-pulse" [ngStyle]="{'color':affectToShow.color}" [ngbTooltip]="tooltip">
                    <ng-template #tooltip>
                        <div [innerHtml]="'affectation.mainContent.table.exportLoadingLabel' | translate | safe:'html'"></div>
                    </ng-template>
                </i>
            </li>
        </ul>
    </div>
</div>

<ng-template #noAffectation>
    <div class="chart chart-doughnut d-flex justify-content-between align-items-center no-affecation">
        <div class="height-100" style="width: 24px">

        </div>
        <div class="chart-legend flex-fill">
            <ul class="0-legend">
                <li ><span class="label px-1" ><span>{{'affectation.automaticSection.noAffectationLabel' | translate}}</span></span></li>
            </ul>
        </div>
    </div>
</ng-template>
