import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {RapportDetaille} from "../../shared/compte-rendu-general";
import {ActivatedRoute} from "@angular/router";
import {Subject} from "rxjs";

@Component({
    selector: 'compliance-general-rapport-detaille',
    templateUrl: './general-rapport-detaille.component.html',
    styleUrls: ['./general-rapport-detaille.component.scss']
})
export class GeneralRapportDetailleComponent implements OnInit, OnChanges {

    @Input() rapportDetaille : RapportDetaille;
    @Input() extendedData: any;
    @Input() destroy$: Subject<boolean>;
    private projectId: string;
    private exerciceId: string;
    private controlId: string;

    constructor(private route: ActivatedRoute, ) {
    }

    ngOnInit() {
        console.log("GeneralRapportDetailleComponent", this.rapportDetaille)
        this.projectId = this.route.snapshot.paramMap.get("projectId");
        this.exerciceId = this.route.snapshot.paramMap.get("exerciceId");
        this.controlId = this.route.snapshot.paramMap.get("controlId");
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.rapportDetaille && changes.rapportDetaille.currentValue){
            this.rapportDetaille = changes.rapportDetaille.currentValue
        }
    }

}
