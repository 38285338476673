export const dashboard = {
    title: "Tableau de Bord TVA",
    legalEntity: {
        period: {
            from:"Période du ",
            to: " au "
        },
        new: "Nouveau"
    },
    tvaDashboard: {
        title: "Contrôles réalisés ({{nbDone}}/{{nbTotal}})",
        sort: {
            riskValue: "Montant de risque calculé",
            acceptedRiskValue: "Montant de risque accepté",
            riskLevel: "Niveau de risque",
            visa: "Visa"
        },
        header: {
            name: "Nom du contrôle",
            period: "Période",
            amountRisk: "Risque TVA calculé",
            acceptedAmountRisk: "Risque TVA accepté",
            actions: "Actions",
            supervision: "Supervision"
        },
        controlData: {
            lastUpdate: "Réalisé le ",
            period: {
                from:"De ",
                to: " à ",
                fec: "Période du FEC"
            },
        },
        probablyInvalid: "Des paramétrages ont été modifiés pour l'exercice. Ces derniers peuvent impacter vos résultats. Nous vous conseillons de relancer le contrôle.",
        modalReport:{
            header:`Choix des éléments à intégrer au rapport*`,
            footer:`(*) Veuillez sélectionner au moins un élément`,
        },
        actions: {
            show: "Accéder aux Résultats",
            run: "Relancer les calculs",
            export: "Génération rapport"
        }
    },
    fecManagement: {
        result: "Résultat",
        fecFile: {
            noFec: "Aucun FEC déposé",
            present: "FEC déposé"
        },
        isBalanced: {
            true: "La balance est équilibrée",
            false: "La balance n'est pas équilibrée",
            notCalculated: "Accéder à la balance"
        },
        accessFiles: "Gestion des fichiers"
    },
    globalRisk: {
        graph: {
            title: "Risque global TVA",
            tooltip: "Risques : {{value}}"
        },
        risk: {
            refused: "Refusé",
            accepted: "Accepté",
            waiting: "Non Visé",
        },
        noRisk: {
            noIncoherence: "Aucune incohérence n'a été détectée",
            withIncoherence: "Des incohérences ont été détectées",
            negativeIncoherence: "Une erreur est survenue, merci de contacter votre administrateur."
        }
    },
    exercice:{
        title:"Création d'un exercice",
        updateTitle:"Modification d'un exercice",
        subtitle: "Créer un exercice pour le dossier ",
        updateSubtitle:"Modifier un exercice pour le dossier ",
        createExerciceButton:"Créer l'exercice",
        validateUpdateExercice:"Valider",
        exerciceAlreadyExist:"L'exercice existe déjà pour le dossier",
        form:{
            label:"Année de clôture de l'exercice : ",
            clotureYear:{
                label:"Année de clôture de l'exercice : ",
                error:"Veuillez saisir une année au format AAAA"
            },
            dateDebutExercice:{
                label:"Date de début de l'exercice",
                error: "Veuillez saisir une date de début d'exercice"
            },
            dateFinExercice:{
                label:"Date de fin de l'exercice",
                error: "Veuillez saisir une date de fin d'exercice"
            },
            date:{
                error:"La date de début d'exercice doit être antérieure à la date de fin d'exercice"
            }
        },
        deleteModal:{
            title:"Confirmation de la suppression d'un exercice",
            content: "Attention, cette action entraîne la suppression des fichiers et des données liés à l'exercice. Etes-vous sûr de vouloir supprimer définitivement l'exercice qui se clôture le "
        },
        error:{
            validatedExercice:`La modification de l'exercice est désactivée sur un exercice validé.`
        }
    },
    startControl: "Lancer les contrôles",
    manageFiles: "Gérer les fichiers",
    dropFiles: "Déposer les fichiers",
    controls:{
        launched:{
            title:{
                one:`Contrôle métier réalisé `,
                many:`Contrôles métiers réalisés `
            },
            nothing: `Aucun contrôle métier n'a été réalisé`
        },
        neverLaunched:{
            title:{
                one:`Contrôle métier non réalisé `,
                many:`Contrôles métiers non réalisés `
            },
            nothing: `Tous les contrôles métiers ont été réalisés`
        }
    },
    graph: {
        pie: {
            title: "Synthèse des contrôles"
        }
    },
    cas0: {
        title: "Module TVA",
        subtitles: {
            first: "Vous n’avez pas encore réalisé de contrôle au titre de l’exercice {{annee}}.",
            second: "Pour commencer vous pouvez choisir entre deux « modes »"
        },
        stepByStep: {
            title: "Le mode « Pas a pas »",
            text: "Vous pouvez exécuter les contrôles les uns à la suite des autres en les paramétrant au fil de l’eau.",
            button: "Accéder à la liste des contrôles"
        },
        automatic: {
            title: "Le mode « Automatique »",
            texts: {
                files: "Nous vous indiquons les fichiers nécessaires à déposer.",
                 controlChoice: "Nous vous aidons à choisir les contrôles les plus pertinents pour votre dossier.",
                 parameters: "Nous vous guidons étape par étape dans le paramétrage de vos contrôles.",
                 launch: "Et vous n’aurez plus qu’à lancer les contrôles automatiquement."
            },
            button: "Commencer les paramétrages"
        }
    }
};
