import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {FormInput} from "../../class/meta/meta-formulaire";
import {MetaFormulaireService} from "../../service/meta/meta-formulaire.service";

@Directive({
    selector: '[complianceDynamicForm]'
})
export class FormDirective implements OnInit{

    @Input() formInput: FormInput;

    constructor(private renderer: Renderer2, private hostElement: ElementRef, private formService:MetaFormulaireService) {

    }

    ngOnInit(): void {
        this.formService.setAttributes(this.formInput, this.renderer, this.hostElement.nativeElement)
    }


}
