
<div class="supervision-control container-fluid w-75 h-100" *ngIf="supervisionForm">
    <form class="d-flex flex-column h-100" [formGroup]="supervisionForm">
        <div class="">
            <div class="">
                <div class="block-group-title">
                    <h2 class="title">{{'supervision.control.title' | translate}}</h2>
                    <h2 class="sub-title">{{'supervision.control.subtitle' | translate}}</h2>
                </div>
            </div>
        </div>
        <div class="row editor-panel">
            <div class="col-9 d-flex flex-column h-100">
                <div class="row obs-n-corr">
                    <div class="col-6 h-100 d-flex flex-column">
                        <div class="block-group-title sub-title">
                            <h2 class="sub-title">{{'supervision.observations' | translate}}</h2>
                        </div>
                        <p-editor class="col p-0" formControlName="observations"
                                  [ngClass]="{'disabled': isDisabled()}">
                            <p-header>
                        <span class="ql-formats">
                            <button class="ql-bold" aria-label="Bold"></button>
                            <button class="ql-italic" aria-label="Italic"></button>
                            <button class="ql-underline" aria-label="Underline"></button>
                        </span>
                                <span class="ql-formats">
                            <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)"></select>
                            <select title="Background Color" class="ql-background"
                                    defaultValue="rgb(255, 255, 255)"></select>
                        </span>
                                <span class="ql-formats">
                            <button class="ql-list" value="ordered"></button>
                            <button class="ql-list" value="bullet"></button>
                        </span>
                                <span class="ql-formats">
                            <button class="ql-clean" aria-label="No style"></button>
                        </span>
                            </p-header>
                        </p-editor>
                    </div>
                    <div class="col-6 h-100 d-flex flex-column">
                        <div class="block-group-title sub-title">
                            <h2 class="sub-title">{{'supervision.fixes' | translate}}</h2>
                        </div>
                        <p-editor class="col p-0" formControlName="fixes"
                                  [ngClass]="{'disabled': isDisabled()}">
                            <p-header>
                        <span class="ql-formats">
                            <button class="ql-bold" aria-label="Bold"></button>
                            <button class="ql-italic" aria-label="Italic"></button>
                            <button class="ql-underline" aria-label="Underline"></button>
                        </span>
                                <span class="ql-formats">
                            <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)"></select>
                            <select title="Background Color" class="ql-background"
                                    defaultValue="rgb(255, 255, 255)"></select>
                        </span>
                                <span class="ql-formats">
                            <button class="ql-list" value="ordered"></button>
                            <button class="ql-list" value="bullet"></button>
                        </span>
                                <span class="ql-formats">
                            <button class="ql-clean" aria-label="No style"></button>
                        </span>
                            </p-header>
                        </p-editor>
                    </div>
                </div>
                <div class="separator my-4"></div>
                <div class="row concl">
                    <div class="col-12 h-100 d-flex flex-column">
                        <div class="block-group-title sub-title">
                            <h2 class="sub-title">{{'supervision.conclusion' | translate}}</h2>
                        </div>
                        <p-editor class="col p-0" formControlName="conclusion"
                                  [ngClass]="{'disabled': isDisabled()}">
                            <p-header>
                        <span class="ql-formats">
                            <button class="ql-bold" aria-label="Bold"></button>
                            <button class="ql-italic" aria-label="Italic"></button>
                            <button class="ql-underline" aria-label="Underline"></button>
                        </span>
                                <span class="ql-formats">
                            <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)"></select>
                            <select title="Background Color" class="ql-background"
                                    defaultValue="rgb(255, 255, 255)"></select>
                        </span>
                                <span class="ql-formats">
                            <button class="ql-list" value="ordered"></button>
                            <button class="ql-list" value="bullet"></button>
                        </span>
                                <span class="ql-formats">
                            <button class="ql-clean" aria-label="No style"></button>
                        </span>
                            </p-header>
                        </p-editor>
                    </div>
                </div>
            </div>
            <div class="col-3 d-flex flex-column h-100">
                <div class="d-flex flex-column justify-content-center">
                    <div class="block-group-title text-center m-3">
                        <h2 class="sub-title">{{'supervision.amountRisk.title' | translate}}</h2>
                    </div>
                    <div class="amount-risk p-0 d-flex flex-column justify-content-center">
                        <div class="d-flex flex-column align-items-center justify-content-center h-auto mb-2 mt-4 ml-4 mr-4">
                            <div class="block-group-title sub-title align-self-sm-start">
                                <h2 class="sub-sub-title">{{'supervision.amountRisk.calculated' | translate}}</h2>
                            </div>
                            <input type="text" complianceLocalizedNumericInput class="form-control mb-4" formControlName="amountRisk" />
                        </div>
                        <div class="d-flex flex-column align-items-center justify-content-center h-auto m-2 ml-4 mr-4">
                            <div class="block-group-title sub-title align-self-sm-start">
                                <h2 class="sub-sub-title">{{'supervision.amountRisk.accepted' | translate}}</h2>
                            </div>
                            <input type="text" complianceLocalizedNumericInput
                                   class="form-control mb-4" formControlName="acceptedAmountRisk"/>
                        </div>
                        <div class="d-flex flex-column align-items-center justify-content-center h-auto m-2 ml-4 mr-4">
                            <div class="block-group-title sub-title align-self-sm-start">
                                <h2 class="sub-sub-title">{{'supervision.amountRisk.denied' | translate}}</h2>
                            </div>
                            <input type="text" complianceLocalizedNumericInput
                                   class="form-control mb-4" formControlName="deniedAmountRisk"/>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-center">
                    <div class="block-group-title text-center m-3">
                        <h2 class="sub-title">{{'supervision.control.visa' | translate}}</h2>
                    </div>
                    <div class="visa p-0 d-flex flex-column justify-content-center">
                        <div class="btn-group btn-group-toggle d-flex flex-column align-items-center" ngbRadioGroup formControlName="visa">
                            <label class="btn btn-info d-flex flex-column align-items-center justify-content-center h-auto m-4" ngbButtonLabel *ngFor="let visa of visaType">
                                <input type="radio" ngbButton [value]="visa.value" [disabled]="exercice?.validation">
                                <span><i class="{{visa.icon}}"></i></span>
                                <span>{{visa.label | translate}}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </form>
</div>

<!-- START : footer -->
<footer id="footer">
    <div class="inner">
        <a href="#" class="btn btn-primary btn-back" (click)="goBack()"><span>{{'buttons.back' | translate}}</span></a>
        <a href="#" (click)="goNext()" class="btn btn-primary btn-next"><span>{{'buttons.finished' | translate}}</span></a>
    </div>
</footer>
