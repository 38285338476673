import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FecInfosService} from "../../../../../../../common/service/globalParams/fecInfos.service";
import {FECInfos} from "../../../../../../../common/class/globalParams/fecInfos";
import {CurrencyPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {CategoryControl, DomaineControl} from "../../../../../../../common/class/control/control-descriptor";
import {Exercice} from "../../../../../../../common/class/exercice/exercice";
import {ComplianceLoaderService} from "../../../../../../../common/service/loader/compliance-loader.service";
import {NotificationService} from "../../../../../../../common/service/push/notification.service";
import {ControlRoutesFlux} from "../../../../../../../common/class/control/control-flux";
import {OriginPage} from "../../../../../../../common/component/control/control-message-handler";

@Component({
    selector: 'compliance-fec-management',
    templateUrl: './fec-management.component.html',
    styleUrls: ['./fec-management.component.scss']
})
export class FecManagementComponent {
    private _projectId: string;
    @Input() set projectId(projectId: string) {
        this._projectId = projectId;
        this.getDatas();
    }

    private _exercice: Exercice;
    @Input() set exercice(exercice: Exercice) {
        this._exercice = exercice;
        this.getDatas();
    }

    get exercice() {
        return this._exercice;
    }

    @Input() disabled: boolean;

    @Output() launchStateMachine = new EventEmitter<{ stateMachineId: string; stepByStep: boolean; }>();
    balanceTexts = {
        fecFile: "dashboard.fecManagement.fecFile.noFec",
        result: undefined,
        isBalanced: undefined
    };
    balanceData: FECInfos;
    private _domain: DomaineControl;
    private _category: CategoryControl;

    constructor(private _route: ActivatedRoute, private _router: Router,
                private _balanceService: FecInfosService, private _currencyPipe: CurrencyPipe,
                private _complianceLoaderService: ComplianceLoaderService, private _notificationService: NotificationService) {
        this._domain = this._route.snapshot.paramMap.get('domain') as DomaineControl;
        this._category = this._route.snapshot.paramMap.get('category') as CategoryControl;
    }

    private getDatas() {
        if (!this._projectId || !this._exercice || !this._exercice.id || this.disabled) {
            return;
        }
        this._balanceService.getBalanceData(this._projectId, this._exercice.id).then((result: FECInfos) => {
            this.balanceData = result;
            const hasResult = !!(result.balanceResult && result.balanceResult.result);
            const resultBalance = hasResult ? this._currencyPipe.transform(this.balanceData.balanceResult.result, 'EUR', 'symbol', '1.0-0') : '&Oslash;';
            this.balanceTexts = {
                fecFile: "dashboard.fecManagement.fecFile." + (result.fecExist ? "present" : "noFec"),
                result: resultBalance,
                isBalanced: "dashboard.fecManagement.isBalanced." + (hasResult ? result.balanceResult.equilibre.toString() : "notCalculated")
            };
        });
    }

    goToGestionFile() {
        if (!this.disabled) {
            this._router.navigate(
                ['/compliance', this._domain, this._category, 'project', this._projectId, this._exercice.id, 'file-list']);
        }
    }

    launchBalance() {
        this._complianceLoaderService.sendLoaderShow();
        if (this.balanceData.balanceResult && this.balanceData.balanceResult.result) {
            const route = this._notificationService.generateParamsRoute(this._projectId, this._domain, this._category, "sm_balance", ControlRoutesFlux["balance"], this._exercice.id);
            this._router.navigate([route], {queryParams: {event: "BALANCE", originPage: OriginPage.DASHBOARD}});
        } else {
            this.launchStateMachine.emit({stateMachineId: "sm_balance", stepByStep: false});
        }
    }
}
