<ng-container *ngFor="let crdBlock of crdDispositionBlock?.blocks">
    <compliance-crd-disposition-block *ngIf="isDispositionBlock(crdBlock); else dataBlock" [crdDispositionBlock]="crdBlock.dispositionBlock"
                                      [ngStyle]="getStyle(crdBlock.dispositionBlock)" [destroy$]="destroy$"></compliance-crd-disposition-block>
    <ng-template #dataBlock>
        <div [ngClass]="crdBlock.dataBlock.options?.class" data-block="true" [ngStyle]="crdBlock.dataBlock.options?.style">
            <compliance-table-block [tableBlock]="crdBlock.dataBlock.tableBlock" *ngIf="compteRenduDetaillUtilsService.isDataTableBlock(crdBlock.dataBlock)" [destroy$]="destroy$"></compliance-table-block>
            <compliance-graph-block [graphBlock]="crdBlock.dataBlock.graphe" *ngIf="compteRenduDetaillUtilsService.isDataGraphBlock(crdBlock.dataBlock)"></compliance-graph-block>
            <compliance-text-block [blockText]="crdBlock.dataBlock.blocText" *ngIf="compteRenduDetaillUtilsService.isDataTextBlock(crdBlock.dataBlock)"></compliance-text-block>
            <compliance-key-number-block [keyNumberBlock]="crdBlock.dataBlock.keyNumberBlock"
                                         *ngIf="compteRenduDetaillUtilsService.isDataKeyNumberBlock(crdBlock.dataBlock)"></compliance-key-number-block>
            <compliance-export-block [exportBlock]="crdBlock.dataBlock.exportBlock" *ngIf="compteRenduDetaillUtilsService.isDataExportBlock(crdBlock.dataBlock)" [destroy$]="destroy$"></compliance-export-block>
        </div>
    </ng-template>


</ng-container>
