<div *ngIf="control" id="content" class="page-check">
    <div class="container">
        <div class="row">

            <div class="col-xl-12">
                <div class="block-group-title">
                    <h2 class="title">{{'control.presentation.title' | translate}}</h2>
                    <p class="sub-title">{{control.nom}}</p>
                </div>


                <div class="block-text-content">
                    <div class="head">
                        <h3 class="title">{{'control.presentation.descriptionTitle' | translate}}</h3>
                    </div>
                    <div class="core">
                        <p [innerHtml]="control.description | safe"></p>
                    </div>
                </div>

                <div class="block-text-content">
                    <div class="head">
                        <h3 class="title">{{'control.presentation.requiredDataTitle' | translate}}</h3>
                    </div>
                    <div class="core">
                        <p [innerHtml]="control.donneeNecessaire | safe"></p>
                    </div>
                </div>

                <div class="block-text-content">
                    <div class="head">
                        <h3 class="title">{{'control.presentation.objectifTitle' | translate}}</h3>
                    </div>
                    <div class="core">
                        <compliance-complex-string *ngFor="let obj of control.objectifs" [pComplexString]="obj" [pContext]="context"></compliance-complex-string>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="block-bottom-actions">
                    <div class="inner">
                        <a href="#" class="btn btn-primary btn-back" (click)="goBack()"><span>{{'buttons.back' | translate}}</span></a>
                        <a href="#" class="btn btn-primary btn-next" (click)="showFocus()"><span>{{'buttons.focus' | translate}}</span></a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
