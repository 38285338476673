export const gestionCookie = {
    title: `Paramétrage des cookies sur Francis Lefebvre e&#8209;Compliance`,
    buttons:{
        saveCookie:"Sauvegarder mes préférences de cookies",
    },
    cookieObligatoire: "Au-delà des cookies de fonctionnement qui permettent de garantir les fonctionnalités importantes et la mesure d'audience anonyme du site, Francis Lefebvre e&#8209;Compliance peut être amené à déposer les deux catégories de cookies suivantes que vous pouvez désactiver. Ces réglages ne seront valables que sur le navigateur que vous utilisez actuellement.",
    successMessage: "La sauvegarde de vos préférences de cookies a bien été prise en compte.",
    personnalPub: "Publicité personnalisée : En fonction de votre navigation, nous pouvons être amenés à afficher des publicités plus pertinentes, en lien avec vos centres d’intérêts.",
    statistic: "Statistiques : Pour améliorer la pertinence et l’ergonomie de nos services, nous sommes susceptibles d’enregistrer des données sur l’utilisation du site (contenus visités, parcours, …)."
};
