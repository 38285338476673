export class LigneFormulaireDto {
    ligneFormulaire: string;
    typeLigneFormulaire: string;
    transcodification: string;
}

export class ParagrapheFormDto {
    nameParagraphe: string;
    lignesFormulaire: LigneFormulaireDto[];
}

export class BlocFormDto {
    nameBloc: string;
    paragrapheFormulaire: ParagrapheFormDto[];
}

export class ManualCa3Form {
    blocFormulaire: BlocFormDto[];
    constructor(){}
}
