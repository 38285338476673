// technical
export const APP_BACK_OFFICE = 'bo';
export const APP_FRONT_OFFICE = 'api';
export const TOKEN_LOCAL_NAME = 'access_token';
export const REFRESH_TOKEN_LOCAL_NAME = 'refresh_token';
export const TOKEN_EXPIRE_DATE_NAME = 'expire_date';

export const SESSION_TAB_ID = "sessionTabId";
// survey FORM RESPONSE TYPE

export const SURVEY_RESPONSE_TYPE_PROJECT = "PROJECT";

// TABS IDS

export const DOSSIER_TAB_IDS = {
    questionId: 'questionId',
    editId: 'editDossier',
    uploadFecId: 'upload-fec',
    filesId: 'files',
    controlId: 'controle',
    codeJournalId: 'codeJournalId',
    balanceId: 'balanceId'
};


// FILE
export enum FileType {
    FEC = "FEC",
    CA3 = "CA3",
    DEB_INTRODUCTION = "DEB_INTRODUCTION",
    DEB_EXPEDITION = "DEB_EXPEDITION",
    DES = "DES",
    LIASSE = "LIASSE",
    ARCHIVE = "ARCHIVE",
    ANY = "ANY"
}


export const FILE_FORMAT_PDF = "PDF";
export const FILE_FORMAT_CSV = "CSV";
export const FILE_FORMAT_EDI = "EDI";
export const FILE_FORMAT_XML = "XML";
export const FILE_FORMAT_ARCHIVE = "ARCHIVE";

export const FILE_STATUS_UPLOADING = "UPLOADING";
export const FILE_STATUS_UPLOADED = "UPLOADED";
export const FILE_STATUS_DONE = "DONE";

export enum FileStatus {
    ERROR_FEC = "ERROR_FEC",
    UPLOADING = "UPLOADING",
    UPLOADED = "UPLOADED",
    QUESTION_SAME_PERIODE = "QUESTION_SAME_PERIODE",
    QUESTION_WRONG_EXERCICE = "QUESTION_WRONG_EXERCICE",
    QUESTION_WRONG_SIREN = "QUESTION_WRONG_SIREN",
    NOT_AUTHORIZED = "NOT_AUTHORIZED",
    EXTRACTING = "EXTRACTING",
    EXTRACTED = "EXTRACTED",
    ERROR = "ERROR"
}

let fileTypes = {};
fileTypes[FileType.FEC] = 'FEC';
fileTypes[FileType.CA3] = 'CA3';
fileTypes[FileType.DEB_INTRODUCTION] = 'DEB (Intro)';
fileTypes[FileType.DEB_EXPEDITION] = 'DEB (Exped)';
fileTypes[FileType.DES] = 'DES';
fileTypes[FileType.LIASSE] = 'Liasse fiscal';
fileTypes[FileType.ARCHIVE] = 'Archive';

export const FILE_TYPES = fileTypes;


// SCRIPT
export const SCRIPT_TAB_IDS = {
    nashorn: 'javascript',
    python: 'python'
};

// data type
export const DATA_TYPE_NUMERIC = "NUMERIC";
export const DATA_TYPE_STRING = "STRING";
export const DATA_TYPE_DATE = "DATE";
export const DATA_TYPE_DATE_LIST = "DATE_LIST";
export const DATA_TYPE_MAP = "MAP";


export const TYPE_CONFIG_CERFA_EDI = "cerfaEdi";
export const TYPE_CONFIG_CCI_CAV = "cciCav";

// calculated value code
export const CALUCLATED_VALUE_CODE_JOURNAUX = "categoriesJournaux";
export const CALUCLATED_VALUE_BALANCE = "balance";
export const CALUCLATED_VALUE_TVA_CONTROLE_2 = "liste_comptes_classe_7";
export const CALCULATED_VALUE_COMPTES_401_NO_TVA_DEDUCTIBLE = "liste_comptes_401_journal_achats_sans_tva_deductible";

// dateFormat

export const DATE_FORMAT = {
    'YYYYMMDD': {moment: 'YYYYMMDD', datePipe: 'yyyyMMdd'},
    'MMMM_YYYY': {moment: 'MMMM YYYY', datePipe: 'MMMMM yyyy'},
    'YYYYMM': {moment: 'YYYYMM', datePipe: 'yyyyMM'},
    'DD/MM/YYYY': {moment: 'DD/MM/YYYY', datePipe: 'dd/MM/yyyy'},
    'DD/MM/YYYY HH:mm': {moment: 'DD/MM/YYYY HH:mm', datePipe: 'dd/MM/yyyy HH:mm'},
    'DD/MM/YYYY à HH:mm': {moment: 'DD/MM/YYYY à HH:mm', datePipe: 'dd/MM/yyyy à HH:mm'},
    'DD/MM/YYYY à HH:mm:ss': {moment: 'DD/MM/YYYY à HH:mm:ss', datePipe: 'dd/MM/yyyy à HH:mm:ss'}
};

export enum COMPLIANCE_TYPE_EXCEPTION {
    FUNCTIONAL = 'FUNCTIONAL',
    OTHERS = 'OTHERS'
}

//global regexp
export const PHONE_REGEX = "^(?:(?:\\+|00)33[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[1-9](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$";

export const CONTROL_FILE_VIE_ID = "control_file_vies";
