<div id="content" class="page-botom-transparent height-100 slimscroll">
    <div class="container-fluid height-100 d-flex flex-column">
        <div class="block-group-title">
            <h2 class="title">{{'video.list.title' | translate}}</h2>
        </div>

        <div class="content col" *ngIf="videoList">
            <div *ngFor="let category of videoList">
                <ul class="list">
                    <li class="title">{{('video.list.categories.' + category.category) | translate}}</li>
                    <li class="video" *ngFor="let video of category.values"
                        (click)="showVideo(video)">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="btn-camera">
                                <button class="btn btn-heroic-secondary">
                                    <i class="fal fa-film-alt text-white text-size-18"></i>
                                </button>
                            </div>

                            <p class="flex-fill col-12">
                                <span>{{video.label}}</span><br>
                            </p>
                        </div>
                    </li>
                </ul>
            </div>



            <div class="block-bottom-actions">
                <div class="inner">
                    <button href="#" class="btn btn-primary btn-back" (click)="goBack()">
                        <span>{{'buttons.back' | translate}}</span></button>
                </div>
            </div>
        </div>
