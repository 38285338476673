<div class="row justify-content-center  " >

    <div class="col-10 align-self-center z-depth-3 p-4" >
<!--        <ngb-tabset [destroyOnHide]="false" (tabChange)="changeTab($event)">-->
<!--            <ngb-tab [id]="tabs.idTabUser">-->
<!--                <ng-template ngbTabTitle>-->
<!--                    <span *ngIf="user">{{user.firstName}} {{user.lastName}}</span>-->
<!--                </ng-template>-->
<!--                <ng-template ngbTabContent>-->
<!--                    <div class="col-12" >-->
<!--                        <compliance-user-form [accountId]="getAccountId()" [app]="app" [disabledForm]="true" [typeUserToCreate]="typeUserToCreate" [user]="user" (validFormEventEmitter)="setUserFormValid($event)"></compliance-user-form>-->
<!--                    </div>-->
<!--                </ng-template>-->
<!--            </ngb-tab>-->
<!--            <ngb-tab [id]="tabs.idTabUserList">-->
<!--                <ng-template ngbTabTitle>{{'user.edit.tabs.userListTitle' | translate}}</ng-template>-->
<!--                <ng-template ngbTabContent>-->
<!--                    <compliance-account-users [app]="app" [accountId]="getAccountId()" [users]="users"></compliance-account-users>-->
<!--                </ng-template>-->
<!--            </ngb-tab>-->
<!--        </ngb-tabset>-->


    </div>
</div>
