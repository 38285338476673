import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComplianceSharedModule} from "../../../../../common/compliance-shared.module";
import {CodesJournauxComponent} from './fec/codes-journaux/codes-journaux.component';
import {BalanceComptableComponent} from './fec/balance-comptable/balance-comptable.component';
import {TableModule} from "../../shared/table/table.module";
import {ControlModule} from "./control/control.module";
import {CalculatedValueService} from "../../../../../common/service/control/calculated-value.service";
import {InformationPageComponent} from "./information-page/information-page.component";
import {TvaRoutingModule} from "./tva-routing.module";
import {VideoComponent} from "./video/video.component";
import {VideoListComponent} from "./video/video-list/video-list.component";

@NgModule({
    imports: [
        CommonModule,
        ComplianceSharedModule,
        TableModule,
        ControlModule,
        TvaRoutingModule
    ],
    providers: [CalculatedValueService],
    declarations: [
        CodesJournauxComponent,
        BalanceComptableComponent,
        InformationPageComponent,
        VideoComponent,
        VideoListComponent,
    ],
    exports:[ CodesJournauxComponent]
})
export class TvaModule {
}
