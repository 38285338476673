<div class="height-100">
    <div class="row justify-content-center height-100 bg-login">
        <div class="col-6 align-self-center text-size-11">
            <form [formGroup]="form" novalidate autocomplete="off" class="form-basic text-left">
                <div class="form-group">
                    <label for="password">{{ i18n.createPassword.passwordLabel }}</label>
                    <input type="password" class="form-control" id="password" formControlName="password"
                           (keyup.enter)="specifyPassword($event);"
                           (keyup)="validate();"
                           aria-describedby="passwordHelp">

                    <div *ngIf="form.controls['password'].errors && form.controls['password'].dirty" id="passwordHelp" class="error-alert">
                        <div [hidden]="!form.controls['password'].errors.required">
                            {{ i18n.createPassword.error.password.required }}
                        </div>
                        <div [hidden]="!form.controls['password'].errors.pattern">
                            <div>{{ i18n.createPassword.error.password.pattern }}</div>
                            <div>{{ i18n.createPassword.error.password.specialCharacters }}</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="confirmPassword">{{ i18n.createPassword.passwordConfirmationLabel }}</label>
                    <input type="password" class="form-control" id="confirmPassword" formControlName="confirmPassword"
                           (keyup.enter)="specifyPassword($event);"
                           aria-describedby="confirmPasswordHelp">

                    <div *ngIf="form.controls.confirmPassword.errors && form.controls['confirmPassword'].dirty" id="passwordConfirmationHelp" class="error-alert">
                        <div [hidden]="!form.controls['confirmPassword'].errors.matchPassword">
                            {{ i18n.createPassword.error.password.confirmPassword }}
                        </div>
                    </div>
                </div>

            </form>
            <div class="d-flex justify-content-between mt-5">
                <button class="btn btn-primary" type="reset">{{ i18n.createPassword.button.cancel }}</button>
                <button class="btn btn-primary" type="button" (click)="specifyPassword($event)"
                        [disabled]="form.invalid">{{ i18n.createPassword.button.create }}
                </button>
            </div>
        </div>
    </div>
</div>
