import {BlocText} from "../../text/bloc-text";
import {Expression, Member} from "../meta-expression";
import {FormInput} from "../meta-formulaire";
import _ from 'lodash';


export enum QuestionType {
    PERIODE = 'PERIODE',
    CHOICE = 'CHOICE',
    SELECTION = 'SELECTION',
    FORM = 'FORM',
}

export enum PeriodeType {
    DAY = 'DAY',
    MONTH = 'MONTH',
}

export enum ActionServiceType {
    LAUNCH_CONTROL = 'LAUNCH_CONTROL',
    BACK_CONTROL = 'BACK_CONTROL',
    CALCULATED_VALUE = 'CALCULATED_VALUE',
}

export enum RouteCode {
    LIST_CONTROL = 'LIST_CONTROL',
    GRAPH_GLOBAL_PARAMS = 'GRAPH_GLOBAL_PARAMS'
}

interface QuestionServiceMeta {
    type: ActionServiceType;
    params?: any;
    // custom?: { uriToken: string, app: string, relativeUri?: string, method: string }
}

export class Button {
    display?: Expression;
    active?: Expression;
    actions?: ActionQuestion[];
    text: { label?: string, icon?: string };
    secondary?:boolean;
}

export interface ProcessValue {
    field: string;
    expression: Expression | Member;
}


export class MetaModal {
    header: BlocText;
    content: BlocText;
    closeIcon?: boolean;
    autoClose: boolean;
    buttons?: Button[];
}

export interface ActionQuestion {
    service?: QuestionServiceMeta;
    processValue?: ProcessValue;
    routing?: RouteCode;
    modal?: MetaModal;
    executeIf?: Expression;
}

export class Question {
    title: BlocText;
    asterix?: BlocText;
    subtitles?: BlocText[];
    buttons?: { [key: string]: Button };
    data?: any;
    responseName?: string;

    constructor(val: Question) {
        _.assignIn(this, val);
        // this.title = val.title;
        // this.asterix = val.asterix;
        // this.subtitles = val.subtitles;
        // this.buttons = val.buttons
        // this.data = val.data;
        // this.responseName = val.responseName;
    }
}

export class PeriodeQuestion extends Question {
    dateDebutPeriode: string;
    dateDebutPeriodeFormat?: string;
    dateFinPeriode: string;
    dateFinPeriodeFormat?: string;
    periodeType: PeriodeType;
    fromFirst?: boolean;

    constructor(val: PeriodeQuestion) {
        super(val);
        this.dateDebutPeriode = val.dateDebutPeriode;
        this.dateDebutPeriodeFormat = val.dateDebutPeriodeFormat;
        this.dateFinPeriode = val.dateFinPeriode;
        this.dateFinPeriodeFormat = val.dateFinPeriodeFormat;
        this.periodeType = val.periodeType;
        this.fromFirst = _.isNil(val.fromFirst) ? true : val.fromFirst;
    }
}

export class ChoiceQuestionItem {
    actions?: ActionQuestion[];
    [keys: string]: any
}

export class MultipleChoiceQuestion extends Question {
    labelField?: string;
    codeField?: string;
    orderField?: number;
    suffix?: string;
    items: ChoiceQuestionItem[];
    formulaire?: FormInput[];
    multiple?: boolean;

    constructor(val: MultipleChoiceQuestion) {
        super(val);
        this.labelField = val.labelField || 'label';
        this.codeField = val.codeField || 'code';
        this.orderField = val.orderField;
        this.items = val.items;
        this.formulaire = val.formulaire;
        this.suffix = val.suffix || '';
        this.multiple = (val.multiple === undefined || val.multiple === null) ? true : val.multiple;
    }
}

export class FormQuestion extends Question {
    formulaire: FormInput[];

    constructor(val: FormQuestion) {
        super(val);
        this.formulaire = val.formulaire;
    }
}

export class MetaQuestion<T extends Question> {
    code: QuestionType;
    question: T;
    dataSaveTarget?: string;

    constructor(code: QuestionType, question: T) {
        this.code = code;
        this.question = question;
    }
}

export enum QuestionEventType {
    ITEM,
    GLOBAL
}

export interface QuestionEvent {
    type: QuestionEventType;
    data: any;
}
