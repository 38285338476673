export const report = {
    elements: {
        page_de_garde: 'Page de garde',
        infos_control: 'Présentation',
        rd: 'Résultats',
        origine_incoherences: 'Origines des incohérences',
        notes: 'Notes',
        observations: 'Observations',
        fixes: 'Corrections',
        risque_tva: 'Risque TVA',
        conclusion: 'Conclusion'
    }
};
