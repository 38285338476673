import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../../../../common/class/compliance-api.service";
import {ExportFile, TYPE_EXPORT, TypeExport} from "./export-file";
import {RestService} from "../../../../../common/service/rest.service";
import {SESSION_TAB_ID} from "../../../../../common/class/compliance-constant";

@Injectable({
    providedIn: 'root'
})
export class ExportFileControlService extends ComplianceApiService {
    private readonly sessionTabId: string;
    export : any = {};

    constructor(private restService: RestService) {
        super('reporting','api');
        this.sessionTabId = sessionStorage.getItem(SESSION_TAB_ID);
        this.export[TYPE_EXPORT.xlsx.realtiveUri]={export : this.exportXlsxFile}
    }

    // async exportFile(typeExport: TypeExport, fileName:string, parameters:string[]) {
    //     let blob:Blob = await this.export[typeExport.type.realtiveUri].export.call(this, parameters);
    //
    //     this.fileSaverService.saveFile(fileName, blob, typeExport);
    // }


    exportXlsxFile(parameters:string[]): Promise<any>{
        parameters.push(this.sessionTabId);
        return this.restService.getFile({
            url:this.baseUri,
            relativeUri : `${TYPE_EXPORT.xlsx.realtiveUri}`,
            parameters: parameters,
        })
    }

    private computeFileName(fileName: string, typeExport: TypeExport) {
        return `${fileName}.${typeExport.type.extention}`;
    }
}
