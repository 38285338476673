<a *ngIf="!exportLoading" href="#" class="text-white" (click)="export(exportBlock.type[0])">
    <!--<i class="fal fa-file-download fa-4x pt-2"></i>-->
    <i class="fal fa-file-spreadsheet fa-4x pt-2"></i>
    <div class="text-size-16">{{exportBlock.label || 'Excel'}}</div>
</a>
<a *ngIf="exportLoading" href="#" class="text-white" [ngbTooltip]="tooltip">
    <i class="fas fa-spinner fa-pulse fa-4x pt-2"></i>
    <div class="text-size-16">{{exportBlock.label || 'Excel'}}</div>
    <ng-template #tooltip>
        <div [innerHtml]="'affectation.mainContent.table.exportLoadingLabel' | translate | safe:'html'"></div>
    </ng-template>
</a>
