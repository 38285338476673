import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';

import {AuthService} from "../../../../../common/service/auth/auth.service";
import {HabilitationService} from "../../../../../common/service/auth/habilitation.service";
import {ComplianceToastrService} from "../../../../../common/service/toastr/compliance-toastr.service";
import {Observable} from "rxjs";
import {CookieGuard} from "./cookie.guard";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private authService: AuthService, private router: Router, private habilitationService: HabilitationService, private toastr: ComplianceToastrService, private cookieGuerd:CookieGuard) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkAuth(next, state);
    }

    canActivateChild(next: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        return this.checkAuth(next, state);
    }

    private checkAuth(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.isOauth2Expire()) {
            let url: string = state.url;

            this.authService.setRedirectUrl(url);
            this.authService.resetAuthValues();
            this.router.navigate(['/login']);
            return false;
        }
        // if (!this.authService.getConnectedUser()){
        //     this.authService.setConnectedUser();
        // }

        console.log(arguments);
        return this.checkRolesRight(next);
    }


    checkRolesRight(next: ActivatedRouteSnapshot): boolean {
        if (!this.habilitationService.isCguRead() && next.routeConfig.path.indexOf('cgvu') < 0) {
            this.router.navigate(['/compliance/cgvu']);
            return false;
        }
        if (this.habilitationService.isCguRead() && next.routeConfig.path.indexOf('cgvu') >= 0) {
            if (this.habilitationService.hasMakeCookieChoice()) {
                this.router.navigate(['/compliance/home']);
            } else {
                this.router.navigate(['compliance', 'gestion-cookie']);
            }
            return false;
        }

        const checkCookie = this.cookieGuerd.checkCookie(next);
        if(!checkCookie) return checkCookie;


        let module = next.data.module;
        if (!module) {
            return true;
        }
        let isAuthorized = this.habilitationService.isAuthorized(module);

        if (!isAuthorized) {
            this.toastr.errorI18n("security.unauthorized", "security.title");
            this.router.navigate(['/']);
        }

        return isAuthorized;
    }

}
