<compliance-login-header></compliance-login-header>
<div class="page-login">

    <div class="container">
        <div class="row">

            <div class="col-xl-12">
                <div class="block-group-title text-white">
                    <h2 class="title">{{'createPassword.title' | translate}}</h2>
                </div>

            </div>
        </div>

        <create-password-form [i18n]="i18n" [tempId]="tempId" (saveEmitter)="specifyPassword($event)"></create-password-form>
    </div>
</div>
