<p [ngClass]="{'pro-tip': meta.blocType && meta.blocType === 'conseil'}">
    <span *ngIf="meta.blocType && meta.blocType === 'attention' " class="warning">ATTENTION : </span>
    <span [innerHtml]="meta.text | safe:'html'"></span>
    <span class="ml-1" *ngIf="meta.export">&emsp;</span>
    <button class="btn btn-primary btn-xs ml-2" *ngIf="meta.export && !exporting" (click)="exportAction()">
        <span *ngIf="meta.export.ecriture?.label" [innerHtml]="meta.export.ecriture.label"></span><i class="fal fa-search"></i>
    </button>
    <button class="btn btn-primary btn-xs ml-2" *ngIf="meta.export && exporting">
        <span *ngIf="meta.export.ecriture?.label" [innerHtml]="meta.export.ecriture.label"></span><i class="fas fa-spinner fa-pulse"></i>
    </button>
</p>
