import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";
import {ControlDescriptor} from "../../class/control/control-descriptor";

@Injectable()
export class ControlFileService extends ComplianceApiService {

    constructor(private restService: RestService) {
        super("controlFile");
    }

    findControlVies(): Promise<ControlDescriptor>{
        return this.restService.get({
            url: this.baseUri,
            relativeUri: 'vies',
            arg: null as ControlDescriptor
        })
    }
}
