import {Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {BgSpaceComponent} from "../../../../../../../common/class/background/bg-compliance";
import {FormBuilder, FormGroup} from "@angular/forms";
import {VALIDATION_TYPE} from "../../../../../../../common/class/control/supervision";
import {CategoryControl, DomaineControl} from "../../../../../../../common/class/control/control-descriptor";
import {ActivatedRoute, Router} from "@angular/router";
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from "rxjs/operators";
import {ExerciceService} from "../../../../../../../common/service/exercice/exercice.service";
import {Exercice} from "../../../../../../../common/class/exercice/exercice";
import {Editor} from "primeng/editor";
import {merge, Subject} from "rxjs";
import {COMPLIANCE_TYPE_EXCEPTION} from "../../../../../../../common/class/compliance-constant";
import {ComplianceToastrService} from "../../../../../../../common/service/toastr/compliance-toastr.service";
import {ComplianceLoaderService} from "../../../../../../../common/service/loader/compliance-loader.service";


@Component({
    selector: 'compliance-exercice-create',
    templateUrl: './exercice-supervision.component.html',
    styleUrls: ['./exercice-supervision.component.scss']
})
export class ExerciceSupervisionComponent extends BgSpaceComponent implements OnInit, OnDestroy {
    public projectId: string;
    public domain: DomaineControl;
    public category: CategoryControl;
    public exerciceId: string;

    supervisionForm: FormGroup;
    validationType = VALIDATION_TYPE;
    private _exercice: Exercice;
    private _oldValue: any;
    private error: any;
    @ViewChildren(Editor) editors:QueryList<Editor>;
    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor( private _router: Router, private _route: ActivatedRoute,
                private _fb: FormBuilder, private _toastr: ComplianceToastrService,
                private _exerciceService: ExerciceService, private _complianceLoaderService:ComplianceLoaderService) {
        super();

        this.projectId = _route.snapshot.paramMap.get('projectId');
        this.domain = _route.snapshot.paramMap.get('domain') as DomaineControl;
        this.category = _route.snapshot.paramMap.get('category') as CategoryControl;
        this.exerciceId = _route.snapshot.paramMap.get('exerciceId');
    }

    ngOnInit() {
        this.supervisionForm = this._fb.group({
            observations: null,
            conclusion: null,
            validation: null
        });
        this.getDatas();
    }

    private async getDatas() {
        this._exerciceService.findExercicesById(this.exerciceId).then((val: Exercice) => {
            this._exercice = val;
            this._complianceLoaderService.sendLoaderHide();
            this._oldValue = val;
            this.supervisionForm.patchValue(val);
            this.editors.forEach((editor) => editor.quill.enable(!val.validation));

            this.supervisionForm.get('validation').valueChanges.pipe(
                takeUntil(this.destroy$),
                debounceTime(200),
                distinctUntilChanged(),
                filter((data) => this._oldValue.validation !== data),
            ).subscribe((data) => {
                this._complianceLoaderService.sendLoaderShow();
                const value = {...this._exercice, ...this.supervisionForm.value};
                this._exerciceService.updateExerciceSupervision(value).then((ret) => {
                    this._oldValue = value;
                    this.editors.forEach((editor) => editor.quill.enable(!value.validation));
                    this._complianceLoaderService.sendLoaderHide();
                }).catch((err) => {
                    this.supervisionForm.patchValue(this._oldValue);
                    if (err.error.type === COMPLIANCE_TYPE_EXCEPTION.FUNCTIONAL) {
                        this.error = err;
                    }
                    this._complianceLoaderService.sendLoaderHide();
                });
            });

            merge(
                this.supervisionForm.get('observations').valueChanges,
                this.supervisionForm.get('conclusion').valueChanges
            ).pipe(
                takeUntil(this.destroy$),
                debounceTime(200),
                map(() => this.supervisionForm.value),
                distinctUntilChanged(),
            ).subscribe((data) => {
                const value = {...this._exercice, ...data};
                this._exerciceService.updateExerciceSupervision(value).then((ret) => {
                    this._oldValue = value;
                });
            });
        });
    }

    goDashboard() {
        this._complianceLoaderService.sendLoaderShow();
        if (this.supervisionForm.value.validation) {
            this._router.navigate(['compliance', this.domain, this.category, 'project', this.projectId, this.exerciceId, 'dashboard']);
        } else {
            this._exerciceService.updateExerciceSupervision({...this._exercice, ...this.supervisionForm.value}).then((ret) => {
                this._router.navigate(['compliance', this.domain, this.category, 'project', this.projectId, this.exerciceId, 'dashboard']);
            });
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
