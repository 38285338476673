import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {CategoryControl, DomaineControl} from "../../../../../common/class/control/control-descriptor";
import {HabilitationService} from "../../../../../common/service/auth/habilitation.service";
import {TvaSubscriptionService} from "../../../../../common/service/subscriptions/tva-subscription.service";
import {FlecTVASubscription} from "../../../../../common/class/subscription/subscription";
import {ComplianceLoaderService} from "../../../../../common/service/loader/compliance-loader.service";

@Component({
    selector: 'compliance-domaine-choice',
    templateUrl: './domaine-choice.component.html',
    styleUrls: ['./domaine-choice.component.scss']
})
export class DomaineChoiceComponent implements OnInit, AfterViewInit, AfterViewChecked {
    @ViewChild('upperTree') upperTreeEl: ElementRef;
    @ViewChild('lowerTree') lowerTreeEl: ElementRef;
    showUpperTree = false;
    scrolled = false;
    domain = DomaineControl;
    category = CategoryControl;
    private _domainChoice: DomaineControl;
    private _categoryChoice: CategoryControl;

    constructor(private _router: Router, private _habilitationService: HabilitationService, private _tvaSubscriptionService: TvaSubscriptionService, private _complianceLoaderService: ComplianceLoaderService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        // this.lowerTreeEl.nativeElement.scrollIntoView();
    }

    ngAfterViewChecked(): void {
        if (!this.scrolled) {
            this.lowerTreeEl.nativeElement.scrollIntoView();
            this.scrolled = true;
        }
    }

    scrollToUpperTree(domain: DomaineControl) {
        this._domainChoice = domain;
        this.upperTreeEl.nativeElement.scrollIntoView({block: 'start', behavior: 'smooth'});
        this.showUpperTree = true;
    }

    executeBackAction() {
        if (this.showUpperTree) {
            this.lowerTreeEl.nativeElement.scrollIntoView({block: 'end', behavior: 'smooth'});
            this.showUpperTree = false;
        }
    }

    goToProject(category: CategoryControl) {
        this._categoryChoice = category;
        this._tvaSubscriptionService.getUserSubscription().then((subscriptionDetails: FlecTVASubscription) => {
            if (subscriptionDetails.showInformationPage && !this._habilitationService.hasReadLightPage()) {
                this._router.navigate(['/compliance', this._domainChoice, this._categoryChoice, 'information-page']);
            } else {
                this._router.navigate(['/compliance', this._domainChoice, this._categoryChoice, 'project', 'list']);
            }

        });
    }
}
