import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ComplianceComponent} from "../compliance.component";
import {AuthGuard} from "../../shared/auth/auth.guard";
import {UserEditComponent} from "./user-edit/user-edit.component";
import {MODULE_USER} from "../../../../../common/class/auth/habilitation";
import {UserAccountComponent} from "./account-users/user-account.component";

const routes: Routes = [
    {
        path: 'compliance',
        component: ComplianceComponent,
        canActivateChild: [AuthGuard],
        children:
            [
                {
                    path: 'user/:type/:userId',
                    component: UserEditComponent,
                    data:{
                        module : MODULE_USER
                    }
                },
                {
                    path: 'user/compte',
                    component: UserAccountComponent,
                    data: {
                        title: "breadcrumb.account"
                    }
                },
                {
                    path: 'user/:type/:tab/:userId',
                    component: UserEditComponent,
                    data:{
                        module : MODULE_USER
                    }
                },
            ]
    }]
;


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UserRoutingModule {
}
