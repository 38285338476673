<!-- START : content -->
<div id="" class="height-100 mx-auto w-70 text-center table-selection">
    <div class="d-flex flex-column">
        <div class="block-group-title">
            <h2 class="title" [innerHtml]="question?.title?.text | translate | safe:'html'"></h2>
            <h2 class="sub-title" *ngFor="let subtitle of question?.subtitles" [innerHtml]="subtitle?.text | translate | safe:'html'"></h2>
        </div>
    </div>
    <div class="block-table scroll-table">
        <p-table [value]="choice" [styleClass]="'table-data table-data--02'" [dataKey]="'code'" sortField="code" [sortOrder]="1">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th >
                        <span [innerHTML]="'question.tableauSelectionSpecifique.headers.code' | translate | safe:'html'"></span>
                    </th>
                    <th class="label-long">
                        <span [innerHTML]="'question.tableauSelectionSpecifique.headers.label' | translate | safe:'html'"></span>
                    </th>
                    <th class="text-white justify-content-end select-all no-select"><span (click)="selectAll()">{{(isAllSelected() ? 'buttons.unselectAll' : 'buttons.selectAll') | translate}}</span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-j>
                <tr>
                    <th class="{{j.checked ? '' : 'unchecked' }}">{{j.code}}</th>
                    <td class="{{j.checked ? '' : 'unchecked' }} label-long">{{j.label}}</td>
                    <td class="{{j.checked ? '' : 'unchecked' }} check" (click)="setSelectionChecked($event, j)">
                        <div class="custom-control custom-checkbox mb-3">
                            <input type="checkbox" class="custom-control-input" [attr.id]="j.code"
                                   value="{{j.checked}}"
                                   [checked]="j.checked"
                                   (change)="setSelectionChecked($event, j)">
                            <label class="custom-control-label" [attr.for]="j.code">&nbsp;</label>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!--<div class="text-right mb-2">-->
        <!--<div class="btn btn-primary" (click)="saveAppareillage()">{{'buttons.validate' | translate}}</div>-->
        <!--</div>-->
    </div>
</div>
<!-- STOP : content -->

<!-- START : footer -->


<footer id="footer">
    <div class="inner">
        <a href="#" class="btn btn-primary btn-back" (click)="goBack()">
            <span>{{(isControl ? question.buttons.back.text.label : 'buttons.back') | translate}}</span>
        </a>
        <button class="btn btn-primary btn-next" [disabled]="!nextActive" (click)="goNext()">
            <span>{{(isControl ? question.buttons.next.text.label : 'buttons.finished') | translate}}</span>
        </button>
    </div>
</footer>
<!-- STOP : footer -->
