import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {LoggingService} from "./logging.service";
import {AuthService} from "../../../../../common/service/auth/auth.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {


    constructor(private _injector: Injector) {
    }

    handleError(error: any): void {
        const loggingService = this._injector.get(LoggingService);
        const authService = this._injector.get(AuthService);
        const detectorService = this._injector.get(DeviceDetectorService);
        let connectedUser = authService.getConnectedUser();
        let userId = connectedUser ? connectedUser.id : null;
        loggingService.logToBack({
            userId: userId,
            browser: detectorService.browser + ' / ' + detectorService.browser_version,
            error: error.message,
            stack: error.stack
        }).then(() => console.error(error));

    }
}
