export const video = {
    title: 'Vidéo de présentation',
    list: {
        title: 'Liste vidéos explicatives',
        categories: {
            fichiers: 'Fichiers',
            control: 'Contrôles',
            general: 'Général'
        }
    }
};
