import {Component, Input, OnInit} from '@angular/core';
import {Graphe} from "../../../../../../../../../../common/class/graphique/graphique";

@Component({
    selector: 'compliance-graph-block',
    templateUrl: './graph-block.component.html',
    styleUrls: ['./graph-block.component.scss']
})
export class GraphBlockComponent implements OnInit {

    @Input() graphBlock: Graphe;
    constructor() {
    }

    ngOnInit() {
    }

}
