import {Component, OnInit} from '@angular/core';
import {AbstractControl} from "../../../../../../../../../common/component/control/abstract-control";
import {BackgroungType} from "../../../../../../../../../common/class/background/bg-compliance";
import {NotificationService} from "../../../../../../../../../common/service/push/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxFileDropEntry} from "ngx-file-drop";
import {FileUtilsService} from "../../../../../../../../../common/service/file/file-utils.service";
import {ComplianceToastrService} from "../../../../../../../../../common/service/toastr/compliance-toastr.service";
import {ViesService} from "../../../../../../shared/upload-file/drag-n-drop/shared/vies.service";
import {takeUntil} from "rxjs/operators";
import moment from "moment";
import {FileSaverService} from "ngx-filesaver";
import {UploadInfo} from "../../../../../../../../../common/class/upload/upload-info";
import {HelpService} from "../../../../../../shared/export-file/help.service";
import {TYPE_EXPORT, TypeExport} from "../../../../../../shared/export-file/export-file";

@Component({
    selector: 'compliance-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.css']
})
export class UploadComponent extends AbstractControl implements OnInit {
    errorMessage: any;
    question = false;
    public uploadInfo: UploadInfo;
    private authorizedExtention: string[] = ['csv'];

    constructor(private _notificationService: NotificationService, private _route: ActivatedRoute, private _router: Router,
                private _fileUtils: FileUtilsService, private _toastr: ComplianceToastrService, private _viesService: ViesService,
                private _fileSaverService: FileSaverService, private _helpService: HelpService) {
        super(BackgroungType.BG_FOREST);
    }

    ngOnInit(): void {
        super.setContextWithRoute(this._route,() => {
        });
        super.subscribeToNotificationControl({
            notificationService: this._notificationService
        });
        this._notificationService.fileNotificationObservable$
            .pipe(takeUntil(this.destroy$))
            .subscribe((msg) => {
                if (msg.error) {
                    this._toastr.error(msg.error);
                }
                if (msg.blob) {
                    const fileName = moment().format("YYYYMMDD_HHMMSS") + "_vies.xlsx";
                    this._fileSaverService.save(this._fileUtils.blobPdfFromBase64String(msg.blob), fileName);
                }
                this._complianceLoaderService.sendLoaderHide();
            });
    }

    onFilesChange(fileList: NgxFileDropEntry[]) {
        this._fileUtils.ngxFileChange({
            toastr: this._toastr,
            translate: this.translate,
            fileList: fileList,
            upload: (file:File)=>this.upload(file)
        })

    }

    private upload(file) {
        this.errorMessage = null;
        if (this._fileUtils.isExtensionAuthorized(file, this.authorizedExtention)) {
            this._complianceLoaderService.sendLoaderShow();
            this._viesService.exportCsv(file, this.projectId, this.exerciceId);
        } else {
            this.uploadInfo = this._fileUtils.fileToUploadInfoSimple(file, this.projectId);
            this.setError(super.getValue('uploadFile.uploadScreen.vies.error.extension'));
        }
    }

    private setError(message) {
        this.errorMessage = message;
        this.question = true;
    }

    uploadHandler(event) {
        this._fileUtils.uploadHandler({
            toastr: this._toastr,
            translate: this.translate,
            fileList: event.target.files,
            upload: (file:File)=>this.upload(file)
        });
        if (event.target) {
            event.target.value = null;
            event.files = [];
        }
    }

    goBack():void {
        this._router.navigate([this._notificationService.generateControlRoute(this.projectId, this.domain, this.category, null, 'list', this.exerciceId)]);
    }

    downloadVies(num, fileName) {
        this._helpService.downloadViesHelp(num).then(blob => {
            this._fileUtils.saveFile(fileName, blob, new TypeExport(TYPE_EXPORT.csv));
        });
    }
}
