import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableComponent} from './table.component';
import {ComplianceSharedModule} from "../../../../../common/compliance-shared.module";
import {NgScrollbarModule} from "ngx-scrollbar";
import {ValueComponent} from './value/value.component';
import {DecimalNoRoundingPipe} from './shared/decimal-no-rounding.pipe';
import {AffectationComponent} from './affectation/affectation.component';
import {TableFormComponent} from './table-form/table-form.component';

@NgModule({
    declarations: [TableComponent, ValueComponent, DecimalNoRoundingPipe, AffectationComponent, TableFormComponent],
    imports: [
        CommonModule,
        ComplianceSharedModule,
        NgScrollbarModule
    ],
    exports: [
        TableComponent, DecimalNoRoundingPipe
    ]
})
export class TableModule {
}
