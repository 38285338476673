<!-- START : content -->
<div class="page-report compte-rendu-general height-100" *ngIf="compteRendu">
    <div class="container height-100 position-relative">
        <div class="row">
            <div class="col-xl-12">
                <div class="block-group-title">
                    <h2 class="title">{{'compteRendu.general.title' | translate}}</h2>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!compteRendu?.noIncoherence; else noIncoherence">
            <ng-container *ngIf="!compteRendu.compteRenduSpecific; else specifique">
                <div class="row incoherence">
                    <div class="colunm col-4">
                        <div class="in">
                            <h3 class="title">{{compteRendu.grapheContainer.titre}}</h3>
                            <div class="row">
                                <ng-container *ngFor="let graph of compteRendu.grapheContainer.graphes">
                                    <div class="block-charts col-12" *ngIf="graph.showGraph">
                                        <compliance-graph [graph]="graph"></compliance-graph>
                                    </div>
                                </ng-container>
                            </div>

                        </div>
                    </div>
                    <div class="colunm col-4">
                        <compliance-general-rapport-detaille
                            [rapportDetaille]="compteRendu.rapportDetaille" [extendedData]="compteRendu.values" [destroy$]="destroy$"></compliance-general-rapport-detaille>

                    </div>
                    <div class="colunm col-4">
                        <div class="in height-100 position-relative">
                            <h3 class="title">{{compteRendu.chiffresCles.titre}}</h3>
                            <ul class="block-key-numbers d-flex flex-column justify-content-between">
                                <ng-container *ngFor="let bloc of compteRendu.chiffresCles.blocs">
                                    <compliance-key-number [bloc]="bloc"></compliance-key-number>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-template #noIncoherence>
            <div class="noIncoherence">
                <div class="check-circle">
                    <i class="fal fa-check-circle"></i>
                </div>
                <h1>{{'tva.controle2.resultNoIncoherence' | translate}}</h1>
            </div>
        </ng-template>
        <ng-template #specifique>
            <div class="noIncoherence">
                <div class="check-circle-small">
                    <i class="fal fa-exclamation-triangle"></i>
                </div>
                <div class="colunm">
                    <compliance-general-rapport-detaille
                        [rapportDetaille]="compteRendu.compteRenduSpecific" [extendedData]="compteRendu.values" [destroy$]="destroy$"></compliance-general-rapport-detaille>
                </div>
            </div>
        </ng-template>
    </div>
</div>
<!-- STOP : content -->

<!-- START : footer -->
<footer id="footer">
    <div class="inner">
        <a href="#" class="btn btn-primary btn-back" (click)="goBack()"><span>{{'buttons.back' | translate}}</span></a>

        <a href="#" (click)="goNext()" class="btn btn-primary btn-next">
            <span>{{(compteRendu?.noIncoherence ? 'buttons.goSupervision' : 'buttons.goCrd') | translate}}</span>
        </a>
    </div>
</footer>
<!-- STOP : footer -->
