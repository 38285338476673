import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {REGEX_MAIL} from "../../validator/compliance-validator";

@Component({
    selector: 'compliance-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
    loginForm: FormGroup;
    @Output() loginEmitter: EventEmitter<any> = new EventEmitter();
    @Output() forgotPasswordEmitter: EventEmitter<any> = new EventEmitter();


    constructor(private _fb: FormBuilder) {
        this.createForm();
    }

    login(event: any): void {
        event.stopPropagation();
        event.preventDefault();
        if (!this.loginForm.invalid) {
            this.loginEmitter.emit({
                email: this.loginForm.value.email,
                password: this.loginForm.value.password
            });
        }
    }

    goTo(event: any): void {
        event.stopPropagation();
        event.preventDefault();
        this.forgotPasswordEmitter.emit('/forgottenPassword');
    }

    private createForm() {
        this.loginForm = this._fb.group({
            email: ['', [
                Validators.required,
                Validators.pattern(REGEX_MAIL)
            ]],
            password: ['', Validators.required]
        });
    }
}
