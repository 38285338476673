import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GestionCookiesComponent} from "./gestion-cookies.component";
import {ComplianceComponent} from "../compliance.component";


const routes: Routes = [
    {
        path: 'compliance',
        component: ComplianceComponent,
        children:
            [
                {
                    path: 'gestion-cookie',
                    component: GestionCookiesComponent,
                    data: {
                        title: "breadcrumb.gestionCookie"
                    }
                },
            ]
    }]
;


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class GestionCookiesRoutingModule {
}
