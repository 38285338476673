import {Injectable} from '@angular/core';
import {RestService} from "../rest.service";

import {ComplianceApiService} from "../../class/compliance-api.service";
import {Dossier} from "../../class/dossier/dossier";
import {CacheService} from "../cache/cache.service";
import {Observable, of} from "rxjs";
import {UploadInfo} from '../../class/upload/upload-info';
import {tap} from "rxjs/operators";
import {SESSION_TAB_ID} from "../../class/compliance-constant";

@Injectable()
export class DossierService extends ComplianceApiService {

    private readonly sessionTabId: string;

    constructor(private restService: RestService, private cacheService: CacheService) {
        super('project');
        this.sessionTabId = sessionStorage.getItem(SESSION_TAB_ID);
    }


    async findByAccount(): Promise<Dossier[]> {
        let projects = await this.restService.get({
            url: this.baseUri,
            arg: null as Dossier[]
        });

        this.cacheService.addAnyToCache(projects);
        return of(projects).toPromise();
    }


    saveDossier(dossier: Dossier): Promise<Dossier> {
        if (dossier.id && dossier.id.length > 0) {
            return this.updateDossier(dossier);
        } else {
            return this.createDossier(dossier);
        }
    }

    upload(uploadInfo: UploadInfo): Observable<any> {
        const formData = super.computeFormData(uploadInfo.file);
        return this.restService.uploadRequest({
            url: this.baseUri,
            relativeUri: `extract/siren`,
            parameters: [uploadInfo.uuid, this.sessionTabId],
            data: formData,
            arg: null
        }).pipe(super.uploadProgressEventMap());
    }

    findBySiren(siren: string): Promise<any> {
        return this.restService.get({
            url: this.baseUri,
            relativeUri: "extract/siren",
            parameters: [siren],
            arg: null as any
        });
    }

    async findById(id: string): Promise<Dossier> {
        let cache = this.cacheService.getData(id);
        if (cache) {
            return of(cache).toPromise();
        }
        let data = await this.restService.get({
            url: this.baseUri,
            parameters: [id],
            arg: null as Dossier
        });
        this.cacheService.addToCache(data.id, data);
        return of(data).toPromise();
    }

    private createDossier(dossier: Dossier): Promise<Dossier> {
        return this.restService.post({
            url: this.baseUri,
            data: dossier,
            arg: dossier
        });
    }

    createProject(project: any): Promise<Dossier> {
        return this.restService.post({
            url: this.baseUri,
            data: project,
            arg: null as any
        });
    }

    private updateDossier(dossier: Dossier): Promise<Dossier> {
        return this.restService.put({
            url: this.baseUri,
            data: dossier,
            arg: dossier
        });
    }

    deleteDossiers(dossierIds: string[]): Promise<any> {
        return this.restService.delete({
            url: this.baseUri,
            parameters: [dossierIds.join(',')],
            arg: null
        });
    }

    findSiren(code: string[], type: string): Promise<any> {
        let url = `https://data.opendatasoft.com/api/records/1.0/search/?dataset=sirene%40public&q=${type}%3A${code}`;
        return this.restService.get({
            url: url,
            arg: null
        });

    }

    enableProject(projectId: string): Promise<any> {
        return this.restService.post({
            url: this.baseUri,
            relativeUri: 'enableProject',
            parameters: [projectId],
            data: null,
            arg: null
        });
    }

    getDataCreationDossier(): Observable<any> {
        let cache = this.cacheService.getData('getDataCreationDossier');
        if (cache) {
            return of(cache);
        }
        return this.restService.getObservable({
            url: this.baseUri,
            relativeUri: 'form/ref',
            arg: null
        }).pipe(tap(data => {
            this.cacheService.addToCache('getDataCreationDossier', data);
        }));
    }
}
