<div class="page-login">

    <div class="container">
        <div class="row">
            <div class="col-xl">
                <h1 class="big-logo"></h1>

                <form (ngSubmit)="login($event)" class="form-basic form-signin" [formGroup]="loginForm" novalidate autocomplete="off">
                    <div class="form-group">
                        <input type="text" id="inputEmail" class="form-control" placeholder="{{'signin.emailPlaceholder' | translate}}"
                               autofocus="" autocomplete="off" formControlName="email" (keyup.enter)="login($event);"/>
                    </div>

                    <div class="form-group">
                        <input type="password" id="inputPassword" class="form-control" formControlName="password"
                               placeholder="{{'signin.passwordPlaceholder' | translate}}" autocomplete="off" (keyup.enter)="login($event);"/>
                    </div>

                    <div class="form-group clearfix">
                        <a href="#" class="required float-right" (click)="goTo($event)">{{'signin.forgottenPasswordLink' | translate}}</a>
                    </div>

                    <div *ngIf="loginForm.invalid && !loginForm.pristine" class="error-alert">
                        <div>{{'signin.error.global' | translate}}</div>
                    </div>

                    <button class="btn btn-primary " type="button" (click)="login($event)" [disabled]="loginForm.invalid">{{'signin.signinButton' | translate}}</button>
                </form>

                <div class="block-presentation">
                    <p class="title">{{'signin.presentation.title' | translate}}</p>
                    <p [innerHTML]="'signin.presentation.text' | translate"></p>
                </div>
            </div>
        </div><!-- /row -->
    </div><!-- /container -->

</div>
