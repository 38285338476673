import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DOSSIER_TAB_IDS, FileType} from "../../../../../common/class/compliance-constant";
import {UploadActionNext, UploadActionType} from "../../../../../common/class/upload/upload-constantes";
import {BackgroungType} from "../../../../../common/class/background/bg-compliance";
import {FileService} from "../../../../../common/service/file/file.service";
import {UploadFile} from "../../../../../common/class/upload/upload-file";
import _ from 'lodash';
import moment from 'moment';
import {UploadInfo} from "../../../../../common/class/upload/upload-info";
import {FileUtilsService} from "../../../../../common/service/file/file-utils.service";
import {NotificationMessage} from "../../../../../common/class/polling/notification-message";
import {NotificationService} from "../../../../../common/service/push/notification.service";
import {FileToUpload, UploadData} from "./shared/upload-data";
import {MetaQuestionService} from "../../../../../common/service/meta/meta-question.service";
import {ComplianceRouteService} from "../../../../../common/service/route/compliance-route.service";
import {AbstractControl} from "../../../../../common/component/control/abstract-control";
import {ConfirmationService} from "primeng/api";

@Component({
    selector: 'compliance-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent extends AbstractControl implements OnInit {
    public projectId: string;
    public uploadAction: UploadActionType;
    public fileType: string;
    public typeFileI18n: string;
    private nexAction: string;
    private nextAdress: any = {};
    uploadFiles: UploadFile[];
    errorFile: UploadInfo;
    uploadData: UploadData;
    noFacultatifFileToupload;
    infoLabels: string[];

    private modalHeader: string;
    private modalContent: string;
    private wording: any;

    constructor(private _route: ActivatedRoute, private _router: Router, private _fileService: FileService, private _fileUtils: FileUtilsService,
                private _notificationService: NotificationService, private _metaQuestionService: MetaQuestionService,
                private _routeService: ComplianceRouteService, private _confirmationService: ConfirmationService) {
        super(BackgroungType.BG_FOREST);
    }

    ngOnInit() {
        this.uploadAction = this._route.snapshot.paramMap.get('action') as UploadActionType;
        this.nexAction = this._route.snapshot.queryParamMap.get('next') as UploadActionNext;

        super.setContextWithRoute(this._route, this.findFiles.bind(this));
        super.subscribeToNotificationControl({
            notificationService: this._notificationService,
            manageDataEvent: (n: NotificationMessage) => this.manageDataEvent(n)
        });

        this.typeFileI18n = `uploadFile.uploadScreen`;
        this.setupNextAdress();
        this.wording = super.getValue("gestionFile");
    }

    protected manageDataEvent(notification: NotificationMessage) {
        this.uploadData = notification.corp;
        this.uploadData.fileTypeList = this.uploadData.fileType.split(",").map(value => value as FileType);
        this.uploadData.uploadedFiles = _.sortBy(this.uploadData.uploadedFiles, 'fileName');
        this.uploadData.fileNotExtracted = _.sortBy(this.uploadData.fileNotExtracted, 'fileName');
        _.each(this.uploadData.fileToUpload, (file: FileToUpload) => {
            file.date = moment(file.dateStr, file.format).format(file.displayFormat);
        });
        this.uploadData.fileToUpload = _.sortBy(this.uploadData.fileToUpload, (file: FileToUpload) => file.type + '-' + file.dateStr);
        const filter = _.filter(this.uploadData.fileToUpload, (file) => !file.facultatif);
        this.noFacultatifFileToupload = filter.length + (this.uploadData.fileNotExtracted?this.uploadData.fileNotExtracted.length:0);
        this.setInfoLabels(this.uploadData);
        this._complianceLoaderService.sendLoaderHide();
    }

    async findFiles() {
        this._controlService.dataControl(this.projectId, this.stateMachineId, this.exerciceId, "UPLOAD", this.stepByStep);
    }

    gotToNext() {
        this._complianceLoaderService.sendLoaderShow();
        this._controlService.continueExecutionControl(this.projectId, this.stateMachineId, this.exerciceId, "UPLOAD", {}, this.stepByStep);
    }

    private setupNextAdress() {
        this.nextAdress[UploadActionNext.balance] = `compliance/fec/balance/${this.projectId}/${this.exerciceId}`;
        this.nextAdress[UploadActionNext.project] = `compliance/dossier/${DOSSIER_TAB_IDS.filesId}/${this.projectId}`;
    }

    async deleteFile(uf: UploadFile) {
        let i18n = super.getTranslation();

        this.modalHeader = this.wording.ModalDeleting.headers.deleteOne;
        this.modalContent = this.wording.ModalDeleting.contents.deleteOne.part1 + uf.fileName + this.wording.ModalDeleting.contents.deleteOne.part2;
        this._confirmationService.confirm({
            acceptVisible: true,
            acceptLabel: i18n.buttons.confirm,
            rejectVisible: true,
            rejectLabel: i18n.buttons.cancel,
            header: this.modalHeader,
            message: this.modalContent,
            accept: () => {
                this.confirmDelete(uf);
            }
        });
    }

    async confirmDelete(uf: UploadFile) {
        uf.deleting = true;
        await this._fileService.deletefiles(this.projectId, this.exerciceId, [uf.id]);
        this.findFiles();
    }

    end(val: UploadInfo) {
        this.findFiles();
    }

    goBack() {
        this._complianceLoaderService.sendLoaderShow();
        this._controlService.backControl(this.projectId, this.stateMachineId, this.exerciceId, this.event, this.stepByStep);
    }

    setInfoLabels(uploadData) {
        this.infoLabels = [];
        const fileTypes = uploadData.fileType.split(",");
        fileTypes.forEach((ft)=>{
            const text = `${this.typeFileI18n}.info.${ft}`;
            let trad = super.getValue(text);
            if (trad !== text){
                this.infoLabels.push(trad);
            }
        });
    }

    manageError($event: UploadInfo) {
        if ($event.error) {
            this.errorFile = $event;
        }
    }

    manageBeginUpload($event: boolean) {
        this.errorFile = undefined;
    }
}
