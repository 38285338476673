import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../../../../common/class/compliance-api.service";
import {TYPE_EXPORT, TypeExport} from "./export-file";
import {RestService} from "../../../../../common/service/rest.service";
import {FileUtilsService} from "../../../../../common/service/file/file-utils.service";

@Injectable({
    providedIn: 'root'
})
export class HelpService extends ComplianceApiService {
    constructor(private restService: RestService, private fileSaverService: FileUtilsService) {
        super('help', 'api');
    }

    async downloadViesHelp(num): Promise<Blob> {
        return this.restService.getFile({
            url: this.baseUri,
            relativeUri: 'vies',
            parameters: [num]
        });
    }

    async downloadCa3Help() {
        let blob: Blob = await this.restService.getFile({
            url: this.baseUri,
            relativeUri: 'ca3',
        });
        this.fileSaverService.saveFile("Fiscal - TVA - Procédure téléchargement des déclarations sur impôt.gouv.fr", blob, new TypeExport(TYPE_EXPORT.pdf));
    }

    private donwloadDebHelp() {
        return this.restService.getFile({
            url: this.baseUri,
            relativeUri: 'deb',
        });
    }
    async donwloadDebIntroductionHelp() {
        let blob: Blob = await this.donwloadDebHelp();
        this.fileSaverService.saveFile("Fiscal - DEB Introduction - Procédure téléchargement des déclarations sur ProDouane", blob, new TypeExport(TYPE_EXPORT.pdf));
    }
    async donwloadDebExpeditionHelp() {
        let blob: Blob = await this.donwloadDebHelp();
        this.fileSaverService.saveFile("Fiscal - DEB Expédition - Procédure téléchargement des déclarations sur ProDouane", blob, new TypeExport(TYPE_EXPORT.pdf));
    }
}
