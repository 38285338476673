import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Cookie, User} from "../../../../../../common/class/user/user";
import {AuthService} from "../../../../../../common/service/auth/auth.service";
import {BgForestComponent} from "../../../../../../common/class/background/bg-compliance";
import {UserService} from "../../../../../../common/service/user/user.service";
import {ComplianceToastrService} from "../../../../../../common/service/toastr/compliance-toastr.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ComplianceValidator, REGEX_PASSWORD} from "../../../../../../common/validator/compliance-validator";
import {DeviceDetectorService} from "ngx-device-detector";
import {LoginService} from "../../../../../../common/service/auth/login.service";
import {PHONE_REGEX} from "../../../../../../common/class/compliance-constant";

@Component({
    selector: 'compliance-user-account',
    templateUrl: './user-account.component.html',
    styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent extends BgForestComponent implements OnInit {
    user: User;
    displayPasswordForm: boolean = false;
    form: FormGroup;
    formUser: FormGroup;
    show= false;

    constructor( private authService: AuthService, private fb: FormBuilder, private _deviceService: DeviceDetectorService,
                 private userService: UserService, private toastr: ComplianceToastrService, private location: Location, private _loginService:LoginService) {
        super()
    }

    ngOnInit() {
        this.user = this.authService.getConnectedUser();
        this.createForm();
    }

    private createForm() {
        this.form = this.fb.group({
            oldPassword: ['', Validators.required],
            newPassword: ['', [
                Validators.required,
                Validators.pattern(REGEX_PASSWORD)
            ]],
            confirmNewPassword: ''
        }, {
            validator: ComplianceValidator.createMatchTwoField('newPassword', 'confirmNewPassword')
        });
        this.formUser = this.fb.group({
            lastName: [this.user.lastName, Validators.required],
            firstName: [this.user.firstName, Validators.required],
            phone: [this.user.phone, Validators.pattern(PHONE_REGEX)],
        });
    }

    public triggerChangePassword(event) {
        event.stopPropagation();
        event.preventDefault();
        if(!this.form.invalid){
            this.changePassword();
        }
    }

    public changePassword() {
        this.userService.updatePassword(this.user.id, this.form.value.oldPassword, this.form.value.newPassword).then(() => {
            this.toastr.successI18n('myAccount.success.updatePassword.msg', 'myAccount.success.updatePassword.title');
            this.displayPasswordForm = false;
        });
    }

    public saveCookie(cookie:any){
        let userCookie = new Cookie(cookie);
        userCookie.addNavigator(this._deviceService.browser);
        this.userService.saveCookiePreference(userCookie).then((user:User) =>{
            this._loginService.refreshTokenBasic();
            this.toastr.successI18n("gestionCookie.successMessage");
        });
    }
    public validate() {
        this.form.controls['confirmNewPassword'].updateValueAndValidity();
    }

    goBack() {
        this.location.back()
    }

    saveUserInformation() {
        let userInfo = this.formUser.getRawValue();
        this.user.firstName = userInfo.firstName;
        this.user.lastName = userInfo.lastName;
        this.user.phone = userInfo.phone;

        this.userService.saveUser(this.user).then(user=>{
            this._loginService.refreshTokenBasic();
            this.toastr.successI18n("La sauvegarde de vos informations a bien été pris en compte");
        })
    }
}
