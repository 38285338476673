import {Injectable} from '@angular/core';
import {UploadActionService} from "../upload-action.service";
import {RestService} from "../../../../../../../../common/service/rest.service";
import {UploadInfo} from "../../../../../../../../common/class/upload/upload-info";
import {Observable} from "rxjs";
import {SESSION_TAB_ID} from "../../../../../../../../common/class/compliance-constant";

@Injectable({
  providedIn: 'root'
})
export class UploadComplianceFileDirectService extends UploadActionService {

    private readonly sessionTabId:string;

    constructor(private restService: RestService) {
        super('upload');
        this.sessionTabId = sessionStorage.getItem(SESSION_TAB_ID);
    }


    upload(uploadInfo: UploadInfo, anneeExercice: any, projectName: string, projectCode: string, authorizedFilesControl: string[]): Observable<any> {
        const formData = super.computeFormData(uploadInfo.file, anneeExercice, projectName, projectCode);

        return this.restService.uploadRequest({
            url: this.baseUri,
            relativeUri: `direct`,
            parameters: [uploadInfo.dossierId, uploadInfo.uuid, String(uploadInfo.type).toUpperCase(), uploadInfo.format, authorizedFilesControl, this.sessionTabId],
            data: formData,
            arg: null
        }).pipe(super.uploadProgressEventMap())
    }
}
