import {Component, OnInit} from '@angular/core';
import {BgSpaceComponent} from "../../../../../common/class/background/bg-compliance";
import {UserService} from "../../../../../common/service/user/user.service";
import {LoginService} from "../../../../../common/service/auth/login.service";
import {LegalService} from "../../shared/export-file/legal.service";
import {Router} from "@angular/router";
import {ComplianceLoaderService} from "../../../../../common/service/loader/compliance-loader.service";

@Component({
    selector: 'compliance-cgvu',
    templateUrl: './cgvu.component.html',
    styleUrls: ['./cgvu.component.scss']
})
export class CgvuComponent extends BgSpaceComponent implements OnInit {

    hasCguCgvRead: boolean = false;
    cgvuText: string;

    constructor(private userService: UserService, private loginService: LoginService,
                private exportFileService: LegalService, private router: Router,
                private _complianceLoaderService: ComplianceLoaderService) {
        super();
    }

    async ngOnInit() {
        this._complianceLoaderService.sendLoaderShow();
        await this.retrieveCgvuText();
        this._complianceLoaderService.sendLoaderHide();
    }

    private async retrieveCgvuText() {
        this.cgvuText = await this.exportFileService.cgvuText();
    }

    update($event) {
        console.log($event);
        this.hasCguCgvRead = $event.target.checked;
    }

    async save() {
        await this.userService.updateHasCguCgvRead();
        await this.loginService.refreshTokenBasic();
        this.router.navigate(['/compliance/domain-choice']);
    }

    downloadCgu() {
        this.exportFileService.downloadCgu();
    }

    downloadPolitique() {
        this.exportFileService.donwloadPolitiqueConfidentialite();
    }
}
