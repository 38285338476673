import {Injectable} from '@angular/core';
import {AffectationCompteData} from "./lot-affectation";

@Injectable({
    providedIn: 'root'
})
export class LotUtilsService {

    constructor() {
    }

    public getOneOrManyFromLotData(data:AffectationCompteData[]):string{
        return data.length>1?"many":"one"
    }

}
