import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {DomSanitizer} from "@angular/platform-browser";
import {VideoService} from "../../../../../../../common/service/video/video.service";
import {CategoryControl, DomaineControl} from "../../../../../../../common/class/control/control-descriptor";

@Component({
    selector: 'compliance-video',
    templateUrl: './video-list.component.html',
    styleUrls: ['./video-list.component.scss']
})
export class VideoListComponent implements OnInit {
    private _domain: DomaineControl;
    private _category: CategoryControl;
    videoList: Array<any>;

    constructor(private _videoService: VideoService, private _sanitizer: DomSanitizer,
                private _route: ActivatedRoute, private _router: Router, private _location: Location) {
    }

    ngOnInit() {
        this._domain = this._route.snapshot.paramMap.get('domain') as DomaineControl;
        this._category = this._route.snapshot.paramMap.get('category') as CategoryControl;
        this.getData();
    }

    async getData() {
        let videoList = await this._videoService.getVideoList();

        let groups = new Set(videoList.map(item => item.categorie)), results = [];
        groups.forEach(g =>
            results.push({
                category: g,
                values: videoList.filter(i => i.categorie === g)
            })
        );
        this.videoList = results;
    }

    goBack() {
        this._location.back();
    }

    showVideo(video) {
        this._router.navigate(['/compliance', this._domain, this._category, 'video', video.code]);
    }
}
