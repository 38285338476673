import {Component, OnDestroy, OnInit} from '@angular/core';
import {CALUCLATED_VALUE_BALANCE} from "../../../../../../../common/class/compliance-constant";
import {CalculatedValue} from "../../../../../../../common/class/control/calculated-value";
import {Balance} from "./shared/balance";
import {NotificationService} from "../../../../../../../common/service/push/notification.service";
import {CalculatedValueService} from "../../../../../../../common/service/control/calculated-value.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import * as _ from "lodash";
import {TableItem, TableItemType} from "../../../../shared/table/shared/table-item";
import {BackgroungType} from "../../../../../../../common/class/background/bg-compliance";
import {NotificationMessage} from "../../../../../../../common/class/polling/notification-message";
import {ControlDescriptor} from "../../../../../../../common/class/control/control-descriptor";
import {FecInfosService} from "../../../../../../../common/service/globalParams/fecInfos.service";
import {FECInfos} from "../../../../../../../common/class/globalParams/fecInfos";
import {CurrencyPipe} from "@angular/common";
import {AbstractControl} from "../../../../../../../common/component/control/abstract-control";
import {ExportType, TYPE_EXPORT, TypeExport} from "../../../../shared/export-file/export-file";
import {ExportFileControlService} from "../../../../shared/export-file/export-file-control.service";
import {FileUtilsService} from "../../../../../../../common/service/file/file-utils.service";

@Component({
    selector: 'compliance-balance-comptable',
    templateUrl: './balance-comptable.component.html',
    styleUrls: ['./balance-comptable.component.scss']
})
export class BalanceComptableComponent extends AbstractControl implements OnInit, OnDestroy {
    public balance: Balance[];
    public sumTotal: Balance;
    public metaBalance: TableItem[];
    private _balanceByCompte: any = {};
    balanceResult = {
        result: undefined,
        isBalanced: undefined,
        isBalancedText: undefined
    };
    private _csvOptions: any = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalseparator: ',',
        showLabels: true
    };
    private _control: ControlDescriptor;
    private _balanceI18n: any;
    private _machineContext: any;
    isFrom = false;
    public balanceData: FECInfos;

    constructor(private _notificationService: NotificationService, private _calculatedValueService: CalculatedValueService,
                private _router: Router, private _route: ActivatedRoute, private _fileSaverService:FileUtilsService,
                private _balanceService: FecInfosService, private _currencyPipe: CurrencyPipe, private _exportService: ExportFileControlService) {
        super(BackgroungType.BG_FOREST);
    }

    ngOnInit() {
        super.setContextWithRoute(this._route, (param: Params) => {
            if (this.controlId) {
                this.findControl();
            } else {
                this.isFrom = !!param['from'];
                if (this.isFrom) {
                    this._controlService.continueExecutionControl(this.projectId, "sm_balance", this.exerciceId, "RELOAD", {}, this.stepByStep);
                } else {
                    this.findBalanceByProjectId();
                }
            }
        });
        this._balanceI18n = super.getTranslation().balance;
        let headerI18n = this._balanceI18n.headers;

        this.metaBalance = [
            {field: 'compteNum', header: headerI18n.compteNum, type: TableItemType.string, isLineHeader: true},
            {field: 'compteLib', header: headerI18n.compteLib, type: TableItemType.string, class: "label-long"},
            {field: 'ran', header: headerI18n.soldeRan, type: TableItemType.number},
            {field: 'debit', header: headerI18n.debit, type: TableItemType.number},
            {field: 'credit', header: headerI18n.credit, type: TableItemType.number},
            {field: 'soldeCloture', header: headerI18n.soldeCloture, type: TableItemType.number}
        ];

        this._csvOptions.headers = [];
        this.metaBalance.forEach(meta => this._csvOptions.headers.push(this.getValue(meta.header)));


        super.subscribeToNotificationControl({
            notificationService: this._notificationService,
            manageDataEvent: (notification: NotificationMessage) => {
                this.manageDataEvent(notification);
            },
            manageDoneEvent: () => {
                this.findBalanceByProjectId();
            }
        });
    }


    async findControl() {
        this._control = await this._controlService.findControlById(this.controlId);
        this._controlService.dataControl(this.projectId, this._control.stateMachineId, this.exerciceId, this.event, this.stepByStep);

    }

    protected manageDataEvent(notification: NotificationMessage) {
        this._machineContext = notification.corp;
        this._controlService.continueExecutionControl(this.projectId, "sm_balance", this.exerciceId, "RELOAD", {"data": this._machineContext}, this.stepByStep);
    }


    findBalanceByProjectId() {
        this._calculatedValueService.findByCodeAndProjectId(this.projectId, CALUCLATED_VALUE_BALANCE, this.exerciceId).then((calculatedValue: CalculatedValue) => {
            this.balance = _.sortBy(calculatedValue.value, 'comptNum') as Balance[];
            this.computeSums();
            this._complianceLoaderService.sendLoaderHide();
        });
        this._balanceService.getBalanceData(this.projectId, this.exerciceId).then((result: FECInfos) => {
            this.balanceData = result;
            const resultBalance = this._currencyPipe.transform(this.balanceData.balanceResult.result, 'EUR', 'symbol', '1.0-0');
            this.balanceResult = {
                result: resultBalance,
                isBalanced: result.balanceResult.equilibre,
                isBalancedText: `dashboard.fecManagement.isBalanced.${result.balanceResult.equilibre.toString()}`,
            };
        });
    }

    private computeSums() {
        const computedBalance = this.computeSumByCompte(this.balance);
        this.balance = computedBalance.balances;
        this.sumTotal = computedBalance.totalGeneral;
    }

    private computeTotalSum(balance: Balance[]): Balance {
        let sum: Balance = {
            soldeCredit: 0,
            soldeDebit: 0,
            size: 0,
            compteLib: "Total général",
            compteNum: "",
            debit: 0,
            credit: 0,
            ran: 0,
            soldeCloture: 0,
            total: true
        };
        _.each(balance, (line: Balance) => {
            this.initValueToZeroIfNull(line);
            line.soldeCloture = line.ran + line.debit - line.credit;
            this.sumLine(sum, line);
        });
        sum.soldeCloture = sum.ran + sum.debit - sum.credit;

        return sum;
    }

    private sumLine(sum: Balance, line: Balance) {
        sum.soldeCredit += (line.soldeCredit);
        sum.soldeDebit += (line.soldeDebit);
        sum.size += (line.size);
        sum.debit += (line.debit);
        sum.credit += (line.credit);
        sum.ran += (line.ran);
    }

    private initValueToZeroIfNull(line: Balance) {
        line.soldeCredit = (line.soldeCredit || 0);
        line.soldeDebit = (line.soldeDebit || 0);
        line.size = (line.size || 0);
        line.debit = (line.debit || 0);
        line.credit = (line.credit || 0);
        line.ran = (line.ran || 0);
        line.soldeCloture = (line.soldeCloture || 0);
    }

    private computeSumByCompte(balance: Balance[]) {
        let balanceByCompte = _.groupBy(balance, (line: Balance) => {
            return line.compteNum.substr(0, 1);
        });
        let globalBalance: Balance[] = [];
        let comptes = _.sortBy(_.keys(balanceByCompte));
        let sumTotal = {
            soldeCredit: 0,
            soldeDebit: 0,
            size: 0,
            compteLib: "Total général",
            compteNum: "",
            debit: 0,
            credit: 0,
            ran: 0,
            soldeCloture: 0,
            total: true
        };


        comptes.forEach((compte) => {
            let balanceByCompteElement = balanceByCompte[compte];
            let subSum = this.computeTotalSum(balanceByCompteElement);
            this.sumLine(sumTotal, subSum);
            subSum.compteLib = `Total classe ${compte}`;
            globalBalance = [...globalBalance, ...balanceByCompteElement, subSum];
            this._balanceByCompte[compte] = {
                balance: balanceByCompteElement,
                sum: subSum
            };
        });
        sumTotal.soldeCloture = sumTotal.ran + sumTotal.debit - sumTotal.credit;

        return {
            balances: globalBalance,
            totalGeneral: sumTotal
        };
    }

    exportBalance() {
        this._exportService.exportXlsxFile([this.projectId, this.exerciceId])
            .then(blob => this._fileSaverService.saveFile("balance", blob, new TypeExport(TYPE_EXPORT[ExportType.xlsx])));
    }

    launchNext() {
        this._complianceLoaderService.sendLoaderShow();
        this._router.navigate(['/compliance', this.domain, this.category, 'project', this.projectId, this.exerciceId, 'dashboard']);
        // if (this.controlId) {
        //     this._controlService.continueExecutionControl(this.projectId, this.stateMachineId, this.exerciceId, "BALANCE", {}, this.stepByStep);
        // } else {
        //     this._controlService.backControl(this.projectId, this.stateMachineId, this.exerciceId, this.event, this.stepByStep);
        // }
    }

    goBack() {
        this._complianceLoaderService.sendLoaderShow();
        this._controlService.backControl(this.projectId, this.stateMachineId, this.exerciceId, this.event, this.stepByStep);
    }
}
