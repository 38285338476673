import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UploadFileComponent} from "./upload-file.component";
import {DragNDropComponent} from "./drag-n-drop/drag-n-drop.component";
import {ComplianceSharedModule} from "../../../../../common/compliance-shared.module";
import {UploadFileRoutingModule} from "./upload-file-routing.module";

@NgModule({
    declarations: [
        UploadFileComponent,
        DragNDropComponent
    ],
    imports: [
        CommonModule,
        ComplianceSharedModule,
        UploadFileRoutingModule
    ],
    providers: [
    ],
    exports: [
        UploadFileComponent,
        DragNDropComponent
    ]
})
export class UploadFileModule {
}
