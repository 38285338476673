import {ComplianceI18nLoader} from "../i18n/compliance-i18n-loader";

export enum BackgroungType {
    BG_FOREST = 'bg-forest',
    BG_TREE = 'bg-tree',
    BG_TREE_NAV = 'tree-nav bg-landscape-03',
    BG_TREE_2 = 'bg-tree-02',
    BG_SPACE = 'bg-space',
    BG_DASHBOARD = 'bg-dashboard',
}

export class BgForestComponent extends ComplianceI18nLoader {

    constructor() {
        super();
        document.body.className = BackgroungType.BG_FOREST;
    }


    public getTranslation(): any {
        return super.getTranslation();
    }

    public getValue(i18nProperty: string, params?: Object): string {
        return super.getValue(i18nProperty, params);
    }
}

export class BgTreeComponent extends ComplianceI18nLoader {

    constructor() {
        super();
        document.body.className = BackgroungType.BG_TREE.toString();
    }


    public getTranslation(): any {
        return super.getTranslation();
    }

    public getValue(i18nProperty: string): string {
        return super.getValue(i18nProperty);
    }
}

export class BgTreeNavComponent extends ComplianceI18nLoader {

    constructor() {
        super();
        document.body.className = BackgroungType.BG_TREE_NAV.toString();
    }


    public getTranslation(): any {
        return super.getTranslation();
    }

    public getValue(i18nProperty: string): string {
        return super.getValue(i18nProperty);
    }
}

export class BgTree2Component extends ComplianceI18nLoader {

    constructor() {
        super();
        document.body.className = BackgroungType.BG_TREE_2.toString();
    }


    public getTranslation(): any {
        return super.getTranslation();
    }

    public getValue(i18nProperty: string): string {
        return super.getValue(i18nProperty);
    }
}

export class BgSpaceComponent extends ComplianceI18nLoader {

    constructor() {
        super();
        document.body.className = BackgroungType.BG_SPACE.toString();
    }


    public getTranslation(): any {
        return super.getTranslation();
    }

    public getValue(i18nProperty: string, params?: Object): string {
        return super.getValue(i18nProperty, params);
    }
}

export class BgDashboardComponent extends ComplianceI18nLoader {

    constructor() {
        super();
        document.body.className = BackgroungType.BG_DASHBOARD.toString();
    }


    public getTranslation(): any {
        return super.getTranslation();
    }

    public getValue(i18nProperty: string, params?: Object): string {
        return super.getValue(i18nProperty, params);
    }
}
