import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {TableItem, TableItemType, TableOption} from "./shared/table-item";
// import {DataTable} from "primeng/primeng";
import _ from 'lodash';
import {MetaExpressionService} from "../../../../../common/service/meta/meta-expression.service";
import {ReportingService} from "../../compliance/project/dashboard/tva-dashboard/reporting.service";
import {ActivatedRoute} from "@angular/router";
import {ConfirmationService} from "primeng/api";
import {Subject} from "rxjs";

interface ActionMenuObject {
    data: any;
    meta: TableItem;
    childElement: HTMLElement;
    position: {
        top: string;
        left: string;
    };
}

@Component({
    selector: 'compliance-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() destroy$: Subject<boolean>;

    private _datas: any[];
    @Input() set datas(datas: any[]){
        if(datas) {
            this._datas = [...datas];
        }
    };
    get datas(){
        return this._datas
    }
    private _footer: any;
    @Input() set footer (footer: any){
        this._footer = footer;
    };
    get footer(){
        return this._footer
    }
    private _items: TableItem[];
    @Input() set items(items: TableItem[]){
        this._items = [...items];
        this._computeItems();
        if(this.datas){
            this.computeDisplayColumn(this.items, this.datas)
        }
    };

    get items(){
        return this._items
    }
    @Input() extendedData: any;
    @Input() tableOption: TableOption;
    @Input() styleClass: string[];
    itemType = TableItemType;
    showMultipleAffectMenu: boolean = false;
    private projectId: string;
    private exerciceId: string;
    private controlId: string;

    @Output() actionMenuEvent: EventEmitter<any> = new EventEmitter();
    @Output() dataChangeEvent: EventEmitter<any> = new EventEmitter();

    @ViewChild('dt') dataTable: any;
    private selectedData: any[] = [];
    private selectAllActive: boolean = false;

    public actionMenuObject: ActionMenuObject;

    constructor(private expressionService: MetaExpressionService, private route: ActivatedRoute, private reportingService: ReportingService, private _confirmationService: ConfirmationService) {

    }

    ngOnInit() {
        console.time('TableComponent');
        this.projectId = this.route.snapshot.paramMap.get("projectId");
        this.exerciceId = this.route.snapshot.paramMap.get("exerciceId");
        this.controlId = this.route.snapshot.paramMap.get("controlId");
        this.tableOption = this.tableOption || {activeField: 'active', activeFieldType: 'activeType'};
        this.styleClass = this.styleClass || ['table-data--02'];
        this.styleClass.push('table-data');
    }


    private _computeItems() {
        this.items.forEach((item: TableItem) => {
            item.type = item.type as TableItemType;
            item.numberFormat = item.numberFormat || '1.0-0';
            if (item.footer) {
                item.footer.numberFormat = item.footer.numberFormat || '1.0-0';
            }
            item.computeValueType = (item.type == TableItemType.string || item.type == TableItemType.number || item.type == TableItemType.currency || item.type == TableItemType.date || item.type == TableItemType.binary);
        });
    }
    ngAfterViewInit(): void {
        console.timeEnd('TableComponent');
    }


    getStyleClass(): string {
        return this.styleClass.join(' ');
    }

    ngOnChanges(changes: SimpleChanges): void {
        // if (changes.datas && changes.datas.currentValue) {
        //     this.datas = [...changes.datas.currentValue];
        // }
        // if (changes.footer && changes.footer.currentValue) {
        //     this.footer = changes.footer.currentValue;
        // }
        // if (changes.items && changes.items.currentValue) {
        //     this.items = changes.items.currentValue;
        //     _.each(this.items, (item: TableItem) => {
        //         item.type = item.type as TableItemType;
        //         item.numberFormat = item.numberFormat || '1.0-0'
        //     })
        // }
    }

    public export() {
        this.dataTable.exportCSV();
    }


    menuAction(data, code: string, field: string) {
        this.actionMenuObject = undefined;
        this.actionMenuEvent.emit({data: [data], code: code});
    }


    menuActionMultiple(code: string) {
        if (this.selectedData && this.selectedData.length) {
            this.showMultipleAffectMenu = false;
            _.each(this.selectedData, (d) => {
                d.active = false;
            });
            this.actionMenuEvent.emit({data: [...this.selectedData], code: code});
            this.selectedData = [];
        }
    }

    _getAbsoluteOffsetFromRelative(el) {   // finds the offset of el from the first parent with position: relative
        let _x = 0;
        let _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft + el.clientLeft;
            _y += el.offsetTop - el.scrollTop + el.clientTop;
            el = el.offsetParent;
            if (el != null && getComputedStyle(el, null).getPropertyValue('position') === "relative") {
                el = null;
            }
        }
        return {top: _y, left: _x};
    }

    _scrollListener = (e) => {
        const tbodyElement = document.querySelector('.ui-table-wrapper tbody');
        if (this.actionMenuObject) {
            this.actionMenuObject = undefined;
        }
        // We destroy scroll event listener, once the action menu is closed
        tbodyElement.removeEventListener('scroll', this._scrollListener);
    };
    _outsideClickListener = (event) => {
        if (this.actionMenuObject && !document.getElementsByClassName('panel')[0].contains(event.target)) {
            this.actionMenuObject = undefined;
        }
        document.removeEventListener('click', this._outsideClickListener);
    };

    showMenu(event, data, meta) {
        event.stopPropagation();

        const elementPosition = this._getAbsoluteOffsetFromRelative(event.currentTarget);
        const tbodyElement = document.querySelector('.p-datatable-wrapper tbody.p-datatable-tbody');

        // We remove existing specific event listener before adding new ones.
        tbodyElement.removeEventListener('scroll', this._scrollListener);
        document.removeEventListener('click', this._outsideClickListener);

        // The action menu is already open for this HTML Element, we just need to close the action menu.
        if (this.actionMenuObject && this.actionMenuObject.childElement === event.currentTarget) {
            this.actionMenuObject = undefined;
            return;
        }
        this.actionMenuObject = {
            data: data,
            meta: meta,
            childElement: event.currentTarget,
            position: {
                top: elementPosition.top - tbodyElement.scrollTop + 'px',
                left: elementPosition.left + event.currentTarget.getBoundingClientRect().width + 'px'
            }
        };
        tbodyElement.addEventListener('scroll', this._scrollListener);
        document.addEventListener('click', this._outsideClickListener);
    }

    showGlobalMultipleActionMenu(field: string) {
        this.actionMenuObject = undefined;
        this.showMultipleAffectMenu = !this.showMultipleAffectMenu;
    }

    updateData(data) {

    }

    rowSelected(data: any) {
        if (this.tableOption.selectionMode) {
            if (this.tableOption.selectionMode === 'multiple') {
                data.active = !data.active;
            }
            if (this.tableOption.selectionMode === 'single') {
                _.each(this.datas, (d) => {
                    if (!_.isEqual(d, data)) {
                        d.active = false;
                    }
                });
                data.active = !data.active;
            }

        }
        this.selectedData = _.filter(this.datas, (d) => d.active);

        console.log(this.selectedData);

    }

    selectAll() {
        this.selectAllActive = !this.isAllSelected();
        this.datas.forEach((d) => {
            d.active = this.selectAllActive;
        });
        this.selectedData = this.datas.filter(value => value.active);
    }

    isAllSelected(): boolean {
        return this.datas.filter(value => value.active).length === this.datas.length;
    }

    computeClass(data, meta: TableItem) {
        let returnClass = {
            "export-cell": !!meta.export
        };
        if (!meta.valueClass) {
            return returnClass;
        }
        if (_.isString(meta.valueClass)) {
            returnClass[meta.valueClass] = true;
        } else {
            returnClass[meta.valueClass.class] = this.expressionService.computeExpression(meta.valueClass.expression, data);
        }
        return returnClass;

    }

    // displayColumn(expression: Expression, data, from):boolean{
    //     if(!expression) return true;
    //     console.log(`display colonne ${from}: `,{expression:expression, data:data, result:this.expressionService.computeExpression(expression, data)});
    //     return <boolean>this.expressionService.computeExpression(expression, data);
    // }

    computeDisplayColumn(items: TableItem[], datas: any[]) {
        items.forEach((item) => {
            let display = true;
            datas.forEach(value => {
                display = display && (!item.display || <boolean>this.expressionService.computeExpression(item.display, value));
            });
            item.hide = !display;
        });
        console.log(`display colonne `, {items, datas});
    }

}
