import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";
import {ParametrageValue} from "../../class/control/parametrage-value";

@Injectable({
  providedIn: 'root'
})
export class ParametrageValueService  extends ComplianceApiService{

    constructor(private restService: RestService) {
        super('parametrageValue');
    }

    findByCodeAndProjectId(projectId:string, code:string, year:string):Promise<ParametrageValue>{
        return this.restService.get({
            url: this.baseUri,
            parameters:[projectId,year, code],
            arg: null as ParametrageValue
        })
    }

    saveParametrageValue(value:ParametrageValue, year:string):Promise<ParametrageValue>{
        return this.restService.put({
            url: this.baseUri,
            parameters:[year],
            data: value,
            arg: null as ParametrageValue
        })
    }


    saveParametrageValues(value:any, projectId:string, code:string, exerciceId:string):Promise<ParametrageValue>{
        return this.restService.put({
            url: this.baseUri,
            parameters:[projectId,exerciceId, code],
            data: value,
            arg: null as ParametrageValue
        })
    }
}
