
<div class="block-group-title" *ngIf="crdItem.title">
    <h2 class="sub-title" [innerHTML]="crdItem.title | translate | safe:'html'"></h2>
</div>

<div>
    <ng-container *ngFor="let dispositionBlock of crdItem.dispositionBlocks">
        <compliance-crd-disposition-block [crdDispositionBlock]="dispositionBlock" [ngStyle]="getStyle(dispositionBlock)" [destroy$]="destroy$"></compliance-crd-disposition-block>
    </ng-container>
</div>
