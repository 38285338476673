<ng-container *ngIf="!manualEdit; else blockForm">
    <div class="name">
        <div class="image float-left"></div>
        {{project.name}}
    </div>
    <ul>
        <li>
            <span class="label">{{'project.create.form.result.address' | translate}}</span>
            {{project.adresse}}
        </li>
        <li>
            <span class="label">{{'project.create.form.result.formeJuridique' | translate}}</span>
            {{project.formeJuridique}}
        </li>
        <li>
            <span class="label">{{'project.create.form.result.activite' | translate}}</span>
            {{getLabel('activites', project.codeApe)}}
        </li>
        <li>
            <span class="label">{{'project.create.form.result.category' | translate}}</span>
            {{getLabel('categories', project.categorie)}}
        </li>
        <li>
            <span class="label">{{'project.create.form.result.siren' | translate}}</span>
            {{project.code}}
        </li>
    </ul>
</ng-container>
<ng-template #blockForm>
    <form [formGroup]="projectForm" novalidate autocomplete="off">
        <div class="name d-flex">
            <div class="image float-left"></div>
            <input type="text" class="form-control label-required"
                   placeholder="{{'project.create.form.result.name' | translate}}"
                   autofocus="" autocomplete="off" formControlName="name" required/>
        </div>
        <ul>
            <li class="d-flex align-items-center">
                <span class="label">{{'project.create.form.result.address' | translate}}</span>
                <input type="text" class="form-control ml-2 flex-grow-1 w-auto"
                       autofocus="" autocomplete="off" formControlName="adresse"/>
            </li>
            <li class="d-flex align-items-center">
                <span class="label">{{'project.create.form.result.formeJuridique' | translate}}</span>
                <p-autoComplete formControlName="formeJuridique" class="ml-2 flex-grow-1 w-auto"
                                [suggestions]="refsSearched.formeJuridiques"
                                field="statutJuridique"
                                (completeMethod)="searchFormeJuridiques($event)"
                                (onSelect)="onSelect($event.statutJuridique, 'formeJuridique')"
                                [dropdown]="true">
                </p-autoComplete>
            </li>
            <li class="d-flex align-items-center">
                <span class="label">{{'project.create.form.result.activite' | translate}}</span>
                <p-autoComplete formControlName="codeApe" class="ml-2 flex-grow-1 w-auto"
                                [suggestions]="refsSearched.activites"
                                field="activite"
                                (completeMethod)="searchActivites($event)"
                                (onSelect)="onSelect($event.code, 'codeApe')"
                                [dropdown]="true">
                </p-autoComplete>
            </li>
            <li class="d-flex align-items-center">
                <span class="label">{{'project.create.form.result.category' | translate}}</span>
                <p-autoComplete formControlName="categorie" class="ml-2 flex-grow-1 w-auto"
                                [suggestions]="refsSearched.categories"
                                field="categories"
                                (completeMethod)="searchCategories($event)"
                                (onSelect)="onSelect($event.code, 'categorie')"
                                [dropdown]="true">
                </p-autoComplete>
            </li>
            <li>
                <span class="label">{{'project.create.form.result.siren' | translate}}</span>
                {{project.code}}
            </li>
        </ul>
    </form>
<!--    <pre>{{projectForm.status | json}}</pre>-->
</ng-template>
