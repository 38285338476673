import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";
import {CalculatedValue} from "../../class/control/calculated-value";

@Injectable()
export class CalculatedValueService extends ComplianceApiService{

  constructor(private restService: RestService) {
      super('calculatedValue');
  }

  findByCodeAndProjectId(projectId:string, code:string, year:string):Promise<CalculatedValue>{
      return this.restService.get({
          url: this.baseUri,
          parameters:[projectId,year, code],
          arg: null as CalculatedValue
      })
  }

  saveCalculatedValue(value:CalculatedValue, year:string):Promise<CalculatedValue>{
      return this.restService.put({
          url: this.baseUri,
          parameters:[year],
          data: value,
          arg: null as CalculatedValue
      })
  }


    saveCalculatedValues(value:CalculatedValue, projectId:string, code:string, exerciceId:string):Promise<CalculatedValue>{
        return this.restService.put({
            url: this.baseUri,
            parameters:[projectId,exerciceId, code],
            data: value,
            arg: null as CalculatedValue
        })
    }

}
