<div class="page-import">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="block-group-title">
                    <h2 class="title big">{{'uploadFile.uploadScreen.title' | translate}}</h2>
                </div>
            </div>

        </div>
    </div>

    <div class="col-xl-12">
        <form class="form-basic form-import-file">
            <div class="form-group">
                <p class="label">
                            <span>
                                {{'uploadFile.uploadScreen.vies.subTitle' | translate}}
                            </span>
                </p>
                <div class="upload-info text-white text-center text-size-12 position-relative my-4">
                    <p>Toute entreprise assujettie dispose d’un numéro d’identification individuel délivré par l’administration fiscale.</p>
                    <p>Le numéro de TVA intracommunautaire est constitué d’une clé informatique à 2&nbsp;chiffres et du numéro SIREN de l’entreprise (9&nbsp;chiffres).</p>
                    <p>Pour lancer ce contrôle, vous devez déposer l'un des 2&nbsp;fichiers csv proposés ici : <a href="#" (click)="downloadVies(1, 'csv - 1 colonne - tva_intracom')">1&nbsp;colonne</a> avec tous vos numéros de TVA intracommunautaire (11&nbsp;caractères) ou <a href="#" (click)="downloadVies(2, 'csv - 2 colonnes - etat_membre_siren')">2&nbsp;colonnes</a> avec la clé du pays (2&nbsp;lettres) et le numéro SIREN (9&nbsp;chiffres).</p>
                    <p><i class="fas fa-exclamation-triangle text-size-18 pr-2 pt-4"></i>Le temps de traitement de votre fichier varie en fonction de sa volumétrie. Merci de laisser cette page ouverte jusqu'au téléchargement du fichier VIES.</p>
                </div>
                <div class="input-file">
                    <div>
                        <div>

                            <ngx-file-drop (onFileDrop)="onFilesChange($event)" dropZoneClassName="project-drop-zone">
                                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                    <div class=" input-file">
                                        <span class="label span-1" [innerHtml]="'upload.dragNDrop.title' | translate | safe:'html'"></span>
                                        <span class="icon">
                                            <input type="file" id="file1" name="file1" title='Ajouter un fichier' (change)="uploadHandler($event)"/>
                                        </span>
                                        <label for="file1" class="label span-2">{{'upload.dragNDrop.fileChoice' | translate}} <br><span>(Csv)</span></label>

                                        <ul class="" id="files">
                                            <li class="text-muted text-center empty"></li>
                                        </ul>
                                    </div>
                                </ng-template>
                            </ngx-file-drop>

                            <div class="error-alert mt-2" *ngIf="errorMessage">
                                <div [innerHTML]="errorMessage | translate | safe:'html'"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!-- START : footer -->
    <footer id="footer">
        <div class="inner">
            <a href="#" class="btn btn-primary btn-back" (click)="goBack()"><span>{{'buttons.back' | translate}}</span></a>
        </div>
    </footer>
</div>
