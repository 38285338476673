import {NgModule} from '@angular/core';
import {ComplianceComponentLibModule} from "./component/module-component";

@NgModule({
    imports: [
        ComplianceComponentLibModule
    ],
    exports: [
        ComplianceComponentLibModule
    ],
    declarations: []
})
export class ComplianceSharedModule {
}
