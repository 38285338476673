// ROLES et HABILITATION
export const ROLE_ADMIN_EFL: string = "adminEFL";
export const ROLE_ADMIN_EFL_PARAMETRAGE: string = "adminEFLParametrage";
export const ROLE_ADMIN: string = "admin";
export const ROLE_USER: string = "user";

export const MODULE_USER: string = "moduleUtilisateur";
export const MODULE_GESTION_DOSSIER: string = "moduleGestionDossier";
export const MODULE_GESTION_SCRIPT: string = "moduleScript";
export const MODULE_GESTION_CONFIGURATION_FORMULAIRE: string = "moduleFormulaireConfiguration";

export const FONCTIONALITE_USER_WRITE: string = "moduleUtilisateurEcrire";
export const FONCTIONALITE_USER_READ : string = "moduleUtilisateurLecture";
export const FONCTIONALITE_GESTION_DOSSIER_WRITE: string = "moduleGestionDossierEcrire";
export const FONCTIONALITE_GESTION_DOSSIER_READ : string = "moduleGestionDossierLecture";
export const FONCTIONALITE_GESTION_CONFIGURATION_FORMULAIRE_WRITE: string = "moduleFormulaireConfigurationEcrire";
export const FONCTIONALITE_GESTION_CONFIGURATION_FORMULAIRE_READ : string = "moduleFormulaireConfigurationLecture";

//MENU PARAMS
export const TYPE_USER_CREATE_ADMIN_EFL: string = ROLE_ADMIN_EFL;
export const TYPE_USER_CREATE_ADMIN_EFL_PARAMETRAGE: string = ROLE_ADMIN_EFL_PARAMETRAGE;
export const TYPE_USER_CREATE_ACCOUNT_ADMIN: string = ROLE_ADMIN;
export const TYPE_USER_CREATE_USER: string = ROLE_USER;

export const DROIT_MENU = {
    user : {
        id:"user",
        roles : [ROLE_ADMIN]
    },
    userEfl : {
        id:"userEfl",
        roles : [ROLE_ADMIN_EFL]
    },
    dossier:{
        id:"dossier",
        roles : [ROLE_ADMIN, ROLE_USER, ROLE_ADMIN_EFL_PARAMETRAGE]
    },
    script:{
        id:"script",
        roles : [ROLE_ADMIN_EFL_PARAMETRAGE]
    },
    formAndEdiConfig:{
        id:"formAndEdiConfig",
        roles : [ROLE_ADMIN_EFL_PARAMETRAGE]
    },
    CciCavConfig:{
        id:"CciCavConfig",
        roles : [ROLE_ADMIN_EFL_PARAMETRAGE]
    }
};

let droits = {};
droits[MODULE_USER] = [ROLE_ADMIN, ROLE_ADMIN_EFL];
droits[MODULE_GESTION_DOSSIER] = [ROLE_ADMIN, ROLE_USER, ROLE_ADMIN_EFL_PARAMETRAGE];
droits[MODULE_GESTION_SCRIPT] = [ROLE_ADMIN_EFL_PARAMETRAGE];
droits[MODULE_GESTION_CONFIGURATION_FORMULAIRE] = [ROLE_ADMIN_EFL_PARAMETRAGE];

export const DROIT_MODULE = droits;

