import {Injectable} from '@angular/core';
import {Dossier} from "../../class/dossier/dossier";
import {Subject} from "rxjs";

@Injectable()
export class DossierUtilsService {

    dossiers: Array<Dossier> = [];

    dossiersChanges = new Subject<Array<Dossier>>();
    changeEmitted$ = this.dossiersChanges.asObservable();

  constructor() { }

  emitChanges(){
      this.dossiersChanges.next();
  }

}
