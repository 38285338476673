import {Type} from "@angular/core";
import {PeriodeQuestionComponent} from "../../../component/question/periode-question/periode-question.component";
import {MetaModal, MetaQuestion, QuestionType} from "./meta-question";
import {MultipleChoiceQuestionComponent} from "../../../component/question/multiple-choice-question/multiple-choice-question.component";
import {TableSelectionComponent} from "../../../component/question/table-selection/table-selection.component";
import {FormQuestionComponent} from "../../../component/question/form-question/form-question.component";


let questionComponant: { [keys: string]: Type<any> } = {};
questionComponant[QuestionType.PERIODE] = PeriodeQuestionComponent;
questionComponant[QuestionType.CHOICE] = MultipleChoiceQuestionComponent;
questionComponant[QuestionType.SELECTION] = TableSelectionComponent;
questionComponant[QuestionType.FORM] = FormQuestionComponent;

export const QUESTION_COMPONANT_BY_TYPE: { [keys: string]: Type<any> } = questionComponant;


// Bouchon
const modal: MetaModal = {
    autoClose: false,
    closeIcon: false,
    header: {text:"test header"},
    content: {text:'<div>ligne 1</div><div>Ligne 2</div>'},
    buttons: [
        {text: {label: "Oui"}}
    ]
};
export const QUESTION_MOCK: { [keys: string]: MetaQuestion<any> } = {
    MOCK_acquisition: {
        code: QuestionType.CHOICE,
        question: {
            multiple: false,
            "buttons": {
                "next": {"active": {"operator": "NOT_EMPTY", "firstMember": {"field": "data"}}, "text": {"label": "buttons.next"}, "actions": [{"service": {"type": "LAUNCH_CONTROL"}}]},
                "back": {"text": {"label": "buttons.back"}, "actions": [{"service": {"type": "BACK_CONTROL"}}]}
            },
            data: 'YES',
            responseName: "acquisitionSpecifique",
            title: {"text": "question.acquisitionSpecifique.title"},
            asterix:{"text": "question.acquisitionSpecifique.asterix"},
            items: [
                {"code": 'YES', "label": "Oui"},
                {"code": 'No', "label": "Non",}
            ]
        }
    },
    MOCK_SELECTION: {
        "code": QuestionType.SELECTION,
        "question": {
            "multiple": true,
            "buttons": {
                "next": {
                    "active": {
                        "operator": "NOT_EMPTY",
                        "firstMember": {
                            "field": "data"
                        }
                    },
                    "text": {
                        "label": "buttons.next"
                    },
                    "actions": [
                        {
                            "service": {
                                "type": "LAUNCH_CONTROL"
                            }
                        }
                    ]
                },
                "back": {
                    "text": {
                        "label": "buttons.back"
                    },
                    "actions": [
                        {
                            "service": {
                                "type": "BACK_CONTROL"
                            }
                        }
                    ]
                }
            },
            "data": null,
            "responseName": "liste_comptes_tva_deductible_achats_biens_services",
            "title": {
                "text": "tableauSelection.title"
            },
            "subtitles": [{
                "text": "tableauSelection.subtitle"
            }],
            "suffix": " %",
            "formulaire": [
                {
                    "formType": "input",
                    "controlType": [
                        {
                            "controlType": "required"
                        },
                        {
                            "controlType": "step",
                            "value": "0.01"
                        },
                        {
                            "controlType": "max",
                            "value": "99.99"
                        }
                    ],
                    "inputType": "number",
                    "id": "tauxTva"
                }
            ],
            "items": [
                {
                    "code": "44587100",
                    "label": "Charges constatÃ©es d'avance"
                },
                {
                    "code": "44583000",
                    "label": "Amort. matÃ©riel de bureau"
                },
                {
                    "code": "44562000",
                    "label": "Amort. matÃ©riel transport"
                },
                {
                    "code": "44580000",
                    "label": "CrÃ©dit agricole cnet"
                },
                {
                    "code": "44563200",
                    "label": "Ass. das juridique et fiscale"
                },
                {
                    "code": "44561000",
                    "label": "Generali assurance bureau"
                },
                {
                    "code": "44569320",
                    "label": "Autres frais de banque"
                },
                {
                    "code": "44581000",
                    "label": "CrÃ©dit agricole cnet"
                },
                {
                    "code": "44582000",
                    "label": "Tva sur immobilisations"
                },
                {
                    "code": "44567000",
                    "label": "Virements"
                },
                {
                    "code": "44562000",
                    "label": "CrÃ©dit agricole cnet"
                },
                {
                    "code": "44586000",
                    "label": "Etat - tva sur fnp"
                },
                {
                    "code": "44581500",
                    "label": "Tva collectÃ©e Ã  20%"
                },
                {
                    "code": "44560000",
                    "label": "Tva sur achats"
                },
                {
                    "code": "44563000",
                    "label": "DÃ©pÃ´t Ã  terme xx779"
                },
                {
                    "code": "44562000",
                    "label": "Tva sur immobilisations"
                }
            ]
        }
    },
    MOCK_FORM: {
        "code": QuestionType.FORM,
        "question": {
            "buttons": {
                "next": {
                    "active": {
                        "operator": "NOT_EMPTY",
                        "firstMember": {
                            "field": "data"
                        }
                    },
                    "text": {
                        "label": "buttons.next"
                    },
                    "actions": [
                        {
                            "service": {
                                "type": "LAUNCH_CONTROL"
                            }
                        }
                    ]
                },
                "back": {
                    "text": {
                        "label": "buttons.back"
                    },
                    "actions": [
                        {
                            "service": {
                                "type": "BACK_CONTROL"
                            }
                        }
                    ]
                }
            },
            "data": null,
            "responseName": "liste_comptes_tva_deductible_achats_biens_services",
            "title": {
                "text": "Quel montant d'acquisitions intracommunautaires avez-vous réalisé entre janvier 2016 et septembre 2016 ?"
            },
            "formulaire": [
                {
                    "formType": "input",
                    "controlType": [
                        {
                            "controlType": "required"
                        },
                        {
                            "controlType": "step",
                            "value": "1"
                        },
                        {
                            "controlType": "min",
                            "value": "0"
                        }
                    ],
                    "inputType": "number",
                    "label": "Montant en €",
                    "id": "tauxTva"
                }
            ],
        }
    }
}
