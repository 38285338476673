import {Component, Input, OnInit} from '@angular/core';
import _ from 'lodash';
import {ActivatedRoute} from "@angular/router";
import {AbstractControl} from "../../../../../../../common/component/control/abstract-control";
import {ControlDescriptor} from "../../../../../../../common/class/control/control-descriptor";
import {NotificationService} from "../../../../../../../common/service/push/notification.service";
import {ComplianceToastrService} from "../../../../../../../common/service/toastr/compliance-toastr.service";
import {BackgroungType} from "../../../../../../../common/class/background/bg-compliance";
import {NotificationMessage} from "../../../../../../../common/class/polling/notification-message";
import {ParametrageValueService} from "../../../../../../../common/service/control/parametrage-value.service";

@Component({
    selector: 'compliance-codes-journaux',
    templateUrl: './codes-journaux.component.html',
    styleUrls: ['./codes-journaux.component.scss']
})
export class CodesJournauxComponent extends AbstractControl implements OnInit {

    @Input() projectId: string;
    journauxObj: any[] = [];
    familleJournaux: any[] = [];
    appareillage: any[] = [];
    appareillageInProgress = false;
    private control: ControlDescriptor;
    private doublons: any = [];
    private _key: string;

    constructor( private notificationService: NotificationService, private parametrageValueService: ParametrageValueService,
                private toaster: ComplianceToastrService, private route: ActivatedRoute) {
        super( BackgroungType.BG_FOREST);
        this._complianceLoaderService.sendLoaderShow();
    }

    ngOnInit() {
        super.setContextWithRoute(this.route, this.findData.bind(this));
        super.subscribeToNotificationControl({
            notificationService: this.notificationService,
            manageDataEvent: (n: NotificationMessage) => this.manageDataEvent(n)
        });
    }

    async findData() {
        if (!this.stateMachineId) {
            let control: ControlDescriptor = await this._controlService.findControlById(this.controlId);
            this.stateMachineId = control.stateMachineId;
        }
        this._controlService.dataControl(this.projectId, this.stateMachineId, this.exerciceId, this.event, this.stepByStep);
    }

    protected manageDataEvent(notification: NotificationMessage) {
        let familleJournaux = super.getValue("tva.journaux");
        this.journauxObj = [];
        this.familleJournaux = [];
        this.appareillage = notification.corp.data;
        this._key = notification.corp.responseName;
        _.each(_.keys(this.appareillage), (key) => {
            this.journauxObj = [...this.journauxObj, ...this.appareillage[key]];
            if (familleJournaux[key]) {
                this.familleJournaux.push({
                    code: key,
                    name: familleJournaux[key]
                });
            }
        });
        this.journauxObj = _.sortBy(this.journauxObj, 'label');
        this.familleJournaux = _.sortBy(this.familleJournaux, "name");
        this.appareillageInProgress = false;
        this._complianceLoaderService.sendLoaderHide();
    }

    containsJournal(journalCode: string, journalPrincipal: string): boolean {
        let journalList = this.appareillage[journalPrincipal];
        return _.some(journalList, {'code': journalCode});
    }

    setJournalValue($event, j: { code: string, label: string }, code: any) {
        let journalList = this.appareillage[code];
        let autreJournaux = this.appareillage["autreJournaux"];
        if ($event.target.checked) {
            journalList.push(j);
            _.remove(autreJournaux, (val: any) => val.code === j.code);
        } else {
            _.remove(journalList, (val: any) => val.code === j.code);
            let {affiliate} = this.isAffiliate(j.code);
            if (!affiliate) {
                autreJournaux.push(j);
            }
        }
        this.extractDoublon();
    }

    private extractDoublon() {
        let temp = [];
        _.forIn(this.appareillage, (value, key) => {
            temp = [...temp, ...value];
        });
        let journauxByFamille = _.groupBy(temp, 'code');
        let doublons = [];
        _.forIn(journauxByFamille, (value, key) => {
            if (value.length > 1) {
                doublons.push(value[0]);
            }
        });
        this.doublons = doublons;
    }

    private isAffiliate(journalCode: string) {
        let affiliate = false;
        let doublon = false;
        if (_.findIndex(this.doublons, {code: journalCode}) >= 0) {
            doublon = true;
        }
        _.each(this.familleJournaux, (fj) => {
            if (this.containsJournal(journalCode, fj.code)) {
                affiliate = true;
            }
        });
        return {affiliate, doublon};
    }

    launchNext() {
        this._complianceLoaderService.sendLoaderShow();
        if (this.stateMachineId) {
            this._controlService.continueExecutionControl(this.projectId, this.stateMachineId, this.exerciceId, this.event, {"parametersToSave" : this.processData()}, this.stepByStep);
        }
    }

    processData() {
        let ret = {};
        ret[this._key] = this.appareillage;
        return ret;
    }

    goBack() {
        this._complianceLoaderService.sendLoaderShow();
        this._controlService.backControl(this.projectId, this.stateMachineId, this.exerciceId, this.event, this.stepByStep);
    }

    async findControl() {
        this.control = await this._controlService.findControlById(this.controlId);
    }

    isRan() {
        return !this.appareillage['JAN'];
    }

    isNoJournalAffected(journal) {
        return !this.appareillage[journal] || !this.appareillage[journal].length;
    }

    isNoJournalClass(code: string) {
        let {affiliate, doublon} = this.isAffiliate(code);
        return affiliate && !doublon ? "" : "no-journal";
    }

    isAllJournauxAffected() {
        let isNotAllObligatoryJournalAffected = false;
        _.each(this.familleJournaux, (journal) => {
            let b = this.isNoAffected(journal.code);
            isNotAllObligatoryJournalAffected = isNotAllObligatoryJournalAffected || b;
        });

        let newVar = !!(this.appareillage["autreJournaux"] && this.appareillage["autreJournaux"].length);
        return newVar || isNotAllObligatoryJournalAffected || this.doublons.length || this.isRan();
    }

    isNoAffected(code: any) {
        if (code === 'JAC' || code === 'JBQ') {
            return this.isNoJournalAffected(code);
        } else if (code === 'JAN') {
            return this.isRan();
        }
        return false;
    }

    noAffectedClass(code) {
        return this.isNoAffected(code) ? "no-journal" : "";
    }
}
