import {RouterModule, Routes} from "@angular/router";
import {ComplianceComponent} from "../../compliance/compliance.component";
import {AuthGuard} from "../auth/auth.guard";
import {NgModule} from "@angular/core";
import {UploadFileComponent} from "./upload-file.component";

const routes: Routes = [
    {
        path: 'compliance',
        component: ComplianceComponent,
        canActivateChild: [AuthGuard],
        children:
            [
                {
                    path: 'upload/:domain/:category/:action/:projectId/:exerciceId/:stateMachineId',
                    component: UploadFileComponent,
                    data: {
                        title: "breadcrumb.control.uploadFiles"
                    }
                }
            ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UploadFileRoutingModule {
}
