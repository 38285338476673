<div id="content" class="page-code-journaux">
    <div class="container-fluid w-90">
        <div class="block-group-title">
            <h2 class="title big">{{'tva.journaux.title' | translate}}</h2>
            <h2 class="sub-title">{{'tva.journaux.subTitle' | translate}}</h2>
        </div>

        <div class="block-table scroll-table" *ngIf="journauxObj.length && !appareillageInProgress">
            <p-table [value]="journauxObj" [styleClass]="'table-data table-data--02'" sortMode="multiple" [dataKey]="'code'" sortField="label" [sortOrder]="-1">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>
                            <span [innerHTML]="'tva.journaux.headers.code' | translate | safe:'html'"></span>
                        </th>
                        <th class="label-long">
                            <span [innerHTML]="'tva.journaux.headers.label' | translate | safe:'html'"></span>
                        </th>
                        <th *ngFor="let principal of familleJournaux" class="justify-content-center"><div class="text-center">{{principal.name}}</div></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-j>
                    <tr class="{{isNoJournalClass(j.code)}}">
                        <th>{{j.code}}</th>
                        <td class="label-long">{{j.label}}</td>
                        <td *ngFor="let principal of familleJournaux" class="check {{noAffectedClass(principal.code)}}" >

                            <div class="custom-control custom-checkbox mb-3">
                                <input type="checkbox" class="custom-control-input" [attr.id]="j.code +'-' + principal.code"
                                       value="{{containsJournal(j.code, principal.code)}}"
                                       [checked]="containsJournal(j.code, principal.code)"
                                       (change)="setJournalValue($event,j, principal.code)">
                                <label class="custom-control-label" [attr.for]="j.code +'-' + principal.code">&nbsp;</label>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <!--<div class="text-right mb-2">-->
            <!--<div class="btn btn-primary" (click)="saveAppareillage()">{{'buttons.validate' | translate}}</div>-->
            <!--</div>-->
        </div>

    </div>
</div>

<!-- START : footer -->
<footer id="footer">
    <div class="inner">
        <button class="btn btn-primary btn-back" (click)="goBack()"><span>{{'buttons.back' | translate}}</span></button>

        <button class="btn btn-primary btn-next" (click)="launchNext()" [disabled]="isAllJournauxAffected()">
            <span>{{(this.controlId ? 'buttons.next' : 'buttons.finished') | translate}}</span></button>
    </div>
</footer>
<!-- STOP : footer -->
