<div id="" class="page-create-exercice" *ngIf="project">

    <div class="container">
        <div class="block-group-title w-70 mx-auto  text-center">
            <h2 *ngIf="!editExerciceAnnee" class="title">{{'dashboard.exercice.title' | translate}}</h2>
            <h2 *ngIf="editExerciceAnnee" class="title">{{'dashboard.exercice.updateTitle' | translate}}</h2>
            <h2 *ngIf="!editExerciceAnnee" class="sub-title">{{'dashboard.exercice.subtitle' | translate}}{{project.name}}</h2>
            <h2 *ngIf="editExerciceAnnee" class="sub-title">{{'dashboard.exercice.updateSubtitle' | translate}}{{project.name}}</h2>
        </div>
        <div class="w-70 mx-auto ">
            <form class="form-basic d-flex justify-content-center flex-column align-items-center" #exerciceForm="ngForm" novalidate>
                <div class="form-group w-70">
                    <label for="exerciceYear">{{'dashboard.exercice.form.clotureYear.label' | translate}}</label>
                    <input type="text" class="form-control" required pattern="\d{4}" [(ngModel)]="exercice.annee" name="annee" id="exerciceYear" (keyup)="searchExercice()" maxlength="4" autofocus="" #annee="ngModel">
                    <div class="error-alert" *ngIf="!annee.pristine && annee.invalid">
                        <div>{{'dashboard.exercice.form.clotureYear.error' | translate}}</div>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-5  w-70" *ngIf="exercice.projectId">
                    <div class="form-group">
                        <div class="label">{{'dashboard.exercice.form.dateDebutExercice.label' | translate}}</div>
                        <p-calendar [(ngModel)]="exercice.dateDebut" dateFormat="dd/mm/yy" name="dateDebut" [locale]="datePickerI18n" inputStyleClass="form-control"
                                    (onInput)="setDateDebut()" (onSelect)="setDateDebut()"
                                    showButtonBar="true" [monthNavigator]="true" [yearNavigator]="true" yearRange="1970:2130" [required]="true"></p-calendar>
                        <div class="error-alert" *ngIf="!exercice.dateDebut">
                            <div>{{'dashboard.exercice.form.dateDebutExercice.error' | translate}}</div>
                        </div>

                    </div>
                    <div class="form-group ml-3">

                        <div class="float-right">
                            <div class="label">{{'dashboard.exercice.form.dateFinExercice.label' | translate}}</div>
                            <p-calendar [(ngModel)]="exercice.dateFin" dateFormat="dd/mm/yy" name="dateFin" [locale]="datePickerI18n" inputStyleClass="form-control"
                                        (onInput)="setDateFin()" (onSelect)="setDateFin()"
                                        showButtonBar="true" [monthNavigator]="true" [yearNavigator]="true" yearRange="1970:2130"></p-calendar>
                        </div>
                        <div class="clearfix"></div>
                        <div class="error-alert" *ngIf="!exercice.dateFin">
                            <div>{{'dashboard.exercice.form.dateFinExercice.error' | translate}}</div>
                        </div>
                    </div>

                </div>
                <div class="d-flex justify-content-around mt-5  w-70" *ngIf="exercice.projectId && !isDatDebutBeforeDateFin()">
                    <div class="error-alert flex-fill">
                        <div>{{'dashboard.exercice.form.date.error' | translate}}</div>
                    </div>
                </div>
            </form>
            <div class="w-70 mx-auto error-alert" *ngIf="showExerciceAlreaydExist">
                <div class="text-size-16">{{exercice.annee}}: {{'dashboard.exercice.exerciceAlreadyExist' | translate}}</div>
            </div>
        </div>


    </div>
    <footer id="footer">
        <div class="inner">
            <button class="btn btn-primary btn-back" (click)="goBack()"><span>{{'buttons.back' | translate}}</span></button>
            <button *ngIf="editExerciceAnnee" class="btn btn-primary btn-next" [disabled]="!isExerciceValid()" (click)="updateExercice()"><span>{{'dashboard.exercice.validateUpdateExercice' | translate}}</span></button>
            <button *ngIf="!editExerciceAnnee" class="btn btn-primary btn-next" [disabled]="!isExerciceValid()" (click)="saveExercice()"><span>{{'dashboard.exercice.createExerciceButton' | translate}}</span></button>
        </div>
    </footer>
</div>
