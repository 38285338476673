import {ApplicationRef, Injectable, OnDestroy} from '@angular/core';
import {SwUpdate} from "@angular/service-worker";
import {concat, interval, Observable, Subject} from "rxjs";
import {first, takeUntil} from "rxjs/operators";

@Injectable()
export class UpdateService implements OnDestroy{

    destroy$: Subject<boolean> = new Subject<boolean>();
    private _updateAvailableSubject: Subject<boolean> = new Subject<boolean>();
    $updateAvailable: Observable<boolean> = this._updateAvailableSubject.asObservable();

    constructor(private swUpdate: SwUpdate, appRef: ApplicationRef) {
        if(this.swUpdate.isEnabled) {
            this.swUpdate.available
                .pipe(takeUntil(this.destroy$))
                .subscribe(evt => {
                    // an update is available, add some logic here.
                    console.warn("service worker update service", evt);
                    this._updateAvailableSubject.next(true);
                });
            const appIsStable$ = appRef.isStable.pipe(
                first(isStable => isStable === true),
                takeUntil(this.destroy$)
            );
            const everySixHours$ = interval(6 * 60 * 60 * 1000);
            const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

            everySixHoursOnceAppIsStable$
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => swUpdate.checkForUpdate());
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


}
