import {AccountOrigin} from "./accountOrigin";

export interface Subscription {
    id: string;
    label: string;
    accessControlVies?:boolean;
}

export class AccountType {
    id: string;
    label: string;
}

export class Account {
    id:string;
    codeIse?: string;
    raisonSociale?:string;
    accountProjectNumber:number;
    accountProjectCreated:number;
    disabled?: boolean;
    deleted?: boolean;
    subscription: Subscription;
    origin: AccountOrigin;
    accountType: AccountType;
    subscriptionEndDate?: Date;
}
