import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ExportBlock, TableBlock} from "../../../shared/compte-rendu-detaille";
import {ComplianceI18nLoader} from "../../../../../../../../../../common/class/i18n/compliance-i18n-loader";
import {TableComponent} from "../../../../../../../shared/table/table.component";
import {CompteRenduDetaillUtilsService} from "../../shared/compte-rendu-detaill-utils.service";
import _ from 'lodash';
import {TableItem} from "../../../../../../../shared/table/shared/table-item";
import {Subject} from "rxjs";

@Component({
    selector: 'compliance-table-block',
    templateUrl: './table-block.component.html',
    styleUrls: ['./table-block.component.scss']
})
export class TableBlockComponent extends ComplianceI18nLoader implements OnInit {
    @Input() destroy$: Subject<boolean>;

    @Input() tableBlock: TableBlock;

    @ViewChild('table') complianceTable: TableComponent;
    items: TableItem[];
    blockExport: ExportBlock;

    constructor(public compteRenduDetaillUtilsService: CompteRenduDetaillUtilsService) {
        super();
    }

    ngOnInit() {
        this.tableBlock.data = this.tableBlock.data || _.get(this.compteRenduDetaillUtilsService.CRDData, this.tableBlock.tableField) || [];
        if (this.tableBlock.footer) {
            this.tableBlock.footer = this.computeFooter(this.tableBlock.footer);
        }
        if (this.tableBlock.order) {
            const order = this.tableBlock.order.split(':');
            this.tableBlock.data = this.tableBlock.data.sort((a, b) => {
                const aValue = a[order[0]];
                const bValue = b[order[0]];
                if (order[1] === '1' || !order[1]) {
                    return aValue < bValue ? -1 : 1;
                } else {
                    return aValue > bValue ? 1 : -1;
                }
            });
        }
        this.items = this.compteRenduDetaillUtilsService.findTableItems(this.tableBlock.metaDataRef);
    }


    export() {
        this.complianceTable.export();
    }


    private computeFooter(footer: any) {
        let footerComputed = {};
        _.forIn(footer, (value, key) => {
            footerComputed[key] = _.get(this.compteRenduDetaillUtilsService.CRDData, value, value);
        });

        return footerComputed;
    }
}
