export const question = {
    periode: {
        title: 'Sélectionner la période à contrôler',
        subtitle: {
            selectEnd: 'Veuillez sélectionner la date jusqu\'à laquelle vous souhaitez réaliser le contrôle',
            selectAll: 'Veuillez sélectionner les dates de début et de fin de la période sur laquelle vous souhaitez réaliser le contrôle'
        }
    },
    choixTva: {
        asterix: {
            controle_8: `Pour la réalisation de ce contrôle, un seul et unique taux de TVA collectée doit être appliqué. Si votre entité juridique réalise des opérations multi-taux de TVA, vous ne pourrez pas effectuer ce contrôle.`
        }
    },
    acquisitionSpecifique: {
        title: `L’entité juridique a-t-elle déclaré en ligne 02 de l'imprimé CA3 des achats spécifiques sur l'exercice ?`,
        asterix: `<p class="text-white">La ligne 02 « Autres opérations imposables » de la CA3 reprend certains produits qui ne constituent pas le chiffre d’affaires courant (notamment les cessions d’immobilisations) mais également des achats de
                    biens et services spécifiques générant une autoliquidation de la TVA :</p>
                    <ul class="ml-5 text-white">
                        <li>l’achat de services relevant de la règle générale « B to B » acquis auprès de prestataires établis hors de l’UE ;</li>
                        <li>l’achat d’or :
                            <ul style='margin-left: 20px;'>
                                <li>sous forme de matière première ou de produits semi-ouvrés d'une pureté égale ou supérieure à 325 millièmes,</li>
                                <li>d'investissement destiné à un usage industriel ;</li>
                            </ul>
                        </li>
                        <li>l’achat de déchets neufs d’industrie et de matières de récupération ;</li>
                        <li>l’achat de prestation de façon portant sur des déchets neufs d’industrie et des matières de récupération ;</li>
                        <li>l'achat d’un service de sous-traitance de travaux de construction en relation avec un bien immobilier ;</li>
                        <li>l’achat de gaz et d’électricité (et services liés) à un acheteur revendeur ;</li>
                        <li>l'achat de quotas d’émission de gaz à effet de serre et d’unités de réduction de ces émissions ;</li>
                        <li>l’achat de services de communications électroniques.</li>
                    </ul>`
    },
    tableauSelectionSpecifique: {
        title: `Comptes de TVA déductible`,
        subtitle: `Sélectionner les comptes de TVA déductible utilisés pour les achats de biens et services`,
        headers: {
            code: "N° compte",
            label: "Intitulé du compte",
        },
    },
    livraisonsBiensApresMontageInstallationUE: {
        title: `L’entité juridique réalise-t-elle des livraisons de biens après montage ou installation (par le vendeur) dans d’autres Etats membres de l’UE sur l'exercice ?`,
        asterix: `<b>Exemple :</b></br> Une société A établie en France, vend une machine a une société B établie en Espagne. La société française se charge de transporter la machine de France vers l’Espagne, puis d'installer ladite machine sur place.
                    </br>Cette opération doit être déclarée par la société française :</p>
                    <ul class="ml-5 text-white">
                        <li>avec le code régime 29 dans la DEB à l’expédition ;</li>
                        <li>dans la ligne 06 de la déclaration de TVA.</li>
                    </ul>`
    },
    montantAicN_2: {
        title: `Avez-vous déclaré plus de 460&nbsp;000&nbsp;€ d'acquisitions intracommunautaires sur vos déclarations de TVA entre janvier {{year}} et décembre {{year}} ?`,
        asterix: `Si vous ne connaissez pas le montant des acquisitions intracommunautaires réalisé sur cette période, nous vous invitons à retourner sur le tableau de bord afin de créer la période concernée et pouvoir ainsi télécharger les DEB à l'introduction. Le montant sera calculé automatiquement.`,
        modal: {
            cas1: {
                header: `Confirmation`,
                content: `<div>Confirmez-vous ne pas avoir dépassé le seuil de 460&nbsp;000&nbsp;€ d'AIC sur vos déclarations de TVA au cours de l'année civile {{year}} ?</div>`
            },
            cas2: {
                header: `Attention`,
                content: `<div>Pour l'année civile {{year}}, nous avons identifié {{montant_aic_N_2_CA3}} € d'AIC sur vos déclarations de TVA.</div><div class="mt-2">Etes-vous sûr de vouloir indiquer ne pas avoir dépassé le seuil de 460&nbsp;000&nbsp;€ d'AIC sur l'année civile {{year}} ?</div>`
            }
        }
    },
    montantAicN_1: {
        title: `Quel montant d'acquisitions intracommunautaires </br>avez-vous réalisé entre janvier {{year}} et {{dateFinSaisie}} ?`,
        asterix: `Si vous ne connaissez pas le montant des acquisitions intracommunautaires réalisé sur cette période, nous vous invitons à retourner sur le tableau de bord afin de créer la période concernée et pouvoir ainsi télécharger les DEB à l'introduction. Le montant sera calculé automatiquement.`,
        modal: {
            confirmCas: {
                header: `Confirmation`,
                content: `Confirmez-vous que vous n'avez pas dépassé le seuil de 460&nbsp;000&nbsp;€ d'AIC entre janvier {{year}} et {{dateFinSaisie}} ?`

            },
            warningCas: {
                header: `Attention`,
                content: `<div>De janvier {{year}} à {{dateFinSaisie}}, nous avons identifié {{montantAicN_1}} € d'AIC sur vos déclarations de TVA.</div><div class="mt-2">Etes-vous sûr de vouloir indiquer ne pas avoir dépassé le seuil de 460&nbsp;000&nbsp;€ d'AIC sur cette période ?</div>`

            }
        }
    }
};


