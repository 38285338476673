import {Component, OnInit} from '@angular/core';
import {BackgroungType} from "../../../../../../../../common/class/background/bg-compliance";
import {ActivatedRoute, Router} from "@angular/router";
import {CompteRenduService} from "../shared/compte-rendu.service";
import {CompteRenduGeneral} from "../shared/compte-rendu-general";
import {ControlDescriptor} from "../../../../../../../../common/class/control/control-descriptor";
import {NotificationService} from "../../../../../../../../common/service/push/notification.service";
import {AbstractControl} from "../../../../../../../../common/component/control/abstract-control";
import {NotificationMessage} from "../../../../../../../../common/class/polling/notification-message";
import {ControlRoutesFlux} from "../../../../../../../../common/class/control/control-flux";
import {Expression} from "../../../../../../../../common/class/meta/meta-expression";
import {Graphe} from "../../../../../../../../common/class/graphique/graphique";
import {MetaExpressionService} from "../../../../../../../../common/service/meta/meta-expression.service";

@Component({
    selector: 'compliance-compte-rendu-general',
    templateUrl: './compte-rendu-general.component.html',
    styleUrls: ['./compte-rendu-general.component.scss']
})
export class CompteRenduGeneralComponent extends AbstractControl implements OnInit {

    public compteRendu: CompteRenduGeneral;
    private control: ControlDescriptor;
    private view: string;
    private viewFrom: string;

    constructor( private compteRenduService: CompteRenduService, private router: Router, private route: ActivatedRoute,
                 private notificationService: NotificationService, private _expressionService: MetaExpressionService) {
        super(BackgroungType.BG_TREE_2);
    }

    ngOnInit() {
        // this.notificationService.initializePush();
        super.setContextWithRoute(this.route, this.findCompteRenduGeneral.bind(this));
        super.subscribeToNotificationControl({notificationService:this.notificationService, manageDataEvent :(n:NotificationMessage)=> this.manageDataEvent(n)});

        this.view = this.route.snapshot.queryParamMap.get('view');
        this.viewFrom = this.route.snapshot.queryParamMap.get('from');
    }


    protected manageDataEvent(notification: NotificationMessage) {
    }

    async findCompteRenduGeneral() {
        this.control = await this._controlService.findControlById(this.controlId);
        this.compteRendu = await this.compteRenduService.findCompteRenduGeneralData(this.projectId, this.exerciceId, this.controlId);
        if (this.compteRendu.grapheContainer) {
            this._computeDisplayGraph(this.compteRendu.grapheContainer.graphes, this.compteRendu.values);
        }
        this._complianceLoaderService.sendLoaderHide();
    }

    goBack() {
        this._complianceLoaderService.sendLoaderShow();
        if (this.view === 'visualisation') {
            if (this.viewFrom === 'controlList') {
                this.router.navigate(['/compliance', 'control', this.domain, this.category, this.projectId, this.exerciceId, 'list']);
            } else {
                this.router.navigate(['/compliance', this.domain, this.category, 'project', this.projectId, this.exerciceId, 'dashboard']);
            }
        } else {
            this._controlService.backControl(this.projectId, this.control.stateMachineId, this.exerciceId, "DONE", this.stepByStep);
        }
    }

    goNext() {
        this._complianceLoaderService.sendLoaderShow();
        let route;
        if (this.compteRendu.noIncoherence) {
            route = this.notificationService.generateControlRoute(this.projectId, this.domain, this.category, this.controlId, ControlRoutesFlux["supervision"], this.exerciceId);
        } else {
            route = this.notificationService.generateControlRoute(this.projectId, this.domain, this.category, this.controlId, 'compte-rendu-detaille', this.exerciceId);
        }
        this.router.navigate([route], {
            queryParams: {cas0: this.compteRendu.noIncoherence ? 'true' : 'false'},
            queryParamsHandling: 'merge',
            preserveFragment: true
        });
    }

    computeExpression(expression: Expression) {

    }

    private _computeDisplayGraph(graphes: Graphe[], values: any) {
        graphes.forEach((graphe: Graphe)=> {
            if(graphe.display){
                console.log([graphe.display, values]);
               graphe.showGraph = !!this._expressionService.computeExpression(graphe.display, values)
            }else{
                graphe.showGraph = true;
            }
        })

    }
}
