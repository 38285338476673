import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {BgForestComponent} from "../../../../../common/class/background/bg-compliance";
import {Router} from "@angular/router";
import {User} from "../../../../../common/class/user/user";
import {AuthService} from "../../../../../common/service/auth/auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {REGEX_MAIL} from "../../../../../common/validator/compliance-validator";
import {Contact} from "./shared/Contact";
import {ComplianceToastrService} from "../../../../../common/service/toastr/compliance-toastr.service";
import {AideService} from "./shared/aide.service";

@Component({
    selector: 'compliance-aide',
    templateUrl: './aide.component.html',
    styleUrls: ['./aide.component.scss']
})
export class AideComponent extends BgForestComponent implements OnInit, OnDestroy {
    connectedUser: User;
    contactForm: FormGroup;

    constructor( private _router: Router, private _fb: FormBuilder, private _authService: AuthService,
                private _location: Location, private _complianceToastr: ComplianceToastrService, private _aideService: AideService) {
        super();
    }

    ngOnInit() {
        const user = this._authService.getConnectedUser();
        this.connectedUser = user ? user : new User();
        this.createForm();
    }

    ngOnDestroy(): void {
    }

    private createForm() {
        this.contactForm = this._fb.group({
            company: [this.connectedUser.account.raisonSociale, [Validators.required]],
            lastName: [this.connectedUser.lastName, [Validators.required]],
            firstName: [this.connectedUser.firstName, [Validators.required]],
            email: [this.connectedUser.email, [Validators.required, Validators.pattern(REGEX_MAIL)]],
            phone: ["", [Validators.required]],
            comment: ["", [Validators.required]]
        });
    }

    submit(form) {
        if (form.valid) {
            let contact: Contact = form.value;
            this._aideService.sendNotification(contact);
            this._complianceToastr.successI18n('help.form.successMessage');
            this.goBack();
        }
    }

    goBack() {
        this._location.back();
    }
}
