<ng-container >
    <div class="modal-controle">
        <button type="button" (click)="showDialog()" class="btn-circle fas fa-info button-circle btn-md"></button>
    </div>
    <p-dialog header="Origine des incohérences" [(visible)]="display" [modal]="true" [responsive]="true" [resizable]="false" [draggable]="false"
              [style]="{width:'700px',wdthMin:'200px'}" [minY]="70"
              [maximizable]="false" [baseZIndex]="10000">
        <div class="text-custom text-justify" >
            <div class="text-size-14 pl-4" *ngFor="let info of control.infos" [innerHtml]="info | safe:'html'"></div>
        </div>
        <p-footer>
            <button type="button" class="btn btn-primary btn-sm"  (click)="display=false" ><i class="fal fa-check mr-2"></i>{{'buttons.close' | translate}}</button>
        </p-footer>
    </p-dialog>

</ng-container>
