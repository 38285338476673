import {Component, Input, OnInit} from '@angular/core';
import {TableItem, TableItemType} from "../shared/table-item";
import _ from 'lodash';
import {MetaExpressionService} from "../../../../../../common/service/meta/meta-expression.service";
import {ActivatedRoute} from "@angular/router";
import {ReportingService} from "../../../compliance/project/dashboard/tva-dashboard/reporting.service";
import {ConfirmationService} from "primeng/api";
import {takeUntil} from "rxjs/operators";
import {TYPE_EXPORT, TypeExport} from "../../export-file/export-file";
import {NotificationService} from "../../../../../../common/service/push/notification.service";
import {FileUtilsService} from "../../../../../../common/service/file/file-utils.service";
import {Subject} from "rxjs";
import {v4 as uuid} from 'uuid';

@Component({
    selector: 'compliance-value',
    templateUrl: './value.component.html',
    styleUrls: ['./value.component.scss']
})
export class ValueComponent implements OnInit {
    private _uuid = uuid();
    private projectId: string;
    private exerciceId: string;
    private controlId: string;
    @Input() data: any;
    @Input() noTd: boolean;
    @Input() meta: TableItem;
    @Input() hasExport: boolean = false;
    @Input() destroy$: Subject<boolean>;


    itemType = TableItemType;
    classToAffect: {};
    exporting = false;
    private _exportNotAvailable: boolean;
    @Input() set ngExportNotAvailable(exportNotAvailable: boolean){
        this._exportNotAvailable = exportNotAvailable;
    }
    constructor(private expressionService: MetaExpressionService, private reportingService: ReportingService, private _confirmationService: ConfirmationService, private route: ActivatedRoute, private _notificationService: NotificationService,
                private _fileUtils: FileUtilsService) {
    }

    ngOnInit() {
        this.projectId = this.route.snapshot.paramMap.get("projectId");
        this.exerciceId = this.route.snapshot.paramMap.get("exerciceId");
        this.controlId = this.route.snapshot.paramMap.get("controlId");
        this.computeClass();
        this._notificationService.fileNotificationObservable$
            .pipe(takeUntil(this.destroy$))
            .subscribe((msg) => {
                if (this.meta.export && this.meta.export.ecriture) {
                    if (this._uuid ==  msg.uuid) {
                        let exportData = this.meta.export.ecriture;
                        let fileName: string = exportData.fileName;
                        if (exportData.periodField) {
                            fileName += ' ' + this.data[exportData.periodField];
                        }
                        this._fileUtils.saveFile(fileName, this._fileUtils.blobPdfFromBase64String(msg.blob), new TypeExport(TYPE_EXPORT[exportData.fileType]));
                        this.exporting = false;
                    }
                }
            });
    }

    computeClass() {
        let returnClass = {};
        if (!this.meta.valueClass) {
            return returnClass;
        }
        if (_.isString(this.meta.valueClass)) {
            returnClass[this.meta.valueClass] = true;
        } else {
            returnClass[this.meta.valueClass.class] = this.expressionService.computeExpression(this.meta.valueClass.expression, this.data);
        }
        this.classToAffect = returnClass;
    }

    exportMeta() {
        if (this.meta.export) {
            if (this._exportNotAvailable === true) {
                this._confirmationService.confirm({
                    acceptVisible: false,
                    rejectVisible: true,
                    rejectLabel: 'Fermer',
                    header: 'Information',
                    message: 'Vous devez relancer le calcul du contrôle pour pouvoir exporter les écritures correspondantes.'
                });
            } else {
                let exportData = this.meta.export.ecriture;
                let period = null;
                if (exportData.periodField) {
                    period = this.data[exportData.periodField];
                }
                this.exporting = true;
                this.reportingService.exportEcritures(this.projectId, this.exerciceId, this.controlId, this._uuid, period, exportData.codes);
            }
        }
    }
}
