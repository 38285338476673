import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ComplianceI18nLoader} from "../../../class/i18n/compliance-i18n-loader";
import {FormQuestion, MetaQuestion, QuestionEvent, QuestionEventType} from "../../../class/meta/question/meta-question";
import {MetaExpressionService} from "../../../service/meta/meta-expression.service";
import {MetaFormulaireService} from "../../../service/meta/meta-formulaire.service";
import {FormGroup} from "@angular/forms";
import {MetaQuestionService} from "../../../service/meta/meta-question.service";
import {ActivatedRoute} from "@angular/router";
import {OriginPage} from "../../control/control-message-handler";

@Component({
    selector: 'compliance-form-question',
    templateUrl: './form-question.component.html',
    styleUrls: ['./form-question.component.scss'],
})
export class FormQuestionComponent extends ComplianceI18nLoader implements OnInit {

    metaQuestion: MetaQuestion<FormQuestion>;
    context: any;
    question: FormQuestion;
    addNew: boolean;
    choice: any = {};

    isControl: boolean;

    @Output() actionEvent: EventEmitter<QuestionEvent> = new EventEmitter();
    nextActive: boolean | number;
    questionFormGroup: FormGroup;
    itemAlreadyExist: boolean;

    constructor(private _route: ActivatedRoute,
                private _expressionService: MetaExpressionService,
                private _formService: MetaFormulaireService,
                private _questionService: MetaQuestionService) {
        super();
        this.isControl = _route.snapshot.queryParamMap.get('originPage') !== OriginPage.GLOBALPARAMS;
    }

    ngOnInit() {
        this.question = new FormQuestion(this.metaQuestion.question);

        console.log(this.question);
        if (this.question.formulaire) {
            this.addNew = false;
            this.questionFormGroup = this._formService.toFormGroup(this.question.formulaire);
            this.questionFormGroup.valueChanges.subscribe((v)=> {
                this.question.data = this._formatedResponse();
                this.metaQuestion.question.data = this.question.data;
                this.computeNextActive();
            });
        }
        this.setForm();
        console.log('FormQuestionComponent ngOnInit', this.choice);
        this.computeNextActive();
    }

    private setForm() {
        if(this.question.data !== undefined) {
            let value = {};
            value[this.question.responseName] = this.question.data;
            this.questionFormGroup.setValue(value);
        }
    }

    goBack() {
        this.actionEvent.emit({
            type: QuestionEventType.GLOBAL,
            data: {code: 'back'}
        });
    }

    goNext() {
        this.actionEvent.emit({
            type: QuestionEventType.GLOBAL,
            data: {code: 'next', data: this.question.data}
        });
    }

    private _formatedResponse() {
        let saveType = this.question.formulaire.find((item) => item.id === this.question.responseName).saveType;
        switch (saveType) {
            case "number":
                return parseInt(this.questionFormGroup.value[this.question.responseName], 10);
            default:
                return this.questionFormGroup.value[this.question.responseName];
            }
    }

    private computeNextActive() {
        let next = this.question.buttons.next;
        if (next) {
            if (next.active) {
                this.nextActive = this._expressionService.computeExpression(next.active, this.question);
            } else {
                this.nextActive = true;
            }
        } else {
            this.nextActive = true;
        }
    }

    showForm() {
        this.addNew = !this.addNew;
    }
}
