import {RouterModule, Routes} from "@angular/router";
import {ComplianceComponent} from "../compliance.component";
import {AuthGuard} from "../../shared/auth/auth.guard";
import {NgModule} from "@angular/core";
import {ProjectFormComponent} from "./project-form/project-form.component";
import {ProjectListComponent} from "./project-list/project-list.component";
import {ExerciceCreateComponent} from "./exercice/exercice-create/exercice-create.component";
import {GestionFileComponent} from "./gestion-file/gestion-file.component";
import {ExerciceSupervisionComponent} from "./exercice/exercice-supervision/exercice-supervision.component";
import {ExerciceAuthGuard} from "../../shared/auth/exercice-auth.guard";
import {ManualCa3Component} from "./gestion-file/edit/ca3/manual-ca3.component";

const routes: Routes = [
    {
        path: 'compliance',
        component: ComplianceComponent,
        canActivateChild: [AuthGuard],
        children:
            [
                {
                    path: ':domain/:category/project',
                    canActivateChild: [AuthGuard],
                    children:
                        [
                            // {
                            //     path: ':siren',
                            //     component: ProjectSirenComponent
                            // },
                            {
                                path: 'create',
                                component: ProjectFormComponent,
                                data: {
                                    title: "breadcrumb.createProject"
                                }
                            },
                            {
                                path: 'list',
                                component: ProjectListComponent
                            },
                            {
                                path: ':projectId',
                                children: [
                                    {
                                        path: 'exercice/create',
                                        component: ExerciceCreateComponent,
                                        data: {
                                            title: "breadcrumb.createExercice"
                                        }
                                    },
                                    {
                                        path: 'exercice/:exerciceId/edit',
                                        component: ExerciceCreateComponent,
                                        canActivate:[ExerciceAuthGuard],
                                        data: {
                                            title: "breadcrumb.editExercice"
                                        }
                                    },
                                    {
                                        path: ':exerciceId/file-list',
                                        component: GestionFileComponent,
                                        canActivate:[ExerciceAuthGuard]
                                    },
                                    {
                                        path: ':exerciceId/file-list/edit-ca3',
                                        component: ManualCa3Component,
                                        canActivate:[ExerciceAuthGuard],
                                        data: {
                                            title: "breadcrumb.edit-ca3"
                                        }
                                    },{
                                        path: ':exerciceId/file-list/edit-ca3/:fileId',
                                        component: ManualCa3Component,
                                        canActivate:[ExerciceAuthGuard],
                                        data: {
                                            title: "breadcrumb.edit-ca3"
                                        }
                                    },
                                    {
                                        path: ':exerciceId/supervision',
                                        component: ExerciceSupervisionComponent,
                                        data: {
                                            title: "breadcrumb.exerciceSupervision"
                                        }
                                    },
                                ]
                            }
                        ]
                }
            ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProjectSirenRoutingModule {
}
