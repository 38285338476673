import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";
import {Exercice} from "../../class/exercice/exercice";
import {CacheService} from "../cache/cache.service";
import {Observable, of} from "rxjs";
import {map, tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ExerciceService extends ComplianceApiService {

    constructor(private restService: RestService, private cacheService: CacheService) {
        super('exercice', 'api');
    }

    async findExercicesByProject(projectId: string): Promise<Exercice[]> {
        let exercices = await this.restService.get({
            url: this.baseUri,
            relativeUri: 'project/list',
            parameters: [projectId],
            arg: null as Exercice[]
        });
        this.cacheService.addAnyToCache(exercices);
        return of(exercices).toPromise();
    }

    findExercicesByProjectAndYear(projectId: string, year: string): Promise<Exercice> {
        return this.restService.get({
            url: this.baseUri,
            parameters: [projectId, year],
            arg: null as Exercice
        });
    }

    async findExercicesById(id: string): Promise<Exercice> {
        let cacheData = this.cacheService.getData(id);
        if (cacheData) {
            return of(cacheData).toPromise();
        }
        let data = await this.restService.get({
            url: this.baseUri,
            parameters: [id],
            arg: null as Exercice
        });
        this.cacheService.addToCache(data.id, data);
        return of(data).toPromise();
    }

    isExerciceValidate(id: string): Observable<boolean> {
        let cacheData: Exercice = this.cacheService.getData(id) as Exercice;
        if (cacheData) {
            return of(cacheData.validation);
        }
        return this.restService.getObservable({
            url: this.baseUri,
            parameters: [id],
            arg: null as Exercice
        }).pipe(
            tap((exercice: Exercice) => {
                this.cacheService.addToCache(exercice.id, exercice);
            }),
            map((exercice: Exercice) => exercice.validation)
        );
    }

    saveExercice(exercice: Exercice): Promise<Exercice> {
        this.cacheService.addToCache(exercice.id, exercice);
        return this.restService.post({
            url: this.baseUri,
            data: exercice,
            arg: null as Exercice
        });
    }

    updateExercice(exercice: Exercice): Promise<Exercice> {
        this.cacheService.addToCache(exercice.id, exercice);
        return this.restService.put({
            url: this.baseUri,
            data: exercice,
            arg: null as Exercice
        });
    }

    updateExerciceSupervision(exercice: Exercice): Promise<Exercice> {
        this.cacheService.addToCache(exercice.id, exercice);
        return this.restService.put({
            url: this.baseUri,
            relativeUri: 'supervision',
            data: exercice,
            arg: null as Exercice
        });
    }

    deleteExercice(exerciceId: string): Promise<any> {
        this.cacheService.deleteFromCache(exerciceId);
        return this.restService.delete({
            url: this.baseUri,
            parameters: [exerciceId],
            arg: null
        });
    }
}
