import {TranslateService} from "@ngx-translate/core";
import {COMPLIANCE_LANGS} from "./i18n";
import * as _ from "lodash";
import {ServiceLocator} from "../locator/service-locator";

export class ComplianceI18nLoader {

    public translate: TranslateService;
    public lang: string;


    constructor() {
        let translate = ServiceLocator.injector.get(TranslateService);
        let langs = translate.getLangs();

        let browserLang = translate.getBrowserLang();
        this.lang = browserLang.match(/fr/) ? 'fr' : 'fr';
        if (!_.includes(langs, this.lang)) {
            translate.addLangs([this.lang]);
        }
        translate.setTranslation(this.lang, COMPLIANCE_LANGS[this.lang]);


        translate.use(this.lang);
        this.translate = translate;
    }

    public getTranslation(): any {
        return COMPLIANCE_LANGS[this.lang];
    }

    public getValue(i18nProperty: string, params?: Object): string {
        if(i18nProperty === undefined || i18nProperty === null || i18nProperty === "")
            return "";
        return this.translate.instant(i18nProperty, params);
    }
}
