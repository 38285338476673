import {Role} from "./role";
import {Account} from "./account";
import {merge} from "lodash"

export class Cookie {

    cookiePubPerso: boolean;
    cookieStat: boolean;
    navigators: string[];

    constructor(cookie) {
        if(cookie) {
            merge(this, cookie);
        }else{
            this.navigators = [];
            this.cookieStat = true;
            this.cookiePubPerso = true;
        }

    }

    addNavigator(navigator: string) {
        this.navigators = this.navigators || [];
        const val = this.navigators.find(value => value === navigator);
        if (!val) {
            this.navigators.push(navigator);
        }
    }

    hasCookieForNavigator(navigator: string): boolean {
        return this.navigators && !!this.navigators.find(value => value === navigator);
    }
}

export class User {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    account: Account;
    role: Role;
    tempUserIdToSetPassword: string;
    password: string;
    cgvu?: string;
    hasReadInformationPage?: boolean;
    lastConnectionDate: Date[];
    validPassword: boolean;
    disabled?: boolean;
    deleted?: boolean;
    numberOfConnections?: number;
    cookie: Cookie;
    subscriptionEndDate?: Date;

    constructor() {
        this.account = new Account();
        this.role = new Role();
    }
}
