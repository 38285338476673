
<div class="supervision-exercice container-fluid w-75 h-100" *ngIf="supervisionForm">
    <form class="row d-flex flex-column h-100" [formGroup]="supervisionForm">
        <div class="row">
            <div class="col-12">
                <div class="block-group-title">
                    <h2 class="title">{{'supervision.exercice.title' | translate}}</h2>
                    <h2 class="sub-title">{{'supervision.exercice.subtitle' | translate}}</h2>
                </div>
            </div>
        </div>
        <div class="row col">
            <div class="col-10 d-flex flex-column h-100">
                <div class="row obs-n-corr">
                    <div class="col-12 h-100 d-flex flex-column">
                        <div class="block-group-title sub-title">
                            <h2 class="sub-title">{{'supervision.observations' | translate}}</h2>
                        </div>
                        <p-editor class="col p-0" formControlName="observations"
                                  [ngClass]="{'disabled': supervisionForm.value.validation}">
                            <p-header>
                                <span class="ql-formats">
                                    <button class="ql-bold" aria-label="Bold"></button>
                                    <button class="ql-italic" aria-label="Italic"></button>
                                    <button class="ql-underline" aria-label="Underline"></button>
                                </span>
                                        <span class="ql-formats">
                                    <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)"></select>
                                    <select title="Background Color" class="ql-background"
                                            defaultValue="rgb(255, 255, 255)"></select>
                                </span>
                                        <span class="ql-formats">
                                    <button class="ql-list" value="ordered"></button>
                                    <button class="ql-list" value="bullet"></button>
                                </span>
                                        <span class="ql-formats">
                                    <button class="ql-clean" aria-label="No style"></button>
                                </span>
                            </p-header>
                        </p-editor>
                    </div>
                </div>
                <div class="separator my-4"></div>
                <div class="row concl">
                    <div class="col-12 h-100 d-flex flex-column">
                        <div class="block-group-title sub-title">
                            <h2 class="sub-title">{{'supervision.conclusion' | translate}}</h2>
                        </div>
                        <p-editor class="col p-0" formControlName="conclusion"
                                  [ngClass]="{'disabled': supervisionForm.value.validation}">
                            <p-header>
                        <span class="ql-formats">
                            <button class="ql-bold" aria-label="Bold"></button>
                            <button class="ql-italic" aria-label="Italic"></button>
                            <button class="ql-underline" aria-label="Underline"></button>
                        </span>
                                <span class="ql-formats">
                            <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)"></select>
                            <select title="Background Color" class="ql-background"
                                    defaultValue="rgb(255, 255, 255)"></select>
                        </span>
                                <span class="ql-formats">
                            <button class="ql-list" value="ordered"></button>
                            <button class="ql-list" value="bullet"></button>
                        </span>
                                <span class="ql-formats">
                            <button class="ql-clean" aria-label="No style"></button>
                        </span>
                            </p-header>
                        </p-editor>
                    </div>
                </div>
            </div>
            <div class="col-2 d-flex flex-column h-100">
                <div class="h-100 d-flex flex-column justify-content-center">
                    <div class="block-group-title text-center m-3">
                        <h2 class="sub-title">{{'supervision.exercice.validation' | translate}}</h2>
                    </div>
                    <div class="validation p-0 d-flex flex-column justify-content-center">
                        <div class="btn-group btn-group-toggle d-flex flex-column justify-content-center align-items-center" ngbRadioGroup formControlName="validation">
                            <label class="btn btn-info d-flex flex-column align-items-center justify-content-center h-auto m-4" ngbButtonLabel *ngFor="let validation of validationType">
                                <input type="radio" ngbButton [value]="validation.value">
                                <span><i class="{{validation.icon}}"></i></span>
                                <span>{{validation.label | translate}}</span>
                            </label>
                        </div>
                    </div>
                    <div class="error my-4">
                        <div class="error-alert h-100" *ngIf="error"><span>{{'supervision.exercice.errorMessage' | translate}}</span></div>
                    </div>
                </div>
            </div>
        </div>


    </form>
</div>

<!-- START : footer -->
<footer id="footer">
    <div class="inner">
        <a href="#" (click)="goDashboard()" class="btn btn-primary btn-next"><span>{{'buttons.finished' | translate}}</span></a>
    </div>
</footer>
