import {Injectable} from '@angular/core';
import {UploadActionService} from "../upload-action.service";
import {RestService} from "../../../../../../../../common/service/rest.service";
import {UploadInfo} from "../../../../../../../../common/class/upload/upload-info";
import {interval, Observable, Subject, Subscription} from "rxjs";
import {AppConfigService} from "../../../../../../../../common/service/app-config.service";
import {AuthService} from "../../../../../../../../common/service/auth/auth.service";
import {LoginService} from "../../../../../../../../common/service/auth/login.service";
import {FileUtilsService} from "../../../../../../../../common/service/file/file-utils.service";
import moment from "moment";
import * as _ from "lodash";
import {SESSION_TAB_ID} from "../../../../../../../../common/class/compliance-constant";

const Resumable: resumablejs.Resumable = require('resumablejs');


@Injectable({
    providedIn: 'root'
})
export class UploadComplianceFileResumableService extends UploadActionService {

    private progressSubject: Subject<any> = new Subject<any>();

    private refreshTokenInterval = interval(3300 * 1000);
    private refreshTokenSubscription?: Subscription;
    private numberUploadingFile = 0;

    private readonly sessionTabId: string;


    constructor(private restService: RestService, private appConfigService: AppConfigService, private authService: AuthService, private loginService: LoginService,
                private fileUtils: FileUtilsService) {
        super('upload');
        this.sessionTabId = sessionStorage.getItem(SESSION_TAB_ID);
    }


    upload(uploadInfo: UploadInfo, anneeExercice: any, projectName: string, projectCode: string, authorizedFilesControl: string[]): Observable<any> {
        this.loginService.refreshTokenBasic().then(() => {
            this.numberUploadingFile++;
            let s3multipartsFile = { "uploadId": "", "partETags": [] };
            uploadInfo.firstChunck = true;
            let resumable = this._getResumable(uploadInfo, anneeExercice);
            this._setResumableEvent(resumable, s3multipartsFile, uploadInfo, anneeExercice, projectName, projectCode);
            resumable.opts.preprocess = (resumableChunk) => {
                this.chunckPreprocess(resumableChunk, resumable, s3multipartsFile, uploadInfo);
            };
            this.fileUtils.resumablesMap[uploadInfo.fileId] = resumable;
            uploadInfo.resumable = resumable;
            resumable.addFile(uploadInfo.file);
            this.subscribeTokenRefresh(resumable, uploadInfo);
        });
        return this.progressSubject.asObservable();
    }

    private chunckPreprocess(resumableChunk, resumable, s3multipartsFile, uploadInfo: UploadInfo) {
        console.log("chunckPreprocess", [resumableChunk, uploadInfo]);
        let startByte = resumableChunk.startByte;
        let endByte = resumableChunk.endByte;
        let file = resumableChunk.fileObj.file;
        let chunck = file.slice(startByte, endByte);
        this.fileUtils.hashFile(chunck).then((hash) => {
            resumableChunk.hash = hash;
            if (!resumable.files[0].isPaused()) {
                resumableChunk.preprocessFinished();
            } else {
                resumableChunk.paused = true;
            }
            resumable.opts.query.resumableChunkId = resumableChunk.hash;
            resumable.opts.query.first = uploadInfo.firstChunck;
            if(uploadInfo.firstChunck) {
                uploadInfo.firstChunck = false;
            }
        });
        resumableChunk.callback = (status, message) => {
            this.chunckCallback(status, message, uploadInfo, s3multipartsFile, resumable);
        };
        //resumable.opts.query.resumableChunkId = resumableChunk.hash;
    }

    private chunckCallback(status, message, uploadInfo: UploadInfo, s3multipartsFile, resumable) {
        if (status && message && status === "success") {
            let multipartS3File = JSON.parse(message);
            if (multipartS3File.abort) {
                uploadInfo.errorMessage = "upload.error.validatedExercice";
                uploadInfo.resumable.cancel();
                uploadInfo.error = true;
                this.progressSubject.next({progress: 0, error: true, value: uploadInfo, uploaded: false});
            } else {
                s3multipartsFile.uploadId = multipartS3File.uploadId;
                s3multipartsFile.partETags.push(multipartS3File.partETags[0]);
                resumable.opts.query.uploadId = multipartS3File.uploadId;
                this.uploadProgress(resumable, undefined);
            }
        }
    }

    private subscribeTokenRefresh(resumable, uploadInfo: UploadInfo) {
        if (!this.refreshTokenSubscription) {
            this.refreshTokenSubscription = this.refreshTokenInterval.subscribe((ok) => {
                if(uploadInfo.resumable.isUploading){
                    this.pauseUpload(uploadInfo);
                }
                    this.loginService.refreshTokenBasic().then(() => {
                        resumable.opts.headers = {'Authorization': 'Bearer ' + this.authService.getToken()};
                        this.resumeUpload(uploadInfo);
                    });
            });
        }
    }

    pauseUpload(uploadInfo: UploadInfo): void {
        uploadInfo.uploading = false;
        uploadInfo.resumable.pause();
    }

    resumeUpload(file: UploadInfo): void {
        file.uploading = true;
        file.resumable.upload();
    }

    private _setResumableEvent(resumable: resumablejs.Resumable, s3multipartsFile: any, uploadInfo: UploadInfo, anneeExercice: any, projectName: string, projectCode: string) {
        resumable.on('uploadStart', () => {
            this.uploadStart(uploadInfo, resumable);
        });
        resumable.on('fileAdded', () => {
            uploadInfo.uploading = true;
            resumable.upload();
        });
        resumable.on('catchAll', (event, file, message) => {
        });
        resumable.on('complete', () => {
            this.uploadCompleted(uploadInfo, s3multipartsFile, anneeExercice, projectName, projectCode);
        });
        resumable.on('fileProgress', (file, message) => {
            this.uploadProgress(resumable, uploadInfo);
        });
        resumable.on('chunkingProgress', () => {
        });
        resumable.on('fileError', () => {
            this.uploadError(uploadInfo);
        });
        resumable.on('fileSuccess', (file, message) => {
            this.uploadSuccess(JSON.parse(message), uploadInfo);
        });
        resumable.on('pause', () => {
            this.uploadPause(resumable);
        });
    }


    private uploadPause(resumable: resumablejs.Resumable) {
        let resumableFile = resumable.files[0];
        resumableFile.pause(true);
        _.forEach(resumableFile.chunks, (chunk) => {
            if (chunk.status() === 'pending' && chunk.preprocessState > 0) {
                chunk.preprocessState = 0;
            }
        });
    }

    public uploadSuccess(res: UploadInfo, uploadInfo: UploadInfo) {
        if (res.errors && res.errors.length) {
            uploadInfo.warning = true;
        }

    }

    private uploadProgress(resumable: resumablejs.Resumable, uploadInfo: UploadInfo) {
        let progress = resumable.progress() * 100;
        console.log(progress);
        progress = Math.floor(progress / 3);
        progress = progress < 5 ? 5 : progress;
        this.progressSubject.next({progress: progress, error: false, value: ""});
    }


    private uploadCompleted(uploadInfo: UploadInfo, s3multipartsFile:any, anneeExercice: any, projectName: string, projectCode: string) {
        uploadInfo.uploading = false;
        this.numberUploadingFile--;
        this.unsubscribe();
        console.log("uploadCompleted",uploadInfo);
        if(!uploadInfo.error){
            this.restService.post({
                url: this.baseUri + "/complete",
                parameters: [this.sessionTabId],
                data: {
                    s3multipartsFile: s3multipartsFile,
                    anneeExercice: anneeExercice,
                    uuid: uploadInfo.uuid,
                    projectName: projectName,
                    projectCode: projectCode
                },
                arg: null
            })
            this.progressSubject.next({progress: 33, error: false, value: uploadInfo, uploaded: true});
        }

    }

    private uploadError(uploadInfo: UploadInfo) {
        uploadInfo.error = true;
        this.numberUploadingFile--;
        this.unsubscribe();
        this.progressSubject.next({progress: 100, error: true, value: uploadInfo, uploaded: true});

    }

    private uploadStart(uploadInfo: UploadInfo, resumable: resumablejs.Resumable) {
        uploadInfo.dateStartUpload = moment().valueOf();

        uploadInfo.uploading = true;
        let resumableFile = resumable.files[0];
        resumableFile.pause(false);
        _.forEach(resumableFile.chunks, (chunk) => {
            if (chunk.status() === 'pending' && chunk.preprocessState > 0) {
                chunk.send();
            }
        });
    }

    private _getResumable(file: UploadInfo, anneeExercice: any) {
        return new Resumable({
            target: `${this.baseUri}/resumable`,
            chunkSize: this.appConfigService.config.chunkSize * 1024 * 1024,
            simultaneousUploads: 1,
            testChunks: false,
            method: "octet",
            maxChunkRetries: 4,
            // query: this.hashChunck(file, exerciceId, anneeExercice, projectName, projectCode), // Was used but doesn't seems to work with the function
            query: {
                projectId: file.dossierId,
                uuid: file.uuid,
                type: file.type,
                format: file.format,
                anneeExercice: JSON.stringify(anneeExercice),
                uploadId: "",
                first:true
            },
            headers: {
                'Authorization': 'Bearer ' + this.authService.getToken(),
                'ngsw-bypass': 'true'
            },
            generateUniqueIdentifier: this.fileUtils.generateId
        });
    }

    // ajoute le dossierId et le hash du chunk à la query xhr
    private hashChunck(file: UploadInfo, exerciceId: string, anneeExercice: any, projectName: string, projectCode: string) {
        return (resumableFile, resumableChunk) => {
            return {
                resumableChunkId: resumableChunk.hash,
                projectId: file.dossierId,
                projectName: projectName,
                projectCode: projectCode,
                uuid: file.uuid,
                type: file.type,
                format: file.format,
                exerciceId: exerciceId,
                anneeExercice: JSON.stringify(anneeExercice)
            };
        };
    }

    private unsubscribe() {
        if (this.numberUploadingFile <= 0 && this.refreshTokenSubscription) {
            this.refreshTokenSubscription.unsubscribe();
            this.refreshTokenSubscription = null;
        }
    }
}
