import {Component} from '@angular/core';
import {BreadcrumbService} from "../../service/breadcrumb/breadcrumb.service";

@Component({
    selector: 'compliance-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

    constructor(public breadcrumbService: BreadcrumbService) {
    }
}
