import {Component, Input, OnInit} from '@angular/core';
import {Menu} from "../../class/menu/menu";
import {ComplianceI18nLoader} from "../../class/i18n/compliance-i18n-loader";
import {AuthService} from "../../service/auth/auth.service";
import {User} from "../../class/user/user";
import {Router} from "@angular/router";
import {LoginService} from "../../service/auth/login.service";
import {CommonSubscriptionService} from "../../service/subscriptions/common-subscription.service";
import {FlecCommonSubscription} from "../../class/subscription/subscription";

@Component({
    selector: 'compliance-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends ComplianceI18nLoader implements OnInit {

    @Input() typeContent:string;
    @Input() menus: Menu[] = [];
    @Input() uploadStatus = false;
    connectedUser: User;
    commonSubscription: FlecCommonSubscription;

    constructor(private _router: Router, private _loginService: LoginService, private _authService: AuthService,
                private _commonSubscriptionservice: CommonSubscriptionService) {
        super();
    }

    ngOnInit() {
        const user = this._authService.getConnectedUser();
        if (user){
            this.getUserSubscription();
        }
        this.connectedUser = user ? user : new User();
    }

    async getUserSubscription() {
        this.commonSubscription = await this._commonSubscriptionservice.getUserSubscription();
    }


    logout(): void {
        this._loginService.logout();
        this._router.navigate(['/login']);
    }

}
