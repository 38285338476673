import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";


import {TableModule} from "primeng/table";
import {LoginFormComponent} from "./login/login-form.component";
import {ForgottenPasswordFormComponent} from "./login/forgotten-password/forgotten-password-form.component";
import {CreatePasswordFormComponent} from "./login/create-password/create-password-form.component";
import {AccountFormComponent} from "./user/account-form/account-form.component";
import {UserFormComponent} from "./user/user-form/user-form.component";
import {UserListComponent} from "./user/user-list/user-list.component";
import {AccountUsersComponent} from "./user/account-users/account-users.component";
import {TranslateModule} from "@ngx-translate/core";
import {MenuComponent} from "./menu/menu.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {MyAccountComponent} from "./my-account/my-account.component";
import {MyInhertLink} from "./directives/my.inner.links.directive";
import {DisableControlDirective} from "./directives/disable-control.directive";
import {FormComponent} from "./form/form.component";
import {NgCircleProgressModule} from "ng-circle-progress";
import {PrettyJsonModule} from "angular2-prettyjson";
import {CodemirrorModule} from "@ctrl/ngx-codemirror";
import {InplaceModule} from "primeng/inplace";
import {QuillModule} from "ngx-quill";
import {HttpClientModule} from "@angular/common/http";
import {NgProgressModule} from "@ngx-progressbar/core";
import {DynamicFormComponent} from "./form/dynamic-form/dynamic-form.component";
import {HeaderComponent} from "./header/header.component";
import {FooterComponent} from "./footer/footer.component";
import {NgxLoadingModule} from "ngx-loading";
import {SafePipe} from "./pipe/safe.pipe";
import {SirenPipe} from "./pipe/siren.pipe";
import {NgScrollbarModule} from "ngx-scrollbar";
import {BreadcrumbComponent} from "./breadcrumb/breadcrumb.component";
import {LoginHeaderComponent} from "./header/login/login-header.component";
import {PeriodeQuestionComponent} from "./question/periode-question/periode-question.component";
import {QuestionComponent} from "./question/question.component";
import {QuestionDirective} from "./directives/question.directive";
import {PeriodeLotYearComponent} from "./question/periode-question/periode-lot-year/periode-lot-year.component";
import {FormDirective} from "./form/form.directive";
import {MultipleChoiceQuestionComponent} from "./question/multiple-choice-question/multiple-choice-question.component";
import {TableSelectionComponent} from "./question/table-selection/table-selection.component";
import {ComplianceDatePipe} from "./pipe/compliance-date.pipe";
import {OnlyIntegerDirective} from "./directives/only-integer.directive";
import {LocalizedNumericInputDirective} from "./directives/localized-numeric-input.directive";
import {FormQuestionComponent} from "./question/form-question/form-question.component";
import {ComplianceBinaryPipe} from "./pipe/compliance-binary.pipe";
import {ComplianceCurrencyOrStringPipe} from "./pipe/compliance-currency-or-string.pipe";
import {LightSubscriptionButtonComponent} from "./subscription/light-subscription-button/light-subscription-button.component";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {MenubarModule} from "primeng/menubar";
import {SharedModule} from "primeng/api";
import {FileUploadModule} from "primeng/fileupload";
import {ProgressBarModule} from "primeng/progressbar";
import {TooltipModule} from "primeng/tooltip";
import {DropdownModule} from "primeng/dropdown";
import {DialogModule} from "primeng/dialog";
import {MultiSelectModule} from "primeng/multiselect";
import {AutoCompleteModule} from "primeng/autocomplete";
import {InputTextareaModule} from "primeng/inputtextarea";
import {CheckboxModule} from "primeng/checkbox";
import {NgxFileDropModule} from "ngx-file-drop";
import {ChartModule} from "primeng/chart";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {SidebarModule} from "primeng/sidebar";
import {CalendarModule} from "primeng/calendar";
import {EditorModule} from "primeng/editor";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {InputSwitchModule} from "primeng/inputswitch";
import {ToggleButtonModule} from "primeng/togglebutton";
import {SelectButtonModule} from "primeng/selectbutton";

@NgModule({
    declarations: [
        LoginFormComponent,
        ForgottenPasswordFormComponent,
        CreatePasswordFormComponent,
        AccountFormComponent,
        UserFormComponent,
        UserListComponent,
        AccountUsersComponent,
        MenuComponent,
        MyInhertLink,
        DisableControlDirective,
        MyAccountComponent,
        FormComponent,
        DynamicFormComponent,
        HeaderComponent,
        FooterComponent,
        SafePipe,
        SirenPipe,
        BreadcrumbComponent,
        LoginHeaderComponent,
        PeriodeQuestionComponent,
        QuestionComponent,
        QuestionDirective,
        PeriodeLotYearComponent,
        FormDirective,
        MultipleChoiceQuestionComponent,
        FormQuestionComponent,
        TableSelectionComponent,
        ComplianceDatePipe,
        ComplianceBinaryPipe,
        ComplianceCurrencyOrStringPipe,
        OnlyIntegerDirective,
        LocalizedNumericInputDirective,
        LightSubscriptionButtonComponent,
    ],
    entryComponents:[
        PeriodeQuestionComponent,
        MultipleChoiceQuestionComponent,
        FormQuestionComponent,
        TableSelectionComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        InputTextModule,
        ButtonModule,
        MenubarModule,
        SharedModule,
        TableModule,
        TranslateModule.forChild(),
        FileUploadModule,
        ProgressBarModule,
        TooltipModule,
        DropdownModule,
        DialogModule,
        NgbModule,
        MultiSelectModule,
        AutoCompleteModule,
        InputTextareaModule,
        QuillModule,
        PrettyJsonModule,
        CodemirrorModule,
        InplaceModule,
        CheckboxModule,
        NgScrollbarModule,
        NgCircleProgressModule.forRoot({
            "radius": 15,
            "space": -5,
            "outerStrokeWidth": 5,
            "innerStrokeColor": "#e4e4e4",
            "innerStrokeWidth": 5,
            "titleColor": "#000",
            "titleFontSize": "12",
            "showUnits": false,
            "showSubtitle": false,
            "showBackground": false,
            "animation": false,
        }),
        NgxFileDropModule,
        ChartModule,
        ConfirmDialogModule,
        // DataListModule,
        SidebarModule,
        NgxLoadingModule,
        NgProgressModule,
        CalendarModule,
        EditorModule
    ],
    exports: [
        LoginFormComponent,
        ForgottenPasswordFormComponent,
        CreatePasswordFormComponent,
        AccountFormComponent,
        UserFormComponent,
        UserListComponent,
        AccountUsersComponent,
        MenuComponent,
        MyAccountComponent,
        FormComponent,
        DynamicFormComponent,

        MyInhertLink,
        DisableControlDirective,

        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        InputTextModule,
        ButtonModule,
        MenubarModule,
        SharedModule,
        TableModule,
        TranslateModule,
        FileUploadModule,
        ProgressBarModule,
        TooltipModule,
        DropdownModule,
        DialogModule,
        NgbModule,
        MultiSelectModule,
        AutoCompleteModule,
        NgCircleProgressModule,
        NgxFileDropModule,
        ChartModule,
        QuillModule,
        InputTextareaModule,
        FormComponent,
        CodemirrorModule,
        PrettyJsonModule,
        InplaceModule,
        ConfirmDialogModule,
        OverlayPanelModule,
        InputSwitchModule,
        ToggleButtonModule,
        // DataListModule,
        SidebarModule,
        NgProgressModule,
        CheckboxModule,
        FooterComponent,
        NgxLoadingModule,
        HeaderComponent,
        SafePipe,
        SirenPipe,
        BreadcrumbComponent,
        NgScrollbarModule,
        LoginHeaderComponent,
        CalendarModule,
        PeriodeQuestionComponent,
        QuestionComponent,
        QuestionDirective,
        PeriodeLotYearComponent,
        FormDirective,
        MultipleChoiceQuestionComponent,
        FormQuestionComponent,
        TableSelectionComponent,
        ComplianceDatePipe,
        ComplianceBinaryPipe,
        ComplianceCurrencyOrStringPipe,
        EditorModule,
        SelectButtonModule,
        OnlyIntegerDirective,
        LocalizedNumericInputDirective,
        LightSubscriptionButtonComponent
    ],
    providers:[
        SafePipe,
        SirenPipe,
        ComplianceDatePipe,
        ComplianceBinaryPipe,
        ComplianceCurrencyOrStringPipe
    ]
})
export class ComplianceComponentLibModule {
}
