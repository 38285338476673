import {Injectable, Renderer2} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ControlType, FormInput, FormInputControl} from "../../class/meta/meta-formulaire";
import {ComplianceValidator} from "../../validator/compliance-validator";

@Injectable({
    providedIn: 'root'
})
export class MetaFormulaireService {

    constructor() {
    }

    toFormGroup(formulaire: FormInput[]) {
        let group: any = {};

        formulaire.forEach(form => {
            form.value = form.value || '';
            let controls = [];
            if (form.controlType) {
                form.controlType.forEach((control) => {
                    let items = this.addControl(control);
                    if (items) {
                        controls.push(items);
                    }
                });
            }

            group[form.id] = new FormControl(form.value, controls);
        });
        return new FormGroup(group);
    }

    private addControl(control: FormInputControl) {
        if (control.controlType === ControlType.required) {
            return Validators.required;
        }
        if (control.controlType === ControlType.pattern) {
            return Validators.pattern(control.value);
        }
        if (control.controlType === ControlType.maxlength) {
            return Validators.maxLength(control.value);
        }
        if (control.controlType === ControlType.max) {
            return Validators.max(control.value);
        }
        if (control.controlType === ControlType.min) {
            return Validators.min(control.value);
        }
        if (control.controlType === ControlType.siren) {
            return ComplianceValidator.checkSiren(control.value);
        }
        return undefined;
    }

    setAttributes(formInput: FormInput, renderer: Renderer2, nativeElement: any) {
        if (formInput.controlType) {
            formInput.controlType.forEach((control) => {
                this.setAttribute(control, renderer, nativeElement);
            });
        }
    }

    private setAttribute(control: FormInputControl, renderer: Renderer2, nativeElement: any) {
        control.value = control.value === undefined || control.value === null ? 'true' : control.value;
        renderer.setAttribute(nativeElement, control.controlType.toString(), control.value);
    }
}
