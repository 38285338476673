<table class="table-data-files" *ngIf="files.length">
    <thead>
    <tr>
        <th class="status-picto">&nbsp;</th>
        <ng-container *ngIf="type !== 'FEC'">
            <th class="label-date-short">{{'gestionFile.table.headers.period' | translate}}</th>
            <th class="label-long">{{'gestionFile.table.headers.fileName' | translate}}</th>
        </ng-container>
        <ng-container *ngIf="type === 'FEC'">
            <th class="label-date-short">{{'gestionFile.table.headers.fileName' | translate}}</th>
            <th class="label-long">&nbsp;</th>
        </ng-container>

        <th class="label-date-long">{{'gestionFile.table.headers.depotDate' | translate}}</th>
        <th class="label-middle">{{'gestionFile.table.headers.who' | translate}}</th>
        <th class="action">&nbsp;</th>
        <th class="action">&nbsp;</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let file of files; let i = index" [ngClass]="{'new':file.new, 'warn': file.status !== 'EXTRACTED'}">
        <td class="status-picto">
            <i *ngIf="file.status !== 'EXTRACTED'"
               pTooltip="{{'gestionFile.table.warnErrorFile' | translate}}"
               tooltipStyleClass="warn-error-file"
               class="fas fa-exclamation-triangle text-primary">
            </i>
            <i *ngIf="file.new" class="fas fa-check text-secondary"></i>
        </td>
        <ng-container *ngIf="type !== 'FEC'">
            <td>{{file.finPeriodeDate | date:'MMMM yyyy' | titlecase}}</td>
            <td>{{file.fileName}}</td>
        </ng-container>

        <ng-container *ngIf="type === 'FEC'">
            <td colspan="2">{{file.fileName}}</td>
        </ng-container>

        <td>{{file.date | date:'dd/MM/yyyy HH:mm'}}</td>
        <td><span class="first-name">{{file.userFirstName}}</span>&nbsp;<span class="last-name">{{file.usrerLastName}}</span></td>
        <td class="action position-relative" style="padding-right: 10px;" (click)="editFile(file)" *ngIf="file.format == 'MANUAL_FORM'">
            <i class="fa fa-pencil text-secondary font-weight-bold"></i>
<!--            <i class="far fa-file-signature"></i>-->
        </td>
        <td class="action position-relative" style="padding-right: 10px;" (click)="exportViesFile(file)" *ngIf="file.type == 'DEB_EXPEDITION' && !file.activeLoader"
            pTooltip="{{toolTipVie}}" tooltipPosition="top" [escape]="false">
            <i class="fa fa-file-download text-secondary font-weight-bold" [ngClass]="{'disabled': !hasVieControl}"></i>
        </td>
        <td class="action position-relative" style="padding-right: 10px;" *ngIf="file.activeLoader">
            <i class="fas fa-spinner fa-spin text-secondary font-weight-bold"></i>
        </td>
        <td *ngIf="file.format != 'MANUAL_FORM' && file.type != 'DEB_EXPEDITION'">
        </td>
        <td class="action position-relative" (click)="deleteFile(file)" *ngIf="!file.deleting">
            <i class="far fa-trash-alt text-primary"></i>
        </td>
        <td class="action position-relative" *ngIf="file.deleting">
            <i class="fas fa-spinner fa-spin"></i>
        </td>
    </tr>
    </tbody>
</table>
