<div class="period mt-4">
    <div class="row">
        <div class="col-12 text-center text-white text-size-15 mb-2"><b>{{lot.year}}</b></div>
    </div>
    <div class="px-5">
        <div class="row ">
            <div class="date col-4 " *ngFor="let month of lot.months" [ngClass]="{'disable':!month.enable, 'active':month.active}">
                <div class=" text-size-12 " (click)="activePeriod(month)"><b>{{month.label}}</b></div>
            </div>
        </div>
    </div>
</div>
