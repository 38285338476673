<div class="page-compte-rendu-detaille page-overview" *ngIf="crd">
    <ng-container *ngIf="controle"><compliance-compte-rendu-detaille-origin-incoherence [control]="controle"></compliance-compte-rendu-detaille-origin-incoherence></ng-container>
    <div class="container-fluid w-75">
        <div class="block-group-title">
            <h2 class="title" [innerHTML]="crd.title | translate | safe:'html'"></h2>
        </div>

        <div class="crd-items">
            <compliance-crd-item-block [crdItem]="crdBlockItem"  *ngFor="let crdBlockItem of crd.compteRenduDetailleItems" [destroy$]="destroy$"></compliance-crd-item-block>
        </div>
    </div>

</div>


<!-- START : footer -->
<footer id="footer">
    <div class="inner">
        <a href="#" class="btn btn-primary btn-back" (click)="goBack()"><span>{{'buttons.back' | translate}}</span></a>


        <a href="#" (click)="goNext()" class="btn btn-primary btn-next"><span>{{'buttons.goSupervision' | translate}}</span></a>
    </div>
</footer>
