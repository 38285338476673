import {Component, OnDestroy, OnInit} from '@angular/core';
import {AFFECTATION_COMPTE, AffectationCompteData, AffectationTitle, AffectationWrapper, Calcul, Lot, LotAffectation} from "./shared/lot-affectation";
import _ from 'lodash';
import {AffectationCompteService} from "./shared/affectation-compte.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ActionMenuItem, TableItem, TableItemType, TableOption} from "../../../../shared/table/shared/table-item";
import {NotificationMessage} from "../../../../../../../common/class/polling/notification-message";
import {NotificationService} from "../../../../../../../common/service/push/notification.service";
import {LotUtilsService} from "./shared/lot-utils.service";
import {MetaExpressionService} from "../../../../../../../common/service/meta/meta-expression.service";
import {AbstractControl} from "../../../../../../../common/component/control/abstract-control";
import {MetaFormulaireService} from "../../../../../../../common/service/meta/meta-formulaire.service";
import {FormControl, FormGroup} from "@angular/forms";
import {BackgroungType} from "../../../../../../../common/class/background/bg-compliance";

@Component({
    selector: 'compliance-affectation-compte',
    templateUrl: './affectation-compte.component.html',
    styleUrls: ['./affectation-compte.component.scss']
})
export class AffectationCompteComponent extends AbstractControl implements OnInit, OnDestroy {
    affectationTranslate: any;

    comptes: AffectationWrapper;

    actualAffectationType: string = AFFECTATION_COMPTE.nonAffecte;
    actualAffectationData: AffectationCompteData[];
    actualLot: Lot;
    nonAffecte: Lot;

    affectedComptes: Lot[];

    nbColumnDropzone: number;

    compteAffecteConst = AFFECTATION_COMPTE;
    private allAffected: boolean;
    private originalActionMenu: ActionMenuItem[];
    // NO_AFFECTATION_AUTO = "NO_AFFECTATION_AUTO";

    formAffectation: { show: false, percent?: number } = {show: false, percent: 100};
    meta: TableItem[];
    tableOption: TableOption;
    showAuto: boolean;

    nextActive: boolean | number;
    affectationAutoFormGroup: FormGroup;
    displayTitles: AffectationTitle;

    constructor(private affectationCompteService: AffectationCompteService, private router: Router, private route: ActivatedRoute,
                private notificationService: NotificationService, private lotUtils: LotUtilsService,
                private expressionService: MetaExpressionService, private formService: MetaFormulaireService) {
        super(BackgroungType.BG_TREE_2);
    }

    ngOnInit() {
        this.tableOption = this.affectationCompteService.getProduitSoumisNonSoumisTableOption();
        this.affectationTranslate = super.getTranslation().affectation;
        super.setContextWithRoute(this.route, this.findControl.bind(this));
        super.subscribeToNotificationControl({
            notificationService: this.notificationService,
            manageDataEvent: (n: NotificationMessage) => this.manageDataEvent(n)
        });
    }

    protected manageDataEvent(notification: NotificationMessage) {
        this.comptes = notification.corp;
        if (this.comptes.describer.affectationAuto) {
            this.affectationAutoFormGroup = this.formService.toFormGroup(this.comptes.describer.affectationAuto.formulaire);
            this.affectationAutoFormGroup.addControl('show', new FormControl(true));
            this.comptes.data.formValues = this.comptes.data.formValues || {};
        }
        this.originalActionMenu = _.find(this.comptes.describer.tableItems, (ti: TableItem) => ti.type === TableItemType.actionMenu).actionMenu;
        this.separateDatas();
        this.getDatas();
        this.selectLotByType(this.actualAffectationType);

        this.computeCalculatedValues();
        this.isNextActive();

        if (this.comptes.describer.affectationAuto) {
            this.showAffectationAuto();
        }

        this._complianceLoaderService.sendLoaderHide();
    }

    private filterActionMenuItem() {
        let item = _.find(this.comptes.describer.tableItems, (ti: TableItem) => ti.type === TableItemType.actionMenu);

        if (this.actualLot.lot.display.type === AFFECTATION_COMPTE.nonAffecte) {
            item.actionMenu = this.originalActionMenu;
        } else {
            item.actionMenu = _.filter(this.originalActionMenu, (actionMenuItem: ActionMenuItem) => actionMenuItem.code !== this.actualLot.lot.display.type);
        }
    }


    private separateDatas() {
        this.nonAffecte = {
            lot: this.comptes.describer.nonAffecte,
            data: this.comptes.data[AFFECTATION_COMPTE.nonAffecte]
        };
        this.displayTitles = {
            display: _.cloneDeep(this.comptes.describer.display),
            data: this.comptes.data[AFFECTATION_COMPTE.nonAffecte]
        };
        this.nonAffecte.data = _.sortBy(this.nonAffecte.data, 'code');
        let sortBy = _.sortBy(_.values(this.comptes.describer.lots), (l: LotAffectation) => l.display.order);
        this.affectedComptes = [];
        _.each(sortBy, (l) => {
            this.comptes.data.lots[l.display.type] = _.sortBy(this.comptes.data.lots[l.display.type], 'code');
            this.affectedComptes.push({lot: l, data: this.comptes.data.lots[l.display.type]});
        });
        this.nbColumnDropzone = Math.floor((Math.min(this.affectedComptes.length, 7) + 2) / 3);
    }

    getAffectionService() {
        this._controlService.dataControl(this.projectId, this.stateMachineId, this.exerciceId, this.event, this.stepByStep);
    }

    clean(c: Lot) {
        this.nonAffecte.data = [...this.nonAffecte.data, ...c.data];
        c.data = [];

        this.saveAffectation();
    }

    processData() {
        let saveData = {};
        _.each(this.affectedComptes, (lot: Lot) => {
            this.comptes.data.lots[lot.lot.display.type] = [...lot.data];
            saveData[lot.lot.display.type] = [];
            _.each(lot.data, (data) => {
                let dataToSave = {};
                if (this.comptes.saveFields.length === 1) {
                    dataToSave = data[this.comptes.saveFields[0]];
                } else {
                    _.each(this.comptes.saveFields, (field) => {
                        dataToSave[field] = data[field];
                    });
                }
                saveData[lot.lot.display.type].push(dataToSave);
            });
        });
        return saveData;
    }

    async saveAffectation() {
        this.processData();
        // await this.affectationCompteService.saveAffectation(this.projectId, this.exerciceId, this.processData(), this.nonAffecte.data.length === 0, this.comptes.targetObjectName);
        this.comptes.data.nonAffecte = [...this.nonAffecte.data];

        this.separateDatas();
        this.selectLotByType(this.actualAffectationType);
        this.allAffected = !!this.nonAffecte.data.length;

        this.isNextActive();
    }

    getMeta() {
        this.meta = this.affectationCompteService.getAffectationProduitSoumisNonSoumis(
            this.affectationTranslate,
            this.actualAffectationType,
            this.formAffectation.show
        );
    }

    getDatas() {
        this.actualLot = this.getDatasByAffectationType(this.actualAffectationType);
        this.actualAffectationData = _.sortBy<AffectationCompteData>(this.actualLot.data, 'code');
    }

    private getDatasByAffectationType(affectationType: string): Lot {
        if (affectationType === AFFECTATION_COMPTE.nonAffecte) {
            return this.nonAffecte;
        } else {
            return _.find(this.affectedComptes, (lot: Lot) => {
                return lot.lot.display.type === affectationType;
            });
        }
    }


    affectData($event: any) {
        let datas = $event.data;
        // data.showMenu = false;

        let affecationCompteRemove = this.getDatasByAffectationType(this.actualAffectationType);
        let affecationCompteAdd = this.getDatasByAffectationType($event.code);
        _.each(datas, (data) => this.affectOne(affecationCompteRemove, affecationCompteAdd, data));
        this.saveAffectation();

    }

    private affectOne(affecationCompteRemove, affecationCompteAdd, data) {
        _.remove<AffectationCompteData>(affecationCompteRemove.data, (c: AffectationCompteData) => {
            let toDelete = true;
            _.each(this.comptes.saveFields, (field) => toDelete = toDelete && c[field] === data[field]);
            return toDelete;
        });
        this.actualLot.data = [...affecationCompteRemove.data];
        affecationCompteAdd.data.push(data);
    }

    setClassColumnDropzone(index: number) {
        let columnClass = {};
        columnClass['col-' + (12 / this.nbColumnDropzone)] = true;
        columnClass['pl-2'] = index % this.nbColumnDropzone !== 0;
        columnClass['pr-2'] = index % this.nbColumnDropzone !== (this.nbColumnDropzone - 1);
        return columnClass;
    }

    selectLot(affectationCompte: Lot) {
        if (affectationCompte.lot.display.type !== AFFECTATION_COMPTE.nonAffecte) {
            this.showAuto = false;
            this.formAffectation.show = false;
            this.displayTitles.display.subTitle = null;
            this.deactiveLigne();
        } else {
            this.showAuto = true;
            this.displayTitles.display.subTitle = _.cloneDeep(this.comptes.describer.display.subTitle);
            this.showAffectationAuto();
        }

        let allLots = [this.nonAffecte, ...this.affectedComptes];
        let actionMenuItem = _.find(this.comptes.describer.tableItems, {type: TableItemType.actionMenu});
        _.each(allLots, (ac: Lot) => {
            ac.lot.active = ac.lot.display.type === affectationCompte.lot.display.type;
            _.each(ac.data, (d) => d[actionMenuItem.field] = false);
            // ac.lot[actionMenuItem.field] = false;
        });

        this.actualAffectationType = affectationCompte.lot.display.type;
        this.actualAffectationData = [...affectationCompte.data];
        this.getMeta();

        this.getDatas();
        this.filterActionMenuItem();
    }

    selectLotByType(affectationType: string) {
        this.selectLot(this.getDatasByAffectationType(affectationType));
    }

    private computeCalculatedValues() {
        if (this.comptes.describer.processDataPipe && this.comptes.describer.processDataPipe.length) {
            let allLots = [this.nonAffecte, ...this.affectedComptes];
            let processes: Calcul[] = [...this.comptes.describer.processDataPipe];
            _.each(allLots, (lot: Lot) => {
                if (lot.lot.processDataPipe) {
                    processes = [...processes, ...lot.lot.processDataPipe];
                }
                _.each(lot.data, (data: AffectationCompteData) => {
                    data.calculatedData = {};
                    let sumAffAuto = 0;
                    _.each(processes, (process: Calcul) => {
                        try {
                            data.calculatedData[process.name] = this.expressionService.computeExpression(process.expression, data);
                            sumAffAuto += data.calculatedData[process.name] as number;
                        } catch (e) {
                            data.calculatedData[AFFECTATION_COMPTE.NO_AFFECTATION_AUTO] = true;
                        }
                    });
                    if (sumAffAuto === 0) {
                        data.calculatedData[AFFECTATION_COMPTE.NO_AFFECTATION_AUTO] = true;
                    }
                });
            });
        }

    }

    showAffectationAuto() {
        if (this.comptes.describer.affectationAuto) {
            if (this.affectationAutoFormGroup.value.show) {
                this.activeLigne();
            } else {
                this.deactiveLigne();
            }
        }
        this.getMeta();
    }

    activeLigne() {
        if (this.comptes.describer.affectationAuto) {
            _.assignIn(this.comptes.data.formValues, this.affectationAutoFormGroup.value);

            if (this.expressionService.computeExpression(this.comptes.describer.affectationAuto.activate, this.comptes.data.formValues)) {
                _.each(this.nonAffecte.data, (compteData: AffectationCompteData) => {
                    let type = null, color;
                    for (let action of this.comptes.describer.affectationAuto.action) {
                        let result = this.expressionService.computeExpression(action.expression, {
                            calculatedData: compteData.calculatedData,
                            formValues: this.comptes.data.formValues
                        });
                        if (result) {
                            type = action.type;
                            color = this.comptes.describer.lots[type].display.color;
                            break;
                        }
                    }

                    let tableItem: TableItem = _.find(this.comptes.describer.tableItems, {isLineHeader: true});
                    tableItem.styleField = 'headerStyle';
                    compteData.headerStyle = {'background': color};
                    compteData.affectAutoType = type;
                });
                this.getDatas();
            }
        }
    }

    deactiveLigne() {
        let allLots = [this.nonAffecte, ...this.affectedComptes];
        allLots.forEach((ac: Lot) => {
            ac.data.forEach((compteData: AffectationCompteData) => {
                let tableItem: TableItem = _.find(this.comptes.describer.tableItems, {isLineHeader: true});
                tableItem.styleField = null;
                compteData.headerStyle = null;
                compteData.affectAutoType = null;
            });
            // ac.lot[actionMenuItem.field] = false;
        });
        this.getDatas();
    }

    affectAuto() {
        let comptes: AffectationCompteData[] = _.remove(this.nonAffecte.data, (d: AffectationCompteData) => {
            return !!d.affectAutoType;
        });

        let compteByType = _.groupBy(comptes, (d: AffectationCompteData) => {
            return d.affectAutoType;
        });

        _.each(_.keys(compteByType), (key) => {
            let comptesByType: AffectationCompteData[] = compteByType[key];
            let lot = this.getDatasByAffectationType(key);
            if (comptesByType && comptesByType.length) {
                lot.data = [...lot.data, ...comptesByType];
            }
        });

        console.log(this.comptes.data);
        console.log(this.comptes.data);

        this.saveAffectation();
    }

    getSize() {
        return this.lotUtils.getOneOrManyFromLotData(this.actualLot.data);
    }

    getNonAffecteSize() {
        return this.lotUtils.getOneOrManyFromLotData(this.nonAffecte.data);
    }

    launchControl() {
        this._complianceLoaderService.sendLoaderShow();
        this._controlService.continueExecutionControl(this.projectId, this.stateMachineId, this.exerciceId, this.event, {
            "parametersToSave": this.processData(),
            "isDone": this.nonAffecte.data.length === 0
        }, this.stepByStep);
    }

    goBack() {
        this._complianceLoaderService.sendLoaderShow();
        this._controlService.backControl(this.projectId, this.stateMachineId, this.exerciceId, this.event, this.stepByStep);
    }

    async findControl() {
        this.actualLot = new Lot();
        this.actualAffectationType = AFFECTATION_COMPTE.nonAffecte;
        this.getAffectionService();
    }

    isNextActive() {
        if (this.comptes.action && this.comptes.action.next && this.comptes.action.next.expression) {
            this.nextActive = this.expressionService.computeExpression(this.comptes.action.next.expression, this.comptes.data);
        } else if (this.controlId) {
            this.nextActive = this.nonAffecte.data.length === 0;
        } else {
            this.nextActive = true;
        }
    }
}



