
let domain = location.hostname ;
let apiUrl = location.protocol + '//' + domain + '/';

export const environment = {
  production: true,
  hmr: false,
  baseHref: "/",
  whiteList: [domain],
  apiUrl : '',
    domain: domain
};
