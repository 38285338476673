import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "../../shared/auth/auth.guard";
import {ComplianceComponent} from "../compliance.component";
import {InformationPageComponent} from "./information-page/information-page.component";
import {VideoComponent} from "./video/video.component";
import {VideoListComponent} from "./video/video-list/video-list.component";

const routes: Routes = [
    {
        path: 'compliance',
        component: ComplianceComponent,
        canActivateChild: [AuthGuard],
        children:
            [
                {
                    path: ':domain/:category',
                    children: [
                        {
                            path: 'information-page',
                            component: InformationPageComponent,
                        },
                        {
                            path: 'video',
                            component: VideoListComponent,
                            data: {
                                title: "breadcrumb.videoList"
                            }
                        },
                        {
                            path: 'video/:videoId',
                            component: VideoComponent,
                            data: {
                                title: "breadcrumb.video"
                            }
                        },
                    ]
                },
            ]
        }
    ];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TvaRoutingModule {
}
