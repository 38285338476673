import {Component, Input, OnInit} from '@angular/core';
import {BlocType, ComplexString, DomType} from "../../../../../../../../../common/class/control/control-descriptor";
import {Router} from "@angular/router";

@Component({
    selector: 'compliance-complex-string',
    templateUrl: './complex-string.component.html',
    styleUrls: ['./complex-string.component.scss']
})
export class ComplexStringComponent implements OnInit {

    complexString: ComplexString;

    @Input() set pComplexString( complexString: ComplexString | string){
        console.log("set pComplexString( complexString: ComplexString | string)", complexString);
        if(typeof complexString === 'string'){
            let cs = new ComplexString();
            cs.text = complexString;
            this.complexString = cs;
        }else {
            this.complexString = complexString;
        }
    };
    context: any;
    @Input() set pContext(context){
        this.context = context;
    }
    blocType = BlocType;
    domType = DomType;

    constructor(private _router:Router) {
    }

    ngOnInit() {
    }

    goTo(link: { [p: string]: string }[]) {
        const route = link.map(value => {
            if(value.hasOwnProperty("static")){
                return value.static;
            }else{
                return this.context[value.param]
            }
        });
        this._router.navigate(route);
    }
}
