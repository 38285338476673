<!-- START : content -->
<div id="" class="height-100 choice">
    <div class="container height-100">
        <div class="d-flex align-items-center justify-content-center  height-100 flex-column">
            <div class="block-group-title text-center">
                <h2 class="title position-relative" >
                    <div class="bulle-right" [innerHtml]="question.title.text | translate:question.title.params | safe:'html'"></div>
                    <i class="fal fa-info-circle bulle-info" *ngIf="question.asterix" [ngbTooltip]="tipAsterix" [closeDelay]="1000">
                        <ng-template #tipAsterix class="asterix">
                            <div [innerHtml]="question.asterix.text | translate | safe:'html'"></div>
                        </ng-template>
                    </i>
                </h2>
            </div>

            <div class="d-flex ">
                <ng-container *ngIf="question.multiple">
                    <div *ngFor="let item of question.items" class="btn-group btn-group-toggle px-3">
                        <label class="btn-info" ngbButtonLabel>
                            <input type="checkbox" ngbButton [(ngModel)]="choice[item[question.codeField]].checked" (change)="setResponseMulti()"
                                   [checked]="choice[item[question.codeField]].checked"> {{item[question.labelField]}}{{question.suffix}}
                        </label>
                    </div>
                </ng-container>
                <ng-container *ngIf="!question.multiple">
                    <div class="btn-group btn-group-toggle px-3" ngbRadioGroup name="choice" [(ngModel)]="choice">
                        <label class="btn-info mx-4" ngbButtonLabel *ngFor="let item of question.items">
                            <input *ngIf="!question.multiple" type="radio" ngbButton [value]="item[question.codeField]" (change)="setResponseSingle(item)"> {{item[question.labelField]}}{{question.suffix}}
                        </label>
                    </div>
                </ng-container>

            </div>
            <ng-container *ngIf="question.formulaire && questionFormGroup">
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-primary btn-circle btn-xl mx-2" (click)="showForm()">+</button>
                </div>
                <div class="d-flex justify-content-between align-items-end text-center pt-4 mx-4 text-center w-40" style="color: #fff" [@showAddTaux]="addNew">
                    <div class="flex-fill mr-3" [formGroup]="questionFormGroup">
                        <div class="form-group" *ngFor="let input of question.formulaire">
                            <!--<label for="taux"><span>Soit manuellement,</span> <br>en saisissant un des numéros suivants :</label>-->
                            <compliance-form [formInput]="input" [parentFormGroup]="questionFormGroup"></compliance-form>
                            <!--<input type="number" step="0.01" id="taux" class="form-control  form-control-lg" autofocus="" autocomplete="off" max="99.99" size="5"-->
                            <!--placeholder="Saisissez un taux de tva." [(ngModel)]="add.tauxToAdd"/>-->
                        </div>
                    </div>
                    <div class="text-right">
                        <div class="form-group">
                            <button class="btn btn-primary" [disabled]="questionFormGroup.invalid" (click)="addItem()">
                                <i *ngIf="question.buttons['add'].text.icon" class="{{question.buttons['add'].text.icon}}"></i>{{question.buttons['add'].text.label | translate}}
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="addNew && itemAlreadyExist" class="justify-content-start mx-4 error-alert w-40">
                    <div class="">{{'choixTva.errors.existRate' | translate}}</div>
                </div>
            </ng-container>

        </div>
    </div>
</div>
<!-- STOP : content -->

<!-- START : footer -->


<footer id="footer">
    <div class="inner">
        <a href="#" class="btn btn-primary btn-back" (click)="goBack()">
            <span>{{(isControl ? question.buttons.back.text.label : 'buttons.back') | translate}}</span>
        </a>
        <button class="btn btn-primary btn-next" [disabled]="!nextActive" (click)="goNext()">
            <span>{{(isControl ? question.buttons.next.text.label : 'buttons.finished') | translate}}</span>
        </button>
    </div>
</footer>
<!-- STOP : footer -->
