import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExportFileComponent} from "./export-file.component";
import {ComplianceSharedModule} from "../../../../../common/compliance-shared.module";

@NgModule({
    declarations: [ExportFileComponent],
    imports: [
        CommonModule,
        ComplianceSharedModule
    ],
    exports:[ExportFileComponent]
})
export class ExportFileModule {
}
