<div class="height-100">
    <div class="row justify-content-center height-100 bg-login">
        <div class="col-6 align-self-center">
            <form [formGroup]="form" novalidate autocomplete="off" class="form-basic">
                <div class="form-group">
                    <label for="inputEmail" class="sr-only">{{'forgottenPassword.emailPlaceholder' | translate}}</label>
                    <input type="text" id="inputEmail" class="form-control width-100" placeholder="{{'forgottenPassword.emailPlaceholder' | translate}}"
                           autofocus="" autocomplete="off" formControlName="email" (ngModelChange)="reactivateButton()" (keyup.enter)="submit($event);"/>
                    <div *ngIf="form.controls['email'].errors && !form.controls['email'].pristine" class="text-primary text-left text-size-11">
                        <div [hidden]="!form.controls['email'].errors.required">{{ i18n.forgottenPassword.error.email.required }}</div>
                        <div [hidden]="!form.controls['email'].errors.pattern">{{ i18n.forgottenPassword.error.email.pattern }}</div>
                    </div>
                </div>
            </form>
            <div class="d-flex justify-content-between mt-5">
                <button class="btn btn-primary" type="reset" (click)="reset($event)">{{ i18n.forgottenPassword.button.cancel }}</button>
                <button class="btn btn-primary" type="button" (click)="submit($event)"
                        [disabled]="oneClickButton()">{{ i18n.forgottenPassword.button.validate }}
                </button>
            </div>
        </div>
    </div>
</div>
