import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import _ from 'lodash';
import {Bloc, Label} from "../../../../../../../../../common/class/text/bloc-text";

@Component({
    selector: 'compliance-key-number',
    templateUrl: './key-number.component.html',
    styleUrls: ['./key-number.component.scss']
})
export class KeyNumberComponent implements OnInit, OnChanges {

    @Input() bloc: Bloc;
    blocByPosition:{[key:string]:Label[]}
    private justifyCss: { left: { flex: string; margin: string }; right: { flex: string; margin: string } };


    constructor() {
        this.justifyCss = {
            left : {
                flex:'justify-content-start',
                margin:'mr-2'
            },
            right:{
                flex:'justify-content-end',
                margin:'ml-2'
            }
        }

    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.bloc && changes.bloc.currentValue){
            this.bloc = changes.bloc.currentValue;
            this.agregate();
        }
    }

    private agregate() {
        this.blocByPosition = _.groupBy(this.bloc.labels, (label:Label)=>{
            return label.position
        })

        console.log("################ KeyNumberComponent.agregate()", this.blocByPosition);
    }

    getFlexJustifyBloc():string{
        return this.justifyCss[this.bloc.align].flex;
    }

    computeMarginByBlocAlign() {
        return this.justifyCss[this.bloc.align].margin;
    }
}
