import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExtractSirenService { //extends UploadActionService {
    // constructor(private restService: RestService) {
    //     super('upload');
    // }


    // upload(uploadInfo: UploadInfo): Observable<any> {
    //     const formData = super.computeFormData(uploadInfo.file);
    //     return this.restService.uploadRequest({
    //         url: this.baseUri,
    //         relativeUri: `extract/siren`,
    //         parameters: [uploadInfo.uuid],
    //         data: formData,
    //         arg: null
    //     }).pipe(super.uploadProgressEventMap())
    // }

    // findBySiren(siren: string): Promise<any>{
    //     return this.restService.get({
    //         url : this.baseUri,
    //         relativeUri: "solr",
    //         parameters: [siren],
    //         arg: null as any
    //     });
    // }

}
