import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Breadcrumb} from "../../class/breadcrumb/breadcrumb";
import {ActivatedRouteSnapshot} from "@angular/router";
import {Dossier} from "../../class/dossier/dossier";
import {ControlDescriptor} from "../../class/control/control-descriptor";
import {DossierService} from "../dossier/dossier.service";
import {ControlService} from "../control/control.service";
import {Exercice} from "../../class/exercice/exercice";
import {ExerciceService} from "../exercice/exercice.service";
import {ComplianceI18nLoader} from "../../class/i18n/compliance-i18n-loader";
import {OriginPage} from "../../component/control/control-message-handler";


@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService extends ComplianceI18nLoader {
    public crumbsSubject: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>(this.initHome());

    constructor(private dossierService: DossierService, private controlService: ControlService, private exerciceService: ExerciceService) {
        super();
    }

    setCrumbs(crumbs: Breadcrumb[]) {
        this.sendMessage(crumbs);
    }

    private sendMessage(crumbsToShow: Breadcrumb[]) {
        this.crumbsSubject.next(crumbsToShow);
    }

    private initHome() {
        return [new Breadcrumb('', '/compliance/home', 0, 'fas fa-home ml-2')];
    }

    async parseRouteSnap(snapshot: ActivatedRouteSnapshot) {
        let breadcrumbs: Breadcrumb[] = this.initHome();
        let domain = snapshot.paramMap.get('domain');
        let category = snapshot.paramMap.get('category');
        let projectId = snapshot.paramMap.get('projectId');
        let project: Dossier;
        let exercice: Exercice;
        let exerciceId = snapshot.paramMap.get('exerciceId');
        let stateMachineId = snapshot.paramMap.get('stateMachineId');
        let originPage = snapshot.queryParams['originPage'];
        let controlId = snapshot.queryParams['controlId'] || snapshot.paramMap.get('controlId');
        let control: ControlDescriptor;

        if (domain && category) {
            if(this._findRoute(snapshot, 'information-page')) {
                this.translate.get('breadcrumb.information', {category: category}).subscribe((filesString: string) => {
                    breadcrumbs = [
                        ...breadcrumbs,
                        {label: filesString, href: `/compliance/${domain}/${category}/information-page`}
                    ];
                });
            }else{
                this.translate.get('breadcrumb.files', {category: category}).subscribe((filesString: string) => {
                    breadcrumbs = [
                        ...breadcrumbs,
                        {label: filesString, href: `/compliance/${domain}/${category}/project/list`}
                    ];
                });
            }
        }
        if (projectId) {
            project = await this.dossierService.findById(projectId);

            if (exerciceId) {
                exercice = await this.exerciceService.findExercicesById(exerciceId);
                breadcrumbs = [
                    ...breadcrumbs,
                    {
                        label: `${project.name} - ${exercice.annee}`,
                        href: `/compliance/${domain}/${category}/project/${project.id}/${exercice.id}/dashboard`
                    }
                ];
            } else {
                breadcrumbs = [
                    ...breadcrumbs,
                    {label: project.name, href: `/compliance/${domain}/${category}/project/${project.id}/dashboard`}
                ];
            }
        }
        if (stateMachineId || controlId) {
            let originPageUrl;
            if (controlId) {
                originPageUrl = {
                    label: super.getValue('breadcrumb.listControl'),
                    href: `/compliance/control/${domain}/${category}/${project.id}/${exerciceId}/list`
                };
            } else {
                switch (originPage) {
                    case OriginPage.LISTCONTROL:
                        originPageUrl = {
                            label: super.getValue('breadcrumb.listControl'),
                            href: `/compliance/control/${domain}/${category}/${project.id}/${exerciceId}/list`
                        };
                        break;
                    case OriginPage.GLOBALPARAMS:
                        originPageUrl = {
                            label: super.getValue('breadcrumb.globalParams.title'),
                            href: `/compliance/${domain}/${category}/project/${projectId}/${exerciceId}/globalParams/graph`
                        };
                        break;
                    case OriginPage.DASHBOARD:
                        break;
                }
            }
            if (originPageUrl) {
                breadcrumbs = [
                    ...breadcrumbs,
                    originPageUrl
                ];
            }
        }
        if (this._findRoute(snapshot, 'file-list')) {
            breadcrumbs = [
                ...breadcrumbs,
                {
                    label: super.getValue('breadcrumb.file-list'),
                    href: `/compliance/${domain}/${category}/project/${projectId}/${exerciceId}/file-list`
                }
            ];
        }
        if (this._findRoute(snapshot, 'uploadViesCsv')) {
            breadcrumbs = [
                ...breadcrumbs,
                {
                    label: super.getValue('breadcrumb.listControl'),
                    href: `/compliance/control/${domain}/${category}/${project.id}/${exerciceId}/list`
                }
            ];
        }
        if (snapshot.data && snapshot.data.title) {
            let label = super.getValue(snapshot.data.title);
            if (controlId) {
                control = await this.controlService.findControlById(controlId);
                label = control.nomCourt + ' - ' + label;
            }
            breadcrumbs = [
                ...breadcrumbs,
                {label: label, href: this._getResolvedUrl(snapshot), queryParams: snapshot.queryParams}
            ];
        }
        this.setCrumbs(breadcrumbs);
    }

    private _getResolvedUrl(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/');
    }

    private _findRoute(route: ActivatedRouteSnapshot, routeToFind: string): boolean {
        return route.pathFromRoot
            .find(v => v.url.find(segment => segment.path === routeToFind) !== undefined) !== undefined;
    }
}
