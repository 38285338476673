import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';

import {AuthService} from "../../../../../common/service/auth/auth.service";
import {HabilitationService} from "../../../../../common/service/auth/habilitation.service";
import {ComplianceToastrService} from "../../../../../common/service/toastr/compliance-toastr.service";
import {Observable} from "rxjs";

@Injectable()
export class CookieGuard implements CanActivate, CanActivateChild {

    constructor(private authService: AuthService, private router: Router, private habilitationService: HabilitationService, private toastr: ComplianceToastrService) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkCookie(next);
    }

    canActivateChild(next: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        return this.checkCookie(next);
    }


    public checkCookie(next: ActivatedRouteSnapshot) {
        if (!this.habilitationService.hasMakeCookieChoice() && next.routeConfig.path.indexOf('gestion-cookie') < 0) {
            this.router.navigate(['/compliance/gestion-cookie']);
            return false;
        }

        return true;
    }
}
