import {Injectable} from "@angular/core";
import {JwtHelperService} from "@auth0/angular-jwt";
import moment from "moment";
import {REFRESH_TOKEN_LOCAL_NAME, TOKEN_EXPIRE_DATE_NAME, TOKEN_LOCAL_NAME} from "../../class/compliance-constant";
import {HabilitationService} from "./habilitation.service";
import {User} from "../../class/user/user";

// const moment = _moment;
@Injectable()
export class AuthService {
    private connectedUser: User;
    private redirectUrl: string;
    private refreshToken: string;
    private jwtHelper = new JwtHelperService();

    constructor(private habilitationService: HabilitationService) {
    }

    getConnectedUser(): User {
        return this.connectedUser;
    }

    setConnectedUser(): void {
        let decodeToken = this.jwtHelper.decodeToken(this.getToken());
        if (decodeToken) {
            this.updateConnectedUser(decodeToken);
        }
    }

    private updateConnectedUser(decodeToken: any) {
        console.log("decoded token : ", decodeToken);
        this.connectedUser = decodeToken.user.user;
        this.habilitationService.setConnectedUser(this.connectedUser);
    }

    getRedirectUrl(): string {
        return this.redirectUrl;
    }

    setRedirectUrl(url: string): void {
        this.redirectUrl = url;
    }


    resetAuthValues(): void {
        localStorage.removeItem(TOKEN_LOCAL_NAME);
        localStorage.removeItem(REFRESH_TOKEN_LOCAL_NAME);
        localStorage.removeItem(TOKEN_EXPIRE_DATE_NAME);
        this.connectedUser = null;
    }

    setToken(token: any) {
        let accessToken = token.access_token;
        let refreshToken = token.refresh_token;
        let decodeToken = this.jwtHelper.decodeToken(accessToken);
        this.updateConnectedUser(decodeToken);
        let decodeFefreshToken = this.jwtHelper.decodeToken(token.refresh_token);
        // console.log("decodeToken", decodeToken);
        // console.log("refreshToken", decodeFefreshToken);
        // console.log("Oauth token", token);
        let expireMoment = moment.unix(decodeFefreshToken.exp);
        // console.log("Oauth expireDate", expireMoment.toString());
        let expireDate = expireMoment.valueOf();
        localStorage.setItem(TOKEN_LOCAL_NAME, accessToken);
        localStorage.setItem(REFRESH_TOKEN_LOCAL_NAME, refreshToken);
        localStorage.setItem(TOKEN_EXPIRE_DATE_NAME, decodeFefreshToken.exp + '');
    }


    public getToken(token_name?: string): string {
        token_name = token_name || TOKEN_LOCAL_NAME;
        return localStorage.getItem(token_name);
    }


    public getDecodedToken(token?: string): any {
        token = token || this.getToken();
        let decodeToken = this.jwtHelper.decodeToken(token);
        return decodeToken;
    }


    public isTokenExpired(): boolean {
        // get the token
        const token = this.getToken();
        // return a boolean reflecting
        // whether or not the token is expired
        if (!token) {
            return false;
        }

        return this.jwtHelper.isTokenExpired(token);
    }

    public isOauth2Expire(): boolean {
        const token = this.getToken(REFRESH_TOKEN_LOCAL_NAME);
        if (!token) {
            return true;
        }

        let expireOauth2TokenTime = localStorage.getItem(TOKEN_EXPIRE_DATE_NAME);
        if (!expireOauth2TokenTime) {
            return true;
        }

        let expireDate = moment.unix(Number(expireOauth2TokenTime));

        let now = moment();
        let before = expireDate.isBefore(now);
        return before;
    }

}
