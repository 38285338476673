import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../../../../../common/class/compliance-api.service";
import {RestService} from "../../../../../../common/service/rest.service";
import {Contact} from "./Contact";

@Injectable({
    providedIn: 'root'
})
export class AideService extends ComplianceApiService {

    constructor(private restService: RestService) {
        super('contact', 'guest');
    }

    sendNotification(contact: Contact): Promise<any> {
        return this.restService.post({
            url: this.baseUri,
            data: contact,
            arg: null
        })
    }
}
