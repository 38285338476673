import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./shared/auth/auth.guard";
import {LoginComponent} from "../../../common/login/login.component";
import {ForgottenPasswordComponent} from "../../../common/login/forgotten-password/forgotten-password.component";
import {CreatePasswordComponent} from "../../../common/login/create-password/create-password.component";
import {AuthGuardLoged} from "../../../common/service/auth/auth-loged.guard";

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [AuthGuardLoged]
    },
    {
        path: 'forgottenPassword',
        component: ForgottenPasswordComponent
    },
    {
        path: 'createPassword/:tempId',
        component: CreatePasswordComponent
    },
    {path: '**', redirectTo: '/compliance/home', pathMatch: 'full', canActivate: [AuthGuard]}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
