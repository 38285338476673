<div class="" style="z-index: 2000">
    <nav aria-label="breadcrumb d-flex align-items-center">
        <ol class="breadcrumb ">
            <li *ngFor="let crumb of breadcrumbService.crumbsSubject | async" class="breadcrumb-item" title="{{crumb.label}}">
                <a *ngIf="crumb.href" [routerLink]="crumb?.href" [queryParams]="crumb?.queryParams"><i *ngIf="crumb.iconClass" class="{{crumb.iconClass}} mr-2"></i>{{crumb.label}}</a>
                <a *ngIf="!crumb.href" (click)="crumb?.click && crumb?.click()"><i *ngIf="crumb.iconClass" class="{{crumb.iconClass}} mr-2"></i>{{crumb.label}}</a>
            </li>
        </ol>
    </nav>
</div>
