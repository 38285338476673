<div class="container-fluid height-100">
    <div class="row height-100">
        <div class="d-flex flex-column data">
            <h1>{{project.name}}</h1>
            <div class="period">
                {{'dashboard.legalEntity.period.from' | translate}}
                {{formControl.value.dateDebut | date:DATE_FORMAT["DD/MM/YYYY"].datePipe}}
                {{'dashboard.legalEntity.period.to' | translate}}
                {{formControl.value.dateFin | date:DATE_FORMAT["DD/MM/YYYY"].datePipe}}
            </div>
            <div class="exercices mt-auto">
                <span class="font-weight-bold">Exercice</span>
                <select [formControl]="formControl">
                    <option value="create">{{'dashboard.legalEntity.new' | translate}}</option>
                    <option *ngFor="let e of exercices" [ngValue]="e">{{e.annee}}</option>
                </select>
                <i *ngIf="!currentExercice.validation"
                   class="fa fa-pencil text-size-18"
                   (click)="editExercice()">
                </i>
                <i class="fa fa-trash text-size-18" (click)="deleteExercice()"></i>
            </div>
        </div>
        <div class="d-flex align-self-center actions">
            <button *ngIf="!currentExercice.validation" (click)="goToGestionFile()">
                <img class="m-auto" src="assets/images/skin/dashboard/files.svg" />
            </button>
            <compliance-light-subscription-button *ngIf="!currentExercice.validation"
                                                  pTooltip="{{'globalParams.accessTooltip' | translate}}" tooltipPosition="bottom"
                                                  [disabled]="!paramsSubscription"
                                                  (click)="goToGlobalParams()">
                <img class="m-auto" src="assets/images/skin/dashboard/parametrages.svg" />
            </compliance-light-subscription-button>
            <button class="global-visa" [ngClass]="{'closed': currentExercice.validation}" (click)="goToExerciceSupervision()">
                <i class="fas fa-comment-alt-lines text-size-18"></i>
            </button>
        </div>
    </div>
</div>
