import {Expression} from "../../../../../../common/class/meta/meta-expression";
import {ExportType} from "../../export-file/export-file";

export interface ActionMenuItem {
    code: string;
    label: string;
}

export enum TableItemType {
    string = "string",
    number = "number",
    currency = "currency",
    date = "date",
    actionMenu = "actionMenu",
    affectation = "affectation",
    form = "form",
    binary = "binary"
}


export enum TableFormItemType {
    text, checkbox
}

export interface GraphField {
    color?: string;
    colorLot?: string;
    label: string;
    field: string
}

export interface TableItemExport {
    ecriture?: {
        codes: string[],
        periodField?: string,
        fileName: string,
        fileType: ExportType
    }
}

export interface TableItem {
    type: TableItemType | string;
    formType?: TableFormItemType;
    dateFormat?: {
        inFormat: string;
        outFormat: string;
    };
    formatObject?: any;
    numberFormat?: string;
    formDataKeyField?: string;
    tooltipField?: string;
    header: string;
    field: string;
    isLineHeader?: boolean
    sort?: boolean;
    hide?: boolean;
    format?: string;
    activeField?: string;
    activeFieldType?: string;
    class?: string | { class?: string, expression: Expression };
    valueClass?: string | { class?: string, expression: Expression };
    styleField?: string;
    actionMenu?: ActionMenuItem[];
    checked?: (data: { [key: string]: any; }) => boolean;
    graphField?: GraphField[];
    prefix?: string;
    footer?: TableItem;
    display?: Expression;
    export?: TableItemExport;

    computeClassToAffect?: { [keys: string]: boolean };
    computeValueType?: boolean;

}


export interface TableOption {
    activeField?: string;
    activeFieldType?: string;
    exportFileName?: string;
    selectionMode?: string
}


