import {Injectable} from '@angular/core';
import {RestService} from "../../../../../../../../common/service/rest.service";
import {ComplianceApiService} from "../../../../../../../../common/class/compliance-api.service";
import {AFFECTATION_COMPTE, LotAffectation} from "./lot-affectation";
import {TableItem, TableItemType, TableOption} from "../../../../../shared/table/shared/table-item";
import _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class AffectationCompteService extends ComplianceApiService {

    constructor(private restService: RestService) {
        super('affectationCompte', 'api')
    }

    findAllAffectation(projectId: string, exericeId:string): Promise<LotAffectation[]> {
        return this.restService.get({
            url: this.baseUri,
            parameters: [projectId, exericeId],
            arg: null as LotAffectation[]
        })
    }
    saveAffectation(projectId:string, exericeId:string, data:any, isDone:boolean, targetObjectName:string): Promise<any> {
        return this.restService.put({
            url: this.baseUri,
            parameters: [projectId, exericeId, isDone, targetObjectName],
            data:data,
            arg: null as LotAffectation[]
        })
    }

    getAffectationProduitSoumisNonSoumis(translate: any, affectationType:string, show:boolean=true): TableItem[] {
        let table = translate.mainContent.table;
        let headers = table.headers;
        let actionMenu = table.actionMenu;
        return [
            {field: "compteNum", header: headers.ccompteNum, type:TableItemType.string, isLineHeader:true, sort:true},
            {field: "compteLib", header: headers.compteLib, type:TableItemType.string, class:"label-long"},
            {field: "affectationItem", header: "", type:TableItemType.affectation,  class:"col-affectation"},
            {field: "showMenu", header: "", type:TableItemType.actionMenu, class:"action", actionMenu:this.filterActionMenuProduitSoumisNonSoumis(affectationType, actionMenu)},
        ]
    }

    getProduitSoumisNonSoumisTableOption():TableOption{
        return {
            activeField : 'active',
            activeFieldType: 'affectAutoType',
            selectionMode:'multiple'
        }
    }

    private getActionMenuProduitSoumisNonSoumis(actionMenu):any[] {
        return [
            {code: AFFECTATION_COMPTE.compteProduit.produitSoumisTva, label: actionMenu.produits_soumis_tva},
            {code: AFFECTATION_COMPTE.compteProduit.produitNonSoumisTva, label: actionMenu.produits_non_soumis_tva},
            {code: AFFECTATION_COMPTE.compteProduit.produitSoumisEtNonSoumisTva, label: actionMenu.produits_mixtes_tva},
        ];
    }

    private filterActionMenuProduitSoumisNonSoumis(affectationType:string, actionMenu):any[]{
        return _.filter(this.getActionMenuProduitSoumisNonSoumis(actionMenu), (item)=>{
            return item.code !== affectationType;
        })
    }
}
