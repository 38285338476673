import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../../../../../../common/class/compliance-api.service";
import {UploadInfo} from "../../../../../../../common/class/upload/upload-info";
import {Observable} from "rxjs";
import {UploadActionType} from "../../../../../../../common/class/upload/upload-constantes";
import {UploadComplianceFileService} from "./compliance-file/upload-compliance-file.service";
import {UploadActionService} from "./upload-action.service";
import {RestService} from "../../../../../../../common/service/rest.service";
import {ManualCa3Form} from "../../../../compliance/project/gestion-file/edit/ca3/manual-ca3-form";
import {UploadFile} from "../../../../../../../common/class/upload/upload-file";

@Injectable({
    providedIn: 'root'
})
export class UploadService extends ComplianceApiService {


    private uploadAction: { [key: string]: UploadActionService } = {};

    constructor(private uploadComplianceFileService: UploadComplianceFileService, private _restService:RestService) {
        super('upload', 'api');
        this.uploadAction[UploadActionType.COMPLIANCE_FILE] = uploadComplianceFileService;
    }

    public upload({uploadInfo, uploadActionType, anneeExercice, projectName, projectCode, authorizedFilesControl}: { uploadInfo: UploadInfo, uploadActionType: UploadActionType, anneeExercice: any, projectName: string, projectCode: string, authorizedFilesControl: string[]}): Observable<any> {
        return this.uploadAction[uploadActionType].upload(uploadInfo, anneeExercice, projectName, projectCode, authorizedFilesControl);
    }

    public getFormCa3(millesime:string):Promise<ManualCa3Form> {
        return this._restService.get({
            url: this.baseUri,
            relativeUri: 'extract/manualCA3',
            parameters: [millesime],
            arg: null as ManualCa3Form
        });
    }

    public saveFormCa3(projectId:string,exerciceId:string,uuid:string,periode:string,data:any):Promise<ManualCa3Form> {
        return this._restService.post({
            url : this.baseUri,
            relativeUri: 'save/manualCA3',
            parameters:[projectId, exerciceId, uuid, periode],
            data: data,
            arg:null as ManualCa3Form
        });
    }

    public updateFormCa3(projectId:string, fileId:string, data:any):Promise<ManualCa3Form> {
        return this._restService.post({
            url : this.baseUri,
            relativeUri: 'update/manualCA3',
            parameters:[projectId, fileId],
            data: data,
            arg:null as ManualCa3Form
        });
    }

    public getManualCA3Values(projectId:string, fileId:string):Promise<any> {
        return this._restService.get({
            url : this.baseUri,
            relativeUri: 'values/manualCA3',
            parameters: [projectId, fileId],
            arg: null as any
        });
    }


    findFile(fileId: string): Promise<UploadFile> {
        return this._restService.get({
            "url": this.baseUri,
            relativeUri: 'findFile',
            parameters: [fileId],
            "arg":null as UploadFile
        });
    }

}
