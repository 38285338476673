import {CategoryControl, DomaineControl} from "../../class/control/control-descriptor";
import {ActivatedRoute, Params} from "@angular/router";
import {BackgroungType} from "../../class/background/bg-compliance";
import {NotificationMessage} from "../../class/polling/notification-message";
import {NotificationService} from "../../service/push/notification.service";
import {OnDestroy} from "@angular/core";
import {ControlMessageHandler, OriginPage} from "./control-message-handler";
import {ComplianceLoaderService} from "../../service/loader/compliance-loader.service";
import {ServiceLocator} from "../../class/locator/service-locator";
import {ControlService} from "../../service/control/control.service";

class ControlMessageContext {
    notificationService: NotificationService;
    manageDataEvent?: (notification: NotificationMessage) => any;
    manageErrorEvent?: (notification: NotificationMessage) => void;
    manageRouteEvent?: (notification: NotificationMessage, domain: DomaineControl, category: CategoryControl, projectId: string, exerciceId: string, stateMachineId: string) => void;
    manageDoneEvent?: (domain?: DomaineControl, category?: CategoryControl, projectId?: string, exerciceId?: string, stateMachineId?: string) => void;
}

export abstract class AbstractControl extends ControlMessageHandler implements OnDestroy {
    public projectId: string;
    public domain: DomaineControl;
    public category: CategoryControl;
    public controlId: string;
    public exerciceId: string;
    public stateMachineId: string;
    public event: string | any;
    public originPage: OriginPage;
    public stepByStep: boolean;
    protected _complianceLoaderService: ComplianceLoaderService;
    protected _controlService: ControlService;

    constructor(backGround: BackgroungType) {
        super();
        document.body.className = backGround.toString();
        this._complianceLoaderService = ServiceLocator.injector.get(ComplianceLoaderService);
        this._controlService = ServiceLocator.injector.get(ControlService);
    }

    protected async setContextWithRoute(route: ActivatedRoute, getDataCallBack) {
        this.projectId = route.snapshot.paramMap.get('projectId');
        this.domain = route.snapshot.paramMap.get('domain') as DomaineControl;
        this.category = route.snapshot.paramMap.get('category') as CategoryControl;
        this.controlId = route.snapshot.paramMap.get('controlId');
        this.exerciceId = route.snapshot.paramMap.get('exerciceId');
        this.stateMachineId = route.snapshot.paramMap.get('stateMachineId');
        this.controlId = route.snapshot.paramMap.get('controlId');
        this.subscriptions.push(route.queryParams.subscribe((param: Params) => {
            this.event = param['event'];
            this.originPage = param['originPage'] as OriginPage;
            this.stepByStep = param['stepByStep'] as boolean;
            this.controlId = param['controlId'] ? param['controlId'] : this.controlId;
            getDataCallBack(param);
        }));
        if (this.controlId) {
            let control = await this._controlService.findControlById(this.controlId);
            this.stateMachineId = control.stateMachineId;
        }
        const context = {
            projectId: this.projectId,
            domain: this.domain,
            category: this.category,
            stateMachineId: this.stateMachineId,
            exerciceId: this.exerciceId,
            messageParams: {
                originPage: this.originPage,
                controlId: this.controlId,
                stepByStep: this.stepByStep
            },
        };
        super.setMessageContext(context);
    }

    protected subscribeToNotificationControl({notificationService, manageErrorEvent, manageDataEvent, manageRouteEvent, manageDoneEvent}: ControlMessageContext) {

        super.subscribeToNotificationControl({
            notificationService: notificationService,
            manageErrorEvent: manageErrorEvent,
            manageDataEvent: manageDataEvent,
            manageRouteEvent: manageRouteEvent,
            manageDoneEvent: manageDoneEvent
        });
    }
}
