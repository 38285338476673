import {Injectable} from '@angular/core';
import {ComplianceApiService} from "../../class/compliance-api.service";
import {RestService} from "../rest.service";
import {FECInfos} from "../../class/globalParams/fecInfos";

@Injectable({
    providedIn: 'root'
})
export class FecInfosService extends ComplianceApiService {

    constructor(private _restService: RestService) {
        super('fec', 'api');
    }

    getBalanceData(projectId: string, exerciceId: string): Promise<FECInfos> {
        return this._restService.get({
            url: this.baseUri,
            relativeUri: 'info',
            parameters: [projectId, exerciceId],
            arg: null as FECInfos
        });
    }
}
