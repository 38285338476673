import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import * as _ from "lodash";
import {PIE_OPTION} from "../../../../../../../common/class/graphique/graphique";
import {ControlForDashboard, STATUS_COLORS, STATUS_RISK_COLORS, StatusControl} from "../../../../../../../common/class/control/control-descriptor";
import {ComplianceI18nLoader} from "../../../../../../../common/class/i18n/compliance-i18n-loader";
import {UIChart} from "primeng/chart";
import {CurrencyPipe} from "@angular/common";
import {Visa} from "../../../../../../../common/class/control/supervision";

@Component({
    selector: 'compliance-global-risk',
    templateUrl: './global-risk.component.html',
    styleUrls: ['./global-risk.component.scss']
})
export class GlobalRiskComponent extends ComplianceI18nLoader implements AfterViewInit {
    private viewInit = false;
    riskAmount: {
        global: number,
        waiting: number,
        refused: number,
        accepted: number,
    };
    private showGraph = true;
    private noRiskText: {
        icon: string,
        color: string,
        text: string
    };

    @Input() set controlList(list: ControlForDashboard[]) {
        this.getGraphDatas(list);
    }

    @ViewChild('chartPolar') uiChart: UIChart;
    polarGraph;
    polarOptionBase = {
        labels: [],
        datasets: [{
            data: [],
            label: [],
            backgroundColor: [],
            borderWidth: 1,
            borderColor: "rgb(255, 255, 255)",
        }]
    };
    polarOption = _.merge(_.cloneDeep(PIE_OPTION), {
        responsive: true,
        maintainAspectRatio: false,
        scale: {
            ticks: {
                display: false,
                beginAtZero: true,
                min: 0
            },
            angleLines: {
                lineWidth: 2,
                color: 'rgb(255, 255, 255)'
            },
            gridLines: {
                lineWidth: 1,
                color: 'rgb(255, 255, 255)'
            }
        },
        tooltips: {
            enabled: true,
            callbacks: {
                title: (items, data) => data.datasets[items[0].datasetIndex].label[items[0].index].title,
                label: (item, data) => data.datasets[item.datasetIndex].label[item.index].label,
            },
            titleFontColor: "rgb(76, 54, 113)",
            bodyFontColor: "rgb(76, 54, 113)",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderColor: "rgb(255, 255, 255)",
            borderWidth: 2,

            /* TODO To adapt or remove */
            // Disable the on-canvas tooltip
            /* enabled: false,
            custom: function(tooltip) {
                // Tooltip Element
                let tooltipEl = document.getElementById('chartjs-tooltip');

                // Hide if no tooltip
                if (tooltip.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltip.body) {
                    let titleLines = tooltip.title || [];
                    let bodyLines = tooltip.body.map(getBody);

                    let innerHtml = '<div>';

                    titleLines.forEach(function(title) {
                        innerHtml += '<span style="font-size: 12px; font-weight: bold">' + title + '</span>';
                    });
                    innerHtml += '</div><dvi>';

                    bodyLines.forEach(function(body, i) {
                        innerHtml += '<span>' + body + '</span>';
                    });
                    innerHtml += '</div>';

                    tooltipEl.innerHTML = innerHtml;
                }

                let positionY = this._chart.canvas.offsetTop;
                let positionX = this._chart.canvas.offsetLeft;

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = positionX + tooltip.caretX + 'px';
                tooltipEl.style.top = positionY + tooltip.caretY + 'px';
                tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
                tooltipEl.style.fontSize = tooltip.bodyFontSize;
                tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
                tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
            }*/
        },
        layout: {
            padding: {
                top: 5,
                right: 5,
                bottom: 5,
                left: 5
            }
        },
        title: {
            display: false
        },
        plugins: {
            // Change options for ALL labels of THIS CHART
            datalabels: {
                display: false
            }
        }
    });

    constructor(private _currencyPipe: CurrencyPipe) {
        super();
    }

    private getGraphDatas(controlList: ControlForDashboard[]) {
        this.polarGraph = _.cloneDeep(this.polarOptionBase);

        let riskedControl = this.computeControl(controlList, new Array<ControlForDashboard>());

        this.riskAmount = {
            global: riskedControl ? riskedControl.reduce((sum, control) => sum + control.amountRiskToDisplay, 0) : null,
            waiting: riskedControl ? riskedControl.filter((control) => control.visa.value === null).reduce((sum, control) => sum + control.amountRiskToDisplay, 0) : null,
            refused: riskedControl ? riskedControl.filter((control) => control.visa.value === Visa.TO_REVIEW).reduce((sum, control) => sum + control.amountRiskToDisplay, 0) : null,
            accepted: riskedControl ? riskedControl.filter((control) => control.visa.value === Visa.VALIDATE).reduce((sum, control) => sum + control.amountRiskToDisplay, 0) : null
        };
        if (!this.riskAmount.global) {
            const hasIncoherence = !!controlList.find((control) => control.status === StatusControl.ORANGE || control.status === StatusControl.RED);
            this.noRiskText = {
                icon: 'fal fa-' + (hasIncoherence ? 'exclamation-triangle' : 'check-circle'),
                color: STATUS_COLORS[(hasIncoherence ? StatusControl.ORANGE : StatusControl.GREEN)],
                text: 'dashboard.globalRisk.noRisk.' + (hasIncoherence ? 'withIncoherence' : 'noIncoherence')
            };
        }
        if (this.riskAmount.global < 0) {
            this.noRiskText = {
                icon: 'fal fa-exclamation-triangle',
                color: StatusControl.RED,
                text: 'dashboard.globalRisk.noRisk.negativeIncoherence'
            };
        }

        riskedControl.forEach((control) => {
            // Waiting for short control code
            this.polarGraph.labels.push(control.nomCourt.split(' ').slice(0, 2).join(' '));
            this.polarGraph.datasets[0].data.push(Math.log(Math.abs(control.amountRiskToDisplay) + 1));
            this.polarGraph.datasets[0].backgroundColor.push(STATUS_RISK_COLORS[control.visa.value ? control.visa.value : Visa.DEFAULT]);
            this.polarGraph.datasets[0].label.push({
                title: control.nomCourt,
                label: this.getValue(
                    'dashboard.globalRisk.graph.tooltip',
                    {value: this._currencyPipe.transform(control.amountRiskToDisplay, 'EUR', 'symbol', '1.0-0')}
                )
            });
        });
        const maxTick = +Math.max(0, ...this.polarGraph.datasets[0].data).toFixed(1);
        this.polarOption.scale.ticks.max = maxTick;
        this.polarOption.scale.ticks.stepSize = maxTick / 4;
        if (this.polarGraph.datasets[0].data.length <= 5) {
            const arrayLength = 5 - this.polarGraph.datasets[0].data.length;
            this.polarGraph.datasets[0].data.push(...Array.apply(null, Array(arrayLength)).map(() => 0));
        }

        this.showGraph = false;
        setTimeout(() => {
            this.showGraph = true;
            this.updateChartUi();
        }, 0);
        this.updateChartUi();
    }

    private computeControl(controlList: ControlForDashboard[], riskedControl: ControlForDashboard[]): ControlForDashboard[] {
        if (!controlList || controlList.length === 0) {
            return [];
        }

        riskedControl = controlList.filter((control) => {
            control.amountRiskToDisplay = control.amountRisk;
            if (control.acceptedAmountRisk || control.acceptedAmountRisk === 0) {
                control.amountRiskToDisplay = control.acceptedAmountRisk;
            }
            return control.amountRiskToDisplay;
        }).sort((control1, control2) => {
            return control2.amountRiskToDisplay - control1.amountRiskToDisplay;
        });
        return riskedControl;
    }

    private updateChartUi() {
        if (this.viewInit && this.uiChart) {
            this.uiChart.chart.update();
        }
    }

    ngAfterViewInit(): void {
        this.viewInit = true;
        this.updateChartUi();
    }
}
