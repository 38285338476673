import {ModuleWithProviders, NgModule} from '@angular/core';
import {JWT_OPTIONS, JwtModule} from "@auth0/angular-jwt";
import {TranslateModule} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {ConfirmationService} from "primeng/api";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {HabilitationService} from "./auth/habilitation.service";
import {AuthenticationService} from "./auth/authentication.service";
import {RestService} from "./rest.service";
import {AuthService} from "./auth/auth.service";
import {LoginService} from "./auth/login.service";
import {ConfirmDeleteService} from "./dialog/confirm-delete.service";
import {DossierService} from "./dossier/dossier.service";
import {ComplianceHttpInterceptor} from "./interceptor/compliance.http.interceptor";
import {UserService} from "./user/user.service";
import {AccountService} from "./user/account.service";
import {PasswordService} from "../login/shared/password.service";
import {ComplianceToastrService} from "./toastr/compliance-toastr.service";
import {TOKEN_LOCAL_NAME} from "../class/compliance-constant";
import {FileUtilsService} from "./file/file-utils.service";
import {FormService} from "./form/form.service";
import {NotificationMessageService} from "./push/notification-message.service";
import {AuthGuardLoged} from "./auth/auth-loged.guard";
import {FileService} from "./file/file.service";
// import {NotificationService} from "./push/notification.service";
import {ControlService} from "./control/control.service";
import {BaseSirenService} from "./siren/base-siren.service";
import {BreadcrumbService} from "./breadcrumb/breadcrumb.service";
import {CacheService} from "./cache/cache.service";
import {ExerciceService} from "./exercice/exercice.service";
import {MetaExpressionService} from "./meta/meta-expression.service";
import {QuestionPeriodService} from "./question/question-period.service";
import {MetaFormulaireService} from "./meta/meta-formulaire.service";
import {MetaQuestionService} from "./meta/meta-question.service";
import {ComplianceRouteService} from "./route/compliance-route.service";
import {NotificationService} from "./push/notification.service";
import {CurrencyPipe} from "@angular/common";
import {SubscriptionService} from "./user/subscription.service";
import {AccountRefService} from "./user/account-ref.service";
import {ControlFileService} from "./control/control.file.service";
import {ConfirmDialogModule} from "primeng/confirmdialog";


export function TokenGetter() {
    return localStorage.getItem(TOKEN_LOCAL_NAME);
}

export function jwtOptionsFactory(tokenService) {
    return {
        tokenGetter: TokenGetter,
        whitelistedDomains: [new RegExp('.*')],
        blacklistedRoutes: [new RegExp('/guest/')],
    };
}

@NgModule({
    imports: [
        HttpClientModule,
        TranslateModule.forRoot(),
        ConfirmDialogModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: []
            },
        }),
    ],
    exports: [HttpClientModule, TranslateModule]
})
export class ComplianceServiceLibModule {
    static forRoot(): ModuleWithProviders<ComplianceServiceLibModule> {
        return {
            ngModule: ComplianceServiceLibModule,
            providers: [
                HabilitationService,
                AuthenticationService,
                RestService,
                AuthService,
                LoginService,
                ConfirmationService,
                ConfirmDeleteService,
                DossierService,
                FileUtilsService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: ComplianceHttpInterceptor,
                    multi: true,
                },
                ToastrService,
                AccountService,
                SubscriptionService,
                PasswordService,
                UserService,
                ComplianceToastrService,
                FormService,
                NotificationMessageService,
                AuthGuardLoged,
                FileService,
                BaseSirenService,
                FileService,
                NotificationService,
                ControlService,
                BreadcrumbService,
                CacheService,
                ExerciceService,
                MetaExpressionService,
                QuestionPeriodService,
                MetaQuestionService,
                MetaFormulaireService,
                ComplianceRouteService,
                AccountRefService,
                CurrencyPipe,
                ControlFileService
            ]
        };
    }
}
