<div id="" class="page-botom-transparent">
    <div class="container-fluid w-60 ">

        <div class="block-group-title">
            <h2 class="title" [innerHtml]="'myAccount.title' | translate | safe"></h2>
        </div>
        <div class="d-flex align-items-start justify-content-around">
            <div class="info card-body shadow form-info">
                <h3 class="text-size-15 mt-2 mb-4 card-title"><b>{{'myAccount.informationCompteLabel' | translate}}</b></h3>
                <form [formGroup]="formUser" novalidate autocomplete="off" class="form-basic">
                    <div class="row">
                        <div class="form-group text-size-13 col">
                            <label for="lastName">{{'myAccount.lastNameLabel' | translate}} :</label>

                            <input type="text" class="form-control" id="lastName" formControlName="lastName"/>
                        </div>
                        <div class="form-group text-size-13 col">
                            <label for="firstName">{{'myAccount.firstNameLabel' | translate}} :</label>
                            <input type="text" class="form-control" id="firstName" formControlName="firstName"/>
                        </div>
                    </div>

                    <div class="form-group text-size-13 ">
                        <label for="phone">{{'myAccount.telephonLabel' | translate}} :</label>
                        <input type="text" class="form-control" id="phone" formControlName="phone"/>
                    </div>
                    <div class="form-group text-size-13">
                        <label for="firstName">{{'myAccount.emailLabel' | translate}} :</label>
                        <input type="text" class="form-control" value="{{user.email}}" readonly disabled/>
                    </div>

                    <div class="d-flex justify-content-center mt-5">
                        <button class="btn btn-primary flex-fill" type="button" (click)="saveUserInformation()"
                                [disabled]="formUser.invalid">{{'myAccount.button.saveInfo' | translate }}
                        </button>
                    </div>
                </form>
            </div>
            <div class="info card-body shadow form-info">
                <h3 class="text-size-15 mt-2 mb-4 card-title"><b>{{'myAccount.changePasswordLabel' | translate}}</b></h3>
                <form [formGroup]="form" novalidate autocomplete="off" class="form-basic">
                    <div class="form-group text-size-13">
                        <label for="oldPassword">{{'myAccount.oldPassword' | translate }}</label>
                        <input type="password" class="form-control" id="oldPassword" formControlName="oldPassword" (keyup.enter)="triggerChangePassword($event);" aria-describedby="oldPasswordHelp">
                        <div *ngIf="form.controls['oldPassword'].errors && form.controls['oldPassword'].dirty" id="oldPasswordHelp" class="text-primary">
                            <div [hidden]="!form.controls['oldPassword'].errors.required">
                                {{'createPassword.error.oldPassword.required' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group text-size-13">
                        <label for="newPassword">{{'myAccount.newPassword' | translate }}</label>
                        <input type="password" class="form-control" id="newPassword" formControlName="newPassword"
                               (keyup.enter)="triggerChangePassword($event);"
                               (keyup)="validate();"

                               aria-describedby="passwordHelp">
                        <div *ngIf="form.controls['newPassword'].errors && form.controls['newPassword'].dirty"
                             id="passwordHelp" class="text-primary">
                            <div [hidden]="!form.controls['newPassword'].errors.required">
                                {{'createPassword.error.password.required' | translate }}
                            </div>
                            <div [hidden]="!form.controls['newPassword'].errors.pattern">
                                <div>{{'createPassword.error.password.pattern' | translate }}</div>
                                <div>{{'createPassword.error.password.specialCharacters' | translate }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group text-size-13">
                        <label for="confirmNewPassword">{{'myAccount.confirmNewPassword' | translate }}</label>
                        <input type="password" class="form-control" id="confirmNewPassword" formControlName="confirmNewPassword"
                               (keyup.enter)="triggerChangePassword($event);"
                               (keyup)="validate();"
                               aria-describedby="confirmPasswordHelp">
                        <div *ngIf="form.controls.confirmNewPassword.errors && form.controls['confirmNewPassword'].dirty" id="passwordConfirmationHelp" class="text-primary">
                            <div [hidden]="!form.controls['confirmNewPassword'].errors.matchPassword">
                                {{'createPassword.error.password.confirmPassword' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mt-5">
                        <button class="btn btn-primary flex-fill" type="button" (click)="changePassword()"
                                [disabled]="form.invalid">{{'myAccount.button.changePassword' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="d-flex align-items-start justify-content-around mt-3">
            <div class="info card-body shadow gestion-cookie flex-fill">
                <h3 class="text-size-15 mt-2 mb-4 card-title d-flex justify-content-between">
                    <div class="font-weight-bold">{{'myAccount.gestionCookie' | translate}}</div>
                    <div class="action show-cookie" (click)="show = !show">
                        <i class=" action fas fa-caret-right text-right fa-2x" [ngClass]="{'fa-caret-down':show, 'fa-caret-right':!show}"></i>
                    </div>
                </h3>
                <compliance-gestion-cookie-preference *ngIf="show" (saveCookieEvent)="saveCookie($event)"></compliance-gestion-cookie-preference>
            </div>
        </div>
    </div>

    <div class="block-bottom-actions">
        <div class="inner text-center d-flex justify-content-between">
            <button href="#" class="btn btn-primary btn-back" (click)="goBack()"><span>{{'buttons.back' | translate}}</span></button>
        </div>
    </div>
</div>
