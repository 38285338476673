import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Dossier} from "../../../../../../../common/class/dossier/dossier";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import * as _ from "lodash";
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from "rxjs/operators";
import {merge, Subject} from "rxjs";

@Component({
    selector: 'compliance-project-block',
    templateUrl: './project-block.component.html',
    styleUrls: ['./project-block.component.scss']
})
export class ProjectBlockComponent implements OnDestroy {
    @Input() manualEdit: boolean;

    private _project: Dossier;
    @Input() set project(project: Dossier) {
        if (_.isEqual(this._project, project)) {
            return;
        }
        this._project = project;
        if (this.manualEdit) {
            if (!!this.projectForm) {
                this._createForm();
            }
            this.projectForm.patchValue(project);
        } else {
            this.validProject.emit(true);
        }
    }

    get project(): Dossier {
        return this._project;
    }

    @Output() projectChange = new EventEmitter<Dossier>();
    @Output() validProject = new EventEmitter<boolean>();

    private _refs: any;
    @Input() set refs(refs: any) {
        this._refs = refs;
        this.refsSearched = _.cloneDeep(refs);
    }

    get refs(): any {
        return this._refs;
    }

    refsSearched: any;
    projectForm: FormGroup;
    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private _fb: FormBuilder) {
        this._createForm();
    }

    private _createForm() {
        this.projectForm = this._fb.group({
            name: ['', Validators.required],
            adresse: [''],
            formeJuridique: [''],
            categorie: [''],
            codeApe: ['']
        });
        merge(
            this.projectForm.get('name').valueChanges,
            this.projectForm.get('adresse').valueChanges
        ).pipe(
            filter(() => this.manualEdit),
            takeUntil(this.destroy$),
            map(() => this.projectForm.value),
            debounceTime(200),
            distinctUntilChanged()
        ).subscribe(value => {
            this.updateProject();
        });
        this.projectForm.statusChanges.subscribe(val => {
            this.validProject.emit(val === "VALID");
        });
    }

    searchFormeJuridiques($event) {
        this.refsSearched.formeJuridiques = this.refs.formeJuridiques.filter(form => {
            return form.statutJuridique.toLowerCase().indexOf($event.query.toLowerCase()) >= 0;
        });
    }

    searchActivites($event) {
        this.refsSearched.activites = this.refs.activites.filter(form => {
            return form.label.toLowerCase().indexOf($event.query.toLowerCase()) >= 0 ||
                form.code.toLowerCase().indexOf($event.query.toLowerCase()) >= 0;
        });
    }

    searchCategories($event) {
        this.refsSearched.categories = this.refs.categories.filter(form => {
            return form.label.toLowerCase().indexOf($event.query.toLowerCase()) >= 0 ||
                form.code.toLowerCase().indexOf($event.query.toLowerCase()) >= 0;
        });
    }

    onSelect(data, field) {
        this._project[field] = data;
        this.updateProject();
    }

    updateProject() {
        const formValue = this.projectForm.value;
        this._project = {
            ...this._project,
            name: formValue.name,
            adresse: formValue.adresse,
            formeJuridique: formValue.formeJuridique ? formValue.formeJuridique.statutJuridique : null,
            categorie: formValue.categorie ? formValue.categorie.code : null,
            codeApe: formValue.codeApe ? formValue.codeApe.code : null,
        };
        this.projectChange.emit(this._project);
    }

    getLabel(field: string, id: string): string {
        if (!field || !id) {
            return '';
        }
        const data = this.refs[field].find(ref => ref.code === id);
        return `${data.label} (${data.code})`;
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
