import {Injectable, OnDestroy} from '@angular/core';

import * as Stomp from '@stomp/stompjs';
import {Client, Frame, StompSubscription} from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import {NotificationMessage, TypePollingMessage} from "../../class/polling/notification-message";
import {NotificationMessageService} from "./notification-message.service";
import {AppConfigService} from "../app-config.service";
import {Router} from "@angular/router";
import {CategoryControl, DomaineControl} from "../../class/control/control-descriptor";
import {AuthService} from "../auth/auth.service";
import {v4 as uuid} from 'uuid';
import {ComplianceToastrService} from "../toastr/compliance-toastr.service";
import {SESSION_TAB_ID} from "../../class/compliance-constant";
import {ComplianceLoaderService} from "../loader/compliance-loader.service";
import {UploadActionType} from "../../class/upload/upload-constantes";
import {ControlRoutesFlux} from "../../class/control/control-flux";
import {Subject} from "rxjs";
import {MessageContext, MessageParameters, OriginPage} from "../../component/control/control-message-handler";
import {globalParams} from "../../class/i18n/fr/globalParams";
import {ConfirmationService} from "primeng/api";
import {ComplianceI18nLoader} from "../../class/i18n/compliance-i18n-loader";

const WARNING_MESSAGE_FIELD = 'warningMessage';

@Injectable()
export class NotificationService extends ComplianceI18nLoader implements OnDestroy {

    controlNotificationSubject = new Subject<any>();
    controlNotificationObservable = this.controlNotificationSubject.asObservable();
    uploadNotificationSubject = new Subject<any>();
    uploadNotificationObservable$ = this.uploadNotificationSubject.asObservable();
    fileNotificationSubject = new Subject<any>();
    fileNotificationObservable$ = this.fileNotificationSubject.asObservable();
    webSocketConnectedSubject = new Subject<any>();
    webSocketConnectedSubjectObservable$ = this.webSocketConnectedSubject.asObservable();

    controlPushClient: Client;
    private _stompSubscription: StompSubscription[] = [];
    private messages: NotificationMessage[] = [];
    private connecting = false;
    private connectedClient = 0;
    private stompError = false;
    private readonly sessionTabId: string;

    constructor(private _notificationMessageService: NotificationMessageService,
                private _appConfigService: AppConfigService, private _router: Router,
                private _authService: AuthService, private _toastr: ComplianceToastrService,
                private _route: Router, private _complianceLoaderService: ComplianceLoaderService,
                private _confirmationService: ConfirmationService) {
        super();
        this.connecting = false;
        this.sessionTabId = sessionStorage.getItem(SESSION_TAB_ID);
        if (!this.sessionTabId) {
            this.sessionTabId = uuid();
            sessionStorage.setItem(SESSION_TAB_ID, this.sessionTabId);
        }
        this.init();
    }

    init() {
        if (!this.connecting) {
            this._authService.setConnectedUser();
            let connectedUser = this._authService.getConnectedUser();
            if (connectedUser) {
                this.connectToWebsocket(connectedUser.id);
            }
        } else {
            if (!this.stompError) {
                setTimeout(() => {
                    this.init();
                }, 200);
            }
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeToQueue();
        if (this.controlPushClient && this.controlPushClient.connected) {
            this.controlPushClient.deactivate();
        }
    }

    private unsubscribeToQueue() {
        if (this._stompSubscription && this._stompSubscription.length) {
            this._stompSubscription.forEach((stompSub) => this.controlPushClient.unsubscribe(stompSub.id));
            this._stompSubscription = [];
        }
    }

    private connectToWebsocket(userId: string) {
        let that = this;
        this.messages = [];
        if (!this.controlPushClient || !this.controlPushClient.connected) {
            this.connecting = true;
            this.controlPushClient = new Stomp.Client({
                webSocketFactory: () => {
                    return new SockJS(`/gs-compliance-websocket?userId=${userId}`);
                },
                // brokerURL: `${apiUrl}/gs-compliance-websocket?userId=${userId}`,
                connectHeaders: {
                    Authorization: this._authService.getToken()
                },
                debug: function (str) {
                    console.log(str);
                },
                reconnectDelay: 5000,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000
            });
            this.controlPushClient.onStompError = (receipt: Frame) => {
                console.warn('NotificationService.onStompError(): ', receipt);
                this.controlPushClient.deactivate();
                this.stompError = true;
                this._complianceLoaderService.sendLoaderHide();
            };

            this.controlPushClient.onConnect = () => {
                if (this.controlPushClient.connected) {
                    this._stompSubscription.push(this.controlPushClient.subscribe("/user/queue/control", (message) => {
                        this.onMessage(message, that.controlNotificationSubject);
                    }, {' X-Auth-Token': ''}));
                    this._stompSubscription.push(this.controlPushClient.subscribe("/user/queue/upload", (message) => {
                        this.onMessage(message, that.uploadNotificationSubject);
                    }, {' X-Auth-Token': ''}));
                    this._stompSubscription.push(this.controlPushClient.subscribe("/user/queue/file", (message) => {
                        this.onMessage(message, that.fileNotificationSubject);
                    }, {' X-Auth-Token': ''}));
                    this._stompSubscription.push(this.controlPushClient.subscribe("/user/queue/errors", (message) => {
                        if (message.body) {
                            this._toastr.errorI18n("errors.generalMessage");
                            this._complianceLoaderService.sendLoaderHide();
                        }
                    }, {' X-Auth-Token': ''}));
                    this.connecting = false;
                    this.webSocketConnectedSubject.next(true);
                }
            };
            this.controlPushClient.activate();
        } else {
            this.webSocketConnectedSubject.next(true);
        }
    }

    private onMessage(message, subject, tabNotExclusif = false) {
        if (message.body) {
            const obj = JSON.parse(message.body);
            if (obj.sessionTabId === this.sessionTabId || tabNotExclusif) {
                console.log("websocket message", obj);
                subject.next(obj);
            } else {
                console.warn("!!!!!!!!!!!!!!!!!!!!!!!!!!! j'ai recu un message d'un autre onglet, je dois le gere sous form de notification utilisateur?");
            }
        }
    }

    disconnect() {
        if (this.controlPushClient) {
            this.unsubscribeToQueue();
            this.controlPushClient.forceDisconnect();
            this.controlPushClient.deactivate();
            this.controlPushClient = null;
        }
    }

    manageMessageEvent({
                           notification, projectId, domain, category, stateMachineId, exerciceId, messageParams,
                           manageErrorEvent = this.manageErrorEvent.bind(this),
                           manageDoneParameterEvent = this.manageDoneParameterEvent.bind(this),
                           manageDoneEvent = this.manageDoneEvent.bind(this),
                           manageRouteEvent = this.manageRouteEvent.bind(this),
                           manageDataEvent = this.manageDataEvent.bind(this),
                           manageExitEvent = this.manageExitEvent.bind(this),
                       }: MessageContext) {
        if (notification.corp.hasOwnProperty(WARNING_MESSAGE_FIELD)) {
            this.manageMessageData(notification);
        }
        switch (notification.type) {
            case TypePollingMessage.DATA: {
                manageDataEvent(notification);
                break;
            }
            case TypePollingMessage.ERROR: {
                manageErrorEvent(notification);
                break;
            }
            case TypePollingMessage.ROUTING: {
                manageRouteEvent(notification, domain, category, projectId, exerciceId, stateMachineId, messageParams);
                break;
            }
            case TypePollingMessage.DONE_PARAMETER: {
                manageDoneParameterEvent(domain, category, projectId, exerciceId, stateMachineId);
                break;
            }
            case TypePollingMessage.DONE: {
                manageDoneEvent(domain, category, projectId, exerciceId, notification.corp, messageParams);
                break;
            }
            case TypePollingMessage.EXIT: {
                manageExitEvent(domain, category, projectId, exerciceId, messageParams.originPage);
                break;
            }
            default: {
                console.warn("NotificationService.manageMessageEvent: Warning unable to process socket message", notification);
            }
        }
    }

    private manageRouteEvent(notification: NotificationMessage, domain: DomaineControl, category: CategoryControl, projectId: string, exerciceId: string, stateMachineId: string, messageParams: MessageParameters) {
        let route = notification.corp.route;
        let event = notification.corp.event;
        let routeToNavigate: string;
        if (route === "upload") {
            routeToNavigate = `compliance/upload/${domain}/${category}/${UploadActionType.COMPLIANCE_FILE}/${projectId}/${exerciceId}/${stateMachineId}`;
        } else if (route === "listControl") {
            routeToNavigate = this.generateControlRoute(projectId, domain, category, null, ControlRoutesFlux.list, exerciceId);
        } else {
            routeToNavigate = this.generateParamsRoute(projectId, domain, category, stateMachineId, ControlRoutesFlux[route], exerciceId);
        }

        if (routeToNavigate) {
            this._router.navigate([routeToNavigate], {queryParams: {event: event, ...messageParams}});
        }
    }

    private manageErrorEvent(notification: NotificationMessage) {
        this._toastr.error(`Une erreur est survenue. Veuillez contacter votre administrateur local.<br/>${notification.corp ? notification.corp : ''}`, "Erreur technique");
    }

    private manageDoneEvent(domain: DomaineControl, category: CategoryControl, projectId: string, exerciceId: string, controlId: string, messageParams: MessageParameters) {
        this._router.navigate(
            [this.generateControlRoute(projectId, domain, category, controlId, ControlRoutesFlux["compte-rendu-general"], exerciceId)], {queryParams: {...messageParams}});
    }

    private manageDoneParameterEvent(domain: DomaineControl, category: CategoryControl, projectId: string, exerciceId: string, stateMachineId: string) {
        this._router.navigate(
            ['/compliance', domain, category, 'project', projectId, exerciceId, 'globalParams', 'graph']);
    }

    private manageDataEvent(notification: NotificationMessage) {}

    private manageExitEvent(domain: DomaineControl, category: CategoryControl, projectId: string, exerciceId: string, originPage = OriginPage.LISTCONTROL) {
        switch (originPage) {
            case OriginPage.DASHBOARD:
                this._router.navigate(['/compliance', domain, category, 'project', projectId, exerciceId, 'dashboard']);
                break;
            case OriginPage.GLOBALPARAMS:
                this._router.navigate(['/compliance', domain, category, 'project', projectId, exerciceId, 'globalParams', 'graph']);
                break;
            case OriginPage.LISTCONTROL:
                this._router.navigate(['/compliance', 'control', domain, category, projectId, exerciceId, 'list']);
                break;
        }
    }

    generateControlRoute(projectId: string, domain: DomaineControl, category: CategoryControl, controlId: string, flux: string, exerciceId: string): string {
        return controlId ? `compliance/control/${domain}/${category}/${projectId}/${exerciceId}/${controlId}/${flux}` : `compliance/control/${domain}/${category}/${projectId}/${exerciceId}/${flux}`;
    }

    generateParamsRoute(projectId: string, domain: DomaineControl, category: CategoryControl, stateMachineId: string, flux: string, exerciceId: string): string {
        return `compliance/${domain}/${category}/project/${projectId}/${exerciceId}/globalParams/${stateMachineId}/${flux}`;
    }

    private manageMessageData(notification: NotificationMessage) {
        const corp = notification.corp;
        const warningMessage = corp[WARNING_MESSAGE_FIELD];
        this._complianceLoaderService.sendLoaderHide();
        this._confirmationService.confirm({
            acceptVisible: true,
            acceptLabel: this.getValue('buttons.confirm'),
            rejectVisible: false,
            message: this.getValue(warningMessage.message),
            header: this.getValue(warningMessage.header),
            accept: () => {}
        });
    }
}
