export class Exercice {
    id: string;
    accountId: string;
    annee: string;
    dateDebutExercice: string;
    dateDebut?:Date;
    dateFinExercice: string;
    dateFin?: Date;
    projectId: string;

    // supervision
    observations: string;
    conclusion: string;
    validation: boolean;
}
