import {Component, OnInit} from '@angular/core';
import {Dossier} from "../../../../../../../common/class/dossier/dossier";
import {Exercice} from "../../../../../../../common/class/exercice/exercice";
import {ActivatedRoute, Router} from "@angular/router";
import {DossierService} from "../../../../../../../common/service/dossier/dossier.service";
import {ExerciceService} from "../../../../../../../common/service/exercice/exercice.service";
import {BgSpaceComponent} from "../../../../../../../common/class/background/bg-compliance";

import moment from 'moment';
import {COMPLIANCE_TYPE_EXCEPTION, DATE_FORMAT} from "../../../../../../../common/class/compliance-constant";
import {Location} from "@angular/common";
import {ComplianceToastrService} from "../../../../../../../common/service/toastr/compliance-toastr.service";


@Component({
    selector: 'compliance-exercice-create',
    templateUrl: './exercice-create.component.html',
    styleUrls: ['./exercice-create.component.scss']
})
export class ExerciceCreateComponent extends BgSpaceComponent implements OnInit {

    domain: string;
    category: string;
    private projectId: string;
    project: Dossier;
    private currentYear: string;
    exercice: Exercice = new Exercice();
    datePickerI18n;
    showExerciceAlreaydExist: boolean = false;
    private redirect: string;
    private editExerciceAnnee: string;


    constructor(private route: ActivatedRoute, private router: Router, private location: Location,
                private dossierService: DossierService, private exerciceService: ExerciceService, private _toastr: ComplianceToastrService) {
        super();
    }

    ngOnInit() {
        this.domain = this.route.snapshot.paramMap.get('domain');
        this.category = this.route.snapshot.paramMap.get('category');
        this.projectId = this.route.snapshot.paramMap.get('projectId');
        this.redirect = this.route.snapshot.queryParamMap.get('redirect');
        this.editExerciceAnnee = this.route.snapshot.paramMap.get('exerciceId');
        this.datePickerI18n = super.getTranslation().datePicker;
        this.getProject();
        this.initEditExercice();
    }

    async getProject() {
        this.project = await this.dossierService.findById(this.projectId);
    }

    async initEditExercice() {
        if (this.editExerciceAnnee) {
            this.exercice = await this.exerciceService.findExercicesById(this.editExerciceAnnee);
            this.exercice.dateDebut = moment(this.exercice.dateDebutExercice, "YYYYMMDD").toDate();
            let moment1 = moment(this.exercice.dateFinExercice, "YYYYMMDD");
            this.exercice.dateFin = moment1.toDate();
        }
    }

    async getExercice() {
        this.showExerciceAlreaydExist = false;
        this.exercice = await this.exerciceService.findExercicesByProjectAndYear(this.projectId, this.exercice.annee);
        this.exercice.dateDebut = moment(this.exercice.dateDebutExercice, "YYYYMMDD").toDate();
        let moment1 = moment(this.exercice.dateFinExercice, "YYYYMMDD");
        this.exercice.dateFin = moment1.toDate();
        console.log(this.exercice);
    }

    searchExercice() {
        if (this.exercice.annee && this.exercice.annee.length == 4 && this.exercice.annee.match(/\d{4}/)) {
            this.getExercice();
        }

    }

    goBack() {
        if (this.redirect) {
            this.router.navigate(['/compliance', this.domain, this.category, 'project', 'list']);
        } else {
            this.location.back();
        }
    }

    isExerciceValid() {
        let bool: boolean = !!this.exercice.accountId && !!this.exercice.projectId && !!this.exercice.annee && !!this.exercice.dateDebutExercice && !!this.exercice.dateFinExercice;
        if (bool) {
            bool = bool && !!this.exercice.annee.match(/\d{4}/);
            bool = bool && this.isDatDebutBeforeDateFin();
        }
        return bool;
    }

    isDatDebutBeforeDateFin() {
        if (!this.exercice.dateDebut || !this.exercice.dateFin) {
            return true;
        }
        return moment(this.exercice.dateDebut).isBefore(moment(this.exercice.dateFin));
    }

    saveExercice() {
        this.exerciceService.saveExercice(this.exercice).then(exercice => {
            if (!exercice.projectId) {
                this.showExerciceAlreaydExist = true;
            } else {
                this.router.navigate(['/compliance', this.domain, this.category, 'project', this.projectId, exercice.id, 'dashboard']);
            }

        }).catch(err => {
            if (err.error.type === COMPLIANCE_TYPE_EXCEPTION.FUNCTIONAL) {
                this._toastr.infoI18n("dashboard.exercice.error.validatedExercice");
            }
        });

    }

    async updateExercice() {
        let exercice = await this.exerciceService.updateExercice(this.exercice);
        this.router.navigate(['/compliance', this.domain, this.category, 'project', this.projectId, exercice.id, 'dashboard']);

    }


    setDateDebut() {
        if (this.exercice.dateDebut) {
            this.exercice.dateDebutExercice = moment(this.exercice.dateDebut).format(DATE_FORMAT.YYYYMMDD.moment);
        } else {
            this.exercice.dateDebutExercice = null;
        }
    }

    setDateFin() {
        if (this.exercice.dateFin) {
            this.exercice.dateFinExercice = moment(this.exercice.dateFin).format(DATE_FORMAT.YYYYMMDD.moment);
        } else {
            this.exercice.dateFinExercice = null;
        }
    }
}
