<div class="w-80 mx-auto">
    <div class="block-group-title text-center">
        <h2 class="title big" [innerHtml]="question.title.text | translate | safe:'html'"></h2>
        <h2 class="sub-title" *ngFor="let subtitle of question?.subtitles" [innerHtml]="subtitle?.text | translate | safe:'html'"></h2>
    </div>
    <div class="d-flex flex-wrap justify-content-center pb-footer">
        <div *ngFor="let lot of lots" class=" my-2 ">
            <compliance-periode-lot-year [lot]="lot" (selectPeriodEvent)="activePeriod($event)"></compliance-periode-lot-year>
        </div>
    </div>
</div>

<footer id="footer">
    <div class="inner">
        <a href="#" class="btn btn-primary btn-back" (click)="goBack()">
            <span>{{(isControl ? question.buttons.back.text.label : 'buttons.back') | translate}}</span>
        </a>
        <button class="btn btn-primary btn-next" [disabled]="!nextActive" (click)="goNext()">
            <span>{{(isControl ? question.buttons.next.text.label : 'buttons.finished') | translate}}</span>
        </button>
    </div>
</footer>
